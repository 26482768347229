@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.partial-payment {
    width: min(400px, 95%);
    height: min(400px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        label {
          width: 306px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 8px;
        }

        input {
          width: 306px;
          height: 70px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 2px;
          font-family: Poppins;
          font-size: 40px;
          font-weight: 500;
          line-height: 56px;
          text-align: center;
          color: var(--oliver-blue);
          margin-bottom: 28px;

          &:hover,
          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 12px;
        }

        .payment-types {
          width: 306px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-line-pack: start;
          align-content: flex-start;

          > button {
            width: 94px;
            height: 94px;
            margin: 0 12px 12px 0;
            border: 0;
            border-radius: 8px;
            background-color: var(--oliver-primary);
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #fff;
            overflow: hidden;

            &:nth-child(3n) {
              margin-right: 0;
            }

            > img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.partial-payment {
    width: 504px;
    height: min(536px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        label {
          width: 348px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 9px;
        }

        input {
          width: 348px;
          height: 83px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          font-family: Poppins;
          font-size: 40px;
          font-weight: 400;
          line-height: 56px;
          text-align: center;
          color: var(--oliver-blue);
          margin-bottom: 35px;

          &:hover,
          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 12px;
        }

        .payment-types {
          width: 348px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-line-pack: start;
          align-content: flex-start;

          > button {
            width: 106px;
            height: 106px;
            margin: 0 13px 13px 0;
            border: 0;
            border-radius: 8px;
            background-color: var(--oliver-primary);
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #fff;
            overflow: hidden;

            &:nth-child(3n) {
              margin-right: 0;
            }

            > img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.partial-payment {
    width: 24.6094vw;
    height: min(26.1719vw, 95%);

    > .subwindow-body > {
      label {
        width: 16.9922vw;
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.4395vw;
      }

      input {
        width: 16.9922vw;
        height: 4.0527vw;
        border: 0.0488vw solid var(--oliver-border);
        border-radius: 0.293vw;
        font-size: 1.9531vw;
        line-height: 2.7344vw;
        margin-bottom: 1.709vw;
      }

      p {
        font-size: 0.7813vw;
        line-height: 0.9766vw;
        margin-bottom: 0.5859vw;
      }

      .payment-types {
        width: 16.9922vw;

        > button {
          width: 5.1758vw;
          height: 5.1758vw;
          margin: 0 0.6348vw 0.6348vw 0;
          border-radius: 0.3906vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }
  }
}
