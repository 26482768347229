@media (orientation: portrait) {
  .refund-wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    > {
      .header {
        width: 100%;
        height: 60px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 13px;

        > {
          button#exitCheckoutButton {
            width: 26px;
            height: 26px;
            background-color: transparent;
            border: none;
            margin-right: 17px;

            > img {
              content: url("../assets/images/svg/LeftAngledBracket-Blue.svg");
              width: 100%;
              height: 100%;
            }
          }

          p {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          button {
            &#userInfoButton {
              display: none;
            }

            &#mobileOptionsButton {
              width: 34px;
              height: 34px;
              background-color: #d1ecff;
              border: 0;
              border-radius: 8px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              margin-right: 10px;

              &.filter {
                background-color: var(--oliver-blue);

                > img {
                  -webkit-filter: brightness(0) invert(1);
                  filter: brightness(0) invert(1);
                }
              }

              > img {
                width: 29.79px;
                height: 29.79px;
              }
            }

            &#mobileAppsButton {
              width: 34px;
              height: 34px;
              background-color: #d1ecff;
              border: 0;
              border-radius: 8px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > img {
                width: 18.62px;
                height: 18.46px;
              }
            }
          }
        }
      }

      .page-options-wrapper {
        top: 59px;
      }

      .cart {
        display: none;
      }

      .refund-body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        overflow-y: scroll;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 10px;

        > {
          .balance-container {
            width: 338px;
            border: 1px solid var(--oliver-border);
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 18px 20px;
            background-color: transparent;
            margin-bottom: 32px;

            > .row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
            }

            p {
              &.style1 {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;
                color: var(--oliver-base-blue);
              }

              &.style2 {
                font-size: 22px;
                font-weight: 600;
                line-height: 31px;
                text-align: left;
                color: var(--oliver-blue);
              }

              &.style3 {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                color: var(--oliver-base-grey);
              }

              &.style4 {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: center;
                color: var(--oliver-base-blue);
              }
            }
          }

          button#bottomText {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: var(--oliver-base-grey);
            margin-bottom: 24px;
            width: 100%;
            padding-top: 5px;
            background-color: transparent;
            border: 0;

            &:hover {
              color: var(--oliver-blue);
            }
          }

          p.style2 {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-grey);
            width: 338px;
            margin-bottom: 9px;
          }

          .button-row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 338px;
            margin-bottom: 15px;

            > button {
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              height: 40px;
              margin-left: 13px;
              background-color: transparent;
              border: 1px solid var(--oliver-border);
              border-radius: 8px;

              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              text-align: center;
              color: var(--oliver-base-blue);

              &:disabled {
                background-color: #f8fafc;
                color: #b0bec9;
              }

              &:not(:disabled):hover {
                border-width: 2px;
                border-color: var(--oliver-blue);
                color: var(--oliver-blue);
              }

              &:first-of-type {
                margin-left: 0;
              }
            }

            + p.style2 {
              padding-top: 7px;
            }
          }

          p.style3 {
            display: none;
          }

          .payment-types {
            margin-top: 29px;
            width: 100%;
            background-color: #f1f9ff;
            padding: 20px 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-top: auto;

            > {
              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: var(--oliver-base-blue);
                margin-bottom: 20px;
              }

              .button-container {
                width: 338px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -ms-flex-line-pack: start;
                align-content: flex-start;

                > button {
                  width: 104px;
                  height: 104px;
                  margin: 0 13px 13px 0;
                  border: 0;
                  border-radius: 8px;
                  background-color: transparent;
                  overflow: hidden;

                  font-size: 18px;
                  font-weight: 500;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;

                  &:nth-child(3n) {
                    margin-right: 0;
                  }

                  > img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }

      .navbar,
      .user-info-wrapper,
      .nav-cover {
        display: none;
      }
    }
  }

  /* Refund Header */

  /* Refund Page Options */

  /* Refund Cart */

  /* Refund Body */

  /* Navbar */

  /* User Info Wrapper */

  /* Nav cover */
}

@media (orientation: landscape) {
  .refund-wrapper {
    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 72px 25vw auto;
    grid-template-columns: 72px 25vw auto;
    -ms-grid-rows: 68px auto;
    grid-template-rows: 68px auto;
    grid-template-areas: "navbar header header" "navbar cart refund-body";

    > {
      .navbar {
        grid-area: navbar;
      }

      .header {
        grid-area: header;
      }

      .cart {
        grid-area: cart;
        border-left: 1px solid var(--oliver-border);
        border-top: 1px solid var(--oliver-border);
      }

      .refund-body {
        grid-area: refund-body;
        border-left: 1px solid var(--oliver-border);
        border-top: 1px solid var(--oliver-border);
      }

      .header {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 17px 0 1px;

        > {
          button#exitCheckoutButton {
            width: 38px;
            height: 38px;
            margin-right: 8px;
            background-color: transparent;
            border: 0;

            > img {
              width: 100%;
              height: 100%;
              content: url("../assets/images/svg/Arrow_Left.svg");
            }
          }

          p {
            font-size: 20px;
            font-weight: 600;
            line-height: 29px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          button {
            &#userInfoButton {
              background-color: transparent;
              border: 0;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              overflow: hidden;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;

              > .avatar {
                width: 100%;
                height: 100%;
              }
            }

            &#mobileOptionsButton,
            &#mobileAppsButton {
              display: none;
            }
          }
        }
      }

      .page-options-wrapper > .page-options > button {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > {
          .img-container {
            width: 24px;
            height: 24px;
            margin-right: 5px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }

          p {
            display: block;

            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
          }
        }
      }

      .cart {
        width: 100%;
        height: 100%;
        border-bottom: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        > {
          .body > .cart-item > {
            .main-row > p.quantity {
              color: var(--oliver-base-grey);
            }

            .increment-input {
              width: 130px;
              height: 36px;
              margin-top: 10px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 5px;
              border: 1px solid var(--oliver-border);
              border-radius: 6px;

              > {
                button {
                  height: 100%;
                  aspect-ratio: 1/1;
                  background-color: #ddf1ff;
                  border: 0;
                  border-radius: 6px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  > img {
                    width: 10px;
                    height: 10px;
                  }
                }

                input {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  border: 0;
                  outline: 0;

                  font-size: 16px;
                  font-weight: 500;
                  line-height: 21px;
                  text-align: center;
                  color: var(--oliver-blue);

                  &:hover {
                    cursor: default;
                  }
                }
              }
            }
          }

          .footer > .totals > .row > {
            button {
              border: 0;
              background-color: transparent;

              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              text-align: left;
              color: var(--oliver-blue);

              &:hover {
                text-decoration: underline;
              }
            }

            p.type2 {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              text-align: right;
              color: var(--oliver-base-blue);
              margin-right: 0 !important;

              > b {
                font-weight: 600;
              }
            }
          }

          .body > .cart-item > .main-row > {
            .quantity {
              color: var(--oliver-base-grey);

              &:hover {
                cursor: default;
                text-decoration: none;
              }
            }

            .content-style:hover {
              color: var(--oliver-base-blue-dark) !important;
              cursor: default;
              text-decoration: none;
            }
          }
        }
      }

      .refund-body {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 60px 60px 40px 60px;

        > {
          .balance-container {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            border: 1px solid var(--oliver-border);
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 25px 30px;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin-bottom: 9px;
            background-color: transparent;
            margin-bottom: 30px;

            > .row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              margin-top: 3px;

              &:nth-child(1) {
                margin-bottom: 7px;
                margin-top: 0;
              }

              &:only-child {
                margin-bottom: 0 !important;
              }
            }

            p {
              &.style1 {
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                text-align: left;
                color: var(--oliver-base-blue);
              }

              &.style2 {
                font-size: 30px;
                font-weight: 600;
                line-height: 42px;
                text-align: right;
                color: var(--oliver-blue);
              }

              &.style3 {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;
                color: var(--oliver-base-grey);
              }

              &.style4 {
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                text-align: left;
                color: var(--oliver-base-blue);
              }
            }
          }

          p.style2 {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-bottom: 12px;
          }

          .button-row {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 19px;

            > button {
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              height: 50px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid var(--oliver-border);
              border-radius: 8px;
              margin-left: 20px;
              background-color: transparent;

              font-size: 18px;
              font-weight: 500;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-base-blue);

              &:not(:disabled):hover {
                border-width: 2px;
                border-color: var(--oliver-blue);
              }

              &:first-of-type {
                margin-left: 0;
              }

              &:disabled {
                background-color: #f8fafc;
                color: #b0bec9;
              }
            }

            + p.style2 {
              padding-top: 11px;
            }
          }

          p.style3 {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-grey);
            margin-top: -8px;
            margin: -8px 0 12px 0;
          }

          .payment-types {
            width: 100%;
            margin-top: 30px;

            > {
              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 14px;
              }

              .button-container {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -ms-flex-line-pack: start;
                align-content: flex-start;

                > button {
                  width: calc((100% - 60px) / 4);
                  aspect-ratio: 1.0357/1;
                  margin: 0 20px 20px 0;
                  border: 0;
                  border-radius: 8px;
                  background-color: transparent;

                  font-size: 25px;
                  font-weight: 500;
                  line-height: 33px;
                  text-align: center;
                  color: #fff;
                  padding: 0;
                  overflow: hidden;

                  &:nth-child(4n) {
                    margin-right: 0;
                  }

                  > img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /* Refund Header */

  /* Refund Page Options */

  /* Refund Cart */

  /* Refund Body */
}

@media (orientation: landscape) {
  .navbar {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
  }
  .header {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }
  .cart {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .refund-body {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .refund-wrapper {
    -ms-grid-columns: 3.5156vw 25vw auto;
    grid-template-columns: 3.5156vw 25vw auto;
    -ms-grid-rows: 3.3203vw auto;
    grid-template-rows: 3.3203vw auto;

    > {
      .cart,
      .refund-body {
        border-left: 0.0488vw solid var(--oliver-border);
        border-top: 0.0488vw solid var(--oliver-border);
      }

      .header {
        padding: 0 0.8301vw 0 0.0488vw;

        > {
          button#exitCheckoutButton {
            width: 1.8555vw;
            height: 1.8555vw;
            margin-right: 0.3906vw;
          }

          p {
            font-size: 0.9766vw;
            line-height: 1.416vw;
          }

          button#userInfoButton {
            width: 1.9531vw;
            height: 1.9531vw;
          }
        }
      }

      .page-options-wrapper > .page-options > button > {
        .img-container {
          width: 1.1719vw;
          height: 1.1719vw;
          margin-right: 0.2441vw;
        }

        p {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }
      }

      .cart {
        border-bottom: 0.0488vw solid var(--oliver-border);

        > {
          .body > .cart-item > .increment-input {
            width: 6.3477vw;
            height: 1.7578vw;
            margin-top: 0.4883vw;
            padding: 0.2441vw;
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.293vw;

            > {
              button {
                border-radius: 0.293vw;

                > img {
                  width: 0.4883vw;
                  height: 0.4883vw;
                }
              }

              input {
                font-size: 0.7813vw;
                line-height: 1.0254vw;
              }
            }
          }

          .footer > .totals > .row > {
            button,
            p.type2 {
              font-size: 0.7813vw;
              line-height: 1.0742vw;
            }
          }
        }
      }

      .refund-body {
        padding: 2.9297vw 2.9297vw 1.9531vw 2.9297vw;

        > {
          .balance-container {
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.3906vw;
            padding: 1.2207vw 1.4648vw;
            margin-bottom: 0.4395vw;
            margin-bottom: 1.4648vw;

            > .row {
              margin-top: 0.1465vw;

              &:nth-child(1) {
                margin-bottom: 0.3418vw;
              }
            }

            p {
              &.style1 {
                font-size: 0.9766vw;
                line-height: 1.3672vw;
              }

              &.style2 {
                font-size: 1.4648vw;
                line-height: 2.0508vw;
              }

              &.style3,
              &.style4 {
                font-size: 0.7813vw;
                line-height: 1.0742vw;
              }
            }
          }

          p.style2 {
            font-size: 0.7813vw;
            line-height: 1.1719vw;
            margin-bottom: 0.5859vw;
          }

          .button-row {
            margin-bottom: 0.9277vw;

            > button {
              height: 2.4414vw;
              border: 0.0488vw solid var(--oliver-border);
              border-radius: 0.3906vw;
              margin-left: 0.9766vw;
              font-size: 0.8789vw;
              line-height: 1.1719vw;

              &:not(:disabled):hover {
                border-width: 0.0977vw;
              }
            }

            + p.style2 {
              padding-top: 0.5371vw;
            }
          }

          p.style3 {
            font-size: 0.6836vw;
            line-height: 0.9766vw;
            margin-top: -0.3906vw;
            margin: -0.3906vw 0 0.5859vw 0;
          }

          .payment-types {
            margin-top: 1.4648vw;

            > {
              p {
                font-size: 0.7813vw;
                line-height: 0.9766vw;
                margin-bottom: 0.6836vw;
              }

              .button-container > button {
                width: calc((100% - 2.9297vw) / 4);
                margin: 0 0.9766vw 0.9766vw 0;
                border-radius: 0.3906vw;
                font-size: 1.2207vw;
                line-height: 1.6113vw;
              }
            }
          }
        }
      }
    }
  }
  .refund-wrapper > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .refund-wrapper > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
  .refund-wrapper > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .refund-wrapper > *:nth-child(4) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  .refund-wrapper > *:nth-child(5) {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .refund-wrapper > *:nth-child(6) {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
  }
}

