@media (orientation: portrait) {
  /* No Account */

  .subwindow.no-account {
    width: 100%;
    height: 100%;

    .landscape-only {
      display: none !important;
    }

    > .subwindow-body {
      padding: 40px 0 33px 0;
      background-color: #fff;

      > {
        img {
          display: none;
        }

        .content {
          width: 100%;
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > {
            img.mobile-only {
              width: 136px;
              height: 47px;
              margin-bottom: 19px;
            }

            p {
              &.mobile-only {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                text-align: center;
                color: var(--oliver-base-blue);
                margin-bottom: 20px;
              }

              &.style1 {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 600;
                line-height: 19px;
                text-align: center;
                color: var(--oliver-base-blue);
                margin: auto 0 50px 0;
              }

              &.style2 {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: var(--oliver-base-blue);
                margin-bottom: 50px;
              }
            }

            button {
              &#createAccount {
                width: 246px;
                height: 50px;
                background-color: var(--oliver-blue);
                border: 0;
                border-radius: 6px;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                margin-bottom: auto;
              }

              &#loginTryAgain {
                width: 173px;
                height: 50px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid var(--oliver-blue);
                border-radius: 6px;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: var(--oliver-blue);
                margin: 35px 0;
              }
            }

            .row {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;

              > {
                p {
                  font-family: Poppins;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 21px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                }

                a {
                  font-family: Poppins;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 21px;
                  text-align: center;
                  color: var(--oliver-blue);
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.no-account {
    width: min(800px, 95%);
    height: min(650px, 95%);

    .mobile-only {
      display: none !important;
    }

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      padding: 0;
      background-color: #fff;
      overflow: hidden;

      > {
        img {
          width: auto;
          height: 100%;
        }

        .content {
          width: 0;
          height: 100%;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          overflow: auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 50px 0 30px 0;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          > {
            img {
              width: 45px;
              height: 45px;
              margin-bottom: 40px;
            }

            p {
              &.style1 {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 600;
                line-height: 19px;
                text-align: center;
                color: var(--oliver-base-blue);
                margin-bottom: 40px;
              }

              &.style2 {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: var(--oliver-base-blue);
                margin-bottom: 40px;
              }
            }

            button {
              &#createAccount {
                width: 265px;
                height: 50px;
                background-color: var(--oliver-blue);
                border: 0;
                border-radius: 6px;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                margin-bottom: auto;
              }

              &#loginTryAgain {
                width: 173px;
                height: 50px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid var(--oliver-blue);
                border-radius: 6px;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: var(--oliver-blue);
                margin: 40px 0;
              }
            }

            .row {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              text-align: center;

              > {
                p {
                  color: var(--oliver-base-blue);
                }

                a {
                  font-weight: 600;
                  color: var(--oliver-blue);
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.no-account {
    width: min(39.0625vw, 95%);
    height: min(31.7383vw, 95%);

    > .subwindow-body > .content {
      padding: 2.4414vw 0 1.4648vw 0;

      > {
        img {
          width: 2.1973vw;
          height: 2.1973vw;
          margin-bottom: 1.9531vw;
        }

        p {
          &.style1 {
            font-size: 0.7813vw;
            line-height: 0.9277vw;
            margin-bottom: 1.9531vw;
          }

          &.style2 {
            font-size: 0.6836vw;
            line-height: 0.9766vw;
            margin-bottom: 1.9531vw;
          }
        }

        button {
          &#createAccount {
            width: 12.9395vw;
            height: 2.4414vw;
            border-radius: 0.293vw;
            font-size: 0.8789vw;
            line-height: 1.1719vw;
          }

          &#loginTryAgain {
            width: 8.4473vw;
            height: 2.4414vw;
            border: 0.0488vw solid var(--oliver-blue);
            border-radius: 0.293vw;
            font-size: 0.8789vw;
            line-height: 1.1719vw;
            margin: 1.9531vw 0;
          }
        }

        .row {
          font-size: 0.7813vw;
          line-height: 1.0254vw;

          > a {
            margin-left: 0.1953vw;
          }
        }
      }
    }
  }
}
