@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.quick-tax {
    width: min(300px, 95%);

    > {
      .subwindow-header {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 55px;
        padding: 0 11px 0 22px;
        min-height: 0;

        > {
          P {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
            color: #243249;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            width: 0;
            max-width: -webkit-max-content;
            max-width: -moz-max-content;
            max-width: max-content;
          }

          button {
            border: 0;
            background-color: transparent;
            padding: 11px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-blue);
            text-decoration: underline;
          }
        }
      }

      .subwindow-body {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 22px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > .row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-top: 8px;

          &:nth-child(1) {
            margin-top: 0;
          }

          > {
            p {
              width: 0;
              -webkit-box-flex: 1;
              -ms-flex-positive: 1;
              flex-grow: 1;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            label {
              &:hover {
                cursor: pointer;
              }

              > {
                input {
                  opacity: 0;
                  position: fixed;
                  left: -10000px;
                }

                .custom-toggle {
                  position: relative;
                  width: 40px;
                  height: 25px;
                  background-color: #b0bec9;
                  border-radius: 25px;

                  > .knob {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    position: absolute;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    background-color: #fff;
                    top: calc(50% - 11px);
                    left: 1.5px;
                    border: 0.392157px solid rgba(0, 0, 0, 0.04);
                    -webkit-box-shadow: 0px 2.35294px 6.27451px
                        rgba(0, 0, 0, 0.15),
                      0px 2.35294px 0.784314px rgba(0, 0, 0, 0.06);
                    box-shadow: 0px 2.35294px 6.27451px rgba(0, 0, 0, 0.15),
                      0px 2.35294px 0.784314px rgba(0, 0, 0, 0.06);
                  }
                }

                input:checked ~ .custom-toggle {
                  background-color: var(--oliver-blue);

                  > .knob {
                    left: auto;
                    right: 1.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.quick-tax {
    right: 50px;
    bottom: 50px;
    width: 300px;

    > {
      .subwindow-header {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 55px;
        padding: 0 11px 0 22px;
        min-height: 0;

        > {
          P {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
            color: #243249;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            width: 0;
            max-width: -webkit-max-content;
            max-width: -moz-max-content;
            max-width: max-content;
          }

          button {
            border: 0;
            background-color: transparent;
            padding: 11px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-blue);
            text-decoration: underline;
          }
        }
      }

      .subwindow-body {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 22px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > .row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-top: 8px;

          &:nth-child(1) {
            margin-top: 0;
          }

          > {
            p {
              width: 0;
              -webkit-box-flex: 1;
              -ms-flex-positive: 1;
              flex-grow: 1;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            label {
              &:hover {
                cursor: pointer;
              }

              > {
                input {
                  opacity: 0;
                  position: fixed;
                  left: -10000px;
                }

                .custom-toggle {
                  position: relative;
                  width: 40px;
                  height: 25px;
                  background-color: #b0bec9;
                  border-radius: 25px;
                  line-height: 0;

                  > .knob {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    position: absolute;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    background-color: #fff;
                    top: calc(50% - 11px);
                    left: 1.5px;
                    border: 0.392157px solid rgba(0, 0, 0, 0.04);
                    -webkit-box-shadow: 0px 2.35294px 6.27451px
                        rgba(0, 0, 0, 0.15),
                      0px 2.35294px 0.784314px rgba(0, 0, 0, 0.06);
                    box-shadow: 0px 2.35294px 6.27451px rgba(0, 0, 0, 0.15),
                      0px 2.35294px 0.784314px rgba(0, 0, 0, 0.06);
                  }
                }

                input:checked ~ .custom-toggle {
                  background-color: var(--oliver-blue);

                  > .knob {
                    left: auto;
                    right: 1.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.quick-tax {
    right: 2.4414vw;
    bottom: 2.4414vw;
    width: 14.6484vw;

    > {
      .subwindow-header {
        height: 2.6855vw;
        padding: 0 0.5371vw 0 1.0742vw;

        > {
          P {
            font-size: 0.7813vw;
            line-height: 1.0742vw;
          }

          button {
            padding: 0.5371vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;
          }
        }
      }

      .subwindow-body {
        padding: 1.0742vw;

        > .row {
          margin-top: 0.3906vw;

          > {
            p {
              font-size: 0.7813vw;
              line-height: 1.0742vw;
            }

            label > {
              input {
                left: -488.2813vw;
              }

              .custom-toggle {
                width: 1.9531vw;
                height: 1.2207vw;
                border-radius: 1.2207vw;

                > .knob {
                  width: 1.0742vw;
                  height: 1.0742vw;
                  top: calc(50% - 0.5371vw);
                  left: 0.0732vw;
                  border: 0.0191vw solid rgba(0, 0, 0, 0.04);
                  -webkit-box-shadow: 0vw 0.1149vw 0.3064vw rgba(0, 0, 0, 0.15),
                    0vw 0.1149vw 0.0383vw rgba(0, 0, 0, 0.06);
                  box-shadow: 0vw 0.1149vw 0.3064vw rgba(0, 0, 0, 0.15),
                    0vw 0.1149vw 0.0383vw rgba(0, 0, 0, 0.06);
                }
              }

              input:checked ~ .custom-toggle > .knob {
                right: 0.0732vw;
              }
            }
          }
        }
      }
    }
  }
}
