@media (orientation: portrait) {
  /* No Customer */

  .subwindow-wrapper > .subwindow.no-customer {
    width: min(384px, 95%);
    height: min(384px, 95%);

    > .subwindow-body {
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p.style1 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 25px;
        }

        img {
          width: 52px;
          height: 52px;
          margin-bottom: 29px;
        }

        p.style2 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 36px;
        }

        button {
          width: 306px;
          height: 50px;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;

          &:first-of-type {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid var(--oliver-blue);
            background-color: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            color: var(--oliver-blue);
            margin-bottom: 11px;

            > img {
              width: 20px;
              height: 20px;
              margin-right: 12px;
            }
          }

          &:nth-last-child(2) {
            background-color: var(--oliver-blue);
            border: 0;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  /* No Customer */

  .subwindow-wrapper > .subwindow.no-customer {
    width: 504px;
    height: min(430px, 95%);

    /* border-radius: 8px; */

    > .subwindow-body {
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          &.style1 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 23px;
          }

          &.style2 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 41px;
          }
        }

        img {
          width: 59px;
          height: 59px;
          margin-bottom: 24px;
        }

        button {
          width: 265px;
          height: 50px;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;

          &:first-of-type {
            background-color: #fff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid var(--oliver-blue);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            color: var(--oliver-blue);
            margin-bottom: 17px;

            > img {
              width: 20px;
              height: 20px;
              margin-right: 12px;
            }
          }

          &:nth-last-child(2) {
            background-color: var(--oliver-blue);
            border: 0;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.no-customer {
    width: 24.6094vw;
    height: min(20.9961vw, 95%);

    > .subwindow-body {
      border-radius: 0.3906vw;

      > {
        p {
          &.style1 {
            font-size: 0.8789vw;
            line-height: 1.1719vw;
            margin-bottom: 1.123vw;
          }

          &.style2 {
            font-size: 0.7813vw;
            line-height: 1.0254vw;
            margin-bottom: 2.002vw;
          }
        }

        img {
          width: 2.8809vw;
          height: 2.8809vw;
          margin-bottom: 1.1719vw;
        }

        button {
          width: 12.9395vw;
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;

          &:first-of-type {
            border: 0.0488vw solid var(--oliver-blue);
            margin-bottom: 0.8301vw;

            > img {
              width: 0.9766vw;
              height: 0.9766vw;
              margin-right: 0.5859vw;
            }
          }
        }
      }
    }
  }
}
