@media (orientation: portrait) {
  .customer-view-wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    > {
      .navbar {
        top: 60px;
        width: 100%;
        height: calc(100% - 60px);
        padding-bottom: 10px;
      }

      .mobile-cv-header {
        width: 100%;
        height: 60px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 16px 0 8px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
      }

      .cv-search {
        position: fixed;
        top: 60px;
        width: 100%;
        height: calc(100% - 60px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        z-index: 3;
        background-color: #fff;
        overflow-y: scroll;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 16px;
        border-top: 1px solid var(--oliver-border);
        -webkit-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        -o-transition: transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;

        &.open {
          -webkit-transform: translateX(0);
              -ms-transform: translateX(0);
                  transform: translateX(0);
        }

        > {
          .header {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;

            > {
              p:not(.mobile-only), #cvAddCustomer {
                display: none;
              }

              #mobileCVExitSearch {
                background-color: transparent;
                border: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                padding: 0 4px;
                
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: var(--oliver-blue);
                margin-bottom: 20px;

                > img {
                  width: 9px;
                  height: 16px;
                  margin-right: 8px;
                }
              }
            }
          }

          .body {
            width: 100%;
            height: 0;
            -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                    flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;

            > {
              .row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                margin-bottom: 11px;

                > {
                  img {
                    display: none;
                  }

                  p {
                    
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 23px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-right: auto;

                    &::after {
                      content: " for Customer";
                    }
                  }

                  button {
                    padding: 5px;
                    
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 23px;
                    text-align: left;
                    color: var(--oliver-blue);
                    text-decoration: underline;
                    background-color: transparent;
                    border: 0;
                  }
                }
              }

              label {
                width: 100%;
                
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 4px;
              }

              input {
                width: 100%;
                height: 44px;
                -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                border: 1px solid var(--oliver-border);
                outline: 0;
                border-radius: 6px;
                margin-bottom: 20px;
                padding: 0 12px;
                
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);

                &:last-of-type {
                  margin-bottom: auto;
                }
              }

              button {
                width: 100%;
                height: 54px;
                background-color: var(--oliver-blue);
                border: 0;
                border-radius: 6px;
                
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                margin-top: 20px;
              }
            }
          }
        }
      }

      .cv-detailed {
        position: fixed;
        top: 60px;
        width: 100%;
        height: calc(100% - 60px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        z-index: 3;
        background-color: #fff;
        overflow-y: scroll;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        -webkit-transform: translateX(100%);
            -ms-transform: translateX(100%);
                transform: translateX(100%);
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        -o-transition: transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;

        > .no-search-results-detailed {
          display: none;
        }

        &.open {
          -webkit-transform: translateX(0);
              -ms-transform: translateX(0);
                  transform: translateX(0);
        }
      }
    }
  }

  /* Navbar Settings	 */

  /* Mobile Header */

  /* Customer View Detailed */
}

@media (orientation: landscape) {
  .customer-view-wrapper {
    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 72px max(328px, 25vw) max(328px, 25vw) 1fr;
    grid-template-columns: 72px max(328px, 25vw) max(328px, 25vw) 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
        grid-template-areas: "navbar cv-search cv-list cv-detailed";

    > {
      .navbar {
        grid-area: navbar;
      }

      .cv-search {
        grid-area: cv-search;
        border-left: 1px solid var(--oliver-border);
      }

      .cv-list {
        grid-area: cv-list;
        border-left: 1px solid var(--oliver-border);
      }

      .cv-detailed {
        grid-area: cv-detailed;
        border-left: 1px solid var(--oliver-border);
      }

      .mobile-cv-header {
        display: none;
      }
    }
  }
}

@media (orientation: landscape) {
  .navbar {
    -ms-grid-row: 1;
    -ms-grid-column: 1
  }
  .cv-search {
    -ms-grid-row: 1;
    -ms-grid-column: 2
  }
  .cv-list {
    -ms-grid-row: 1;
    -ms-grid-column: 3
  }
  .cv-detailed {
    -ms-grid-row: 1;
    -ms-grid-column: 4
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .customer-view-wrapper {
    -ms-grid-columns: 3.5156vw max(16.0156vw, 25vw) max(16.0156vw, 25vw) 1fr;
    grid-template-columns: 3.5156vw max(16.0156vw, 25vw) max(16.0156vw, 25vw) 1fr;

    > {
      .cv-search, .cv-list, .cv-detailed {
        border-left: 0.0488vw solid var(--oliver-border);
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .customer-view-wrapper {
      -ms-grid-columns: 72px max(27.3333vw, 25vw) max(27.3333vw, 25vw) 1fr;
      grid-template-columns: 72px max(27.3333vw, 25vw) max(27.3333vw, 25vw) 1fr;

    > {
      .cv-search, .cv-list, .cv-detailed {
        border-left: 0.0833vw solid var(--oliver-border);
      }
    }
  }
}