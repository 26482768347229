@media (orientation: portrait) {
  .subwindow.delete-customer-confirm {
    width: min(344px, 95%);
    height: min(292px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 34px;
        }

        button {
          width: 133px;
          height: 40px;
          border-radius: 6px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 24px;
          text-align: center;

          &#deleteCustomer {
            background-color: var(--oliver-red);
            border: 0;
            color: #fff;
            margin-bottom: 14px;
          }

          &#cancelDeleteCustomer {
            border: 1px solid var(--oliver-blue);
            background-color: #fff;
            color: var(--oliver-blue);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow.delete-customer-confirm {
    width: min(504px, 95%);
    height: min(295px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 26px;

          > br {
            display: none;
          }
        }

        button {
          width: 170px;
          height: 48px;
          border-radius: 6px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;

          &#deleteCustomer {
            background-color: var(--oliver-red);
            border: 0;
            color: #fff;
            margin-bottom: 17px;
          }

          &#cancelDeleteCustomer {
            border: 1px solid var(--oliver-blue);
            background-color: #fff;
            color: var(--oliver-blue);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow.delete-customer-confirm {
    width: min(24.6094vw, 95%);
    height: min(14.4043vw, 95%);

    > .subwindow-body > {
      p {
        font-size: 0.7813vw;
        line-height: 1.0254vw;
        margin-bottom: 1.2695vw;
      }

      button {
        width: 8.3008vw;
        height: 2.3438vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;

        &#deleteCustomer {
          margin-bottom: 0.8301vw;
        }

        &#cancelDeleteCustomer {
          border: 0.0488vw solid var(--oliver-blue);
        }
      }
    }
  }
}
