@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.link-launcher-subwindow {
    width: 100%;
    height: 100%;
    border-radius: 0;

    > {
      .subwindow-header {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        > img {
          margin: 0 14px 0 16px;
        }
      }

      .subwindow-body {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        border-radius: 0;
        padding: 0;

        > {
          .link-header {
            width: 100%;
            height: 74px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid var(--oliver-border);
            padding: 0 16px;

            > {
              .link-wrapper {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                > {
                  img {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    border-radius: 6px;
                    margin-right: 16px;
                  }

                  .text-col {
                    width: 0;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;

                    > p {
                      max-width: 95%;
                      overflow: hidden;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                      text-overflow: ellipsis;

                      &.style1 {
                        font-family: Poppins;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        text-align: left;
                        color: var(--oliver-base-blue);
                      }

                      &.style2 {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        text-align: left;
                        color: var(--oliver-base-grey);
                      }
                    }
                  }
                }
              }

              button {
                width: 128px;
                height: 32px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid var(--oliver-base-grey);
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-family: Poppins;
                font-size: 13px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
                color: var(--oliver-base-grey);

                > img {
                  width: 13px;
                  height: 13px;
                  margin-right: 6px;
                }
              }
            }
          }

          iframe {
            width: 100%;
            height: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.link-launcher-subwindow {
    width: 1000px;
    height: min(95%, 794px);

    > {
      .subwindow-header {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        > img {
          margin: 0 14px 0 16px;
        }
      }

      .subwindow-body {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0;

        > {
          .link-header {
            width: 100%;
            height: 74px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid var(--oliver-border);
            padding: 0 16px;

            > {
              .link-wrapper {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                > {
                  img {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    border-radius: 6px;
                    margin-right: 16px;
                  }

                  .text-col {
                    width: 0;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;

                    > p {
                      max-width: 95%;
                      overflow: hidden;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                      text-overflow: ellipsis;

                      &.style1 {
                        font-family: Poppins;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        text-align: left;
                        color: var(--oliver-base-blue);
                      }

                      &.style2 {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        text-align: left;
                        color: var(--oliver-base-grey);
                      }
                    }
                  }
                }
              }

              button {
                width: 128px;
                height: 32px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid var(--oliver-base-grey);
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-family: Poppins;
                font-size: 13px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
                color: var(--oliver-base-grey);

                > img {
                  width: 13px;
                  height: 13px;
                  margin-right: 6px;
                }
              }
            }
          }

          iframe {
            width: 100%;
            height: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.link-launcher-subwindow {
    width: 48.8281vw;
    height: min(95%, 38.7695vw);

    > {
      .subwindow-header > img {
        margin: 0 0.6836vw 0 0.7813vw;
      }

      .subwindow-body > .link-header {
        height: 3.6133vw;
        border-bottom: 0.0488vw solid var(--oliver-border);
        padding: 0 0.7813vw;

        > {
          .link-wrapper > {
            img {
              width: 1.9531vw;
              height: 1.9531vw;
              border-radius: 0.293vw;
              margin-right: 0.7813vw;
            }

            .text-col > p {
              &.style1 {
                font-size: 0.7813vw;
                line-height: 1.1719vw;
              }

              &.style2 {
                font-size: 0.5859vw;
                line-height: 0.8301vw;
              }
            }
          }

          button {
            width: 6.25vw;
            height: 1.5625vw;
            border: 0.0488vw solid var(--oliver-base-grey);
            border-radius: 0.293vw;
            font-size: 0.6348vw;
            line-height: 0.8301vw;

            > img {
              width: 0.6348vw;
              height: 0.6348vw;
              margin-right: 0.293vw;
            }
          }
        }
      }
    }
  }
}
