@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.remove-cash {
    width: min(384px, 95%);
    height: min(445px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        .text-row {
          width: 309px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 17px;

          > p {
            &.style1 {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            &.style2 {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              text-align: left;
              color: var(--oliver-blue);
            }
          }
        }

        .input-row {
          width: 309px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding-bottom: 26px;
          border-bottom: 1px solid var(--oliver-border);
          margin-bottom: 20px;

          > {
            label {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            input {
              width: 174px;
              height: 50px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid var(--oliver-border);
              border-radius: 6px;
              padding: 0 12px;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: right;
              color: var(--oliver-base-blue);

              &:focus {
                border-color: var(--oliver-blue);
              }
            }
          }
        }

        label {
          width: 309px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 12px;
        }

        textarea {
          width: 306px;
          height: 96px;
          outline: 0;
          resize: none;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          padding: 12px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 23px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        button {
          width: 306px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.remove-cash {
    width: 504px;
    height: min(534px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        .text-row {
          width: 427px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding-bottom: 21px;
          border-bottom: 1px solid var(--oliver-border);
          margin-bottom: 31px;

          > p {
            &.style1 {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            &.style2 {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              text-align: right;
              color: var(--oliver-blue);
            }
          }
        }

        .input-row {
          width: 427px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 17px;

          > {
            label {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            input {
              width: 241px;
              height: 50px;
              outline: 0;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid var(--oliver-border);
              border-radius: 6px;
              padding: 0 12px;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-blue);

              &:focus {
                border-color: var(--oliver-blue);
              }
            }
          }
        }

        label {
          width: 427px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 6px;
        }

        textarea {
          width: 427px;
          height: 144px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          outline: 0;
          resize: none;
          padding: 12px;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 23px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        button {
          width: 427px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.remove-cash {
    width: 24.6094vw;
    height: min(26.0742vw, 95%);

    > .subwindow-body > {
      .text-row {
        width: 20.8496vw;
        padding-bottom: 1.0254vw;
        border-bottom: 0.0488vw solid var(--oliver-border);
        margin-bottom: 1.5137vw;

        > p {
          &.style1 {
            font-size: 0.7813vw;
            line-height: 1.1719vw;
          }

          &.style2 {
            font-size: 0.9766vw;
            line-height: 1.4648vw;
          }
        }
      }

      .input-row {
        width: 20.8496vw;
        margin-bottom: 0.8301vw;

        > {
          label {
            font-size: 0.7813vw;
            line-height: 1.1719vw;
          }

          input {
            width: 11.7676vw;
            height: 2.4414vw;
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.293vw;
            padding: 0 0.5859vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;
          }
        }
      }

      label {
        width: 20.8496vw;
        font-size: 0.7813vw;
        line-height: 1.1719vw;
        margin-bottom: 0.293vw;
      }

      textarea {
        width: 20.8496vw;
        height: 7.0313vw;
        padding: 0.5859vw;
        border: 0.0488vw solid var(--oliver-border);
        border-radius: 0.293vw;
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 1.123vw;
      }

      button {
        width: 20.8496vw;
        height: 2.4414vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
