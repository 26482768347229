@media (orientation: portrait) {
  /* Edit Customer */

  .subwindow.edit-customer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0;

    > .subwindow-body {
      border: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      height: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding: 20px;
      display: block;
      overflow-y: scroll;
      border-radius: 0;

      > {
        div {
          &:not(.button-row) {
            width: 100%;
            border-top: 1px solid var(--oliver-border);
            padding-top: 15px;
            margin-top: 16px;
          }

          &:first-of-type {
            border-top: 0 !important;
            padding-top: 0 !important;
            margin-top: 0 !important;

            > .input-row:last-child > .input-col:last-child {
              margin-top: 0 !important;
            }
          }

          > {
            p {
              font-family: Poppins;
              font-size: 15px;
              font-weight: 600;
              line-height: 23px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            .input-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              margin-top: 16px;

              > .input-col {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-top: 16px;
                position: relative;

                &:nth-child(1) {
                  margin-top: 0 !important;
                }

                > label {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  padding-bottom: 4px;
                  z-index: 2;

                  &:hover {
                    cursor: pointer;
                  }
                }

                input {
                  height: 42px;
                  border: 1px solid var(--oliver-border);
                  border-radius: 6px;
                  padding: 0 12px;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);

                  &:hover {
                    border-color: var(--oliver-blue);
                  }

                  &:focus {
                    border-width: 2px;
                    border-color: var(--oliver-blue);
                  }

                  &.error {
                    border-color: var(--error);

                    ~ .error-wrapper {
                      position: absolute;
                      top: 0;
                      right: 0;

                      &::after {
                        content: "*Error";
                        font-family: Poppins;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 19px;
                        text-align: left;
                        color: var(--error);
                      }
                    }

                    &.invalid-input ~ .error-wrapper::after {
                      content: "*Invalid Input";
                    }

                    &.required ~ .error-wrapper::after {
                      content: "*Required";
                    }
                  }
                }

                > .dropdown-wrapper {
                  width: 100%;
                  height: 42px;
                  position: relative;
                  background-color: #fff;
                  line-height: 0;

                  &.open {
                    z-index: 3;

                    > input {
                      border-color: var(--oliver-blue);
                      border-radius: 6px 6px 0 0;
                    }

                    > img {
                      -webkit-transform: rotate(180deg) translateY(-2px);
                      -ms-transform: rotate(180deg) translateY(-2px);
                      transform: rotate(180deg) translateY(-2px);
                    }

                    > .option-container {
                      display: block;
                    }
                  }

                  > input {
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    position: relative;
                    background-color: transparent;

                    &:focus {
                      border-width: 1px;
                    }

                    &:hover {
                      cursor: pointer;
                    }
                  }

                  > img {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    right: 12px;
                    top: calc(50% - 10px);
                    z-index: 1;
                  }

                  > .option-container {
                    display: none;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 100%;
                    background-color: #fff;
                    max-height: 400%;
                    border: 1px solid var(--oliver-blue);
                    border-top: 0;
                    border-radius: 0 0 6px 6px;
                    overflow: auto;
                  }

                  > .option-container > .option {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 100%;
                    height: 42px;
                    padding: 0 12px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    border-radius: 0 0 6px 6px;
                    border-top: 1px solid var(--oliver-blue);

                    &:nth-child(1) {
                      border-top: 0 !important;
                    }

                    > p {
                      width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                      text-overflow: ellipsis;
                      font-family: Poppins;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }

                    &:hover {
                      cursor: pointer;

                      > p {
                        color: var(--oliver-blue);
                      }
                    }
                  }
                }
              }
            }

            .title-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;

              > {
                p {
                  font-family: Poppins;
                  font-size: 15px;
                  font-weight: 600;
                  line-height: 23px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  margin-right: auto;
                }

                label {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  font-family: Poppins;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: left;
                  color: var(--oliver-base-blue);

                  &:hover {
                    cursor: pointer;
                  }

                  > {
                    input {
                      position: fixed;
                      left: -10000px;
                      opacity: 0;
                    }

                    .custom-checkbox {
                      width: 18px;
                      height: 18px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      border: 1px solid var(--oliver-border);
                      border-radius: 4px;
                      margin-right: 12px;

                      > img {
                        width: 10px;
                        height: 10px;
                        display: none;
                      }
                    }

                    input:checked ~ .custom-checkbox {
                      border-color: var(--oliver-blue);

                      > img {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .button-row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          margin-top: 30px;

          > button {
            width: 100%;
            height: 44px;
            margin-top: 16px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;

            &:first-of-type {
              margin-top: 0 !important;
            }

            &#editCustomerDelete {
              background-color: #fff;
              color: var(--error);
              border: 1px solid var(--error);
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > img {
                width: 20px;
                height: 21px;
                margin-right: 10px;
              }

              &.disabled {
                border-color: var(--oliver-new-grey);
                color: var(--oliver-new-grey);

                > img {
                  -webkit-filter: brightness(0) saturate(100%) invert(78%)
                    sepia(14%) saturate(234%) hue-rotate(164deg) brightness(95%)
                    contrast(92%);
                  filter: brightness(0) saturate(100%) invert(78%) sepia(14%)
                    saturate(234%) hue-rotate(164deg) brightness(95%)
                    contrast(92%);
                }
              }
            }
          }
        }

        div > .input-row.hidden {
          display: none;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  /* Edit Customer */

  .subwindow.edit-customer {
    width: 744px;
    height: min(95%, 1090px);
    overflow: hidden;

    > .subwindow-body {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      height: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding: 20px 68px;
      display: block;
      overflow-y: scroll;

      > {
        div {
          &:not(.button-row) {
            width: 100%;
            border-top: 1px solid var(--oliver-border);
            padding-top: 15px;
            margin-top: 16px;
          }

          &:first-of-type {
            border-top: 0 !important;
            padding-top: 0 !important;
            margin-top: 0 !important;
          }

          > {
            p {
              font-family: Poppins;
              font-size: 15px;
              font-weight: 600;
              line-height: 23px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            .input-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              margin-top: 16px;

              > .input-col {
                width: 0;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-left: 14px;
                position: relative;

                &:nth-child(1) {
                  margin-left: 0 !important;
                }

                > label {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  padding-bottom: 4px;
                  z-index: 2;

                  &:hover {
                    cursor: pointer;
                  }
                }

                input {
                  height: 42px;
                  border: 1px solid var(--oliver-border);
                  border-radius: 6px;
                  padding: 0 12px;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);

                  &:hover {
                    border-color: var(--oliver-blue);
                  }

                  &:focus {
                    border-width: 2px;
                    border-color: var(--oliver-blue);
                  }

                  &.error {
                    border-color: var(--error);

                    ~ .error-wrapper {
                      position: absolute;
                      top: 0;
                      right: 0;

                      &::after {
                        content: "*Error";
                        font-family: Poppins;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 19px;
                        text-align: left;
                        color: var(--error);
                      }
                    }

                    &.invalid-input ~ .error-wrapper::after {
                      content: "*Invalid Input";
                    }

                    &.required ~ .error-wrapper::after {
                      content: "*Required";
                    }
                  }
                }

                > .dropdown-wrapper {
                  width: 100%;
                  height: 42px;
                  position: relative;
                  background-color: #fff;

                  &.open {
                    z-index: 3;
                    -webkit-box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.1);
                    box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.1);

                    > input {
                      border-color: var(--oliver-blue);
                      border-radius: 6px 6px 0 0;
                    }

                    > img {
                      -webkit-transform: rotate(180deg) translateY(-2px);
                      -ms-transform: rotate(180deg) translateY(-2px);
                      transform: rotate(180deg) translateY(-2px);
                    }

                    > .option-container {
                      display: block;
                    }
                  }

                  > input {
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    position: relative;
                    background-color: transparent;
                    padding-right: 40px;

                    &:focus {
                      border-width: 1px;
                    }

                    &:hover {
                      cursor: pointer;
                    }
                  }

                  > img {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    right: 12px;
                    top: calc(50% - 10px);
                    z-index: 1;
                  }

                  > .option-container {
                    display: none;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 100%;
                    background-color: #fff;
                    max-height: 400%;
                    border: 1px solid var(--oliver-blue);
                    border-top: 0;
                    border-radius: 0 0 6px 6px;
                    overflow: auto;
                  }

                  > .option-container > .option {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 100%;
                    height: 42px;
                    padding: 0 12px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    border-radius: 0 0 6px 6px;
                    border-top: 1px solid var(--oliver-blue);

                    &:nth-child(1) {
                      border-top: 0 !important;
                    }

                    > p {
                      width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                      text-overflow: ellipsis;
                      font-family: Poppins;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }

                    &:hover {
                      cursor: pointer;

                      > p {
                        color: var(--oliver-blue);
                      }
                    }
                  }
                }
              }
            }

            .title-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;

              > {
                p {
                  font-family: Poppins;
                  font-size: 15px;
                  font-weight: 600;
                  line-height: 23px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  margin-right: auto;
                }

                label {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  font-family: Poppins;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: left;
                  color: var(--oliver-base-blue);

                  &:hover {
                    cursor: pointer;
                  }

                  > {
                    input {
                      position: fixed;
                      left: -10000px;
                      opacity: 0;
                    }

                    .custom-checkbox {
                      width: 18px;
                      height: 18px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      border: 1px solid var(--oliver-border);
                      border-radius: 4px;
                      margin-right: 12px;

                      > img {
                        width: 10px;
                        height: 10px;
                        display: none;
                      }
                    }

                    input:checked ~ .custom-checkbox {
                      border-color: var(--oliver-blue);

                      > img {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .button-row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-top: 30px;

          > button {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            margin-left: 14px;
            height: 44px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;

            &:first-of-type {
              margin-left: 0 !important;
            }

            &#editCustomerDelete {
              background-color: #fff;
              color: var(--error);
              border: 1px solid var(--error);
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > img {
                width: 20px;
                height: 21px;
                margin-right: 10px;
              }

              &.disabled {
                border-color: var(--oliver-new-grey);
                color: var(--oliver-new-grey);

                > img {
                  -webkit-filter: brightness(0) saturate(100%) invert(78%)
                    sepia(14%) saturate(234%) hue-rotate(164deg) brightness(95%)
                    contrast(92%);
                  filter: brightness(0) saturate(100%) invert(78%) sepia(14%)
                    saturate(234%) hue-rotate(164deg) brightness(95%)
                    contrast(92%);
                }
              }
            }
          }
        }

        div > .input-row.hidden {
          display: none;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow.edit-customer {
    width: 36.3281vw;
    height: min(95%, 53.2227vw);

    > .subwindow-body {
      padding: 0.9766vw 3.3203vw;

      > {
        div {
          &:not(.button-row) {
            border-top: 0.0488vw solid var(--oliver-border);
            padding-top: 0.7324vw;
            margin-top: 0.7813vw;
          }

          > {
            p {
              font-size: 0.7324vw;
              line-height: 1.123vw;
            }

            .input-row {
              margin-top: 0.7813vw;

              > .input-col {
                margin-left: 0.6836vw;

                > label {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                  padding-bottom: 0.1953vw;
                }

                input {
                  height: 2.0508vw;
                  border: 0.0488vw solid var(--oliver-border);
                  border-radius: 0.293vw;
                  padding: 0 0.5859vw;
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;

                  &:focus {
                    border-width: 0.0977vw;
                  }

                  &.error ~ .error-wrapper::after {
                    font-size: 0.6348vw;
                    line-height: 0.9277vw;
                  }
                }

                > .dropdown-wrapper {
                  height: 2.0508vw;

                  &.open {
                    -webkit-box-shadow: 0vw 0.4883vw 0.9766vw
                      rgba(119, 135, 147, 0.1);
                    box-shadow: 0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.1);

                    > input {
                      border-radius: 0.293vw 0.293vw 0 0;
                    }

                    > img {
                      -webkit-transform: rotate(180deg) translateY(-0.0977vw);
                      -ms-transform: rotate(180deg) translateY(-0.0977vw);
                      transform: rotate(180deg) translateY(-0.0977vw);
                    }
                  }

                  > input {
                    padding-right: 1.9531vw;

                    &:focus {
                      border-width: 0.0488vw;
                    }
                  }

                  > img {
                    width: 0.9766vw;
                    height: 0.9766vw;
                    right: 0.5859vw;
                    top: calc(50% - 0.4883vw);
                  }

                  > .option-container {
                    border: 0.0488vw solid var(--oliver-blue);
                    border-radius: 0 0 0.293vw 0.293vw;

                    > .option {
                      height: 2.0508vw;
                      padding: 0 0.5859vw;
                      border-radius: 0 0 0.293vw 0.293vw;
                      border-top: 0.0488vw solid var(--oliver-blue);

                      > p {
                        font-size: 0.6836vw;
                        line-height: 0.9766vw;
                      }
                    }
                  }
                }
              }
            }

            .title-row > {
              p {
                font-size: 0.7324vw;
                line-height: 1.123vw;
              }

              label {
                font-size: 0.7813vw;
                line-height: 1.1719vw;

                > {
                  input {
                    left: -488.2813vw;
                  }

                  .custom-checkbox {
                    width: 0.8789vw;
                    height: 0.8789vw;
                    border: 0.0488vw solid var(--oliver-border);
                    border-radius: 0.1953vw;
                    margin-right: 0.5859vw;

                    > img {
                      width: 0.4883vw;
                      height: 0.4883vw;
                    }
                  }
                }
              }
            }
          }
        }

        .button-row {
          margin-top: 1.4648vw;

          > button {
            margin-left: 0.6836vw;
            height: 2.1484vw;
            border-radius: 0.293vw;
            font-size: 0.8789vw;
            line-height: 1.1719vw;

            &#editCustomerDelete {
              border: 0.0488vw solid var(--error);

              > img {
                width: 0.9766vw;
                height: 1.0254vw;
                margin-right: 0.4883vw;
              }
            }
          }
        }
      }
    }
  }
}
