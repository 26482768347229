@media (orientation: portrait) {
  .avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--oliver-blue);
    border-radius: 50%;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #fff;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  :disabled > .avatar {
    background-color: var(--oliver-new-grey);
  }
}

@media (orientation: landscape) {
  .avatar {
    border-radius: 50%;
    background-color: var(--oliver-blue);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;

    > .img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  :disabled > .avatar {
    background-color: var(--oliver-new-grey);
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .avatar {
    font-size: 0.6836vw;
    line-height: 0.9766vw;
  }
}
