@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.detailed-tax {
    width: min(500px, 95%);
    height: min(678px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 0;

      > {
        .header-row {
          width: 100%;
          height: 45px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 22px;
          border-bottom: 1px solid var(--oliver-border);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > {
            p {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              letter-spacing: 0em;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            :nth-child(1) {
              width: 45%;
            }

            :nth-child(2) {
              width: 40%;
            }

            :nth-child(3),
            :nth-child(4) {
              display: none;
            }

            :nth-child(5) {
              width: 15%;
            }
          }
        }

        .options-container {
          width: 100%;
          height: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          overflow-y: scroll;

          > .option-row {
            width: 100%;
            height: 60px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 22px;
            background-color: #f1f9ff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &:nth-child(2n) {
              background-color: transparent;
            }

            > {
              :nth-child(1) {
                width: 45%;
              }

              :nth-child(2) {
                width: 40%;
              }

              :nth-child(3),
              :nth-child(4) {
                display: none;
              }

              :nth-child(5) {
                width: 15%;
              }

              p {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                text-align: left;
                color: var(--oliver-base-blue);
                padding-right: 15px;
              }

              .checkbox-wrapper {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                &:hover {
                  cursor: pointer;
                }

                > {
                  input {
                    position: fixed;
                    left: -10000px;
                    opacity: 0;
                  }

                  .custom-checkbox {
                    width: 18px;
                    height: 18px;
                    border: 1px solid var(--oliver-border);
                    border-radius: 4px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    > img {
                      display: none;
                      width: 12px;
                      height: 9.46px;
                    }
                  }

                  input:checked ~ .custom-checkbox > img {
                    display: block;
                  }
                }
              }
            }
          }
        }

        .footer {
          width: 100%;
          height: 85px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-top: 1px solid var(--oliver-border);

          > button {
            width: 280px;
            height: 44px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.detailed-tax {
    width: 744px;
    height: min(735px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 0;

      > {
        .header-row {
          width: 100%;
          height: 45px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 22px;
          border-bottom: 1px solid var(--oliver-border);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > {
            p {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              letter-spacing: 0em;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            :nth-child(1) {
              width: 29.2857%;
            }

            :nth-child(2) {
              width: 16.8571%;
            }

            :nth-child(3) {
              width: 17.8571%;
            }

            :nth-child(4) {
              width: 29.5714%;
            }

            :nth-child(5) {
              width: 6.4287%;
            }
          }
        }

        .options-container {
          width: 100%;
          height: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          overflow-y: scroll;

          > .option-row {
            width: 100%;
            height: 60px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 22px;
            background-color: #f1f9ff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &:nth-child(2n) {
              background-color: transparent;
            }

            > {
              :nth-child(1) {
                width: 29.2857%;
              }

              :nth-child(2) {
                width: 16.8571%;
              }

              :nth-child(3) {
                width: 17.8571%;
              }

              :nth-child(4) {
                width: 29.5714%;
              }

              :nth-child(5) {
                width: 6.4287%;
              }

              p {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                text-align: left;
                color: var(--oliver-base-blue);
                padding-right: 15px;
              }

              .checkbox-wrapper {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                &:hover {
                  cursor: pointer;
                }

                > {
                  input {
                    position: fixed;
                    left: -10000px;
                    opacity: 0;
                  }

                  .custom-checkbox {
                    width: 18px;
                    height: 18px;
                    border: 1px solid var(--oliver-border);
                    border-radius: 4px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    > img {
                      display: none;
                      width: 12px;
                      height: 9.46px;
                    }
                  }

                  input:checked ~ .custom-checkbox > img {
                    display: block;
                  }
                }
              }
            }
          }
        }

        .footer {
          width: 100%;
          height: 85px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-top: 1px solid var(--oliver-border);

          > button {
            width: 280px;
            height: 44px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.detailed-tax {
    width: 36.3281vw;
    height: min(35.8887vw, 95%);

    > .subwindow-body > {
      .header-row {
        height: 2.1973vw;
        padding: 0 1.0742vw;
        border-bottom: 0.0488vw solid var(--oliver-border);

        > p {
          font-size: 0.6836vw;
          line-height: 0.8301vw;
        }
      }

      .options-container > .option-row {
        height: 2.9297vw;
        padding: 0 1.0742vw;

        > {
          p {
            font-size: 0.6836vw;
            line-height: 0.8301vw;
            padding-right: 0.7324vw;
          }

          .checkbox-wrapper > {
            input {
              left: -488.2813vw;
            }

            .custom-checkbox {
              width: 0.8789vw;
              height: 0.8789vw;
              border: 0.0488vw solid var(--oliver-border);
              border-radius: 0.1953vw;

              > img {
                width: 0.5859vw;
                height: 0.4619vw;
              }
            }
          }
        }
      }

      .footer {
        height: 4.1504vw;
        border-top: 0.0488vw solid var(--oliver-border);

        > button {
          width: 13.6719vw;
          height: 2.1484vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }
  }
}
