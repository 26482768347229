@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.product-not-found {
    width: min(372px, 95%);
    height: min(372px, 95%);

    > .subwindow-body {
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p.style1 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 20px;
          margin: 34px 0 20px 0;
        }

        img {
          width: 81.17px;
          height: 51.5px;
          margin-bottom: 34.5px;
          -webkit-transform: translateX(10px);
          -ms-transform: translateX(10px);
          transform: translateX(10px);
        }

        p.style2 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 51px;
        }

        button {
          width: 306px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.product-not-found {
    width: 504px;
    height: min(400px, 95%);

    > .subwindow-body {
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p.style1 {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 24px;
        }

        img {
          width: 81px;
          height: 52px;
          margin-bottom: 40px;
          -webkit-transform: translateX(10px);
          -ms-transform: translateX(10px);
          transform: translateX(10px);
        }

        p.style2 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 36px;
        }

        button {
          width: 198px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.product-not-found {
    width: 24.6094vw;
    height: min(19.5313vw, 95%);

    > .subwindow-body {
      border-radius: 0.3906vw;

      > {
        p.style1 {
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin-bottom: 1.1719vw;
        }

        img {
          width: 3.9551vw;
          height: 2.5391vw;
          margin-bottom: 1.9531vw;
          -webkit-transform: translateX(0.4883vw);
          -ms-transform: translateX(0.4883vw);
          transform: translateX(0.4883vw);
        }

        p.style2 {
          font-size: 0.7813vw;
          line-height: 1.0254vw;
          margin-bottom: 1.7578vw;
        }

        button {
          width: 9.668vw;
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }
  }
}
