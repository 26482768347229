@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.add-customer-note {
    width: min(384px, 95%);
    height: min(400px, 95%);

    > .subwindow-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      > {
        label {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          width: 306px;
          margin-bottom: 9px;
        }

        textarea {
          width: 306px;
          height: 180px;
          box-sizing: border-box;
          outline: 0;
          resize: none;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          padding: 12px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 19px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        button {
          width: 306px;
          height: 50px;
          box-sizing: border-box;
          border: 0;
          border-radius: 6px;
          background-color: var(--oliver-blue);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.add-customer-note {
    width: 504px;
    height: min(420px, 95%);

    > .subwindow-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      > {
        label {
          width: 424px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 9px;
        }

        textarea {
          width: 424px;
          height: 189px;
          resize: none;
          outline: 0;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          box-sizing: border-box;
          padding: 12px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 20px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        button {
          width: 424px;
          height: 48px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.add-customer-note {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);

    > .subwindow-body > {
      label {
        width: 20.7031vw;
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.4395vw;
      }

      textarea {
        width: 20.7031vw;
        height: 9.2285vw;
        border: 0.0488vw solid var(--oliver-border);
        border-radius: 0.293vw;
        padding: 0.5859vw;
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.9766vw;
      }

      button {
        width: 20.7031vw;
        height: 2.3438vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}

