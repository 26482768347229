@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.out-of-stock {
    width: min(344px, 95%);
    height: min(323px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 8px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      > {
        .auto-margin-bottom {
          margin: 0;
        }

        p.style1 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 18px;
        }

        img {
          width: 69px;
          height: 69px;
          margin-bottom: 19px;
        }

        p.style2 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 38px;

          > button {
            background-color: transparent;
            border: 0;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
            color: var(--oliver-blue);

            &:hover {
              text-decoration: underline;
            }
          }
        }

        button {
          width: 306px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.out-of-stock {
    width: 504px;
    height: min(350px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 8px;

      > {
        p.style1 {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          margin-bottom: 24px;
        }

        img {
          width: 69px;
          height: 69px;
          margin-bottom: 13px;
        }

        p.style2 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 30px;

          > button {
            background-color: transparent;
            border: 0;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-blue);

            &:hover {
              text-decoration: underline;
            }
          }
        }

        button {
          width: 198px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.out-of-stock {
    width: 24.6094vw;
    height: min(17.0898vw, 95%);

    > .subwindow-body {
      border-radius: 0.3906vw;

      > {
        p.style1 {
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin-bottom: 1.1719vw;
        }

        img {
          width: 3.3691vw;
          height: 3.3691vw;
          margin-bottom: 0.6348vw;
        }

        p.style2 {
          font-size: 0.7813vw;
          line-height: 1.0254vw;
          margin-bottom: 1.4648vw;

          > button {
            font-size: 0.7813vw;
            line-height: 1.0254vw;
          }
        }

        button {
          width: 9.668vw;
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
        }
      }
    }
  }
}
