@media (orientation: portrait) {
  .page-options-wrapper {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
    background-color: rgba(61, 76, 102, 0.4);
    z-index: 3;

    &.hidden {
      z-index: 1;
      display: none;
    }

    > .page-options {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      padding: 16.5px 28px 16.5px 28px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      border-radius: 0 0 12px 12px;

      > {
        p {
          width: 100%;

          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 12px;
        }

        button {
          width: 100%;
          height: 47px;
          background-color: transparent;
          border: 0;
          border-radius: 8px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > .img-container {
            width: 30px;
            height: 30px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-right: 9px;
            position: relative;

            > img {
              width: 75%;
              height: 75%;
            }
          }

          &#notificationsButton > .img-container > img {
            width: 90%;
            height: 90%;

            &.highlight {
              -webkit-filter: brightness(0) saturate(100%) invert(65%)
                sepia(43%) saturate(6218%) hue-rotate(180deg) brightness(94%)
                contrast(93%);
              filter: brightness(0) saturate(100%) invert(65%) sepia(43%)
                saturate(6218%) hue-rotate(180deg) brightness(94%) contrast(93%);
            }
          }

          &#customFeeDiscountButton > .img-container > img {
            width: 70%;
            height: 70%;
          }

          &#addNoteButton > .img-container > img {
            width: 70%;
            height: 70%;
            -webkit-transform: translateX(10%);
            -ms-transform: translateX(10%);
            transform: translateX(10%);
          }

          &#clearCartButton > .img-container > img {
            width: 60%;
            height: 60%;
          }

          > {
            .img-container > .badge {
              position: absolute;
              top: 0;
              right: 0;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              width: 50%;
              height: 50%;
              border-radius: 50%;
              border: 2px solid #fff;
              background-color: var(--oliver-blue);

              &.red {
                background-color: var(--oliver-red);
              }

              &.green {
                background-color: var(--oliver-teal);
              }

              &.grey {
                background-color: #c4c4c4;
              }
            }

            p {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              text-align: left;
              color: var(--oliver-base-blue);
            }
          }

          &:disabled > {
            p {
              color: var(--oliver-new-grey) !important;
            }

            .img-container > img {
              -webkit-filter: brightness(0) saturate(100%) invert(78%)
                sepia(14%) saturate(234%) hue-rotate(164deg) brightness(95%)
                contrast(92%) !important;
              filter: brightness(0) saturate(100%) invert(78%) sepia(14%)
                saturate(234%) hue-rotate(164deg) brightness(95%) contrast(92%) !important;
            }
          }

          &.hidden {
            display: none !important;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .page-options-wrapper {
    position: fixed;
    top: 22px;
    right: 76px;

    > .page-options {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          display: none;
        }

        button {
          background-color: transparent;
          border: 0;
          width: 24px;
          height: 24px;
          margin-right: 10px;

          &:first-of-type {
            margin-right: 0;
          }

          > .img-container {
            position: relative;
            width: 100%;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            line-height: 0;
          }

          &#notificationsButton > .img-container > img {
            width: 100%;
            height: 100%;

            &.highlight {
              -webkit-filter: brightness(0) saturate(100%) invert(47%)
                sepia(69%) saturate(786%) hue-rotate(169deg) brightness(95%)
                contrast(91%);
              filter: brightness(0) saturate(100%) invert(47%) sepia(69%)
                saturate(786%) hue-rotate(169deg) brightness(95%) contrast(91%);
            }
          }

          &#customFeeDiscountButton > .img-container > img {
            width: 66.6667%;
            height: 66.6667%;
          }

          &#addNoteButton > .img-container > img {
            width: 75.5417%;
            height: 62.5%;
            -webkit-transform: translateY(12%);
            -ms-transform: translateY(12%);
            transform: translateY(12%);
          }

          &#clearCartButton > .img-container > img {
            width: 60%;
            height: auto;
          }

          > {
            .img-container > .badge {
              position: absolute;
              top: 0;
              right: 0;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              width: 50%;
              height: 50%;
              border-radius: 50%;
              border: 2px solid #fff;
              background-color: var(--oliver-blue);

              &.red {
                background-color: var(--oliver-red);
              }

              &.green {
                background-color: var(--oliver-teal);
              }

              &.grey {
                background-color: #c4c4c4;
              }
            }

            p {
              display: none;
            }
          }

          &:disabled > {
            p {
              color: var(--oliver-new-grey) !important;
            }

            .img-container > img {
              -webkit-filter: brightness(0) saturate(100%) invert(78%)
                sepia(14%) saturate(234%) hue-rotate(164deg) brightness(95%)
                contrast(92%) !important;
              filter: brightness(0) saturate(100%) invert(78%) sepia(14%)
                saturate(234%) hue-rotate(164deg) brightness(95%) contrast(92%) !important;
            }
          }

          &.hidden {
            display: none !important;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .page-options-wrapper {
    top: 1.0742vw;
    right: 3.7109vw;

    > .page-options > button {
      width: 1.1719vw;
      height: 1.1719vw;
      margin-right: 0.4883vw;

      > .img-container > .badge {
        border: 0.0977vw solid #fff;
      }
    }
  }
}
