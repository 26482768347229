@media (orientation: portrait) {
  .app-launcher-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: #fff;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    > .app-launcher {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      > {
        .header {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          height: 72px;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-bottom: 1px solid var(--oliver-border);

          > {
            button {
              width: 40px;
              height: 40px;
              margin: 0 8px;
              background-color: transparent;
              border: 0;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > img {
                width: 10.64px;
                height: 18px;
              }
            }

            p {
              font-size: 20px;
              font-weight: 500;
              line-height: 28px;
              text-align: left;
              color: var(--oliver-base-blue);
            }
          }
        }

        .body {
          width: 100%;
          height: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          overflow-y: scroll;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          background-color: #f1f9ff;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 20px;

          > {
            img {
              width: 257px;
              height: 176px;
              margin: auto;
              display: none;

              &:only-child {
                display: block;
              }
            }

            button {
              width: 100%;
              background-color: transparent;
              border: 0;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-bottom: 20px;

              &:last-of-type {
                margin-bottom: 0;
              }

              > {
                .img-container {
                  width: 55px;
                  height: 55px;
                  border-radius: 10px;
                  margin-right: 27px;
                  overflow: hidden;

                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }

                p {
                  max-width: 100%;

                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }

    &.hidden {
      -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
}

@media (orientation: landscape) {
  .app-launcher-wrapper {
    z-index: 3;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 72px;
    top: 0;
    background-color: rgba(123, 140, 152, 0.3);
    border-left: 1px solid var(--oliver-border);

    &.hidden {
      display: none;
    }

    > .app-launcher {
      width: 375px;
      height: 100%;
      overflow: auto;
      background-color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      > {
        .header {
          width: 100%;
          padding: 24px 0;

          > {
            button {
              display: none;
            }

            p {
              font-size: 16px;
              font-weight: 600;
              line-height: 21px;
              text-align: left;
              margin-left: 29px;
              color: var(--oliver-base-blue);
            }
          }
        }

        .body {
          width: 100%;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 29px;

          > {
            img {
              display: none;
              width: 260px;
              height: 165px;
              margin: auto;

              &:only-child {
                display: block;
              }
            }

            button {
              width: 100%;
              margin-bottom: 20px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              border: 0;
              background-color: transparent;

              > {
                .img-container {
                  width: 55px;
                  height: 55px;
                  margin-right: 26px;
                  overflow: hidden;
                  border-radius: 10px;

                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }

                p {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }

    &:not(.hidden) ~ .nav-cover {
      display: none;
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .app-launcher-wrapper {
    left: 3.5156vw;
    border-left: 0.0488vw solid var(--oliver-border);

    > .app-launcher {
      width: 18.3105vw;

      > {
        .header {
          padding: 1.1719vw 0;

          > p {
            font-size: 0.7813vw;
            line-height: 1.0254vw;
            margin-left: 1.416vw;
          }
        }

        .body {
          padding: 0 1.416vw;

          > {
            img {
              width: 12.6953vw;
              height: 8.0566vw;
            }

            button {
              margin-bottom: 0.9766vw;

              > {
                .img-container {
                  width: 2.6855vw;
                  height: 2.6855vw;
                  margin-right: 1.2695vw;
                  border-radius: 0.4883vw;
                }

                p {
                  font-size: 0.7813vw;
                  line-height: 1.1719vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
