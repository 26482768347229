@media (orientation: portrait) {
  /* Advanced Search */

  .subwindow-wrapper > .subwindow.advanced-search {
    width: 100%;
    height: 100%;
    border-radius: 0;

    > {
      .subwindow-header {
        position: relative;
        width: 100%;
        height: 90px;
        background-color: #f1f4f6;
        z-index: 2;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 8px 8px 0 0;

        > {
          p {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 21px;
            border-radius: 0;
          }

          button {
            width: 32px;
            height: 32px;
            top: 11px;
            right: 11px;
            background-color: #fff;
          }

          .input-wrapper {
            position: absolute;
            width: 310px;
            height: 55px;
            top: 64px;
            left: calc(50% - 155px);
            -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
            line-height: 0;

            > {
              #advSearchInputIcon {
                width: 30px;
                height: 29px;
                position: absolute;
                top: 13px;
                left: 11px;
                -webkit-filter: brightness(0) saturate(100%) invert(72%)
                  sepia(7%) saturate(349%) hue-rotate(169deg) brightness(95%)
                  contrast(92%);
                filter: brightness(0) saturate(100%) invert(72%) sepia(7%)
                  saturate(349%) hue-rotate(169deg) brightness(95%)
                  contrast(92%);
              }

              #advSearchInputCancel {
                opacity: 0;
                width: 30px;
                height: 30px;
                position: absolute;
                right: 11px;
                top: 13px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                background-color: transparent;
                border: 0;

                &:hover {
                  cursor: default;
                }

                > img {
                  width: 17.5px;
                  height: 17.5px;
                }
              }

              input {
                width: 100%;
                height: 100%;
                border: 1px solid var(--oliver-border);
                border-radius: 6px;
                padding: 0 52px 0 52px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);

                &:focus {
                  border-color: var(--oliver-blue);

                  ~ {
                    #advSearchInputIcon {
                      -webkit-filter: none;
                      filter: none;
                    }

                    #advSearchInputCancel {
                      opacity: 1;

                      &:hover {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .subwindow-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0;

        > {
          .left-col {
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding-top: 51px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              p {
                display: none;
              }

              .radio-group {
                width: 310px;
                height: 40px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-bottom: 19px;
                z-index: 2;

                > {
                  .dropdown-input {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    border: 1px solid var(--oliver-border);
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 0 12px;
                    border-radius: 8px;

                    &.open {
                      border-radius: 8px 8px 0 0;
                    }

                    > p {
                      font-family: Poppins;
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 17px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                      margin-right: auto;

                      > b {
                        font-weight: 600;
                      }
                    }
                  }

                  label {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    display: none;
                    width: 100%;
                    height: 100%;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    border: 1px solid var(--oliver-border);
                    border-top: 0;
                    background-color: #fff;
                    z-index: 2;
                    padding: 0 12px;
                  }

                  .dropdown-input.open ~ label {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                  }

                  label {
                    &:hover {
                      cursor: pointer;
                    }

                    &:last-of-type {
                      border-radius: 0 0 8px 8px;
                    }

                    > {
                      input[type="radio"] {
                        position: fixed;
                        left: -10000px;
                        opacity: 0;
                      }

                      .custom-radio {
                        display: none;
                      }

                      p {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        text-align: left;
                        color: var(--oliver-base-blue);
                      }
                    }
                  }
                }
              }

              .recent-searches {
                display: none;
              }
            }
          }

          .right-col {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              .start-searching {
                display: none;
                width: 100%;
                height: 100%;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-top: -54px;

                &:only-child {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                }

                > {
                  img {
                    width: 43px;
                    height: 42px;
                    margin-bottom: 30px;
                  }

                  p {
                    &.style1 {
                      font-family: Poppins;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 22px;
                      text-align: center;
                      color: var(--oliver-base-blue);
                      margin-bottom: 20px;
                    }

                    &.style2 {
                      font-family: Poppins;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: center;
                      color: var(--oliver-base-blue);
                    }
                  }
                }
              }

              .header {
                width: 310px;
                border-bottom: 1px solid var(--oliver-border);

                > p {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  margin-bottom: 5.69px;

                  > b {
                    font-weight: 600;
                  }
                }
              }

              .body {
                width: 100%;
                height: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 15px 0;
                overflow-y: scroll;

                > {
                  .no-results {
                    display: none;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    &:only-child {
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                    }

                    > {
                      p {
                        &.style1 {
                          font-family: Poppins;
                          font-size: 16px;
                          font-weight: 600;
                          line-height: 23px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                          margin-bottom: 5.69px;
                        }

                        &.style2 {
                          font-family: Poppins;
                          font-size: 12px;
                          font-weight: 400;
                          line-height: 18px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                        }
                      }

                      .divider {
                        width: 100px;
                        height: 1px;
                        background-color: var(--oliver-border);
                        margin: 30px 0;
                      }

                      button {
                        width: 210px;
                        height: 40px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        border: 1px solid var(--oliver-blue);
                        border-radius: 6px;
                        background-color: #fff;
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        text-align: left;
                        color: var(--oliver-blue);
                        margin-top: 20px;

                        > img {
                          width: 16px;
                          height: 16px;
                          margin-right: 10px;
                        }
                      }
                    }
                  }

                  .search-result {
                    width: 310px;
                    // height: 200px;
                    border-radius: 6px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 15px 16.5px 18px 16.5px;
                    background-color: #ebf6ff;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    margin-top: 13px;

                    &:nth-child(2) {
                      margin-top: 0;
                    }

                    &.customer {
                      background-color: #ebf6ff;
                    }

                    &.product {
                      // height: 220px;
                      background-color: #f1f4f6;
                    }

                    &.group {
                      height: 220px;
                      background-color: #fff5f1;
                    }

                    > {
                      .col {
                        width: 100%;
                        margin-bottom: auto;

                        > p {
                          max-width: 100%;
                          overflow: hidden;
                          white-space: nowrap;
                          -o-text-overflow: ellipsis;
                          text-overflow: ellipsis;

                          &:last-of-type {
                            margin-bottom: 0;
                          }

                          &.style1 {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: left;
                            color: var(--oliver-blue);
                            margin-bottom: 4px;
                          }

                          &.style2 {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: left;
                            color: var(--oliver-base-blue);
                            margin-bottom: 4px;
                          }

                          &.style3 {
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 17px;
                            text-align: left;
                            color: var(--oliver-base-blue);
                            margin-bottom: 2px;
                          }
                        }
                      }

                      .row {
                        width: 100%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;

                        > button {
                          width: 87px;
                          height: 65px;
                          display: -webkit-box;
                          display: -ms-flexbox;
                          display: flex;
                          -webkit-box-orient: vertical;
                          -webkit-box-direction: normal;
                          -ms-flex-direction: column;
                          flex-direction: column;
                          -webkit-box-align: center;
                          -ms-flex-align: center;
                          align-items: center;
                          -webkit-box-pack: center;
                          -ms-flex-pack: center;
                          justify-content: center;
                          background-color: var(--oliver-blue);
                          border: 0;
                          border-radius: 6px;
                          font-family: Poppins;
                          text-align: center;
                          color: #fff;
                          margin-right: 8px;

                          &:last-of-type {
                            margin-right: 0;
                          }

                          &.search-view {
                            font-size: 11px;
                            font-weight: 500;
                            line-height: 15px;

                            > img {
                              width: 20px;
                              height: 20px;
                              margin-bottom: 3px;
                            }
                          }

                          &.search-transactions {
                            font-family: Poppins;
                            font-size: 11px;
                            font-weight: 500;
                            line-height: 15px;

                            > img {
                              width: 24px;
                              height: 24px;
                              margin-bottom: 3px;
                            }
                          }

                          &.search-add-to-sale {
                            font-size: 11px;
                            font-weight: 500;
                            line-height: 15px;

                            > img {
                              width: 24px;
                              height: 24px;
                              margin-bottom: 3px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.advanced-search {
    width: 840px;
    height: 95%;

    > {
      .subwindow-header {
        position: relative;
        width: 100%;
        height: 127px;
        background-color: #f1f4f6;
        z-index: 2;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 8px 8px 0 0;

        > {
          p {
            font-family: Poppins;
            font-size: 25px;
            font-weight: 500;
            line-height: 33px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 22px;
          }

          button {
            width: 32px;
            height: 32px;
            top: 16px;
            right: 16px;
            background-color: #fff;
          }

          .input-wrapper {
            position: absolute;
            width: 760px;
            height: 55px;
            top: 99px;
            left: 40px;
            -webkit-filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.1));

            > {
              #advSearchInputIcon {
                width: 30px;
                height: 29px;
                position: absolute;
                top: 13px;
                left: 11px;
                -webkit-filter: brightness(0) saturate(100%) invert(72%)
                  sepia(7%) saturate(349%) hue-rotate(169deg) brightness(95%)
                  contrast(92%);
                filter: brightness(0) saturate(100%) invert(72%) sepia(7%)
                  saturate(349%) hue-rotate(169deg) brightness(95%)
                  contrast(92%);
              }

              #advSearchInputCancel {
                opacity: 0;
                width: 30px;
                height: 30px;
                position: absolute;
                right: 11px;
                top: 13px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                background-color: transparent;
                border: 0;

                &:hover {
                  cursor: default;
                }

                > img {
                  width: 17.5px;
                  height: 17.5px;
                }
              }

              input {
                width: 100%;
                height: 100%;
                border: 1px solid var(--oliver-border);
                border-radius: 6px;
                padding: 0 52px 0 52px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);

                &:focus {
                  border-color: var(--oliver-blue);

                  ~ {
                    #advSearchInputIcon {
                      -webkit-filter: none;
                      filter: none;
                    }

                    #advSearchInputCancel {
                      opacity: 1;

                      &:hover {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .subwindow-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0;

        > {
          .left-col {
            width: 260px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding-top: 74px;
            border-right: 1px solid var(--oliver-border);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            overflow: auto;

            > {
              p {
                width: 196px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 15px;

                &:last-of-type {
                  margin-bottom: 8px;
                }
              }

              .radio-group {
                width: 196px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-bottom: 33px;

                > {
                  .dropdown-input {
                    display: none;
                  }

                  label {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-bottom: 12px;

                    &:hover {
                      cursor: pointer;
                    }

                    > {
                      input[type="radio"] {
                        position: fixed;
                        left: -10000px;
                        opacity: 0;
                      }

                      .custom-radio {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        border: 1px solid var(--oliver-border);
                        margin-right: 8px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;

                        > img {
                          width: 8px;
                          height: 8px;
                          display: none;
                        }
                      }

                      p {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                        color: var(--oliver-base-blue);
                      }

                      input[type="radio"]:checked ~ .custom-radio {
                        border-color: var(--oliver-blue);

                        > img {
                          display: block;
                        }

                        ~ p {
                          color: var(--oliver-blue);
                        }
                      }
                    }
                  }
                }
              }

              .recent-searches {
                width: 196px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;

                > a {
                  font-family: Poppins;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 18px;
                  text-align: left;
                  color: var(--oliver-blue);
                  margin-bottom: 8px;
                  text-decoration: none;

                  &:last-of-type {
                    margin-bottom: 0;
                  }

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .right-col {
            padding-top: 54px;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            > {
              .start-searching {
                display: none;
                width: 100%;
                height: 100%;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding-bottom: 54px;

                &:only-child {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                }

                > {
                  img {
                    width: 43px;
                    height: 42px;
                    margin: auto 0 30px 0;
                  }

                  p {
                    &.style1 {
                      font-family: Poppins;
                      font-size: 18px;
                      font-weight: 600;
                      line-height: 25px;
                      text-align: center;
                      color: var(--oliver-base-blue);
                      margin-bottom: 20px;
                    }

                    &.style2 {
                      font-family: Poppins;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: center;
                      color: var(--oliver-base-blue);
                      margin-bottom: auto;
                    }
                  }
                }
              }

              .header {
                border-bottom: 1px solid var(--oliver-border);

                > p {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  margin: 0 0 14px 22px;

                  > b {
                    font-weight: 600;
                  }
                }
              }

              .body {
                width: 100%;
                height: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 22px;
                overflow: auto;

                > {
                  .no-results {
                    display: none;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    &:only-child {
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                    }

                    > {
                      p {
                        &.style1 {
                          font-family: Poppins;
                          font-size: 18px;
                          font-weight: 600;
                          line-height: 25px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                          margin-bottom: 5px;
                        }

                        &.style2 {
                          font-family: Poppins;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                        }
                      }

                      .divider {
                        width: 100px;
                        height: 1px;
                        background-color: var(--oliver-border);
                        margin: 30px 0;
                      }

                      button {
                        width: 225px;
                        height: 40px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        border: 1px solid var(--oliver-blue);
                        border-radius: 6px;
                        background-color: #fff;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: left;
                        color: var(--oliver-blue);
                        margin-top: 10px;

                        > img {
                          width: 20px;
                          height: 20px;
                          margin-right: 12px;
                        }
                      }
                    }
                  }

                  .search-result {
                    width: 100%;
                    // height: 148px;
                    border-radius: 6px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 18px 20px;
                    background-color: #ebf6ff;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-top: 14px;

                    &:nth-child(2) {
                      margin-top: 0;
                    }

                    &.customer {
                      background-color: #ebf6ff;
                    }

                    &.product {
                      background-color: #f1f4f6;
                    }

                    &.group {
                      background-color: #fff5f1;
                    }

                    > {
                      .col {
                        width: 0;
                        -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;
                        height: 100%;
                        margin-right: auto;

                        > p {
                          max-width: 95%;
                          overflow: hidden;
                          white-space: nowrap;
                          -o-text-overflow: ellipsis;
                          text-overflow: ellipsis;

                          &:last-of-type {
                            margin-bottom: 0;
                          }

                          &.style1 {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: left;
                            color: var(--oliver-blue);
                            margin-bottom: 13px;
                          }

                          &.style2 {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: left;
                            color: var(--oliver-base-blue);
                            margin-bottom: 4px;
                          }

                          &.style3 {
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 17px;
                            text-align: left;
                            color: var(--oliver-base-blue);
                            margin-bottom: 4px;
                          }
                        }
                      }

                      .row {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        > button {
                          width: 70px;
                          height: 65px;
                          display: -webkit-box;
                          display: -ms-flexbox;
                          display: flex;
                          -webkit-box-orient: vertical;
                          -webkit-box-direction: normal;
                          -ms-flex-direction: column;
                          flex-direction: column;
                          -webkit-box-align: center;
                          -ms-flex-align: center;
                          align-items: center;
                          -webkit-box-pack: center;
                          -ms-flex-pack: center;
                          justify-content: center;
                          background-color: var(--oliver-blue);
                          border: 0;
                          border-radius: 6px;
                          font-family: Poppins;
                          text-align: center;
                          color: #fff;
                          margin-right: 8px;

                          &:last-of-type {
                            margin-right: 0;
                          }

                          &.search-view {
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 14px;

                            > img {
                              width: 20px;
                              height: 20px;
                              margin-bottom: 3px;
                            }
                          }

                          &.search-transactions,
                          &.search-add-to-sale {
                            font-size: 8px;
                            font-weight: 500;
                            line-height: 11px;

                            > img {
                              width: 24px;
                              height: 24px;
                              margin-bottom: 3px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.advanced-search {
    width: 41.0156vw;

    > {
      .subwindow-header {
        height: 6.2012vw;
        border-radius: 0.3906vw 0.3906vw 0 0;

        > {
          p {
            font-size: 1.2207vw;
            line-height: 1.6113vw;
            margin-bottom: 1.0742vw;
          }

          button {
            width: 1.5625vw;
            height: 1.5625vw;
            top: 0.7813vw;
            right: 0.7813vw;
          }

          .input-wrapper {
            width: 37.1094vw;
            height: 2.6855vw;
            top: 4.834vw;
            left: 1.9531vw;
            -webkit-filter: drop-shadow(
              0vw 0.7324vw 1.4648vw rgba(0, 0, 0, 0.1)
            );
            filter: drop-shadow(0vw 0.7324vw 1.4648vw rgba(0, 0, 0, 0.1));

            > {
              #advSearchInputIcon {
                width: 1.4648vw;
                height: 1.416vw;
                top: 0.6348vw;
                left: 0.5371vw;
              }

              #advSearchInputCancel {
                width: 1.4648vw;
                height: 1.4648vw;
                right: 0.5371vw;
                top: 0.6348vw;

                > img {
                  width: 0.8545vw;
                  height: 0.8545vw;
                }
              }

              input {
                border: 0.0488vw solid var(--oliver-border);
                border-radius: 0.293vw;
                padding: 0 2.5391vw 0 2.5391vw;
                font-size: 0.6836vw;
                line-height: 0.9766vw;
              }
            }
          }
        }
      }

      .subwindow-body > {
        .left-col {
          width: 12.6953vw;
          padding-top: 3.6133vw;
          border-right: 0.0488vw solid var(--oliver-border);

          > {
            p {
              width: 9.5703vw;
              font-size: 0.6836vw;
              line-height: 0.9766vw;
              margin-bottom: 0.7324vw;

              &:last-of-type {
                margin-bottom: 0.3906vw;
              }
            }

            .radio-group {
              width: 9.5703vw;
              margin-bottom: 1.6113vw;

              > label {
                margin-bottom: 0.5859vw;

                > {
                  input[type="radio"] {
                    left: -488.2813vw;
                  }

                  .custom-radio {
                    width: 0.8789vw;
                    height: 0.8789vw;
                    border: 0.0488vw solid var(--oliver-border);
                    margin-right: 0.3906vw;

                    > img {
                      width: 0.3906vw;
                      height: 0.3906vw;
                    }
                  }

                  p {
                    font-size: 0.6836vw;
                    line-height: 0.9766vw;
                  }
                }
              }
            }

            .recent-searches {
              width: 9.5703vw;

              > a {
                font-size: 0.6348vw;
                line-height: 0.8789vw;
                margin-bottom: 0.3906vw;
              }
            }
          }
        }

        .right-col {
          padding-top: 2.6367vw;

          > {
            .start-searching {
              padding-bottom: 2.6367vw;

              > {
                img {
                  width: 2.0996vw;
                  height: 2.0508vw;
                  margin: auto 0 1.4648vw 0;
                }

                p {
                  &.style1 {
                    font-size: 0.8789vw;
                    line-height: 1.2207vw;
                    margin-bottom: 0.9766vw;
                  }

                  &.style2 {
                    font-size: 0.6836vw;
                    line-height: 0.9766vw;
                  }
                }
              }
            }

            .header {
              border-bottom: 0.0488vw solid var(--oliver-border);

              > p {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
                margin: 0 0 0.6836vw 1.0742vw;
              }
            }

            .body {
              padding: 1.0742vw;

              > {
                .no-results > {
                  p {
                    &.style1 {
                      font-size: 0.8789vw;
                      line-height: 1.2207vw;
                      margin-bottom: 0.2441vw;
                    }

                    &.style2 {
                      font-size: 0.6836vw;
                      line-height: 0.9766vw;
                    }
                  }

                  .divider {
                    width: 4.8828vw;
                    height: 0.0488vw;
                    margin: 1.4648vw 0;
                  }

                  button {
                    width: 10.9863vw;
                    height: 1.9531vw;
                    border: 0.0488vw solid var(--oliver-blue);
                    border-radius: 0.293vw;
                    font-size: 0.6836vw;
                    line-height: 0.8789vw;
                    margin-top: 0.4883vw;

                    > img {
                      width: 0.9766vw;
                      height: 0.9766vw;
                      margin-right: 0.5859vw;
                    }
                  }
                }

                .search-result {
                  height: 7.2266vw;
                  border-radius: 0.293vw;
                  padding: 0.8789vw 0.9766vw;
                  margin-top: 0.6836vw;

                  > {
                    .col > p {
                      &.style1 {
                        font-size: 0.6836vw;
                        line-height: 0.9766vw;
                        margin-bottom: 0.6348vw;
                      }

                      &.style2 {
                        font-size: 0.6836vw;
                        line-height: 0.9766vw;
                        margin-bottom: 0.1953vw;
                      }

                      &.style3 {
                        font-size: 0.5859vw;
                        line-height: 0.8301vw;
                        margin-bottom: 0.1953vw;
                      }
                    }

                    .row > button {
                      width: 3.418vw;
                      height: 3.1738vw;
                      border-radius: 0.293vw;
                      margin-right: 0.3906vw;

                      &.search-view {
                        font-size: 0.4883vw;
                        line-height: 0.6836vw;

                        > img {
                          width: 0.9766vw;
                          height: 0.9766vw;
                          margin-bottom: 0.1465vw;
                        }
                      }

                      &.search-transactions,
                      &.search-add-to-sale {
                        font-size: 0.3906vw;
                        line-height: 0.5371vw;

                        > img {
                          width: 1.1719vw;
                          height: 1.1719vw;
                          margin-bottom: 0.1465vw;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
