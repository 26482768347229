@media (orientation: portrait) {
  .subwindow.product-unsupported {
    width: min(344px, 95%);
    height: min(372px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 8px;

      > {
        p.style1 {
          margin: auto 0 27px 0;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
        }

        img {
          width: 50px;
          height: 52px;
          margin-bottom: 27px;
        }

        p.style2 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 33px;

          > br.landscape {
            display: none;
          }
        }

        button {
          width: 306px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow.product-unsupported {
    width: 504px;
    height: 400px;

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p.style1 {
          margin: auto 0 33px 0;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          color: var(--oliver-base-blue);
        }

        img {
          width: 50px;
          height: 52px;
          margin-bottom: 33px;
        }

        p.style2 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 33px;

          > br.mobile {
            display: none;
          }
        }

        button {
          width: 198px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin-bottom: auto;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow.product-unsupported {
    width: 24.6094vw;
    height: 19.5313vw;

    > .subwindow-body > {
      p.style1 {
        margin: auto 0 1.6113vw 0;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }

      img {
        width: 2.4414vw;
        height: 2.5391vw;
        margin-bottom: 1.6113vw;
      }

      p.style2 {
        font-size: 0.7813vw;
        line-height: 1.0254vw;
        margin-bottom: 1.6113vw;
      }

      button {
        width: 9.668vw;
        height: 2.4414vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
