@media (orientation: portrait) {
  /* Custom Fee-Cart Discount */

  .subwindow {
    &.custom-fee {
      width: min(384px, 95%);
      height: min(535px, 95%);
    }

    &.cart-discount {
      width: min(384px, 95%);
      height: min(740px, 95%);
    }

    &.custom-fee > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    &.cart-discount > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > .auto-margin-top {
        margin: 0 !important;
      }
    }

    &.custom-fee > .subwindow-body > #cartDiscountDiv,
    &.cart-discount > .subwindow-body > #customFeeDiv {
      display: none;
    }

    &.custom-fee > .subwindow-body > p,
    &.cart-discount > .subwindow-body > p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: var(--oliver-base-grey);
      margin-bottom: 9px;
    }

    &.custom-fee > .subwindow-body > .toggle-container,
    &.cart-discount > .subwindow-body > .toggle-container {
      width: 285px;
      height: 50px;
      background-color: #fff;
      border: 1px solid var(--oliver-border);
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 6px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin-bottom: 24px;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label,
    &.cart-discount > .subwindow-body > .toggle-container > label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      height: 100%;
      border: 0;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label:hover,
    &.cart-discount > .subwindow-body > .toggle-container > label:hover {
      cursor: pointer;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label > input,
    &.cart-discount > .subwindow-body > .toggle-container > label > input {
      position: fixed;
      left: -10000px;
      opacity: 0;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label > .custom-radio,
    &.cart-discount
      > .subwindow-body
      > .toggle-container
      > label
      > .custom-radio {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 6px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: var(--oliver-base-grey);
    }

    &.custom-fee
      > .subwindow-body
      > .toggle-container
      > label
      > input:checked
      + .custom-radio,
    &.cart-discount
      > .subwindow-body
      > .toggle-container
      > label
      > input:checked
      + .custom-radio {
      background-color: var(--oliver-blue);
      color: #fff;
    }

    &.custom-fee > .subwindow-body > #customFeeDiv {
      width: 302px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      > {
        label {
          width: 100%;
          padding-bottom: 8px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);

          &:hover {
            cursor: pointer;
          }
        }

        input {
          &#customFeeLabel {
            width: 100%;
            height: 44px;
            margin-bottom: 14px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 12px;
            border-radius: 6px;
            border: 1px solid var(--oliver-border);
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;

            &:hover {
              border-color: var(--oliver-blue);
            }

            &:focus {
              border-color: var(--oliver-blue);
              border-width: 2px;
            }
          }

          &#customFeeAmount {
            width: 100%;
            height: 83px;
            border-radius: 6px;
            border: 1px solid var(--oliver-border);
            padding: 0 12px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-family: Poppins;
            font-size: 40px;
            font-weight: 400;
            line-height: 56px;
            text-align: center;
            color: var(--oliver-blue);
            margin-bottom: 8px;

            &:hover {
              border-color: var(--oliver-blue);
            }

            &:focus {
              border-color: var(--oliver-blue);
              border-width: 2px;
            }
          }
        }

        p {
          width: 100%;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-grey);
          margin-bottom: 20px;
        }

        .custom-toggle-wrapper {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-bottom: 30px;

          > {
            input {
              position: fixed;
              left: -10000px;
              opacity: 0;
            }

            .custom-toggle {
              width: 40px;
              height: 25px;
              border-radius: 25px;
              background-color: var(--oliver-spacer);
              margin-left: 20px;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                left: 2px;
                top: calc(50% - 11px);
                width: 22px;
                height: 22px;
                background-color: #fff;
                border-radius: 50%;
                -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15),
                  0px 2px 1px rgba(0, 0, 0, 0.06);
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15),
                  0px 2px 1px rgba(0, 0, 0, 0.06);
              }
            }

            input:checked ~ .custom-toggle {
              background-color: var(--oliver-blue);

              &::after {
                left: auto;
                right: 2px;
              }
            }
          }
        }

        button {
          width: 100%;
          height: 50px;
          border-radius: 6px;
          border: 0;
          background-color: var(--oliver-blue);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow {
    &.custom-fee {
      width: 504px;
      height: min(544px, 95%);
    }

    &.cart-discount {
      width: 504px;
      height: 758px;
    }

    &.custom-fee > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    &.cart-discount > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > .auto-margin-top {
        margin: 0 !important;
      }
    }

    &.custom-fee > .subwindow-body > #cartDiscountDiv,
    &.cart-discount > .subwindow-body > #customFeeDiv {
      display: none;
    }

    &.custom-fee > .subwindow-body > p,
    &.cart-discount > .subwindow-body > p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: var(--oliver-base-grey);
      margin-bottom: 9px;
    }

    &.custom-fee > .subwindow-body > .toggle-container,
    &.cart-discount > .subwindow-body > .toggle-container {
      width: 285px;
      height: 50px;
      background-color: #fff;
      border: 1px solid var(--oliver-border);
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 6px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin-bottom: 24px;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label,
    &.cart-discount > .subwindow-body > .toggle-container > label {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      height: 100%;
      border: 0;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label:hover,
    &.cart-discount > .subwindow-body > .toggle-container > label:hover {
      cursor: pointer;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label > input,
    &.cart-discount > .subwindow-body > .toggle-container > label > input {
      position: fixed;
      left: -10000px;
      opacity: 0;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label > .custom-radio,
    &.cart-discount
      > .subwindow-body
      > .toggle-container
      > label
      > .custom-radio {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 6px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: var(--oliver-base-grey);
    }

    &.custom-fee
      > .subwindow-body
      > .toggle-container
      > label
      > input:checked
      + .custom-radio,
    &.cart-discount
      > .subwindow-body
      > .toggle-container
      > label
      > input:checked
      + .custom-radio {
      background-color: var(--oliver-blue);
      color: #fff;
    }

    &.custom-fee > .subwindow-body > #customFeeDiv {
      width: 348px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      > {
        label {
          width: 100%;
          padding-bottom: 8px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);

          &:hover {
            cursor: pointer;
          }
        }

        input {
          &#customFeeLabel {
            width: 100%;
            height: 44px;
            margin-bottom: 14px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 12px;
            border-radius: 6px;
            border: 1px solid var(--oliver-border);
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;

            &:hover {
              border-color: var(--oliver-blue);
            }

            &:focus {
              border-color: var(--oliver-blue);
              border-width: 2px;
            }
          }

          &#customFeeAmount {
            width: 100%;
            height: 83px;
            border-radius: 6px;
            border: 1px solid var(--oliver-border);
            padding: 0 12px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-family: Poppins;
            font-size: 40px;
            font-weight: 400;
            line-height: 56px;
            text-align: center;
            color: var(--oliver-blue);
            margin-bottom: 8px;

            &:hover {
              border-color: var(--oliver-blue);
            }

            &:focus {
              border-color: var(--oliver-blue);
              border-width: 2px;
            }
          }
        }

        p {
          width: 100%;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 20px;
        }

        label.custom-toggle-wrapper {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: var(--oliver-base-blue);
          padding-bottom: 30px;

          > {
            input {
              position: fixed;
              left: -10000px;
              opacity: 0;
            }

            .custom-toggle {
              width: 40px;
              height: 25px;
              border-radius: 25px;
              background-color: var(--oliver-spacer);
              margin-left: 20px;
              position: relative;

              &::after {
                position: absolute;
                top: calc(50% - 11px);
                left: 1.5px;
                content: "";
                width: 22px;
                height: 22px;
                background-color: #fff;
                border-radius: 50%;
                -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15),
                  0px 2px 1px rgba(0, 0, 0, 0.06);
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15),
                  0px 2px 1px rgba(0, 0, 0, 0.06);
              }
            }

            input:checked ~ .custom-toggle {
              background-color: var(--oliver-blue);

              &::after {
                left: auto;
                right: 1.5px;
              }
            }
          }
        }

        button {
          width: 100%;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow {
    &.custom-fee {
      width: 24.6094vw;
      height: min(26.5625vw, 95%);
    }

    &.cart-discount {
      width: 24.6094vw;
      height: 37.0117vw;
    }

    &.custom-fee > .subwindow-body > p,
    &.cart-discount > .subwindow-body > p {
      font-size: 0.6836vw;
      line-height: 0.9766vw;
      margin-bottom: 0.4395vw;
    }

    &.custom-fee > .subwindow-body > .toggle-container,
    &.cart-discount > .subwindow-body > .toggle-container {
      width: 13.916vw;
      height: 2.4414vw;
      border: 0.0488vw solid var(--oliver-border);
      border-radius: 0.3906vw;
      padding: 0.293vw;
      margin-bottom: 1.1719vw;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label > input,
    &.cart-discount > .subwindow-body > .toggle-container > label > input {
      left: -488.2813vw;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label > .custom-radio,
    &.cart-discount
      > .subwindow-body
      > .toggle-container
      > label
      > .custom-radio {
      border-radius: 0.293vw;
      font-size: 0.7813vw;
      line-height: 1.0254vw;
    }

    &.custom-fee > .subwindow-body > #customFeeDiv {
      width: 16.9922vw;

      > {
        label {
          padding-bottom: 0.3906vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }

        input {
          &#customFeeLabel {
            height: 2.1484vw;
            margin-bottom: 0.6836vw;
            padding: 0 0.5859vw;
            border-radius: 0.293vw;
            border: 0.0488vw solid var(--oliver-border);
            font-size: 0.6836vw;
            line-height: 0.9766vw;

            &:focus {
              border-width: 0.0977vw;
            }
          }

          &#customFeeAmount {
            height: 4.0527vw;
            border-radius: 0.293vw;
            border: 0.0488vw solid var(--oliver-border);
            padding: 0 0.5859vw;
            font-size: 1.9531vw;
            line-height: 2.7344vw;
            margin-bottom: 0.3906vw;

            &:focus {
              border-width: 0.0977vw;
            }
          }
        }

        p {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
          margin-bottom: 0.9766vw;
        }

        label.custom-toggle-wrapper {
          font-size: 0.7813vw;
          line-height: 1.0742vw;
          padding-bottom: 1.4648vw;

          > {
            input {
              left: -488.2813vw;
            }

            .custom-toggle {
              width: 1.9531vw;
              height: 1.2207vw;
              border-radius: 1.2207vw;
              margin-left: 0.9766vw;

              &::after {
                top: calc(50% - 0.5371vw);
                left: 0.0732vw;
                width: 1.0742vw;
                height: 1.0742vw;
                -webkit-box-shadow: 0vw 0.0977vw 0.293vw rgba(0, 0, 0, 0.15),
                  0vw 0.0977vw 0.0488vw rgba(0, 0, 0, 0.06);
                box-shadow: 0vw 0.0977vw 0.293vw rgba(0, 0, 0, 0.15),
                  0vw 0.0977vw 0.0488vw rgba(0, 0, 0, 0.06);
              }
            }

            input:checked ~ .custom-toggle::after {
              right: 0.0732vw;
            }
          }
        }

        button {
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }
  }
}
