@media (orientation: portrait) {
  /* Add Tile */

  .subwindow-wrapper > .subwindow.add-tile {
    width: min(384px, 95%);
    height: min(425px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        label {
          width: 306px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 9px;
        }

        .dropdown-search {
          width: 306px;
          height: 44px;
          margin-bottom: 28px;
          z-index: 2;
          position: relative;
          line-height: 0;

          > {
            img.search {
              position: absolute;
              width: 24px;
              height: 24px;
              left: 10px;
              top: 10px;
              -webkit-filter: brightness(0) saturate(100%) invert(81%) sepia(4%)
                saturate(573%) hue-rotate(169deg) brightness(87%) contrast(85%);
              filter: brightness(0) saturate(100%) invert(81%) sepia(4%)
                saturate(573%) hue-rotate(169deg) brightness(87%) contrast(85%);
            }

            button#cancelDropdownSearch {
              display: none;
              position: absolute;
              width: 18px;
              height: 18px;
              right: 15px;
              top: 14.5px;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              background-color: transparent;
              border: 0;

              > img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &.open {
            > {
              img.search {
                -webkit-filter: none;
                filter: none;
              }

              button#cancelDropdownSearch {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
              }
            }

            > input {
              border-radius: 6px 6px 0 0;
            }

            > .option-container {
              display: block;
            }
          }

          > input {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border: 1px solid var(--oliver-border);
            border-radius: 6px;
            padding: 0 38px 0 40px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
          }

          > {
            input.error {
              border-color: var(--error);
            }

            .error-message {
              position: absolute;
              right: 0;
              top: -8px;
              -webkit-transform: translateY(-100%);
              -ms-transform: translateY(-100%);
              transform: translateY(-100%);
              display: none;
              line-height: 0;

              &::after {
                content: "*Error";
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: var(--error);
              }
            }

            input.error {
              + .error-message {
                display: block;
              }

              &.duplicate + .error-message::after {
                content: "*Product already exists";
              }
            }

            .option-container {
              display: none;
              width: 100%;

              /* height: fit-content; */
              max-height: 300%;
              border-radius: 0 0 6px 6px;
              overflow-y: scroll;
            }
          }

          > {
            .option-container {
              &:hover {
                cursor: pointer;
              }

              > .dropdown-option {
                width: 100%;
                height: 44px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 0 12px;
                background-color: #fff;
                border: 1px solid var(--oliver-border);
                border-top: 0;

                > p {
                  max-width: 100%;
                  overflow-x: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                &:hover {
                  background-color: var(--oliver-blue);

                  > p {
                    color: #fff;
                  }
                }
              }
            }

            .dropdown-option:last-child {
              border-radius: 0 0 6px 6px;
            }
          }
        }

        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 11px;
        }

        .radio-group {
          width: 235.86px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          margin-bottom: 11.04px;

          > label {
            margin-right: 17.26px;
            margin: 0 17.26px 14.96px 0;

            &:hover {
              cursor: pointer;
            }

            &:nth-child(4n) {
              margin-right: 0;
            }

            > {
              input[type="radio"] {
                position: fixed;
                left: -10000px;
                opacity: 0;
              }

              .custom-radio-button {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 46px;
                height: 46px;
                border-radius: 7px;
                background-color: #fff;
              }

              input[type="radio"] {
                &:checked ~ .custom-radio-button {
                  border: 2px solid #000 !important;
                }

                &:disabled ~ .custom-radio-button {
                  opacity: 0.25;
                }
              }
            }
          }
        }

        button {
          width: 306px;
          height: 50px;
          border: 0;
          border-radius: 8px;
          background-color: var(--oliver-blue);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.add-tile {
    width: 504px;
    height: min(420px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      > {
        label {
          width: 432px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 8px;
        }

        .dropdown-search {
          width: 432px;
          height: 44px;
          margin-bottom: 28px;
          z-index: 2;
          position: relative;
          line-height: 0;

          > {
            img.search {
              position: absolute;
              width: 24px;
              height: 24px;
              left: 10px;
              top: 10px;
              -webkit-filter: brightness(0) saturate(100%) invert(81%) sepia(4%)
                saturate(573%) hue-rotate(169deg) brightness(87%) contrast(85%);
              filter: brightness(0) saturate(100%) invert(81%) sepia(4%)
                saturate(573%) hue-rotate(169deg) brightness(87%) contrast(85%);
            }

            button#cancelDropdownSearch {
              display: none;
              position: absolute;
              width: 18px;
              height: 18px;
              right: 15px;
              top: 14.5px;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              background-color: transparent;
              border: 0;

              > img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &.open {
            > {
              img.search {
                -webkit-filter: none;
                filter: none;
              }

              button#cancelDropdownSearch {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
              }
            }

            > input {
              border-radius: 6px 6px 0 0;
            }

            > .option-container {
              display: block;
            }
          }

          > input {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border: 1px solid var(--oliver-border);
            border-radius: 6px;
            padding: 0 38px 0 40px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
          }

          > {
            input.error {
              border-color: var(--error);
            }

            .error-message {
              display: none;
              position: absolute;
              top: -8px;
              right: 0;
              -webkit-transform: translateY(-100%);
              -ms-transform: translateY(-100%);
              transform: translateY(-100%);

              &::after {
                content: "*Error";
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--error);
              }
            }

            input.error {
              + .error-message {
                display: block;
              }

              &.duplicate + .error-message::after {
                content: "*This product has already been added";
              }
            }

            .option-container {
              display: none;
              width: 100%;

              /* height: fit-content; */
              max-height: 400%;
              border-radius: 0 0 6px 6px;
              overflow-y: scroll;
            }
          }

          > {
            .option-container {
              &:hover {
                cursor: pointer;
              }

              > .dropdown-option {
                width: 100%;
                height: 44px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 0 12px;
                background-color: #fff;
                border: 1px solid var(--oliver-border);
                border-top: 0;

                > p {
                  max-width: 100%;
                  overflow-x: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                &:hover {
                  background-color: var(--oliver-blue);

                  > p {
                    color: #fff;
                  }
                }
              }
            }

            .dropdown-option:last-child {
              border-radius: 0 0 6px 6px;
            }
          }
        }

        p {
          width: 432px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 10px;
        }

        .radio-group {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 39px;

          > label {
            margin-right: 15px;

            &:hover {
              cursor: pointer;
            }

            &:last-of-type {
              margin-right: 0;
            }

            > {
              input[type="radio"] {
                position: fixed;
                left: -10000px;
                opacity: 0;
              }

              .custom-radio-button {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 40px;
                height: 40px;
                border-radius: 8px;
                background-color: #fff;
              }

              input[type="radio"] {
                &:checked ~ .custom-radio-button {
                  border: 2px solid #000 !important;
                }

                &:disabled ~ .custom-radio-button {
                  opacity: 0.25;
                }
              }
            }
          }
        }

        button {
          width: 432px;
          height: 44px;
          border: 0;
          border-radius: 6px;
          background-color: var(--oliver-blue);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;

          &:disabled {
            background-color: var(--oliver-new-grey);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.add-tile {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);

    > .subwindow-body > {
      label {
        width: 21.0938vw;
        font-size: 0.6836vw;
        line-height: 0.8789vw;
        margin-bottom: 0.3906vw;
      }

      .dropdown-search {
        width: 21.0938vw;
        height: 2.1484vw;
        margin-bottom: 1.3672vw;

        > {
          img.search {
            width: 1.1719vw;
            height: 1.1719vw;
            left: 0.4883vw;
            top: 0.4883vw;
          }

          button#cancelDropdownSearch {
            width: 0.8789vw;
            height: 0.8789vw;
            right: 0.7324vw;
            top: 0.708vw;
          }

          input {
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.293vw;
            padding: 0 1.8555vw 0 1.9531vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;
          }
        }

        &.open > input {
          border-radius: 0.293vw 0.293vw 0 0;
        }

        > {
          .error-message {
            top: -0.3906vw;

            &::after {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
            }
          }

          .option-container {
            border-radius: 0 0 0.293vw 0.293vw;

            > .dropdown-option {
              height: 2.1484vw;
              padding: 0 0.5859vw;
              border: 0.0488vw solid var(--oliver-border);

              > p {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
              }
            }
          }

          .dropdown-option:last-child {
            border-radius: 0 0 0.293vw 0.293vw;
          }
        }
      }

      p {
        width: 21.0938vw;
        font-size: 0.6836vw;
        line-height: 0.8789vw;
        margin-bottom: 0.4883vw;
      }

      .radio-group {
        margin-bottom: 1.9043vw;

        > label {
          margin-right: 0.7324vw;

          > {
            input[type="radio"] {
              left: -488.2813vw;
            }

            .custom-radio-button {
              width: 1.9531vw;
              height: 1.9531vw;
              border-radius: 0.3906vw;
            }

            input[type="radio"]:checked ~ .custom-radio-button {
              border: 0.0977vw solid #000 !important;
            }
          }
        }
      }

      button {
        width: 21.0938vw;
        height: 2.1484vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
