@media (orientation: portrait) {
  .subwindow.refund-show-payments {
    width: min(344px, 95%);

    > .subwindow-body {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      max-height: calc(95vh - 54px);
      padding: 20px 36px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: fit-content;
      overflow: auto;

      > {
        .row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          width: 100%;
          margin-top: 11px;

          &:nth-child(1) {
            margin-top: 0;
          }

          > p {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: var(--oliver-base-blue);

            > b {
              font-weight: 500;
            }
          }
        }

        button {
          width: 100%;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin-top: 33px;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow.refund-show-payments {
    width: min(504px, 95%);

    > .subwindow-body {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      max-height: calc(95vh - 65px);
      padding: 20px 36px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: fit-content;
      overflow: auto;

      > {
        .row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          width: 100%;
          margin-top: 11px;

          &:nth-child(1) {
            margin-top: 0;
          }

          > p {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: var(--oliver-base-blue);

            > b {
              font-weight: 500;
            }
          }
        }

        button {
          width: 100%;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin-top: 33px;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow.refund-show-payments {
    width: min(24.6094vw, 95%);

    > .subwindow-body {
      max-height: calc(95vh - 3.1738vw);
      padding: 0.9766vw 1.7578vw;

      > {
        .row {
          margin-top: 0.5371vw;

          > p {
            font-size: 0.8789vw;
            line-height: 1.1719vw;
          }
        }

        button {
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin-top: 1.6113vw;
        }
      }
    }
  }
}
