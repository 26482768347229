@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: min(384px, 95%);
    height: min(666px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        .text-row {
          width: 321px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 20px;

          > p {
            &.style1 {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            &.style2 {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              text-align: right;
              color: var(--oliver-base-blue);
            }
          }
        }

        .numpad-wrapper {
          width: 321px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 16px;
          background-color: rgba(212, 222, 229, 0.5);
          border-radius: 8px;
          margin-bottom: 14px;

          > {
            .input-wrapper {
              width: 100%;
              height: 93px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid var(--oliver-border);
              background-color: #fff;
              border-radius: 6px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > {
                p {
                  font-family: Poppins;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  text-align: center;
                  color: var(--oliver-base-grey);
                  margin-bottom: 11px;
                }

                input {
                  width: 90%;
                  height: 42px;
                  border: 0;
                  font-family: Poppins;
                  font-size: 40px;
                  font-weight: 400;
                  line-height: 40px;
                  text-align: center;
                  color: var(--oliver-blue);
                }
              }
            }

            .numpad-row {
              width: 100%;
              height: 68px;
              margin-top: 9px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;

              > button {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                margin-left: 9px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid var(--oliver-border);
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-family: Poppins;
                font-size: 30px;
                font-weight: 400;
                line-height: 45px;
                letter-spacing: 0px;
                text-align: center;
                color: var(--oliver-base-blue);

                &:first-of-type {
                  margin-left: 0;
                }

                > img {
                  width: 40px;
                  height: 40px;
                }
              }
            }
          }
        }

        button {
          width: 321px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: 504px;
    height: min(709px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        .text-row {
          width: 321px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 26px;

          > p {
            &.style1 {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            &.style2 {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 26px;
              text-align: right;
              color: var(--oliver-base-blue);
            }
          }
        }

        .numpad-wrapper {
          width: 321px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 16px;
          background-color: rgba(212, 222, 229, 0.5);
          border-radius: 8px;
          margin-bottom: 18px;

          > {
            .input-wrapper {
              width: 100%;
              height: 93px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid var(--oliver-border);
              background-color: #fff;
              border-radius: 6px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > {
                p {
                  font-family: Poppins;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  text-align: center;
                  color: var(--oliver-base-grey);
                  margin-bottom: 11px;
                }

                input {
                  width: 90%;
                  height: 42px;
                  border: 0;
                  font-family: Poppins;
                  font-size: 40px;
                  font-weight: 400;
                  line-height: 40px;
                  text-align: center;
                  color: var(--oliver-blue);
                }
              }
            }

            .numpad-row {
              width: 100%;
              height: 68px;
              margin-top: 9px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;

              > button {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                margin-left: 9px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid var(--oliver-border);
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-family: Poppins;
                font-size: 30px;
                font-weight: 400;
                line-height: 45px;
                letter-spacing: 0px;
                text-align: center;
                color: var(--oliver-base-blue);

                &:first-of-type {
                  margin-left: 0;
                }

                > img {
                  width: 40px;
                  height: 40px;
                }
              }
            }
          }
        }

        button {
          width: 321px;
          height: 60px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.store-credit-pay {
    width: 24.6094vw;
    height: min(34.6191vw, 95%);

    > .subwindow-body > {
      .text-row {
        width: 15.6738vw;
        margin-bottom: 1.2695vw;

        > p {
          &.style1 {
            font-size: 0.8789vw;
            line-height: 1.1719vw;
          }

          &.style2 {
            font-size: 0.9766vw;
            line-height: 1.2695vw;
          }
        }
      }

      .numpad-wrapper {
        width: 15.6738vw;
        padding: 0.7813vw;
        border-radius: 0.3906vw;
        margin-bottom: 0.8789vw;

        > {
          .input-wrapper {
            height: 4.541vw;
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.293vw;

            > {
              p {
                font-size: 0.5859vw;
                line-height: 0.8301vw;
                margin-bottom: 0.5371vw;
              }

              input {
                height: 2.0508vw;
                font-size: 1.9531vw;
                line-height: 1.9531vw;
              }
            }
          }

          .numpad-row {
            height: 3.3203vw;
            margin-top: 0.4395vw;

            > button {
              margin-left: 0.4395vw;
              border: 0.0488vw solid var(--oliver-border);
              border-radius: 0.293vw;
              font-size: 1.4648vw;
              line-height: 2.1973vw;
              letter-spacing: 0vw;

              > img {
                width: 1.9531vw;
                height: 1.9531vw;
              }
            }
          }
        }
      }

      button {
        width: 15.6738vw;
        height: 2.9297vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
