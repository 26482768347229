@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.product-discount {
    width: min(384px, 95%);
    height: min(344px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        label {
          width: 302px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 9px;
        }

        input {
          width: 302px;
          height: 83px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          outline: 0;
          font-family: Poppins;
          font-size: 40px;
          font-weight: 400;
          line-height: 56px;
          text-align: center;
          color: var(--oliver-blue);
          margin-bottom: 23px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        p {
          font-family: Poppins;
          font-size: 13px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-grey);
          margin-bottom: 12px;
        }

        .button-row {
          width: 302px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          > button {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 44px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;

            &:first-of-type {
              margin-right: 14px;
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.product-discount {
    width: 504px;
    height: min(420px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        label {
          width: 348px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 9px;
        }

        input {
          width: 348px;
          height: 83px;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          outline: 0;
          font-family: Poppins;
          font-size: 40px;
          font-weight: 400;
          line-height: 56px;
          text-align: center;
          color: var(--oliver-blue);
          margin-bottom: 23px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        p {
          width: 348px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 10px;
        }

        .button-row {
          width: 348px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          > button {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 44px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #fff;

            &:first-of-type {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.product-discount {
    width: 24.6094vw;
    height: min(20.5078vw, 95%);

    > .subwindow-body > {
      label {
        width: 16.9922vw;
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.4395vw;
      }

      input {
        width: 16.9922vw;
        height: 4.0527vw;
        border: 0.0488vw solid var(--oliver-border);
        border-radius: 0.293vw;
        font-size: 1.9531vw;
        line-height: 2.7344vw;
        margin-bottom: 1.123vw;
      }

      p {
        width: 16.9922vw;
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.4883vw;
      }

      .button-row {
        width: 16.9922vw;

        > button {
          height: 2.1484vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;

          &:first-of-type {
            margin-right: 0.7813vw;
          }
        }
      }
    }
  }
}
