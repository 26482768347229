

@media (orientation: portrait) {
  .idle-register-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: scroll;

    > {
      #logoutRegisterButton {
        position: absolute;
        left: 0;
        padding: 5px;
        background-color: transparent;
        border: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: #fff;

        > img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }

      header {
        width: 343px;
        padding: 30px 0;
        border-bottom: 1px solid #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        > {
          img {
            width: 106.86px;
            height: 97.72px;
            margin-right: 28.14px;
          }

          .col {
            max-width: 65%;

            > {
              p {
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                   text-overflow: ellipsis;

                &.style1 {
                  
                  font-size: 30px;
                  font-weight: 500;
                  line-height: 43px;
                  text-align: left;
                  color: #fff;
                }

                &.style2 {
                  
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 28px;
                  text-align: left;
                  color: #fff;
                  margin-bottom: -8px;
                }

                &.style3 {
                  
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 28px;
                  text-align: left;
                  color: #fff;
                }
              }

              button#closeRegister1 {
                display: none;
              }
            }
          }
        }
      }

      main {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        > {
          p {
            
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
            color: #fff;
            margin: 30px 0 19.47px 0;
          }

          .pinpad {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            width: 278px;
            margin-bottom: 10px;

            > {
              p.error-message {
                padding: 11px 28px;
                background-color: #ffe7e8;
                border-radius: 8px;
                
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: var(--error);
                margin: -10px 0 20px 0;
              }

              .pin-entries {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-bottom: 17px;

                > .pin-entry {
                  width: 16px;
                  height: 16px;
                  background-color: #fff;
                  margin-left: 10px;
                  border-radius: 50%;

                  &:first-child {
                    margin: 0;
                  }

                  &.entered {
                    background-color: var(--oliver-base-blue);
                  }
                }

                &.incorrect {
                  -webkit-animation: shake 0.3s;
                          animation: shake 0.3s;

                  > .pin-entry {
                    background-color: var(--oliver-red);
                  }
                }
              }

              .pin-button-row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                gap: 16px;
                margin-bottom: 16px;

                > button {
                  width: 82px;
                  height: 82px;
                  -webkit-box-sizing: border-box;
                          box-sizing: border-box;
                  border: 0;
                  border-radius: 8px;
                  background-color: #fff;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                          flex-direction: column;
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                  -webkit-box-pack: center;
                      -ms-flex-pack: center;
                          justify-content: center;
                }

                &:last-child > button:first-child {
                  margin-left: auto;
                }

                > button {
                  &.backspace {
                    background-color: transparent;
                    border: 0;

                    > img {
                      width: 50px;
                      height: 40px;
                    }
                  }

                  > {
                    p {
                      
                      font-size: 36px;
                      font-weight: 400;
                      line-height: 36px;
                      text-align: center;
                      color: var(--oliver-base-blue);
                    }

                    .text-row {
                      
                      font-size: 10px;
                      font-weight: 300;
                      line-height: 15px;
                      letter-spacing: 0px;
                      text-align: center;
                      color: var(--oliver-base-blue);

                      &.no-opacity {
                        opacity: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          button#closeRegister2 {
            width: 337px;
            height: 50px;
            background-color: var(--oliver-base-blue);
            border: 0;
            border-radius: 8px;
            
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin: auto 0 20px 0;
          }
        }
      }
    }
  }
}

@media (orientation: portrait) and (max-width: 375px) {
  .idle-register-wrapper > {
    #logoutRegisterButton {
      padding: 1.3333vw;
      font-size: 4.2667vw;
      line-height: 6.9333vw;

      > img {
        width: 5.3333vw;
        height: 5.3333vw;
        margin-right: 1.3333vw;
      }
    }

    header {
      width: 91.4667vw;
      padding: 8vw 0;
      border-bottom: 0.2667vw solid #fff;

      > {
        img {
          width: 28.496vw;
          height: 26.0587vw;
          margin-right: 7.504vw;
        }

        .col > p {
          &.style1 {
            font-size: 8vw;
            line-height: 11.4667vw;
          }

          &.style2 {
            font-size: 4.2667vw;
            line-height: 7.4667vw;
            margin-bottom: -2.1333vw;
          }

          &.style3 {
            font-size: 4.2667vw;
            line-height: 7.4667vw;
          }
        }
      }
    }

    main > {
      p {
        font-size: 4.2667vw;
        line-height: 6.1333vw;
        margin: 8vw 0 5.192vw 0;
      }

      .pinpad {
        width: 74.1333vw;
        margin-bottom: 2.6667vw;

        > {
          p.error-message {
            padding: 2.9333vw 7.4667vw;
            border-radius: 2.1333vw;
            font-size: 3.7333vw;
            line-height: 5.3333vw;
            margin: -2.6667vw 0 5.3333vw 0;
          }

          .pin-entries {
            margin-bottom: 4.5333vw;

            > .pin-entry {
              width: 4.2667vw;
              height: 4.2667vw;
              margin-left: 2.6667vw;
            }
          }

          .pin-button-row {
            gap: 4.2667vw;
            margin-bottom: 4.2667vw;

            > button {
              width: 21.8667vw;
              height: 21.8667vw;
              border-radius: 2.1333vw;

              &.backspace > img {
                width: 13.3333vw;
                height: 10.6667vw;
              }

              > {
                p {
                  font-size: 9.6vw;
                  line-height: 9.6vw;
                }

                .text-row {
                  font-size: 2.6667vw;
                  line-height: 4vw;
                  letter-spacing: 0vw;
                }
              }
            }
          }
        }
      }

      button#closeRegister2 {
        width: 89.8667vw;
        height: 13.3333vw;
        border-radius: 2.1333vw;
        font-size: 4.8vw;
        line-height: 6.4vw;
        margin: auto 0 5.3333vw 0;
      }
    }
  }
}

@media (orientation: landscape) {
  .idle-register-wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--oliver-blue);
    overflow: auto;

    > {
      #logoutRegisterButton {
        position: absolute;
        padding: 10px;
        top: 14px;
        left: 14px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        background-color: transparent;
        border: 0;
        
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        color: #fff;

        > img {
          width: 24px;
          height: 24px;
          margin-right: 7px;
        }
      }

      header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin: auto 171px auto auto;

        > {
          img {
            width: 276.68px;
            height: 253px;
            margin: 20px 0 17px 0;
          }

          .col > {
            p {
              max-width: 30vw;
              -o-text-overflow: ellipsis;
                 text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              &.style1 {
                
                font-size: 48px;
                font-weight: 300;
                line-height: 69px;
                text-align: center;
                color: #fff;
                margin-bottom: 10px;
              }

              &.style2 {
                
                font-size: 24px;
                font-weight: 500;
                line-height: 28px;
                text-align: center;
                color: #fff;
                margin-bottom: 1px;
              }

              &.style3 {
                
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                text-align: center;
                color: #fff;
                margin-bottom: 17px;
              }
            }

            .divider {
              width: 261px;
              height: 1px;
              background-color: #fff;
              margin-bottom: 14px;
            }

            button#closeRegister1 {
              width: 261px;
              height: 50px;
              border: 0;
              border-radius: 4px;
              background-color: var(--oliver-base-blue);
              
              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              color: #fff;
              margin-bottom: 20px;
            }
          }
        }
      }

      main {
        margin: auto auto auto 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

        > {
          p {
            
            font-size: 20px;
            font-weight: 400;
            line-height: 29px;
            text-align: center;
            color: #fff;
            margin: 20px 0 24.69px 0;
          }

          .pinpad {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            width: 278px;
            margin-bottom: 10px;

            > {
              p.error-message {
                padding: 11px 28px;
                background-color: #ffe7e8;
                border-radius: 8px;
                
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: var(--error);
                margin: -10px 0 20px 0;
              }

              .pin-entries {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-bottom: 17px;

                > .pin-entry {
                  width: 16px;
                  height: 16px;
                  background-color: #fff;
                  margin-left: 10px;
                  border-radius: 50%;

                  &:first-child {
                    margin: 0;
                  }

                  &.entered {
                    background-color: var(--oliver-base-blue);
                  }
                }

                &.incorrect {
                  -webkit-animation: shake 0.3s;
                          animation: shake 0.3s;

                  > .pin-entry {
                    background-color: var(--oliver-red);
                  }
                }
              }

              .pin-button-row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                gap: 16px;
                margin-bottom: 16px;

                > button {
                  width: 82px;
                  height: 82px;
                  -webkit-box-sizing: border-box;
                          box-sizing: border-box;
                  border: 0;
                  border-radius: 8px;
                  background-color: #fff;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                          flex-direction: column;
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                  -webkit-box-pack: center;
                      -ms-flex-pack: center;
                          justify-content: center;
                }

                &:last-child > button:first-child {
                  margin-left: auto;
                }

                > button {
                  &.backspace {
                    background-color: transparent;
                    border: 0;

                    > img {
                      width: 50px;
                      height: 40px;
                    }
                  }

                  > {
                    p {
                      
                      font-size: 36px;
                      font-weight: 400;
                      line-height: 36px;
                      text-align: center;
                      color: var(--oliver-base-blue);
                    }

                    .text-row {
                      
                      font-size: 10px;
                      font-weight: 300;
                      line-height: 15px;
                      letter-spacing: 0px;
                      text-align: center;
                      color: var(--oliver-base-blue);

                      &.no-opacity {
                        opacity: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          button#closeRegister2 {
            display: none;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .idle-register-wrapper > {
    #logoutRegisterButton {
      padding: 0.4883vw;
      top: 0.6836vw;
      left: 0.6836vw;
      font-size: 0.9766vw;
      line-height: 1.4648vw;

      > img {
        width: 1.1719vw;
        height: 1.1719vw;
        margin-right: 0.3418vw;
      }
    }

    header {
      margin: auto 8.3496vw auto auto;

      > {
        img {
          width: 13.5098vw;
          height: 12.3535vw;
          margin: 0.9766vw 0 0.8301vw 0;
        }

        .col > {
          p {
            &.style1 {
              font-size: 2.3438vw;
              line-height: 3.3691vw;
              margin-bottom: 0.4883vw;
            }

            &.style2 {
              font-size: 1.1719vw;
              line-height: 1.3672vw;
              margin-bottom: 0.0488vw;
            }

            &.style3 {
              font-size: 0.8789vw;
              line-height: 1.3672vw;
              margin-bottom: 0.8301vw;
            }
          }

          .divider {
            width: 12.7441vw;
            height: 0.0488vw;
            margin-bottom: 0.6836vw;
          }

          button#closeRegister1 {
            width: 12.7441vw;
            height: 2.4414vw;
            border-radius: 0.1953vw;
            font-size: 0.8789vw;
            line-height: 1.1719vw;
            margin-bottom: 0.9766vw;
          }
        }
      }
    }

    main > {
      p {
        font-size: 0.9766vw;
        line-height: 1.416vw;
        margin: 0.9766vw 0 1.2056vw 0;
      }

      .pinpad {
        width: 13.5742vw;
        margin-bottom: 0.4883vw;

        > {
          p.error-message {
            padding: 0.5371vw 1.3672vw;
            border-radius: 0.3906vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;
            margin: -0.4883vw 0 0.9766vw 0;
          }

          .pin-entries {
            margin-bottom: 0.8301vw;

            > .pin-entry {
              width: 0.7813vw;
              height: 0.7813vw;
              margin-left: 0.4883vw;
            }
          }

          .pin-button-row {
            gap: 0.7813vw;
            margin-bottom: 0.7813vw;

            > button {
              width: 4.0039vw;
              height: 4.0039vw;
              border-radius: 0.3906vw;

              &.backspace > img {
                width: 2.4414vw;
                height: 1.9531vw;
              }

              > {
                p {
                  font-size: 1.7578vw;
                  line-height: 1.7578vw;
                }

                .text-row {
                  font-size: 0.4883vw;
                  line-height: 0.7324vw;
                  letter-spacing: 0vw;
                }
              }
            }
          }
        }
      }
    }
  }
}

