@media (orientation: portrait) {
  /* Transactions Detailed */

  .transactions-detailed {
    position: fixed;
    top: 70px;
    background-color: #fff;
    z-index: 3;
    width: 100%;
    height: calc(100% - 70px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-y: scroll;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    &.open {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    > {
      .no-orders-detailed {
        display: none;
      }

      .detailed-header-mobile {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > #mobileDetailedExit {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          background-color: transparent;
          border: 0;

          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: var(--oliver-blue);
          margin-right: auto;

          > img {
            width: 9px;
            height: 16px;
            margin-right: 9px;
          }
        }
      }

      .scrollable {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > {
          .quick-info {
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            background-color: #e9f5fd;

            > .row {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-top: 5px;

              &:first-child {
                margin-top: 0;
              }

              > {
                * {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex: 1;
                  flex: 1;
                }

                p {
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;

                  &:nth-child(2) {
                    text-align: right !important;
                  }

                  &.style1 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: var(--oliver-base-blue);
                  }

                  &.style2 {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    color: var(--oliver-base-grey);
                  }

                  &.style3 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-blue);

                    > b {
                      font-weight: 600;
                    }
                  }
                }

                .group {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;

                  > {
                    img {
                      width: 18px;
                      height: 16px;
                      margin-right: 8px;
                    }

                    p {
                      width: 0;
                      -webkit-box-flex: 1;
                      -ms-flex-positive: 1;
                      flex-grow: 1;

                      font-size: 16px;
                      font-weight: 600;
                      line-height: 21px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }
                  }
                }
              }
            }

            p.red {
              color: var(--error) !important;
            }

            > .row > .group {
              > p.style1 {
                font-weight: 500;
                display: contents;

                + img {
                  cursor: pointer;

                  &:active {
                    scale: 0.85;
                  }
                }
              }

              -webkit-box-pack: end;

              -ms-flex-pack: end;

              justify-content: end;
              gap: 6px;
            }

            &.refund {
              > .row > .group > p.style1 {
                color: var(--error);
              }

              background-color: #ff525f1a;

              > .row > .group > p.style1 + img {
                -webkit-filter: invert(100%) sepia(67%) saturate(2056%)
                  hue-rotate(332deg) brightness(121%) contrast(103%);
                filter: invert(100%) sepia(67%) saturate(2056%)
                  hue-rotate(332deg) brightness(121%) contrast(103%);
              }
            }

            > .row > .group > p.style1 {
              color: var(--oliver-blue);
            }

            &.completed {
              background-color: #e9f5fd !important;
            }

            p > b.strikethrough {
              text-decoration: line-through !important;
            }
          }

          .customer-info {
            width: calc(100% - 40px);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 15px 0 20px 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            border-bottom: 1px solid var(--oliver-border);

            > {
              .col {
                width: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                > p {
                  &.style1 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-bottom: 8px;
                  }

                  &.style2 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-bottom: 4px;
                    max-width: 95%;
                    overflow: hidden;
                    white-space: nowrap;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                  }

                  &:last-of-type {
                    margin: 0;
                  }
                }
              }

              button {
                width: 154px;
                height: 40px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid var(--oliver-blue);
                border-radius: 6px;
                background-color: #fff;

                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: var(--oliver-blue);
              }
            }
          }

          .order-details {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            padding: 15px 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            min-height: 100px;

            > {
              p {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 24px;
              }

              .item {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-top: 20px;

                &:nth-child(2) {
                  margin-top: 0;
                }

                > {
                  .img-container {
                    position: relative;
                    width: 68px;
                    height: 69px;
                    margin-right: 24px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border-radius: 4px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    line-height: 0;

                    > {
                      .quantity {
                        position: absolute;
                        min-width: 26px;
                        height: 26px;
                        padding: 0 3px;
                        right: 0px;
                        top: 0px;
                        background-color: #fff;
                        border-radius: 6px;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        border: 0;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        background-color: var(--oliver-blue);

                        > p {
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: center;
                          color: #fff;
                        }
                      }

                      img {
                        width: 100%;
                        height: auto;
                        border-radius: 4px;
                      }
                    }
                  }

                  .col {
                    width: 0;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;

                    > {
                      .main-row {
                        width: 100%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        > p {
                          font-size: 14px;
                          font-weight: 500;
                          line-height: 20px;
                          text-align: left;
                          color: var(--oliver-base-grey);

                          > b {
                            color: var(--oliver-base-blue);
                            font-weight: 500;
                          }

                          &:first-of-type {
                            color: var(--oliver-base-blue);
                            margin-right: auto;
                          }
                        }
                      }

                      .item-fields {
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        width: 100%;
                        padding-top: 6px;

                        > p {
                          max-width: 100%;
                          overflow: hidden;
                          white-space: nowrap;
                          -o-text-overflow: ellipsis;
                          text-overflow: ellipsis;

                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: left;
                          color: var(--oliver-base-grey);
                        }
                      }

                      .modifier-fields {
                        width: 100%;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        padding: 8px 0 0 13px;

                        > .row {
                          width: 100%;
                          display: -webkit-box;
                          display: -ms-flexbox;
                          display: flex;

                          > p {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19px;
                            text-align: left;
                            color: var(--oliver-base-grey);
                            width: 0;
                            -webkit-box-flex: 1;
                            -ms-flex: 1;
                            flex: 1;
                            max-width: 100%;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            padding-right: 10px;

                            &:last-of-type {
                              text-align: right;
                              padding-right: 0;
                            }
                          }
                        }
                      }

                      .product-total-fields {
                        width: 100%;
                        padding-top: 8px;

                        > div {
                          width: 100%;
                          padding-top: 6px;
                          border-top: 1px solid var(--oliver-border);
                          margin-top: 6px;

                          &:first-of-type {
                            border-top: 0 !important;
                            padding-top: 0 !important;
                            margin-top: 0 !important;
                          }

                          > .row {
                            width: 100%;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;

                            > p {
                              font-size: 14px;
                              font-weight: 400;
                              line-height: 19px;
                              text-align: left;
                              color: var(--oliver-base-grey);
                              width: 0;
                              -webkit-box-flex: 1;
                              -ms-flex: 1;
                              flex: 1;
                              padding-right: 10px;
                              max-width: 100%;
                              overflow: hidden;
                              white-space: nowrap;
                              -o-text-overflow: ellipsis;
                              text-overflow: ellipsis;

                              &:last-of-type {
                                text-align: right;
                                padding-right: 0;
                              }

                              > b {
                                font-weight: 500;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .custom-fields {
                width: 100%;
                margin-top: 15px;

                > p {
                  &.style1 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-bottom: 7px;
                  }

                  &.style2 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-left: 14px;
                    max-width: calc(100% - 14px);
                    overflow: hidden;
                    white-space: nowrap;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    margin-top: 3px;

                    &:nth-child(2) {
                      margin-top: 0;
                    }
                  }
                }
              }

              .transaction-totals-wrapper {
                width: 100%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 15px 0;
                border-top: 2px solid var(--oliver-border);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                margin-top: 15px;

                > .transaction-totals {
                  width: min(400px, 75%);

                  > .row {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding-bottom: 6px;

                    &.border-bottom {
                      border-bottom: 1px solid var(--oliver-border);
                      margin-bottom: 11px;
                    }

                    &.border-top {
                      padding: 6px 0 0 0;
                      border-top: 1px solid var(--oliver-border);
                    }

                    > p {
                      &:nth-child(2) {
                        text-align: right;
                        width: 0;
                        -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;
                        overflow: hidden;
                        white-space: nowrap;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                      }

                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-blue);

                      > b {
                        font-weight: 500;

                        &.bold2 {
                          font-weight: 600;
                        }
                      }

                      &.style2 {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        text-align: left;
                        color: var(--oliver-base-grey);
                      }
                    }
                  }
                }
              }

              .refund-header {
                width: 100%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 5px 8px;
                background-color: #f1f4f6;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin: 25px 0 15px 0;

                > p {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex: 1;
                  flex: 1;
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;

                  &.style1 {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    text-align: left;
                    padding-right: 10px;
                    color: var(--oliver-base-blue);
                  }

                  &.style2 {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: right;
                    color: var(--oliver-base-blue);
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        position: relative;
        width: calc(100% - 32px);
        padding: 81px 0 16px 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        > button {
          &:not(#receiptButton) {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 54px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;

            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }

          &:last-of-type {
            margin-left: 19px;
          }

          &#receiptButton {
            width: 100%;
            position: absolute;
            height: 54px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid var(--oliver-blue);
            background-color: #fff;
            border-radius: 6px;
            top: 16px;

            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-blue);
          }

          &#refundButtonLandscape {
            display: none;
          }
        }
      }
    }
  }

  /* Color for refund state */
}

@media (orientation: landscape) {
  /* Transaction Detailed */

  .transactions-detailed {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: auto;

    > {
      .no-orders-detailed {
        margin: auto 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        display: none;

        &:only-child {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }

        > p {
          &.style1 {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            color: var(--oliver-base-grey);
            margin-bottom: 10px;
          }

          &.style2 {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: var(--oliver-base-grey);
          }
        }
      }

      .detailed-header-mobile {
        display: none;
      }

      .scrollable {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        overflow: auto;

        /* padding-top: 15px; */

        > {
          .quick-info {
            width: calc(100% - 40px);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 15px;
            margin-top: 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            background-color: #e9f5fd;

            &.refund {
              background-color: rgba(255, 82, 95, 0.1);

              > .row > .group > p.style1 {
                color: var(--error);

                + img {
                  -webkit-filter: invert(100%) sepia(67%) saturate(2056%)
                    hue-rotate(332deg) brightness(121%) contrast(103%);
                  filter: invert(100%) sepia(67%) saturate(2056%)
                    hue-rotate(332deg) brightness(121%) contrast(103%);
                }
              }
            }

            > .row {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-top: 5px;

              &:first-child {
                margin-top: 0;
              }

              > {
                * {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex: 1;
                  flex: 1;
                }

                p {
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;

                  &:nth-child(2) {
                    text-align: right !important;
                  }

                  &.style1 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: var(--oliver-base-blue);
                  }

                  &.style2 {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    color: var(--oliver-base-grey);
                  }

                  &.style3 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-blue);

                    > b {
                      font-weight: 600;
                    }
                  }
                }

                .group {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;

                  > {
                    img {
                      width: 18px;
                      height: 16px;
                      margin-right: 8px;
                    }

                    p {
                      width: 0;
                      -webkit-box-flex: 1;
                      -ms-flex-positive: 1;
                      flex-grow: 1;

                      font-size: 16px;
                      font-weight: 600;
                      line-height: 21px;
                      text-align: left;
                      color: var(--oliver-base-blue);

                      &.style1 {
                        font-weight: 500;
                        display: contents;

                        + img {
                          cursor: pointer;

                          &:active {
                            scale: 0.85;
                          }
                        }

                        color: var(--oliver-blue);
                      }
                    }
                  }

                  -webkit-box-pack: end;

                  -ms-flex-pack: end;

                  justify-content: end;
                  gap: 6px;
                }
              }
            }

            &.completed {
              background-color: #e9f5fd !important;
            }

            p > b.strikethrough {
              text-decoration: line-through !important;
            }
          }

          .customer-info {
            width: calc(100% - 40px);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 15px 0 20px 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            border-bottom: 1px solid var(--oliver-border);

            > {
              .col {
                width: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                > p {
                  &.style1 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-bottom: 8px;
                  }

                  &.style2 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-bottom: 4px;
                    max-width: 95%;
                    overflow: hidden;
                    white-space: nowrap;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                  }

                  &:last-of-type {
                    margin: 0;
                  }
                }
              }

              button {
                width: 154px;
                height: 40px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid var(--oliver-blue);
                border-radius: 6px;
                background-color: #fff;

                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: var(--oliver-blue);
              }
            }
          }

          .order-details {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            padding: 15px 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            min-height: 100px;

            > {
              p {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 24px;
              }

              .item {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-top: 20px;

                &:nth-child(2) {
                  margin-top: 0;
                }

                > {
                  .img-container {
                    position: relative;
                    width: 68px;
                    height: 69px;
                    margin-right: 24px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border-radius: 4px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    line-height: 0;

                    > {
                      .quantity {
                        position: absolute;
                        min-width: 26px;
                        height: 26px;
                        padding: 0 3px;
                        right: 0px;
                        top: 0px;
                        background-color: #fff;
                        border-radius: 6px;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        border: 0;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        background-color: var(--oliver-blue);

                        > p {
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: center;
                          color: #fff;
                        }
                      }

                      img {
                        width: 100%;
                        height: auto;
                        border-radius: 4px;
                      }
                    }
                  }

                  .col {
                    width: 0;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;

                    > {
                      .main-row {
                        width: 100%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        > p {
                          font-size: 14px;
                          font-weight: 500;
                          line-height: 20px;
                          text-align: left;
                          color: var(--oliver-base-grey);

                          > b {
                            color: var(--oliver-base-blue);
                            font-weight: 500;
                          }

                          &:first-of-type {
                            color: var(--oliver-base-blue);
                            margin-right: auto;
                          }
                        }
                      }

                      .item-fields {
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        width: 100%;
                        padding-top: 6px;

                        > p {
                          max-width: 100%;
                          overflow: hidden;
                          white-space: nowrap;
                          -o-text-overflow: ellipsis;
                          text-overflow: ellipsis;

                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: left;
                          color: var(--oliver-base-grey);
                        }
                      }

                      .modifier-fields {
                        width: 100%;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        padding: 8px 0 0 13px;

                        > .row {
                          width: 100%;
                          display: -webkit-box;
                          display: -ms-flexbox;
                          display: flex;

                          > p {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19px;
                            text-align: left;
                            color: var(--oliver-base-grey);
                            width: 0;
                            -webkit-box-flex: 1;
                            -ms-flex: 1;
                            flex: 1;
                            max-width: 100%;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            padding-right: 10px;

                            &:last-of-type {
                              text-align: right;
                              padding-right: 0;
                            }
                          }
                        }
                      }

                      .product-total-fields {
                        width: 100%;
                        padding-top: 8px;

                        > div {
                          width: 100%;
                          padding-top: 6px;
                          border-top: 1px solid var(--oliver-border);
                          margin-top: 6px;

                          &:first-of-type {
                            border-top: 0 !important;
                            padding-top: 0 !important;
                            margin-top: 0 !important;
                          }

                          > .row {
                            width: 100%;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;

                            > p {
                              font-size: 14px;
                              font-weight: 400;
                              line-height: 19px;
                              text-align: left;
                              color: var(--oliver-base-grey);
                              width: 0;
                              -webkit-box-flex: 1;
                              -ms-flex: 1;
                              flex: 1;
                              padding-right: 10px;
                              max-width: 100%;
                              overflow: hidden;
                              white-space: nowrap;
                              -o-text-overflow: ellipsis;
                              text-overflow: ellipsis;

                              &:last-of-type {
                                text-align: right;
                                padding-right: 0;
                              }

                              > b {
                                font-weight: 500;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .custom-fields {
                width: 100%;
                margin-top: 15px;

                > p {
                  &.style1 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-bottom: 7px;
                  }

                  &.style2 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-left: 14px;
                    max-width: calc(100% - 14px);
                    overflow: hidden;
                    white-space: nowrap;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    margin-top: 3px;

                    &:nth-child(2) {
                      margin-top: 0;
                    }
                  }
                }
              }

              .transaction-totals-wrapper {
                width: 100%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 15px 0;
                border-top: 2px solid var(--oliver-border);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                margin-top: 15px;

                > .transaction-totals {
                  width: min(400px, 75%);

                  > .row {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding-bottom: 6px;

                    &.border-bottom {
                      border-bottom: 1px solid var(--oliver-border);
                      margin-bottom: 11px;
                    }

                    &.border-top {
                      padding: 6px 0 0 0;
                      border-top: 1px solid var(--oliver-border);
                    }

                    > p {
                      &:nth-child(2) {
                        text-align: right;
                        width: 0;
                        -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;
                        overflow: hidden;
                        white-space: nowrap;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                      }

                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-blue);

                      > b {
                        font-weight: 500;

                        &.bold2 {
                          font-weight: 600;
                        }
                      }

                      &.style2 {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        text-align: left;
                        color: var(--oliver-base-grey);
                      }
                    }
                  }
                }
              }

              .refund-header {
                width: 100%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 5px 8px;
                background-color: #f1f4f6;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin: 25px 0 15px 0;

                > p {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex: 1;
                  flex: 1;
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;

                  &.style1 {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    text-align: left;
                    padding-right: 10px;
                    color: var(--oliver-base-blue);
                  }

                  &.style2 {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: right;
                    color: var(--oliver-base-blue);
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 15px 20px;
        border-top: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > button {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          height: 44px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;

          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin-left: 12px;

          &#refundButtonLandscape {
            margin-left: 0;
          }

          &#refundButtonMobile {
            display: none;
          }
        }
      }
    }
  }

  /* Background color for refund */

  /* Color for refund state */

  /* Color for refund state */
}

@media (orientation: landscape) and (min-width: 2048px) {
  .transactions-detailed > {
    .no-orders-detailed > p {
      &.style1 {
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.4883vw;
      }

      &.style2 {
        font-size: 0.6348vw;
        line-height: 0.8789vw;
      }
    }

    .scrollable > {
      .quick-info {
        width: calc(100% - 1.9531vw);
        padding: 0.7324vw;
        margin-top: 0.7324vw;

        > .row {
          margin-top: 0.2441vw;

          > {
            p {
              &.style1 {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
              }

              &.style2 {
                font-size: 0.6348vw;
                line-height: 0.8789vw;
              }

              &.style3 {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
              }
            }

            .group > {
              img {
                width: 0.8789vw;
                height: 0.7813vw;
                margin-right: 0.3906vw;
              }

              p {
                font-size: 0.7813vw;
                line-height: 1.0254vw;
              }
            }
          }
        }
      }

      .customer-info {
        width: calc(100% - 1.9531vw);
        padding: 0.7324vw 0 0.9766vw 0;
        border-bottom: 0.0488vw solid var(--oliver-border);

        > {
          .col > p {
            &.style1 {
              font-size: 0.7813vw;
              line-height: 1.1719vw;
              margin-bottom: 0.3906vw;
            }

            &.style2 {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
              margin-bottom: 0.1953vw;
            }
          }

          button {
            width: 7.5195vw;
            height: 1.9531vw;
            border: 0.0488vw solid var(--oliver-blue);
            border-radius: 0.293vw;
            font-size: 0.7813vw;
            line-height: 1.1719vw;
          }
        }
      }

      .order-details {
        padding: 0.7324vw 0.9766vw;
        min-height: 4.8828vw;

        > {
          p {
            font-size: 0.7813vw;
            line-height: 1.1719vw;
            margin-bottom: 1.1719vw;
          }

          .item {
            margin-top: 0.9766vw;

            > {
              .img-container {
                width: 3.3203vw;
                height: 3.3691vw;
                margin-right: 1.1719vw;
                border-radius: 0.1953vw;

                > {
                  .quantity {
                    min-width: 1.2695vw;
                    height: 1.2695vw;
                    padding: 0 0.1465vw;
                    right: 0vw;
                    top: 0vw;
                    border-radius: 0.293vw;

                    > p {
                      font-size: 0.6836vw;
                      line-height: 0.9766vw;
                    }
                  }

                  img {
                    border-radius: 0.1953vw;
                  }
                }
              }

              .col > {
                .main-row > p {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                }

                .item-fields {
                  padding-top: 0.293vw;

                  > p {
                    font-size: 0.6836vw;
                    line-height: 0.9766vw;
                  }
                }

                .modifier-fields {
                  padding: 0.3906vw 0 0 0.6348vw;

                  > .row > p {
                    font-size: 0.6836vw;
                    line-height: 0.9277vw;
                    padding-right: 0.4883vw;
                  }
                }

                .product-total-fields {
                  padding-top: 0.3906vw;

                  > div {
                    padding-top: 0.293vw;
                    border-top: 0.0488vw solid var(--oliver-border);
                    margin-top: 0.293vw;

                    > .row > p {
                      font-size: 0.6836vw;
                      line-height: 0.9277vw;
                      padding-right: 0.4883vw;
                    }
                  }
                }
              }
            }
          }

          .custom-fields {
            margin-top: 0.7324vw;

            > p {
              &.style1 {
                font-size: 0.7813vw;
                line-height: 1.1719vw;
                margin-bottom: 0.3418vw;
              }

              &.style2 {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
                margin-left: 0.6836vw;
                max-width: calc(100% - 0.6836vw);
                margin-top: 0.1465vw;
              }
            }
          }

          .transaction-totals-wrapper {
            padding: 0.7324vw 0;
            border-top: 0.0977vw solid var(--oliver-border);
            margin-top: 0.7324vw;

            > .transaction-totals {
              width: min(19.5313vw, 75%);

              > .row {
                padding-bottom: 0.293vw;

                &.border-bottom {
                  border-bottom: 0.0488vw solid var(--oliver-border);
                  margin-bottom: 0.5371vw;
                }

                &.border-top {
                  padding: 0.293vw 0 0 0;
                  border-top: 0.0488vw solid var(--oliver-border);
                }

                > p {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;

                  &.style2 {
                    font-size: 0.5859vw;
                    line-height: 0.8301vw;
                  }
                }
              }
            }
          }

          .refund-header {
            padding: 0.2441vw 0.3906vw;
            margin: 1.2207vw 0 0.7324vw 0;

            > p {
              &.style1 {
                font-size: 0.6836vw;
                line-height: 1.0254vw;
                padding-right: 0.4883vw;
              }

              &.style2 {
                font-size: 0.5859vw;
                line-height: 0.8789vw;
              }
            }
          }
        }
      }
    }

    .footer {
      padding: 0.7324vw 0.9766vw;
      border-top: 0.0488vw solid var(--oliver-border);

      > button {
        height: 2.1484vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
        margin-left: 0.5859vw;
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .transactions-detailed > {
    .no-orders-detailed > p {
      &.style1 {
        font-size: 1.1667vw;
        line-height: 1.6667vw;
        margin-bottom: 0.8333vw;
      }

      &.style2 {
        font-size: 1.0833vw;
        line-height: 1.5vw;
      }
    }

    .scrollable > {
      .quick-info {
        width: calc(100% - 3.3333vw);
        padding: 1.25vw;
        margin-top: 1.25vw;

        > .row {
          margin-top: 0.4167vw;

          > {
            p {
              &.style1 {
                font-size: 1.1667vw;
                line-height: 1.6667vw;
              }

              &.style2 {
                font-size: 1.0833vw;
                line-height: 1.5vw;
              }

              &.style3 {
                font-size: 1.1667vw;
                line-height: 1.6667vw;
              }
            }

            .group > {
              img {
                width: 1.5vw;
                height: 1.3333vw;
                margin-right: 0.6667vw;
              }

              p {
                font-size: 1.3333vw;
                line-height: 1.75vw;
              }
            }
          }
        }
      }

      .customer-info {
        width: calc(100% - 3.3333vw);
        padding: 1.25vw 0 1.6667vw 0;
        border-bottom: 0.0833vw solid var(--oliver-border);

        > {
          .col > p {
            &.style1 {
              font-size: 1.3333vw;
              line-height: 2vw;
              margin-bottom: 0.6667vw;
            }

            &.style2 {
              font-size: 1.1667vw;
              line-height: 1.6667vw;
              margin-bottom: 0.3333vw;
            }
          }

          button {
            width: 12.8333vw;
            height: 3.3333vw;
            border: 0.0833vw solid var(--oliver-blue);
            border-radius: 0.5vw;
            font-size: 1.3333vw;
            line-height: 2vw;
          }
        }
      }

      .order-details {
        padding: 1.25vw 1.6667vw;
        min-height: 8.3333vw;

        > {
          p {
            font-size: 1.3333vw;
            line-height: 2vw;
            margin-bottom: 2vw;
          }

          .item {
            margin-top: 1.6667vw;

            > {
              .img-container {
                width: 5.6667vw;
                height: 5.75vw;
                margin-right: 2vw;
                border-radius: 0.3333vw;

                > {
                  .quantity {
                    min-width: 2.1667vw;
                    height: 2.1667vw;
                    padding: 0 0.25vw;
                    right: 0vw;
                    top: 0vw;
                    border-radius: 0.5vw;

                    > p {
                      font-size: 1.1667vw;
                      line-height: 1.6667vw;
                    }
                  }

                  img {
                    border-radius: 0.3333vw;
                  }
                }
              }

              .col > {
                .main-row > p {
                  font-size: 1.1667vw;
                  line-height: 1.6667vw;
                }

                .item-fields {
                  padding-top: 0.5vw;

                  > p {
                    font-size: 1.1667vw;
                    line-height: 1.6667vw;
                  }
                }

                .modifier-fields {
                  padding: 0.6667vw 0 0 1.0833vw;

                  > .row > p {
                    font-size: 1.1667vw;
                    line-height: 1.5833vw;
                    padding-right: 0.8333vw;
                  }
                }

                .product-total-fields {
                  padding-top: 0.6667vw;

                  > div {
                    padding-top: 0.5vw;
                    border-top: 0.0833vw solid var(--oliver-border);
                    margin-top: 0.5vw;

                    > .row > p {
                      font-size: 1.1667vw;
                      line-height: 1.5833vw;
                      padding-right: 0.8333vw;
                    }
                  }
                }
              }
            }
          }

          .custom-fields {
            margin-top: 1.25vw;

            > p {
              &.style1 {
                font-size: 1.3333vw;
                line-height: 2vw;
                margin-bottom: 0.5833vw;
              }

              &.style2 {
                font-size: 1.1667vw;
                line-height: 1.6667vw;
                margin-left: 1.1667vw;
                max-width: calc(100% - 1.1667vw);
                margin-top: 0.25vw;
              }
            }
          }

          .transaction-totals-wrapper {
            padding: 1.25vw 0;
            border-top: 0.1667vw solid var(--oliver-border);
            margin-top: 1.25vw;

            > .transaction-totals {
              width: min(33.3333vw, 75%);

              > .row {
                padding-bottom: 0.5vw;

                &.border-bottom {
                  border-bottom: 0.0833vw solid var(--oliver-border);
                  margin-bottom: 0.9167vw;
                }

                &.border-top {
                  padding: 0.5vw 0 0 0;
                  border-top: 0.0833vw solid var(--oliver-border);
                }

                > p {
                  font-size: 1.1667vw;
                  line-height: 1.6667vw;

                  &.style2 {
                    font-size: 1vw;
                    line-height: 1.4167vw;
                  }
                }
              }
            }
          }

          .refund-header {
            padding: 0.4167vw 0.6667vw;
            margin: 2.0833vw 0 1.25vw 0;

            > p {
              &.style1 {
                font-size: 1.1667vw;
                line-height: 1.75vw;
                padding-right: 0.8333vw;
              }

              &.style2 {
                font-size: 1vw;
                line-height: 1.5vw;
              }
            }
          }
        }
      }
    }

    .footer {
      padding: 1.25vw 1.6667vw;
      border-top: 0.0833vw solid var(--oliver-border);

      > button {
        height: 3.6667vw;
        border-radius: 0.5vw;
        font-size: 1.5vw;
        line-height: 2vw;
        margin-left: 1vw;
      }
    }
  }
}
