@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.end-session {
    width: 100%;
    height: 100%;
    border-radius: 0;

    > {
      .subwindow-header {
        display: none;
      }

      .subwindow-body {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 0;
        padding: 0;

        > {
          button.mobile-close-subwindow {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 109px;
            height: 40px;
            background-color: #fff;
            border: 1px solid var(--oliver-blue);
            border-radius: 6px;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-blue);
            -ms-flex-item-align: start;
            align-self: flex-start;
            margin: 20px 0 0 20px;

            > img {
              width: 7.1px;
              height: 14.2px;
              margin-right: 14.9px;
            }
          }

          p {
            &.style1 {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-base-blue);
              margin: 20px 0 17px 0;
            }

            &.style2 {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: center;
              color: var(--oliver-base-blue);
              margin-bottom: 27px;
            }
          }

          button:not(.mobile-close-subwindow) {
            width: 230px;
            height: 50px;
            border: 0;
            background-color: var(--oliver-red);
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.end-session {
    width: 504px;
    height: min(400px, 95%);

    > .subwindow-body {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        button.mobile-close-subwindow {
          display: none;
        }

        p {
          &.style1 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 17px;
          }

          &.style2 {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 35px;
          }
        }

        button {
          width: 230px;
          height: 50px;
          border: 0;
          background-color: var(--oliver-red);
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.end-session {
    width: 24.6094vw;
    height: min(19.5313vw, 95%);

    > .subwindow-body > {
      p {
        &.style1 {
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin-bottom: 0.8301vw;
        }

        &.style2 {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
          margin-bottom: 1.709vw;
        }
      }

      button {
        width: 11.2305vw;
        height: 2.4414vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
