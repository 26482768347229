@media (orientation: portrait) {
  /* Switch Users */

  .subwindow-wrapper > .subwindow.switch-user {
    position: fixed;
    width: 100%;
    height: 100%;
    border-radius: 0;

    > .subwindow-body {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        button.mobile-close-subwindow {
          display: none;
        }

        .group {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin: 10px 0 17px 0;

          > {
            img {
              width: 19.72px;
              height: 20px;
              margin-right: 11.28px;
            }

            p {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-base-blue);
            }
          }
        }

        p.style1 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 24px;
        }

        .divider {
          width: 65px;
          height: 1px;
          margin-bottom: 24px;
          background-color: var(--oliver-border);
        }

        p.style2 {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 400;
          line-height: 29px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 24px;
        }

        .pinpad {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 278px;
          margin-bottom: 10px;

          > {
            p.error-message {
              padding: 11px 28px;
              background-color: #ffe7e8;
              border-radius: 8px;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: center;
              color: var(--error);
              margin: -10px 0 20px 0;
            }

            .pin-entries {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              margin-bottom: 17px;

              > .pin-entry {
                width: 16px;
                height: 16px;
                background-color: #b0bec9;
                margin-left: 10px;
                border-radius: 50%;

                &:first-child {
                  margin: 0;
                }

                &.entered {
                  background-color: var(--oliver-blue);
                }
              }

              &.incorrect {
                -webkit-animation: shake 0.3s;
                animation: shake 0.3s;

                > .pin-entry {
                  background-color: var(--oliver-red);
                }
              }
            }

            .pin-button-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              gap: 16px;
              margin-bottom: 16px;

              > button {
                width: 82px;
                height: 82px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid #b0bec9;
                border-radius: 8px;
                background-color: #fff;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
              }

              &:last-child > button:first-child {
                margin-left: auto;
              }

              > button {
                &.backspace {
                  background-color: transparent;
                  border: 0;

                  > img {
                    width: 50px;
                    height: 40px;
                  }
                }

                > p {
                  font-family: Poppins;
                  font-size: 36px;
                  font-weight: 400;
                  line-height: 36px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                }

                > .text-row {
                  font-family: Poppins;
                  font-size: 10px;
                  font-weight: 300;
                  line-height: 15px;
                  letter-spacing: 0px;
                  text-align: center;
                  color: var(--oliver-base-blue);

                  &.no-opacity {
                    opacity: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.switch-user {
    width: 504px;
    height: min(780px, 95%);

    > .subwindow-body {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        button.mobile-close-subwindow {
          display: none;
        }

        .group {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 17px;

          > {
            img {
              width: 19px;
              height: 20px;
              margin-right: 11px;
            }

            p {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-base-blue);
            }
          }
        }

        p.style1 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 24px;
        }

        .divider {
          width: 65px;
          height: 1px;
          margin-bottom: 24px;
          background-color: var(--oliver-border);
        }

        p.style2 {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 400;
          line-height: 29px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 24px;
        }

        .pinpad {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 278px;

          > {
            p.error-message {
              padding: 11px 28px;
              background-color: #ffe7e8;
              border-radius: 8px;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: center;
              color: var(--error);
              margin: -10px 0 20px 0;
            }

            .pin-entries {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              margin-bottom: 17px;

              > .pin-entry {
                width: 16px;
                height: 16px;
                background-color: #b0bec9;
                margin-left: 10px;
                border-radius: 50%;

                &:first-child {
                  margin: 0;
                }

                &.entered {
                  background-color: var(--oliver-blue);
                }
              }

              &.incorrect {
                -webkit-animation: shake 0.3s;
                animation: shake 0.3s;

                > .pin-entry {
                  background-color: var(--oliver-red);
                }
              }
            }

            .pin-button-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              gap: 16px;
              margin-bottom: 16px;

              > button {
                width: 82px;
                height: 82px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid #b0bec9;
                border-radius: 8px;
                background-color: #fff;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
              }

              &:last-child > button:first-child {
                margin-left: auto;
              }

              > button {
                &.backspace {
                  background-color: transparent;
                  border: 0;

                  > img {
                    width: 50px;
                    height: 40px;
                  }
                }

                > {
                  p {
                    font-family: Poppins;
                    font-size: 36px;
                    font-weight: 400;
                    line-height: 36px;
                    text-align: center;
                    color: var(--oliver-base-blue);
                  }

                  .text-row {
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 300;
                    line-height: 15px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: var(--oliver-base-blue);

                    &.no-opacity {
                      opacity: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.switch-user {
    width: 24.6094vw;
    height: min(38.0859vw, 95%);

    > .subwindow-body > {
      .group {
        margin-bottom: 0.8301vw;

        > {
          img {
            width: 0.9277vw;
            height: 0.9766vw;
            margin-right: 0.5371vw;
          }

          p {
            font-size: 0.8789vw;
            line-height: 1.1719vw;
          }
        }
      }

      p.style1 {
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 1.1719vw;
      }

      .divider {
        width: 3.1738vw;
        height: 0.0488vw;
        margin-bottom: 1.1719vw;
      }

      p.style2 {
        font-size: 0.9766vw;
        line-height: 1.416vw;
        margin-bottom: 1.1719vw;
      }

      .pinpad {
        width: 13.5742vw;

        > {
          p.error-message {
            padding: 0.5371vw 1.3672vw;
            border-radius: 0.3906vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;
            margin: -0.4883vw 0 0.9766vw 0;
          }

          .pin-entries {
            margin-bottom: 0.8301vw;

            > .pin-entry {
              width: 0.7813vw;
              height: 0.7813vw;
              margin-left: 0.4883vw;
            }
          }

          .pin-button-row {
            gap: 0.7813vw;
            margin-bottom: 0.7813vw;

            > button {
              width: 4.0039vw;
              height: 4.0039vw;
              border: 0.0488vw solid #b0bec9;
              border-radius: 0.3906vw;

              &.backspace > img {
                width: 2.4414vw;
                height: 1.9531vw;
              }

              > {
                p {
                  font-size: 1.7578vw;
                  line-height: 1.7578vw;
                }

                .text-row {
                  font-size: 0.4883vw;
                  line-height: 0.7324vw;
                  letter-spacing: 0vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
