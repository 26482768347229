

@media (orientation: portrait) {
  .subwindow.discount-error {
    width: min(344px, 95%);
    height: min(325px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 8px;

      > {
        p {
          > br.landscape {
            display: none;
          }

          &.style1 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 20px;
            margin: auto 0 20px 0;
          }

          &.style2 {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 20px;
          }

          &:last-of-type {
            margin-bottom: 50px;
          }
        }

        button {
          width: 306px;
          height: 50px;
          border-radius: 6px;
          border: 0;
          background-color: var(--oliver-blue);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow.discount-error {
    width: min(95%, 504px);
    height: min(95%, 400px);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          > br.mobile {
            display: none;
          }

          &.style1 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin: auto 0 15px 0;
          }

          &.style2 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 15px;
          }

          &:last-of-type {
            margin-bottom: 50px;
          }
        }

        button {
          width: 198px;
          height: 50px;
          border: 0;
          border-radius: 6px;
          background-color: var(--oliver-blue);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin-bottom: auto;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow.discount-error {
    width: min(95%, 24.6094vw);
    height: min(95%, 19.5313vw);

    > .subwindow-body > {
      p {
        &.style1 {
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin-bottom: 0.7324vw;
          margin: auto 0 0.7324vw 0;
        }

        &.style2 {
          font-size: 0.7813vw;
          line-height: 1.0254vw;
          margin-bottom: 0.7324vw;
        }

        &:last-of-type {
          margin-bottom: 2.4414vw;
        }
      }

      button {
        width: 9.668vw;
        height: 2.4414vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}

