@media (orientation: portrait) {
  /* --------------------Transactions-------------------- */

  .transactions-wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    > {
      .navbar {
        top: 60px;
        width: 100%;
        height: calc(100% - 60px);
        padding-bottom: 10px;
      }

      .mobile-transactions-header {
        width: 100%;
        height: 70px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 13px 0 8px;

        > {
          #mobileNavToggle {
            width: 40px;
            height: 40px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-right: 8px;
            background-color: transparent;
            border: 0;

            > img {
              content: url("../assets/images/svg/Hamburger-Menu-Icon.svg");
              width: 20px;
              height: 13.33px;
            }

            &.opened > img {
              content: url("../assets/images/svg/Hamburger-Menu-Active-Icon.svg");
              width: 39px;
              height: 39px;
            }
          }

          p {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          #mobileTransactionsSearchButton {
            width: 34px;
            height: 34px;
            background-color: #d1ecff;
            border: 0;
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-right: 10px;

            > img {
              width: 21px;
              height: 21px;
            }
          }

          #mobileAppsButton {
            width: 34px;
            height: 34px;
            background-color: #d1ecff;
            border: 0;
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            > img {
              width: 18.62px;
              height: 18.46px;
              -webkit-transform: translateX(1px);
              -ms-transform: translateX(1px);
              transform: translateX(1px);
            }
          }

          button.filter {
            background-color: var(--oliver-blue) !important;

            > img {
              -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
            }
          }
        }
      }

      .mobile-refund.cart {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        background-color: #fff;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);

        &.hidden {
          -webkit-transform: translateY(100%);
          -ms-transform: translateY(100%);
          transform: translateY(100%);
        }

        > {
          .refund-cart-header {
            width: 100%;
            height: 65px;
            border-bottom: 1px solid var(--oliver-border);
            padding: 0 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            > {
              p {
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-right: auto;
              }

              button {
                width: 32px;
                height: 32px;
                border-radius: 6px;
                border: 0;
                background-color: #f1f4f6;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                > img {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }

          .body > .cart-item > {
            .main-row > p.quantity {
              color: var(--oliver-base-grey);
            }

            .increment-input {
              width: 130px;
              height: 36px;
              margin-top: 10px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 5px;
              border: 1px solid var(--oliver-border);
              border-radius: 6px;

              > {
                button {
                  height: 100%;
                  aspect-ratio: 1/1;
                  background-color: #ddf1ff;
                  border: 0;
                  border-radius: 6px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  > img {
                    width: 10px;
                    height: 10px;
                  }
                }

                input {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  border: 0;
                  outline: 0;

                  font-size: 16px;
                  font-weight: 500;
                  line-height: 21px;
                  text-align: center;
                  color: var(--oliver-blue);

                  &:hover {
                    cursor: default;
                  }
                }
              }
            }
          }

          .footer > {
            .totals > .row > {
              button {
                border: 0;
                background-color: transparent;

                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;
                color: var(--oliver-blue);

                &:hover {
                  text-decoration: underline;
                }
              }

              p.type2 {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                text-align: right;
                color: var(--oliver-base-blue);
                margin-right: 0 !important;

                > b {
                  font-weight: 600;
                }
              }
            }

            button {
              width: calc(100% - 30px);
              height: 54px;
              background-color: var(--oliver-blue);
              margin: 0 auto 17px auto;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              border: 0;
              border-radius: 10px;

              font-size: 18px;
              font-weight: 500;
              line-height: 24px;
              text-align: center;
              color: #fff;

              > img {
                width: 26px;
                height: 26px;
                margin-left: 12px;
              }
            }
          }

          .body > .cart-item > .main-row > {
            .quantity {
              color: var(--oliver-base-grey);

              &:hover {
                cursor: default;
                text-decoration: none;
              }
            }

            .content-style:hover {
              color: var(--oliver-base-blue-dark) !important;
              cursor: default;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .transactions-wrapper {
    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 72px max(328px, 25vw) max(328px, 25vw) 1fr;
    grid-template-columns: 72px max(328px, 25vw) max(328px, 25vw) 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "navbar transactions-search transactions-list transactions-detailed";

    > {
      .transactions-search {
        grid-area: transactions-search;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-left: 1px solid var(--oliver-border);
      }

      .transactions-list {
        grid-area: transactions-list;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-left: 1px solid var(--oliver-border);
      }

      .transactions-detailed {
        grid-area: transactions-detailed;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-left: 1px solid var(--oliver-border);
      }

      .mobile-transactions-header,
      .mobile-refund.cart {
        display: none;
      }
    }
  }
}

@media (orientation: landscape) {
  .transactions-search {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
  .transactions-list {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .transactions-detailed {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .transactions-wrapper {
    -ms-grid-columns: 3.5156vw max(16.0156vw, 25vw) max(16.0156vw, 25vw) 1fr;
    grid-template-columns: 3.5156vw max(16.0156vw, 25vw) max(16.0156vw, 25vw) 1fr;

    > {
      .transactions-search,
      .transactions-list,
      .transactions-detailed {
        border-left: 0.0488vw solid var(--oliver-border);
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .transactions-wrapper {
    -ms-grid-columns: 72px max(27.3333vw, 25vw) max(27.3333vw, 25vw) 1fr;
    grid-template-columns: 72px max(27.3333vw, 25vw) max(27.3333vw, 25vw) 1fr;

    > {
      .transactions-search,
      .transactions-list,
      .transactions-detailed {
        border-left: 0.0833vw solid var(--oliver-border);
      }
    }
  }
}
