@media (orientation: portrait) {
  .close-register-wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--oliver-blue);
    overflow-y: scroll;

    > {
      button {
        position: fixed;
        width: 78px;
        height: 52.5px;
        left: 0;
        top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: transparent;
        border: 0;

        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        text-align: center;
        color: #fff;

        > img {
          width: auto;
          height: 12.5px;
          margin-right: 4px;
        }

        &.hidden {
          display: none;
        }
      }

      header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 343px;
        padding: 30.28px 0;
        border-bottom: 1px solid #fff;

        &.hidden {
          display: none;
        }

        > {
          img {
            width: 106.86px;
            height: 97.72px;
            margin-right: 28.14px;
          }

          .col {
            max-width: 65%;

            > p {
              max-width: 100%;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              &.style1 {
                font-size: 30px;
                font-weight: 500;
                line-height: 43px;
                text-align: left;
                color: #fff;
                margin-bottom: -2px;
              }

              &.style2 {
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                text-align: left;
                color: #fff;
                margin-bottom: -8px;
              }

              &.style3 {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                text-align: left;
                color: #fff;
              }
            }
          }
        }
      }

      main {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > {
          *.hidden {
            display: none !important;
          }

          .step1 {
            width: 100%;
            height: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            > {
              p {
                &.style1 {
                  font-size: 25px;
                  font-weight: 500;
                  line-height: 36px;
                  text-align: center;
                  color: #fff;
                  margin: 20px 0;
                }

                &.style2 {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 23px;
                  text-align: center;
                  color: #fff;
                  margin-bottom: 19.47px;
                }
              }

              .pinpad {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-bottom: 26px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 278px;
                margin-bottom: 10px;

                > {
                  p.error-message {
                    padding: 11px 28px;
                    background-color: #ffe7e8;
                    border-radius: 8px;

                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    color: var(--error);
                    margin: -10px 0 20px 0;
                  }

                  .pin-entries {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin-bottom: 17px;

                    > .pin-entry {
                      width: 16px;
                      height: 16px;
                      background-color: #fff;
                      margin-left: 10px;
                      border-radius: 50%;

                      &:first-child {
                        margin: 0;
                      }

                      &.entered {
                        background-color: var(--oliver-base-blue);
                      }
                    }

                    &.incorrect {
                      -webkit-animation: shake 0.3s;
                      animation: shake 0.3s;

                      > .pin-entry {
                        background-color: var(--oliver-red);
                      }
                    }
                  }

                  .pin-button-row {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    gap: 16px;
                    margin-bottom: 16px;

                    > button {
                      width: 82px;
                      height: 82px;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      border: 0;
                      border-radius: 8px;
                      background-color: #fff;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-orient: vertical;
                      -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                      flex-direction: column;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;
                    }

                    &:last-child > button:first-child {
                      margin-left: auto;
                    }

                    > button {
                      &.backspace {
                        background-color: transparent;
                        border: 0;

                        > img {
                          width: 50px;
                          height: 40px;
                        }
                      }

                      > {
                        p {
                          font-size: 36px;
                          font-weight: 400;
                          line-height: 36px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                        }

                        .text-row {
                          font-size: 10px;
                          font-weight: 300;
                          line-height: 15px;
                          letter-spacing: 0px;
                          text-align: center;
                          color: var(--oliver-base-blue);

                          &.no-opacity {
                            opacity: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .step2 {
            width: 100%;
            height: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              p {
                &.style1 {
                  font-size: 25px;
                  font-weight: 500;
                  line-height: 36px;
                  text-align: center;
                  color: #fff;
                  margin: 16px 0 18px 0;
                }

                &.style2 {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;
                  margin-bottom: 20px;

                  > b {
                    font-weight: 500;
                  }
                }
              }

              button#openCashDrawer {
                width: 327px;
                height: 70px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 2px solid #fff;
                border-radius: 8px;
                background-color: transparent;

                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                color: #fff;
              }

              .input-column {
                width: 100%;
                padding: 29px 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-bottom: auto;

                > .input-row {
                  width: 327px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                  justify-content: space-between;
                  margin-top: 20px;

                  &:nth-child(1) {
                    margin-top: 0;
                  }

                  > {
                    label {
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 24px;
                      text-align: left;
                      color: #fff;
                    }

                    input {
                      width: 183.79px;
                      height: 50px;
                      border: 0;
                      outline: 0;
                      border-radius: 8px;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      padding: 18px;

                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                      margin-bottom: auto;
                    }
                  }
                }
              }

              button#saveCount {
                width: 327px;
                height: 50px;
                border: 0;
                border-radius: 8px;
                background-color: var(--oliver-base-blue);

                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                margin-bottom: 26px;
                margin-top: 15px;
              }
            }
          }

          .step3 {
            width: 100%;
            height: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              p {
                &.style1 {
                  font-size: 25px;
                  font-weight: 500;
                  line-height: 36px;
                  text-align: center;
                  color: #fff;
                  margin: 16px 0 18px 0;
                }

                &.style2 {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;
                  margin-bottom: 30px;

                  > b {
                    font-weight: 500;
                  }
                }
              }

              label:first-of-type {
                display: none;
              }

              .closing-totals {
                width: 347px;
                border-radius: 8px;
                background-color: #fff;
                padding: 29px 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-bottom: 30px;

                .tablet-divider,
                > .tablet-row {
                  display: none;
                }

                p {
                  &.style1 {
                    font-family: Roboto Mono;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 27px;
                    text-align: center;
                    color: var(--oliver-base-blue);
                    margin-bottom: 22.68px;
                  }

                  &.style2 {
                    font-family: Roboto Mono;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 21px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-bottom: 10px;
                  }

                  &.style3 {
                    font-family: Roboto Mono;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                  }
                }

                .divider {
                  width: 100%;
                  height: 1px;
                  background-color: var(--oliver-base-blue);
                  margin-bottom: 10px;
                }

                > .col {
                  width: 310.77px;
                  margin-top: 33px;

                  &:nth-child(2) {
                    margin-top: 0;
                  }

                  > .row {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-top: 13px;

                    &:nth-child(1) {
                      margin-top: 0;
                    }

                    > p:last-of-type {
                      margin-left: auto;
                    }
                  }

                  &.manual > .row:nth-child(3) {
                    display: none;
                  }
                }
              }

              label {
                width: 343px;

                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
                color: #fff;
              }

              textarea {
                width: 343px;
                height: 143px;
                outline: 0;
                resize: none;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 12px;
                border: 0;
                border-radius: 8px;
                margin-bottom: 25px;

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
              }

              button {
                width: 343px;
                height: 54px;
                border-radius: 8px;

                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                margin-bottom: 16px;

                &#printReport {
                  background-color: transparent;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  border: 2px solid #fff;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: portrait) and (max-width: 375px) {
  .close-register-wrapper > {
    button {
      width: 20.8vw;
      height: 14vw;
      font-size: 4vw;
      line-height: 4vw;

      > img {
        height: 3.3333vw;
        margin-right: 1.0667vw;
      }
    }

    header {
      width: 91.4667vw;
      padding: 8.0747vw 0;
      border-bottom: 0.2667vw solid #fff;

      > {
        img {
          width: 28.496vw;
          height: 26.0587vw;
          margin-right: 7.504vw;
        }

        .col > p {
          &.style1 {
            font-size: 8vw;
            line-height: 11.4667vw;
            margin-bottom: -0.5333vw;
          }

          &.style2 {
            font-size: 4.2667vw;
            line-height: 7.4667vw;
            margin-bottom: -2.1333vw;
          }

          &.style3 {
            font-size: 4.2667vw;
            line-height: 7.4667vw;
          }
        }
      }
    }

    main > {
      .step1 > {
        p {
          &.style1 {
            font-size: 6.6667vw;
            line-height: 9.6vw;
            margin: 5.3333vw 0;
          }

          &.style2 {
            font-size: 4.2667vw;
            line-height: 6.1333vw;
            margin-bottom: 5.192vw;
          }
        }

        .pinpad {
          margin-bottom: 6.9333vw;
          width: 74.1333vw;
          margin-bottom: 2.6667vw;

          > {
            p.error-message {
              padding: 2.9333vw 7.4667vw;
              border-radius: 2.1333vw;
              font-size: 3.7333vw;
              line-height: 5.3333vw;
              margin: -2.6667vw 0 5.3333vw 0;
            }

            .pin-entries {
              margin-bottom: 4.5333vw;

              > .pin-entry {
                width: 4.2667vw;
                height: 4.2667vw;
                margin-left: 2.6667vw;
              }
            }

            .pin-button-row {
              gap: 4.2667vw;
              margin-bottom: 4.2667vw;

              > button {
                width: 21.8667vw;
                height: 21.8667vw;
                border-radius: 2.1333vw;

                &.backspace > img {
                  width: 13.3333vw;
                  height: 10.6667vw;
                }

                > {
                  p {
                    font-size: 9.6vw;
                    line-height: 9.6vw;
                  }

                  .text-row {
                    font-size: 2.6667vw;
                    line-height: 4vw;
                    letter-spacing: 0vw;
                  }
                }
              }
            }
          }
        }
      }

      .step2 > {
        p {
          &.style1 {
            font-size: 6.6667vw;
            line-height: 9.6vw;
            margin: 4.2667vw 0 4.8vw 0;
          }

          &.style2 {
            font-size: 4.2667vw;
            line-height: 6.4vw;
            margin-bottom: 5.3333vw;
          }
        }

        button#openCashDrawer {
          width: 87.2vw;
          height: 18.6667vw;
          border: 0.5333vw solid #fff;
          border-radius: 2.1333vw;
          font-size: 4.8vw;
          line-height: 6.4vw;
        }

        .input-column {
          padding: 7.7333vw 0;

          > .input-row {
            width: 87.2vw;
            margin-top: 5.3333vw;

            > {
              label {
                font-size: 4.2667vw;
                line-height: 6.4vw;
              }

              input {
                width: 49.0107vw;
                height: 13.3333vw;
                border-radius: 2.1333vw;
                padding: 4.8vw;
                font-size: 3.7333vw;
                line-height: 5.3333vw;
              }
            }
          }
        }

        button#saveCount {
          width: 87.2vw;
          height: 13.3333vw;
          border-radius: 2.1333vw;
          font-size: 4.8vw;
          line-height: 6.4vw;
          margin-bottom: 6.9333vw;
          margin-top: 4vw;
        }
      }

      .step3 > {
        p {
          &.style1 {
            font-size: 6.6667vw;
            line-height: 9.6vw;
            margin: 4.2667vw 0 4.8vw 0;
          }

          &.style2 {
            font-size: 4.2667vw;
            line-height: 6.4vw;
            margin-bottom: 8vw;
          }
        }

        .closing-totals {
          width: 92.5333vw;
          border-radius: 2.1333vw;
          padding: 7.7333vw 0;
          margin-bottom: 8vw;

          p {
            &.style1 {
              font-size: 4.8vw;
              line-height: 7.2vw;
              margin-bottom: 6.048vw;
            }

            &.style2 {
              font-size: 4.2667vw;
              line-height: 5.6vw;
              margin-bottom: 2.6667vw;
            }

            &.style3 {
              font-size: 4.2667vw;
              line-height: 5.6vw;
            }
          }

          .divider {
            height: 0.2667vw;
            margin-bottom: 2.6667vw;
          }

          > .col {
            width: 82.872vw;
            margin-top: 8.8vw;

            > .row {
              margin-top: 3.4667vw;
            }
          }
        }

        label {
          width: 91.4667vw;
          font-size: 4.2667vw;
          line-height: 6.4vw;
        }

        textarea {
          width: 91.4667vw;
          height: 38.1333vw;
          padding: 3.2vw;
          border-radius: 2.1333vw;
          margin-bottom: 6.6667vw;
          font-size: 3.7333vw;
          line-height: 5.3333vw;
        }

        button {
          width: 91.4667vw;
          height: 14.4vw;
          border-radius: 2.1333vw;
          font-size: 4.8vw;
          line-height: 6.4vw;
          margin-bottom: 4.2667vw;

          &#printReport {
            border: 0.5333vw solid #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .close-register-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-blue);
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    line-height: 0;

    > {
      button {
        position: absolute;
        left: 10px;
        top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 132.1px;
        height: 52px;
        border: 0;
        background-color: transparent;

        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #fff;

        > img {
          width: 8.2px;
          height: 16.4px;
          margin-right: 8px;
        }

        &.hidden {
          display: none;
        }
      }

      header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: auto 0 auto auto;

        > {
          img {
            width: 276.68px;
            height: 253px;
            margin-bottom: 17px;
          }

          .col > {
            p {
              max-width: 30vw;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              &.style1 {
                font-size: 48px;
                font-weight: 300;
                line-height: 69px;
                text-align: center;
                color: #fff;
              }

              &.style2 {
                font-size: 24px;
                font-weight: 500;
                line-height: 28px;
                text-align: center;
                color: #fff;
                margin-bottom: 1px;
              }

              &.style3 {
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                text-align: center;
                color: #fff;
              }
            }

            .divider {
              width: 261px;
              height: 1px;
              background-color: #fff;
              margin-bottom: 14px;
            }
          }
        }
      }

      main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: auto auto auto 0;

        > {
          *.hidden {
            display: none !important;
          }

          .step1 {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-left: 171px;

            > {
              .auto-margin-bottom,
              .auto-margin-top {
                margin: 0;
              }

              p.style1 {
                font-size: 24px;
                font-weight: 600;
                line-height: 35px;
                text-align: center;
                color: #fff;
                margin: 26px 0 36px 0;
              }

              .divider {
                width: 90px;
                height: 1px;
                background-color: #fff;
                margin-bottom: 43px;
              }

              p.style2 {
                font-size: 20px;
                font-weight: 400;
                line-height: 29px;
                text-align: center;
                color: #fff;
                margin-bottom: 24.69px;
              }

              .pinpad {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-bottom: 26px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 278px;
                margin-bottom: 10px;

                > {
                  p.error-message {
                    padding: 11px 28px;
                    background-color: #ffe7e8;
                    border-radius: 8px;

                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    color: var(--error);
                    margin: -10px 0 20px 0;
                  }

                  .pin-entries {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin-bottom: 17px;

                    > .pin-entry {
                      width: 16px;
                      height: 16px;
                      background-color: #fff;
                      margin-left: 10px;
                      border-radius: 50%;

                      &:first-child {
                        margin: 0;
                      }

                      &.entered {
                        background-color: var(--oliver-base-blue);
                      }
                    }

                    &.incorrect {
                      -webkit-animation: shake 0.3s;
                      animation: shake 0.3s;

                      > .pin-entry {
                        background-color: var(--oliver-red);
                      }
                    }
                  }

                  .pin-button-row {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    gap: 16px;
                    margin-bottom: 16px;

                    > button {
                      width: 82px;
                      height: 82px;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      border: 0;
                      border-radius: 8px;
                      background-color: #fff;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-orient: vertical;
                      -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                      flex-direction: column;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;
                    }

                    &:last-child > button:first-child {
                      margin-left: auto;
                    }

                    > button {
                      &.backspace {
                        background-color: transparent;
                        border: 0;

                        > img {
                          width: 50px;
                          height: 40px;
                        }
                      }

                      > {
                        p {
                          font-size: 36px;
                          font-weight: 400;
                          line-height: 36px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                        }

                        .text-row {
                          font-size: 10px;
                          font-weight: 300;
                          line-height: 15px;
                          letter-spacing: 0px;
                          text-align: center;
                          color: var(--oliver-base-blue);

                          &.no-opacity {
                            opacity: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .step2 {
            width: 416px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-left: 155.32px;

            > {
              p.style1 {
                font-size: 24px;
                font-weight: 600;
                line-height: 35px;
                text-align: center;
                color: #fff;
                margin: 26px 0 30px 0;
              }

              .divider {
                width: 87.07px;
                height: 1px;
                background-color: #fff;
                margin-bottom: 49px;
              }

              p.style2 {
                width: 100%;

                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #fff;
                margin-bottom: 22px;

                > b {
                  font-weight: 600;
                }
              }

              button#openCashDrawer {
                width: 100%;
                height: 50px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 2px solid #fff;
                border-radius: 8px;
                background-color: transparent;

                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                color: #fff;
                margin-bottom: 38px;
              }

              .input-column {
                width: 100%;
                margin-bottom: 38px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;

                > .input-row {
                  width: 100%;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                  justify-content: space-between;
                  margin-top: 21px;

                  &:nth-child(1) {
                    margin-top: 0;
                  }

                  > {
                    label {
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 24px;
                      text-align: left;
                      color: #fff;
                    }

                    input {
                      width: 232.4px;
                      height: 50px;
                      border: 0;
                      border-radius: 6px;
                      outline: 0;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      padding: 0 18px;

                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }
                  }
                }
              }

              button#saveCount {
                width: 100%;
                height: 50px;
                background-color: var(--oliver-base-blue);
                border: 0;
                border-radius: 8px;

                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                margin-bottom: 26px;
              }
            }
          }

          .step3 {
            width: 619px;
            margin-left: 76.32px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            > {
              p {
                &.style1 {
                  font-size: 24px;
                  font-weight: 600;
                  line-height: 35px;
                  text-align: center;
                  color: #fff;
                  margin: 26px 0 38px 0;
                }

                &.style2 {
                  display: none;
                }
              }

              label {
                width: 100%;

                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
                color: #fff;
                margin-bottom: 9px;
              }

              .closing-totals {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                width: 100%;
                background-color: #fff;
                border-radius: 8px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 0 15px;
                margin-bottom: 25px;

                > {
                  p.style1 {
                    display: none;
                  }

                  .tablet-row {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin: 12px 0 22px 0;

                    > p {
                      font-family: Roboto Mono;
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 24px;
                      color: var(--oliver-base-blue);
                      width: 20%;
                      text-align: right;

                      &:nth-child(1) {
                        width: 40%;
                        text-align: left;
                      }
                    }
                  }

                  .col {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin-bottom: 16px;

                    > {
                      p {
                        font-family: Roboto Mono;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: var(--oliver-base-blue);
                        width: 40%;
                      }

                      .row {
                        width: 20%;
                      }
                    }

                    &:not(.total) > .row > p:first-of-type {
                      display: none;
                    }

                    > .row > p:last-of-type {
                      font-family: Roboto Mono;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 24px;
                      text-align: right;
                      color: var(--oliver-base-blue);
                    }
                  }

                  .tablet-divider {
                    width: calc(100% + 30px);
                    height: 1px;
                    margin-left: -15px;
                    background-color: var(--oliver-border);
                    margin-bottom: 16px;
                  }

                  .col.total > {
                    p {
                      font-family: Roboto Mono;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 24px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                      margin-right: auto;
                    }

                    .row > p {
                      font-family: Roboto Mono;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 24px;
                      text-align: right;
                      color: var(--oliver-base-blue);
                    }
                  }
                }
              }

              textarea {
                width: 100%;
                height: 105px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 0;
                border-radius: 8px;
                outline: 0;
                resize: none;
                padding: 12px;

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 29px;
              }

              button {
                &#printReport {
                  width: 244px;
                  height: 50px;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  border: 2px solid #fff;
                  border-radius: 8px;
                  background-color: transparent;

                  font-size: 18px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;
                  margin-bottom: 29px;
                  -ms-flex-item-align: center;
                  -ms-grid-row-align: center;
                  align-self: center;
                }

                &#closeRegister {
                  width: 100%;
                  height: 50px;
                  border: 0;
                  border-radius: 8px;
                  background-color: var(--oliver-base-blue);

                  font-size: 18px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;
                  margin-bottom: 26px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .close-register-wrapper > {
    button {
      left: 0.4883vw;
      top: 0.4883vw;
      width: 6.4502vw;
      height: 2.5391vw;
      font-size: 0.9766vw;
      line-height: 1.4648vw;

      > img {
        width: 0.4004vw;
        height: 0.8008vw;
        margin-right: 0.3906vw;
      }
    }

    header > {
      img {
        width: 13.5098vw;
        height: 12.3535vw;
        margin-bottom: 0.8301vw;
      }

      .col > {
        p {
          &.style1 {
            font-size: 2.3438vw;
            line-height: 3.3691vw;
          }

          &.style2 {
            font-size: 1.1719vw;
            line-height: 1.3672vw;
            margin-bottom: 0.0488vw;
          }

          &.style3 {
            font-size: 0.8789vw;
            line-height: 1.3672vw;
          }
        }

        .divider {
          width: 12.7441vw;
          height: 0.0488vw;
          margin-bottom: 0.6836vw;
        }
      }
    }

    main > {
      .step1 {
        margin-left: 8.3496vw;

        > {
          p.style1 {
            font-size: 1.1719vw;
            line-height: 1.709vw;
            margin: 1.2695vw 0 1.7578vw 0;
          }

          .divider {
            width: 4.3945vw;
            height: 0.0488vw;
            margin-bottom: 2.0996vw;
          }

          p.style2 {
            font-size: 0.9766vw;
            line-height: 1.416vw;
            margin-bottom: 1.2056vw;
          }

          .pinpad {
            margin-bottom: 1.2695vw;
            width: 13.5742vw;
            margin-bottom: 0.4883vw;

            > {
              p.error-message {
                padding: 0.5371vw 1.3672vw;
                border-radius: 0.3906vw;
                font-size: 0.6836vw;
                line-height: 0.9766vw;
                margin: -0.4883vw 0 0.9766vw 0;
              }

              .pin-entries {
                margin-bottom: 0.8301vw;

                > .pin-entry {
                  width: 0.7813vw;
                  height: 0.7813vw;
                  margin-left: 0.4883vw;
                }
              }

              .pin-button-row {
                gap: 0.7813vw;
                margin-bottom: 0.7813vw;

                > button {
                  width: 4.0039vw;
                  height: 4.0039vw;
                  border-radius: 0.3906vw;

                  &.backspace > img {
                    width: 2.4414vw;
                    height: 1.9531vw;
                  }

                  > {
                    p {
                      font-size: 1.7578vw;
                      line-height: 1.7578vw;
                    }

                    .text-row {
                      font-size: 0.4883vw;
                      line-height: 0.7324vw;
                      letter-spacing: 0vw;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .step2 {
        width: 20.3125vw;
        margin-left: 7.584vw;

        > {
          p.style1 {
            font-size: 1.1719vw;
            line-height: 1.709vw;
            margin: 1.2695vw 0 1.4648vw 0;
          }

          .divider {
            width: 4.2515vw;
            height: 0.0488vw;
            margin-bottom: 2.3926vw;
          }

          p.style2 {
            font-size: 0.7813vw;
            line-height: 1.1719vw;
            margin-bottom: 1.0742vw;
          }

          button#openCashDrawer {
            height: 2.4414vw;
            border: 0.0977vw solid #fff;
            border-radius: 0.3906vw;
            font-size: 0.8789vw;
            line-height: 1.1719vw;
            margin-bottom: 1.8555vw;
          }

          .input-column {
            margin-bottom: 1.8555vw;

            > .input-row {
              margin-top: 1.0254vw;

              > {
                label {
                  font-size: 0.7813vw;
                  line-height: 1.1719vw;
                }

                input {
                  width: 11.3477vw;
                  height: 2.4414vw;
                  border-radius: 0.293vw;
                  padding: 0 0.8789vw;
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                }
              }
            }
          }

          button#saveCount {
            height: 2.4414vw;
            border-radius: 0.3906vw;
            font-size: 0.8789vw;
            line-height: 1.1719vw;
            margin-bottom: 1.2695vw;
          }
        }
      }

      .step3 {
        width: 30.2246vw;
        margin-left: 3.7266vw;

        > {
          p.style1 {
            font-size: 1.1719vw;
            line-height: 1.709vw;
            margin: 1.2695vw 0 1.8555vw 0;
          }

          label {
            font-size: 0.7813vw;
            line-height: 1.1719vw;
            margin-bottom: 0.4395vw;
          }

          .closing-totals {
            border-radius: 0.3906vw;
            padding: 0 0.7324vw;
            margin-bottom: 1.2207vw;

            > {
              .tablet-row {
                margin: 0.5859vw 0 1.0742vw 0;

                > p {
                  font-size: 0.7813vw;
                  line-height: 1.1719vw;
                }
              }

              .col {
                margin-bottom: 0.7813vw;

                > {
                  p,
                  .row > p:last-of-type {
                    font-size: 0.7813vw;
                    line-height: 1.1719vw;
                  }
                }
              }

              .tablet-divider {
                width: calc(100% + 1.4648vw);
                height: 0.0488vw;
                margin-left: -0.7324vw;
                margin-bottom: 0.7813vw;
              }

              .col.total > {
                p,
                .row > p {
                  font-size: 0.7813vw;
                  line-height: 1.1719vw;
                }
              }
            }
          }

          textarea {
            height: 5.127vw;
            border-radius: 0.3906vw;
            padding: 0.5859vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;
            margin-bottom: 1.416vw;
          }

          button {
            &#printReport {
              width: 11.9141vw;
              height: 2.4414vw;
              border: 0.0977vw solid #fff;
              border-radius: 0.3906vw;
              font-size: 0.8789vw;
              line-height: 1.1719vw;
              margin-bottom: 1.416vw;
            }

            &#closeRegister {
              height: 2.4414vw;
              border-radius: 0.3906vw;
              font-size: 0.8789vw;
              line-height: 1.1719vw;
              margin-bottom: 1.2695vw;
            }
          }
        }
      }
    }
  }
}
