// Oliver Logo Animation

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animate-svg-fill-1 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #a1e663;
  }
}

@keyframes animate-svg-fill-2 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #47d2a5;
  }
}

@keyframes animate-svg-fill-3 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #1db9ca;
  }
}

@keyframes animate-svg-fill-4 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #2797e8;
  }
}

@keyframes animate-svg-fill-5 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #a568bc;
  }
}

@keyframes animate-svg-fill-6 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #ff525f;
  }
}

@keyframes animate-svg-fill-7 {
  0%,
  80%,
  100% {
    transform: scale(0.98);
    fill: transparent;
  }

  10%,
  70% {
    transform: scale(1);
    fill: #ff8552;
  }
}

@keyframes animate-svg-fill-8 {
  0% {
    transform: translate(35px, -35px);
    fill: transparent;
  }

  10%,
  40% {
    transform: translate(0px, 0px);
    fill: #ffd026;
  }

  50%,
  100% {
    transform: translate(-35px, 35px);
    fill: transparent;
  }
}

@media (orientation: portrait) and (min-width: 600px) {
  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(4.1667vw, -4.1667vw);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-4.1667vw, 4.1667vw);
      fill: transparent;
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(1.2207vw, -1.2207vw);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-1.2207vw, 1.2207vw);
      fill: transparent;
    }
  }
}
@media (orientation: landscape) and (max-width: 1200px) {
  @keyframes animate-svg-fill-8 {
    0% {
      transform: translate(2.0833vw, -2.0833vw);
      fill: transparent;
    }

    10%,
    40% {
      transform: translate(0px, 0px);
      fill: #ffd026;
    }

    50%,
    100% {
      transform: translate(-2.0833vw, 2.0833vw);
      fill: transparent;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes tileSideUp {
  0% {
    transform: translateY(16px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes wavelines {
  0% {
    background-position: -360px 0;
  }
  100% {
    background-position: 360px 0;
  }
}

@keyframes fadeInSmall {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
