@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.adjust-credit {
    width: min(400px, 95%);
    height: min(498px, 95%);

    > .subwindow-body {
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          color: var(--oliver-base-grey);
          margin-bottom: 10px;
        }

        .toggle-wrapper {
          width: 307px;
          height: 50px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 8px;
          background-color: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 6px;
          margin-bottom: 21px;

          > label {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;

            &:hover {
              cursor: pointer;
            }

            > {
              input {
                position: fixed;
                left: -10000px;
                opacity: 0;
              }

              .custom-radio {
                width: 100%;
                height: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                text-align: center;
                color: var(--oliver-base-grey);
                border-radius: 6px;
              }

              input:checked ~ .custom-radio {
                background-color: var(--oliver-blue);
                color: #fff;
              }
            }
          }
        }

        .text-row {
          width: 306px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 8px;

          > p {
            &.style1 {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            &.style2 {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              text-align: right;
              color: var(--oliver-blue);
            }
          }
        }

        .change-credit-row {
          width: 306px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 16px;

          &.hidden {
            display: none;
          }

          > {
            p {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            input {
              width: 160px;
              height: 50px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid var(--oliver-border);
              border-radius: 6px;
              padding: 0 12px;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: right;
              color: var(--oliver-base-blue);

              &:focus {
                border-color: var(--oliver-blue);
              }
            }
          }
        }

        label {
          width: 306px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 12px;
        }

        textarea {
          width: 306px;
          height: 96px;
          outline: 0;
          resize: none;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          padding: 12px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 19px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        button {
          width: 306px;
          height: 50px;
          border: 0;
          border-radius: 6px;
          background-color: var(--oliver-blue);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.adjust-credit {
    width: 504px;
    height: min(635px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          color: var(--oliver-base-grey);
          margin-bottom: 9px;
        }

        .toggle-wrapper {
          width: 320px;
          height: 50px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 8px;
          background-color: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 6px;
          margin-bottom: 24px;

          > label {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;

            &:hover {
              cursor: pointer;
            }

            > {
              input {
                position: fixed;
                left: -10000px;
                opacity: 0;
              }

              .custom-radio {
                width: 100%;
                height: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                text-align: center;
                color: var(--oliver-base-grey);
                border-radius: 6px;
              }

              input:checked ~ .custom-radio {
                background-color: var(--oliver-blue);
                color: #fff;
              }
            }
          }
        }

        .text-row {
          width: 427px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding-top: 21px;
          border-top: 1px solid var(--oliver-border);
          margin-bottom: 18px;

          > p {
            &.style1 {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            &.style2 {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              text-align: right;
              color: var(--oliver-blue);
            }
          }
        }

        .change-credit-row {
          width: 427px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding-bottom: 24px;
          border-bottom: 1px solid var(--oliver-border);
          margin-bottom: 18px;

          &.hidden {
            display: none;
          }

          > {
            p {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
            }

            input {
              width: 242px;
              height: 50px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid var(--oliver-border);
              border-radius: 6px;
              padding: 0 12px;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-blue);

              &:focus {
                border-color: var(--oliver-blue);
              }
            }
          }
        }

        label {
          width: 427px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 6px;
        }

        textarea {
          width: 427px;
          height: 144px;
          outline: 0;
          resize: none;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          padding: 12px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 24px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        button {
          width: 427px;
          height: 50px;
          border: 0;
          border-radius: 6px;
          background-color: var(--oliver-blue);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.adjust-credit {
    width: 24.6094vw;
    height: min(31.0059vw, 95%);

    > .subwindow-body > {
      p {
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.4395vw;
      }

      .toggle-wrapper {
        width: 15.625vw;
        height: 2.4414vw;
        border: 0.0488vw solid var(--oliver-border);
        border-radius: 0.3906vw;
        padding: 0.293vw;
        margin-bottom: 1.1719vw;

        > label > {
          input {
            left: -488.2813vw;
          }

          .custom-radio {
            font-size: 0.7813vw;
            line-height: 1.0254vw;
            border-radius: 0.293vw;
          }
        }
      }

      .text-row {
        width: 20.8496vw;
        padding-top: 1.0254vw;
        border-top: 0.0488vw solid var(--oliver-border);
        margin-bottom: 0.8789vw;

        > p {
          &.style1 {
            font-size: 0.7813vw;
            line-height: 1.1719vw;
          }

          &.style2 {
            font-size: 0.9766vw;
            line-height: 1.4648vw;
          }
        }
      }

      .change-credit-row {
        width: 20.8496vw;
        padding-bottom: 1.1719vw;
        border-bottom: 0.0488vw solid var(--oliver-border);
        margin-bottom: 0.8789vw;

        > {
          p {
            font-size: 0.7813vw;
            line-height: 1.1719vw;
          }

          input {
            width: 11.8164vw;
            height: 2.4414vw;
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.293vw;
            padding: 0 0.5859vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;
          }
        }
      }

      label {
        width: 20.8496vw;
        font-size: 0.7813vw;
        line-height: 1.1719vw;
        margin-bottom: 0.293vw;
      }

      textarea {
        width: 20.8496vw;
        height: 7.0313vw;
        border: 0.0488vw solid var(--oliver-border);
        border-radius: 0.293vw;
        padding: 0.5859vw;
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 1.1719vw;
      }

      button {
        width: 20.8496vw;
        height: 2.4414vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
