@media (orientation: portrait) {
  .cash-management-wrapper {
    width: 100%;
    height: 100%;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    > {
      .navbar {
        top: 60px;
        width: 100%;
        height: calc(100% - 60px);
        padding-bottom: 10px;
      }

      .mobile-cm-header {
        width: 100%;
        height: 70px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 13px 0 8px;

        > {
          #mobileNavToggle {
            width: 40px;
            height: 40px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-right: 8px;
            background-color: transparent;
            border: 0;

            > img {
              content: url("../assets/images/svg/Hamburger-Menu-Icon.svg");
              width: 20px;
              height: 13.33px;
            }

            &.opened > img {
              content: url("../assets/images/svg/Hamburger-Menu-Active-Icon.svg");
              width: 39px;
              height: 39px;
            }
          }

          p {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          #mobileTransactionsSearchButton {
            width: 34px;
            height: 34px;
            background-color: #d1ecff;
            border: 0;
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-right: 10px;

            > img {
              width: 21px;
              height: 21px;
            }
          }

          #mobileAppsButton {
            width: 34px;
            height: 34px;
            background-color: #d1ecff;
            border: 0;
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            > img {
              width: 18.62px;
              height: 18.46px;
              -webkit-transform: translateX(1px);
              -ms-transform: translateX(1px);
              transform: translateX(1px);
            }
          }

          button.filter {
            background-color: var(--oliver-blue) !important;

            > img {
              -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  }

  /* Navbar Settings	 */

  /* Mobile Header */
}

@media (orientation: landscape) {
  .cash-management-wrapper {
    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 72px 33vw 1fr;
    grid-template-columns: 72px 33vw 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "navbar cm-list cm-detailed";

    > {
      .navbar {
        grid-area: navbar;
      }

      .cm-list {
        grid-area: cm-list;
        border-left: 1px solid var(--oliver-border);
      }

      .cm-detailed {
        grid-area: cm-detailed;
        border-left: 1px solid var(--oliver-border);
      }

      .mobile-cm-header {
        display: none;
      }
    }
  }
}

@media (orientation: landscape) {
  .navbar {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .cm-list {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
  .cm-detailed {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .cash-management-wrapper {
    -ms-grid-columns: 3.5156vw 33vw 1fr;
    grid-template-columns: 3.5156vw 33vw 1fr;

    > {
      .cm-list,
      .cm-detailed {
        border-left: 0.0488vw solid var(--oliver-border);
      }
    }
  }
}
