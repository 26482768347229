@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow {
    &.email-receipt {
      width: min(384px, 95%);
      height: min(275px, 95%);

      > .subwindow-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > {
          label {
            width: 306px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-grey);
            margin-bottom: 9px;
          }

          input {
            width: 306px;
            height: 44px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 12px;
            border: 1px solid var(--oliver-border);
            border-radius: 6px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-bottom: 20px;
          }

          button {
            width: 306px;
            height: 50px;
            border: 0;
            border-radius: 6px;
            background-color: var(--oliver-blue);
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }

    &.receipt {
      width: min(384px, 95%);
      height: min(300px, 95%);

      > .subwindow-body {
        width: 100%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > {
          .receipt-options {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &.hidden {
              display: none;
            }

            > {
              p {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                text-align: center;
                color: var(--oliver-base-grey);
              }

              button {
                width: 265px;
                height: 50px;
                margin-top: 17px;
                border-radius: 6px;
                border: 0;
                background-color: var(--oliver-blue);
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;

                &:first-of-type {
                  margin-top: 21px;
                }
              }
            }
          }

          .receipt-email {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &.hidden {
              display: none;
            }

            > {
              label {
                width: 306px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                color: var(--oliver-base-grey);
                padding-bottom: 5px;

                &:hover {
                  cursor: pointer;

                  + input {
                    border-color: var(--oliver-blue);
                  }
                }
              }

              input {
                width: 309px;
                height: 50px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid var(--oliver-border);
                border-radius: 6px;
                padding: 0 12px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 17px;

                &:hover {
                  border-color: var(--oliver-blue);
                }

                &:focus {
                  border-width: 2px;
                  border-color: var(--oliver-blue);
                }
              }

              button {
                width: 306px;
                height: 50px;
                border: 0;
                background-color: var(--oliver-blue);
                border-radius: 6px;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow {
    &.email-receipt {
      display: none;
    }

    &.receipt {
      width: 504px;
      height: 295px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      > .subwindow-body {
        width: 100%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > {
          .receipt-options {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &.hidden {
              display: none;
            }

            > {
              p {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                text-align: center;
                color: var(--oliver-base-blue);
              }

              button {
                width: 265px;
                height: 50px;
                margin-top: 17px;
                border-radius: 6px;
                border: 0;
                background-color: var(--oliver-blue);
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;

                &:first-of-type {
                  margin-top: 21px;
                }
              }
            }
          }

          .receipt-email {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &.hidden {
              display: none;
            }

            > {
              label {
                width: 350px;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                color: var(--oliver-base-blue);
                padding-bottom: 7px;

                &:hover {
                  cursor: pointer;

                  + input {
                    border-color: var(--oliver-blue);
                  }
                }
              }

              input {
                width: 350px;
                height: 50px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid var(--oliver-border);
                border-radius: 6px;
                padding: 0 12px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 17px;

                &:hover {
                  border-color: var(--oliver-blue);
                }

                &:focus {
                  border-width: 2px;
                  border-color: var(--oliver-blue);
                }
              }

              button {
                width: 350px;
                height: 50px;
                border: 0;
                background-color: var(--oliver-blue);
                border-radius: 6px;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.receipt {
    width: 24.6094vw;
    height: 14.4043vw;

    > .subwindow-body > {
      .receipt-options > {
        p {
          font-size: 0.7813vw;
          line-height: 1.0254vw;
        }

        button {
          width: 12.9395vw;
          height: 2.4414vw;
          margin-top: 0.8301vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;

          &:first-of-type {
            margin-top: 1.0254vw;
          }
        }
      }

      .receipt-email > {
        label {
          width: 17.0898vw;
          font-size: 0.7813vw;
          line-height: 1.0254vw;
          padding-bottom: 0.3418vw;
        }

        input {
          width: 17.0898vw;
          height: 2.4414vw;
          border: 0.0488vw solid var(--oliver-border);
          border-radius: 0.293vw;
          padding: 0 0.5859vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
          margin-bottom: 0.8301vw;

          &:focus {
            border-width: 0.0977vw;
          }
        }

        button {
          width: 17.0898vw;
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }
  }
}
