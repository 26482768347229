@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.split-by-product {
    width: min(384px, 95%);
    height: min(425px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0;

      > {
        .body {
          width: 100%;
          height: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          overflow-y: scroll;
          padding: 6px 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > .product-row {
            width: 306px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 14px 0;

            > {
              .main-row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                > {
                  .text-group {
                    margin-right: auto;

                    > p {
                      font-family: Poppins;
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 22px;
                      text-align: left;
                      color: var(--oliver-base-blue);

                      /* max-width: ; */
                    }
                  }

                  .increment-input {
                    width: 143.85px;
                    height: 47px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    border: 1px solid var(--oliver-border);
                    border-radius: 6px;
                    background-color: #fff;

                    > {
                      button {
                        border: 0;
                        background-color: transparent;
                        width: 25%;
                        height: 100%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;

                        > img {
                          width: 20px;
                          height: 20px;
                        }
                      }

                      input {
                        width: 50%;
                        border: 0;
                        outline: 0;
                        background-color: transparent;
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 25px;
                        text-align: center;
                        color: var(--oliver-base-blue);
                      }
                    }
                  }
                }
              }

              .secondary-row {
                display: none;
              }
            }
          }
        }

        .footer {
          width: 100%;
          height: 70px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-top: 1px solid var(--oliver-border);

          > button {
            width: 306px;
            height: 50px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.split-by-product {
    width: 504px;
    height: min(502px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0;

      > {
        .body {
          height: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding-top: 25px;
          overflow-y: scroll;

          > .product-row {
            width: 423px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 20px 0;
            border-top: 1px solid var(--oliver-border);

            &:nth-child(1) {
              padding-top: 0;
              border: 0;
            }

            > {
              .main-row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                > {
                  .text-group {
                    margin-right: auto;

                    > p {
                      font-family: Poppins;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }
                  }

                  .increment-input {
                    width: 148px;
                    height: 44px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border: 1px solid var(--oliver-border);
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    border-radius: 6px;
                    background-color: #fff;

                    > {
                      button {
                        width: 25%;
                        height: 100%;
                        background-color: transparent;
                        border: 0;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;

                        > img {
                          width: 20px;
                          height: 20px;
                        }
                      }

                      input {
                        border: 0;
                        outline: 0;
                        width: 50%;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        color: var(--oliver-base-blue);
                      }
                    }
                  }
                }
              }

              .secondary-row {
                width: 100%;
                margin-top: 13px;

                > p {
                  font-family: Poppins;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }
              }
            }
          }
        }

        .footer {
          width: 100%;
          height: 90px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-top: 1px solid var(--oliver-border);

          > button {
            width: 425px;
            height: 50px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.split-by-product {
    width: 24.6094vw;
    height: min(24.5117vw, 95%);

    > .subwindow-body > {
      .body {
        padding-top: 1.2207vw;

        > .product-row {
          width: 20.6543vw;
          padding: 0.9766vw 0;
          border-top: 0.0488vw solid var(--oliver-border);

          > {
            .main-row > {
              .text-group > p {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
              }

              .increment-input {
                width: 7.2266vw;
                height: 2.1484vw;
                border: 0.0488vw solid var(--oliver-border);
                border-radius: 0.293vw;

                > {
                  button > img {
                    width: 0.9766vw;
                    height: 0.9766vw;
                  }

                  input {
                    font-size: 0.6836vw;
                    line-height: 0.9766vw;
                  }
                }
              }
            }

            .secondary-row {
              margin-top: 0.6348vw;

              > p {
                font-size: 0.5859vw;
                line-height: 0.8301vw;
              }
            }
          }
        }
      }

      .footer {
        height: 4.3945vw;
        border-top: 0.0488vw solid var(--oliver-border);

        > button {
          width: 20.752vw;
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }
  }
}
