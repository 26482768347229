@media (orientation: portrait) {
  .transactions-list {
    width: 100%;
    height: calc(100% - 70px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    > {
      .header {
        width: 100%;
        height: 55px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 16px;
        border-bottom: 1px solid var(--oliver-border);

        > {
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: 10px;
          }

          .sort-wrapper {
            width: 155px;
            height: 36px;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.open {
              z-index: 10;
              -webkit-box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
              box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);

              > img {
                -webkit-transform: rotate(180deg) translateY(-2px);
                -ms-transform: rotate(180deg) translateY(-2px);
                transform: rotate(180deg) translateY(-2px);
              }

              > input {
                border-color: var(--oliver-blue);
                border-radius: 8px 8px 0 0;
              }

              > .option-container {
                display: block;
              }
            }

            > img {
              position: absolute;
              top: calc(50% - 6px);
              right: 9px;
              width: 13px;
              height: 13px;
              -webkit-filter: brightness(0) saturate(100%) invert(53%)
                sepia(37%) saturate(1027%) hue-rotate(165deg) brightness(91%)
                contrast(99%);
              filter: brightness(0) saturate(100%) invert(53%) sepia(37%)
                saturate(1027%) hue-rotate(165deg) brightness(91%) contrast(99%);
            }

            > input {
              width: 100%;
              height: 100%;
              border: 1px solid var(--oliver-border);
              border-radius: 8px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0 25px 0 10px;

              font-size: 14px;
              line-height: 20px;
              color: var(--oliver-blue);
            }

            > {
              input:hover {
                border-color: var(--oliver-blue);
                cursor: pointer;
              }

              .option-container {
                width: 100%;
                display: none;
              }
            }

            > .option-container > .option {
              width: 100%;
              height: 36px;
              background-color: #fff;
              border: 1px solid var(--oliver-border);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0 10px;

              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-blue);

              &:hover {
                cursor: pointer;
                color: var(--oliver-blue);
              }

              &:last-child {
                border-radius: 0 0 8px 8px;
              }
            }
          }
        }
      }

      .body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        overflow-y: scroll;
        background-color: #f8fafc;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > {
          .no-results {
            width: 100%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-top: 58px;
            display: none;
            border: 0 !important;

            &:only-child {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
            }

            > p {
              &.style1 {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: center;
                color: var(--oliver-base-grey);
                margin-bottom: 10px;
              }

              &.style2 {
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                color: var(--oliver-base-grey);
              }
            }
          }

          .filter-name {
            width: 100%;
            height: 30px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-color: #f1f4f6;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 0 30px;
            border-top: 1px solid var(--oliver-border);

            &:nth-child(2) {
              border: 0;
            }

            > p {
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              text-align: left;
              color: var(--oliver-base-grey);
            }
          }

          .transaction-card {
            position: relative;
            width: 100%;
            height: 106px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-color: #fff;
            border: 0;
            border-top: 1px solid var(--oliver-border);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding: 15px 15px 15px 20px;
          }

          :last-child {
            border-bottom: 1px solid var(--oliver-border);
          }

          .transaction-card {
            > {
              .col {
                height: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;

                &:nth-child(1) {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                  flex-direction: column;
                }

                > {
                  p {
                    &.style1 {
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 24px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }

                    &.style2 {
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 17px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }
                  }

                  .row {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-top: auto;

                    > {
                      img {
                        width: 14px;
                        height: 12px;
                        margin-right: 8px;
                      }

                      p {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        text-align: left;
                        color: var(--oliver-base-blue);
                      }
                    }
                  }

                  p {
                    &.style3 {
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                      margin-bottom: auto;
                    }

                    &.style4 {
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 17px;
                      text-align: left;
                      color: var(--oliver-base-grey);
                      margin-top: auto;
                      font-style: italic;
                      padding-right: 1px;
                    }
                  }
                }
              }

              .selected-indicator {
                display: none;
                width: 7px;
                height: 76px;
                position: absolute;
                top: calc(50% - 38px);
                left: 0;
                background-color: var(--oliver-blue);
                border-radius: 0 4px 4px 0;
              }
            }

            &.selected {
              background-color: #e9f5fd;

              p {
                color: var(--oliver-blue) !important;
              }

              img {
                -webkit-filter: brightness(0) saturate(100%) invert(47%)
                  sepia(74%) saturate(816%) hue-rotate(173deg) brightness(95%)
                  contrast(92%);
                filter: brightness(0) saturate(100%) invert(47%) sepia(74%)
                  saturate(816%) hue-rotate(173deg) brightness(95%)
                  contrast(92%);
              }

              > .selected-indicator {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .transactions-list {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    > {
      .header {
        width: 100%;
        height: 68px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 20px;

        > {
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          .sort-wrapper {
            width: 155px;
            height: 36px;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            line-height: 0;

            &.open {
              z-index: 10;
              -webkit-box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
              box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);

              > img {
                -webkit-transform: rotate(180deg) translateY(-2px);
                -ms-transform: rotate(180deg) translateY(-2px);
                transform: rotate(180deg) translateY(-2px);
              }

              > input {
                border-color: var(--oliver-blue);
                border-radius: 8px 8px 0 0;
              }

              > .option-container {
                display: block;
              }
            }

            > img {
              position: absolute;
              top: calc(50% - 6px);
              right: 9px;
              width: 13px;
              height: 13px;
              -webkit-filter: brightness(0) saturate(100%) invert(53%)
                sepia(37%) saturate(1027%) hue-rotate(165deg) brightness(91%)
                contrast(99%);
              filter: brightness(0) saturate(100%) invert(53%) sepia(37%)
                saturate(1027%) hue-rotate(165deg) brightness(91%) contrast(99%);
            }

            > input {
              width: 100%;
              height: 100%;
              border: 1px solid var(--oliver-border);
              border-radius: 8px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0 25px 0 10px;

              font-size: 14px;
              line-height: 20px;
              color: var(--oliver-blue);
            }

            > {
              input:hover {
                border-color: var(--oliver-blue);
                cursor: pointer;
              }

              .option-container {
                width: 100%;
                display: none;
              }
            }

            > .option-container > .option {
              width: 100%;
              height: 36px;
              background-color: #fff;
              border: 1px solid var(--oliver-border);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0 10px;

              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-blue);

              &:hover {
                cursor: pointer;
                color: var(--oliver-blue);
              }

              &:last-child {
                border-radius: 0 0 8px 8px;
              }
            }
          }
        }
      }

      .body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        overflow: auto;
        background-color: #f8fafc;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > {
          .no-results {
            width: 100%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-top: 58px;
            display: none;
            border: 0 !important;

            &:only-child {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
            }

            > p {
              &.style1 {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: center;
                color: var(--oliver-base-grey);
                margin-bottom: 10px;
              }

              &.style2 {
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                color: var(--oliver-base-grey);
              }
            }
          }

          .filter-name {
            width: 100%;
            height: 30px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-top: 1px solid var(--oliver-border);
            background-color: #f1f4f6;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 0 16px;

            > p {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;

              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-grey);
            }
          }

          :first-child {
            border-top: 0;
          }

          :last-child {
            border-bottom: 1px solid var(--oliver-border);
          }

          button {
            position: relative;
            width: 100%;
            height: 96px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-color: #fff;
            border: 0;
            border-top: 1px solid var(--oliver-border);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding: 10px 20px 10px 24px;
            line-height: 0;

            > {
              .col {
                height: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;

                &:first-child {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  margin-right: 10px;
                }

                p {
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                }

                > {
                  p {
                    &.style1 {
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 24px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }

                    &.style2 {
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 17px;
                      text-align: left;
                      color: var(--oliver-base-blue);
                    }
                  }

                  .row {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-top: auto;

                    > {
                      img {
                        width: 14px;
                        height: 12px;
                        margin-right: 8px;
                      }

                      p {
                        max-width: calc(100% - 22px);

                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        text-align: left;
                        color: var(--oliver-base-blue);
                      }
                    }
                  }

                  p {
                    &.style3 {
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: right;
                      color: var(--oliver-base-blue);
                      margin-bottom: auto;
                    }

                    &.style4 {
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 17px;
                      text-align: right;
                      color: var(--oliver-base-blue);
                      font-style: italic;
                      padding-right: 1px;
                    }
                  }
                }
              }

              .selected-indicator {
                display: none;
                position: absolute;
                left: 0;
                top: calc(50% - 38px);
                width: 7px;
                height: 76px;
                background-color: var(--oliver-blue);
                border-radius: 0 4px 4px 0;
              }
            }

            &.selected {
              background-color: #e9f5fd;

              p {
                color: var(--oliver-blue) !important;
              }

              img {
                -webkit-filter: brightness(0) saturate(100%) invert(68%)
                  sepia(82%) saturate(3941%) hue-rotate(179deg) brightness(90%)
                  contrast(103%);
                filter: brightness(0) saturate(100%) invert(68%) sepia(82%)
                  saturate(3941%) hue-rotate(179deg) brightness(90%)
                  contrast(103%);
              }

              > .selected-indicator {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .transactions-list > {
    .header {
      height: 3.3203vw;
      border-bottom: 0.0488vw solid var(--oliver-border);
      padding: 0 0.9766vw;

      > {
        p {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }

        .sort-wrapper {
          width: 7.5684vw;
          height: 1.7578vw;

          &.open {
            -webkit-box-shadow: 0vw 0.293vw 0.4883vw rgba(94, 94, 94, 0.1);
            box-shadow: 0vw 0.293vw 0.4883vw rgba(94, 94, 94, 0.1);

            > img {
              -webkit-transform: rotate(180deg) translateY(-0.0977vw);
              -ms-transform: rotate(180deg) translateY(-0.0977vw);
              transform: rotate(180deg) translateY(-0.0977vw);
            }

            > input {
              border-radius: 0.3906vw 0.3906vw 0 0;
            }
          }

          > img {
            top: calc(50% - 0.293vw);
            right: 0.4395vw;
            width: 0.6348vw;
            height: 0.6348vw;
          }

          > input {
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.3906vw;
            padding: 0 1.2207vw 0 0.4883vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;
          }

          > .option-container > .option {
            height: 1.7578vw;
            border: 0.0488vw solid var(--oliver-border);
            padding: 0 0.4883vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;

            &:last-child {
              border-radius: 0 0 0.3906vw 0.3906vw;
            }
          }
        }
      }
    }

    .body > {
      .no-results {
        margin-top: 2.832vw;

        > p {
          &.style1 {
            font-size: 0.6836vw;
            line-height: 0.9766vw;
            margin-bottom: 0.4883vw;
          }

          &.style2 {
            font-size: 0.6348vw;
            line-height: 0.8789vw;
          }
        }
      }

      .filter-name {
        height: 1.4648vw;
        border-top: 0.0488vw solid var(--oliver-border);
        padding: 0 0.7813vw;

        > p {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }
      }

      :last-child {
        border-bottom: 0.0488vw solid var(--oliver-border);
      }

      button {
        height: 4.6875vw;
        border-top: 0.0488vw solid var(--oliver-border);
        padding: 0.4883vw 0.9766vw 0.4883vw 1.1719vw;

        > {
          .col {
            &:first-child {
              margin-right: 0.4883vw;
            }

            > {
              p {
                &.style1 {
                  font-size: 0.7813vw;
                  line-height: 1.1719vw;
                }

                &.style2 {
                  font-size: 0.5859vw;
                  line-height: 0.8301vw;
                }
              }

              .row > {
                img {
                  width: 0.6836vw;
                  height: 0.5859vw;
                  margin-right: 0.3906vw;
                }

                p {
                  max-width: calc(100% - 1.0742vw);
                  font-size: 0.5859vw;
                  line-height: 0.8301vw;
                }
              }

              p {
                &.style3 {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                }

                &.style4 {
                  font-size: 0.5859vw;
                  line-height: 0.8301vw;
                  padding-right: 0.04883vw;
                }
              }
            }
          }

          .selected-indicator {
            top: calc(50% - 1.8555vw);
            width: 0.3418vw;
            height: 3.7109vw;
            border-radius: 0 0.1953vw 0.1953vw 0;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .transactions-list > {
    .header {
      height: 5.6667vw;
      border-bottom: 0.0833vw solid var(--oliver-border);
      padding: 0 1.6667vw;

      > {
        p {
          font-size: 1.1667vw;
          line-height: 1.6667vw;
        }

        .sort-wrapper {
          width: 12.9167vw;
          height: 3vw;

          &.open {
            -webkit-box-shadow: 0vw 0.5vw 0.8333vw rgba(94, 94, 94, 0.1);
            box-shadow: 0vw 0.5vw 0.8333vw rgba(94, 94, 94, 0.1);

            > img {
              -webkit-transform: rotate(180deg) translateY(-0.1667vw);
              -ms-transform: rotate(180deg) translateY(-0.1667vw);
              transform: rotate(180deg) translateY(-0.1667vw);
            }

            > input {
              border-radius: 0.6667vw 0.6667vw 0 0;
            }
          }

          > img {
            top: calc(50% - 0.5vw);
            right: 0.75vw;
            width: 1.0833vw;
            height: 1.0833vw;
          }

          > input {
            border: 0.0833vw solid var(--oliver-border);
            border-radius: 0.6667vw;
            padding: 0 2.0833vw 0 0.8333vw;
            font-size: 1.1667vw;
            line-height: 1.6667vw;
          }

          > .option-container > .option {
            height: 3vw;
            border: 0.0833vw solid var(--oliver-border);
            padding: 0 0.8333vw;
            font-size: 1.1667vw;
            line-height: 1.6667vw;

            &:last-child {
              border-radius: 0 0 0.6667vw 0.6667vw;
            }
          }
        }
      }
    }

    .body > {
      .no-results {
        margin-top: 4.8333vw;

        > p {
          &.style1 {
            font-size: 1.1667vw;
            line-height: 1.6667vw;
            margin-bottom: 0.8333vw;
          }

          &.style2 {
            font-size: 1.0833vw;
            line-height: 1.5vw;
          }
        }
      }

      .filter-name {
        height: 2.5vw;
        border-top: 0.0833vw solid var(--oliver-border);
        padding: 0 1.3333vw;

        > p {
          font-size: 1.1667vw;
          line-height: 1.6667vw;
        }
      }

      :last-child {
        border-bottom: 0.0833vw solid var(--oliver-border);
      }

      button {
        height: 8vw;
        border-top: 0.0833vw solid var(--oliver-border);
        padding: 0.8333vw 1.6667vw 0.8333vw 2vw;

        > {
          .col {
            &:first-child {
              margin-right: 0.8333vw;
            }

            > {
              p {
                &.style1 {
                  font-size: 1.3333vw;
                  line-height: 2vw;
                }

                &.style2 {
                  font-size: 1vw;
                  line-height: 1.4167vw;
                }
              }

              .row > {
                img {
                  width: 1.1667vw;
                  height: 1vw;
                  margin-right: 0.6667vw;
                }

                p {
                  max-width: calc(100% - 1.8333vw);
                  font-size: 1vw;
                  line-height: 1.4167vw;
                }
              }

              p {
                &.style3 {
                  font-size: 1.1667vw;
                  line-height: 1.6667vw;
                }

                &.style4 {
                  font-size: 1vw;
                  line-height: 1.4167vw;
                  padding-right: 0.08333vw;
                }
              }
            }
          }

          .selected-indicator {
            top: calc(50% - 3.1667vw);
            width: 0.5833vw;
            height: 6.3333vw;
            border-radius: 0 0.3333vw 0.3333vw 0;
          }
        }
      }
    }
  }
}
