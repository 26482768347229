@media (orientation: portrait) {
  .cart {
    background-color: #fff;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    &.open {
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0);
    }

    > {
      .mobile-header {
        width: 100%;
        height: 62px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 16px 0 24px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        > {
          p {
            
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          button {
            width: 32px;
            height: 32px;
            border-radius: 6px;
            border: 0;
            background-color: #f1f4f6;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;

            > img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        overflow-y: scroll;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 16px 12px 16px;

        > {
          img {
            display: none;
            width: 41.80554%;
            height: auto;
            margin: auto;

            &:only-child {
              display: block;
            }
          }

          .cart-item {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            width: 100%;
            padding: 12px 0;
            border-top: 1px solid var(--oliver-border);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;

            &:nth-child(2) {
              border-top: 0;
            }

            > {
              .main-row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                &.aligned {
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                }

                > {
                  .quantity {
                    
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    margin-right: 15px;
                    margin: 0 15px 0 8px;
                    color: var(--oliver-blue);

                    &:hover {
                      text-decoration: underline;
                    }
                  }

                  .content-style {
                    
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-blue-dark);
                    width: 0;
                    -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                            flex-grow: 1;
                    padding-right: 10px;

                    &:hover {
                      text-decoration: underline;
                      color: var(--oliver-blue);
                    }

                    &.line-capped {
                      overflow: hidden;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                         text-overflow: ellipsis;
                    }
                  }

                  .price {
                    
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: right;
                    color: var(--oliver-base-blue);
                    margin-right: 12.63px;
                  }

                  button.remove-cart-item {
                    width: 18px;
                    height: 18px;
                    border: 0;
                    background-color: transparent;
                    margin-right: 6.63px;

                    > img {
                      width: 100%;
                      height: 100%;
                    }

                    &:hover > img {
                      cursor: pointer;
                      -webkit-filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1963%) hue-rotate(322deg) brightness(96%) contrast(111%);
                              filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1963%) hue-rotate(322deg) brightness(96%) contrast(111%);
                    }
                  }

                  .tag {
                    padding: 5.5px 11px;
                    background-color: rgba(39, 151, 232, 0.2);
                    border: 0;
                    border-radius: 6px;
                    
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 15px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-right: 14px;

                    &.customer {
                      background-color: rgba(39, 151, 232, 0.2);
                    }

                    &.group {
                      background-color: rgba(165, 104, 188, 0.2);
                    }

                    &.custom-fee {
                      background-color: rgba(255, 208, 38, 0.2);
                    }

                    &.cart-note {
                      background-color: rgba(255, 133, 82, 0.2);
                    }
                  }
                }
              }

              .secondary-col {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                padding-left: 32px;
                padding: 0 34px 0 32px;
                margin-top: 10px;

                > {
                  p {
                    
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    text-align: left;
                    color: var(--oliver-base-grey);

                    > b {
                      font-weight: 600;
                    }
                  }

                  .row {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    width: 100%;

                    > p {
                      
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 17px;
                      text-align: left;
                      color: var(--oliver-base-grey);
                      -webkit-box-flex: 1;
                          -ms-flex: 1;
                              flex: 1;

                      &:last-of-type {
                        text-align: right;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        width: 100%;
        background-color: #f8fafc;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        border-top: 1px solid var(--oliver-border);

        > {
          .totals {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            width: 100%;
            padding: 15px;

            > .row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              margin-top: 7px;

              &:nth-child(1) {
                margin-top: 0;
              }

              > {
                p {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);

                  > b {
                    font-weight: 500;
                  }

                  &:first-of-type {
                    margin-right: 7px;
                  }

                  &:last-of-type {
                    margin-left: auto;
                  }
                }

                button {
                  &#editCartDiscount {
                    background-color: transparent;
                    border: 0;
                    
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    text-align: left;
                    color: var(--oliver-blue);
                  }

                  &#taxesButton {
                    background-color: transparent;
                    border: 0;
                    
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: var(--oliver-blue);
                    margin-right: 4px;
                  }
                }
              }
            }
          }

          .checkout-container {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            width: 100%;
            border-top: 1px solid var(--oliver-border);
            height: 84px;
            line-height: 0;

            > button {
              width: 100%;
              height: 100%;
              border: 0;
              background-color: transparent;
              padding: 15px;

              > p {
                background-color: var(--oliver-blue);
                width: 100%;
                height: 100%;
                border-radius: 8px;
                
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .cart {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    > {
      .mobile-header {
        display: none;
      }

      .body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        overflow: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 16px 12px 16px;

        > {
          img {
            display: none;
            width: 41.80554%;
            height: auto;
            margin: auto;

            &:only-child {
              display: block;
            }
          }

          .cart-item {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            width: 100%;
            padding: 12px 0;
            border-top: 1px solid var(--oliver-border);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;

            &:nth-child(2) {
              border-top: 0;
            }

            > {
              .main-row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                &.aligned {
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                }

                > {
                  .quantity {
                    
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    margin-right: 15px;
                    margin: 0 15px 0 8px;
                    color: var(--oliver-blue);
                  }

                  .content-style {
                    
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-blue-dark);
                    width: 0;
                    -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                            flex-grow: 1;
                    padding-right: 10px;
                  }

                  .quantity:hover {
                    cursor: pointer;
                    color: var(--oliver-blue);
                    text-decoration: underline;
                  }

                  .content-style {
                    &:hover {
                      cursor: pointer;
                      color: var(--oliver-blue);
                      text-decoration: underline;
                    }

                    &.line-capped {
                      overflow: hidden;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                         text-overflow: ellipsis;
                    }
                  }

                  .price {
                    
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: right;
                    color: var(--oliver-base-blue);
                    margin-right: 12.63px;
                  }

                  button.remove-cart-item {
                    width: 18px;
                    height: 18px;
                    border: 0;
                    background-color: transparent;
                    margin-right: 6.63px;
                    line-height: 0;

                    > img {
                      width: 100%;
                      height: 100%;
                    }

                    &:hover > img {
                      cursor: pointer;
                      -webkit-filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1963%) hue-rotate(322deg) brightness(96%) contrast(111%);
                              filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(1963%) hue-rotate(322deg) brightness(96%) contrast(111%);
                    }
                  }

                  .tag {
                    padding: 5.5px 11px;
                    background-color: rgba(39, 151, 232, 0.2);
                    border: 0;
                    border-radius: 6px;
                    
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 15px;
                    text-align: left;
                    color: var(--oliver-base-blue);
                    margin-right: 14px;

                    &.customer {
                      background-color: rgba(39, 151, 232, 0.2);
                    }

                    &.group {
                      background-color: rgba(165, 104, 188, 0.2);
                    }

                    &.custom-fee {
                      background-color: rgba(255, 208, 38, 0.2);
                    }

                    &.cart-note {
                      background-color: rgba(255, 133, 82, 0.2);
                    }
                  }
                }
              }

              .secondary-col {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                padding: 0 34px 0 32px;
                margin-top: 10px;

                > {
                  p {
                    
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    text-align: left;
                    color: var(--oliver-base-grey);

                    > b {
                      font-weight: 600;
                    }
                  }

                  .row {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    width: 100%;

                    > p {
                      
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 17px;
                      text-align: left;
                      color: var(--oliver-base-grey);
                      -webkit-box-flex: 1;
                          -ms-flex: 1;
                              flex: 1;

                      &:last-of-type {
                        text-align: right;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        width: 100%;
        background-color: #f8fafc;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        border-top: 1px solid var(--oliver-border);

        > {
          .totals {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            width: 100%;
            padding: 15px;

            > .row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              margin-top: 7px;

              &:nth-child(1) {
                margin-top: 0;
              }

              > {
                p {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue-dark);

                  > b {
                    font-weight: 500;

                    &.bolder {
                      font-weight: 700;
                    }
                  }

                  &:first-of-type {
                    margin-right: 7px;
                  }

                  &:last-of-type {
                    margin-left: auto;
                  }
                }

                button {
                  &#editCartDiscount {
                    background-color: transparent;
                    border: 0;
                    
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    text-align: left;
                    color: var(--oliver-blue);
                  }

                  &#taxesButton {
                    background-color: transparent;
                    border: 0;
                    
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: var(--oliver-blue);
                    margin-right: 4px;
                  }
                }
              }
            }
          }

          .checkout-container {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            width: 100%;
            border-top: 1px solid var(--oliver-border);
            height: 84px;

            > button {
              width: 100%;
              height: 100%;
              border: 0;
              background-color: transparent;
              padding: 15px;

              > p {
                background-color: var(--oliver-blue);
                width: 100%;
                height: 100%;
                border-radius: 8px;
                
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .cart > {
    .body {
      padding: 0 0.7813vw 0.5859vw 0.7813vw;

      > .cart-item {
        padding: 0.5859vw 0;
        border-top: 0.0488vw solid var(--oliver-border);

        > {
          .main-row > {
            .quantity {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
              margin-right: 0.7324vw;
              margin: 0 0.7324vw 0 0.3906vw;
            }

            .content-style {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
              padding-right: 0.4883vw;
            }

            .price {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
              margin-right: 0.6167vw;
            }

            button.remove-cart-item {
              width: 0.8789vw;
              height: 0.8789vw;
              margin-right: 0.3237vw;
            }

            .tag {
              padding: 0.2686vw 0.5371vw;
              border-radius: 0.293vw;
              font-size: 0.5371vw;
              line-height: 0.7324vw;
              margin-right: 0.6836vw;
            }
          }

          .secondary-col {
            padding: 0 1.6602vw 0 1.5625vw;
            margin-top: 0.4883vw;

            > {
              p, .row > p {
                font-size: 0.5859vw;
                line-height: 0.8301vw;
              }
            }
          }
        }
      }
    }

    .footer {
      border-top: 0.0488vw solid var(--oliver-border);

      > {
        .totals {
          padding: 0.7324vw;

          > .row {
            margin-top: 0.3418vw;

            > {
              p {
                font-size: 0.6836vw;
                line-height: 0.9766vw;

                &:first-of-type {
                  margin-right: 0.3418vw;
                }
              }

              button {
                &#editCartDiscount {
                  font-size: 0.5859vw;
                  line-height: 0.8301vw;
                }

                &#taxesButton {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                  margin-right: 0.1953vw;
                }
              }
            }
          }
        }

        .checkout-container {
          border-top: 0.0488vw solid var(--oliver-border);
          height: 4.1016vw;

          > button {
            padding: 0.7324vw;

            > p {
              border-radius: 0.3906vw;
              font-size: 0.8789vw;
              line-height: 1.1719vw;
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .cart > {
    .body {
      padding: 0 1.3333vw 1vw 1.3333vw;

      > .cart-item {
        padding: 1vw 0;
        border-top: 0.0833vw solid var(--oliver-border);

        > {
          .main-row > {
            .quantity {
              font-size: 1.1667vw;
              line-height: 1.6667vw;
              margin-right: 1.25vw;
              margin: 0 1.25vw 0 0.6667vw;
            }

            .content-style {
              font-size: 1.1667vw;
              line-height: 1.6667vw;
              padding-right: 0.8333vw;
            }

            .price {
              font-size: 1.1667vw;
              line-height: 1.6667vw;
              margin-right: 1.0525vw;
            }

            button.remove-cart-item {
              width: 1.5vw;
              height: 1.5vw;
              margin-right: 0.5525vw;
            }

            .tag {
              padding: 0.4583vw 0.9167vw;
              border-radius: 0.5vw;
              font-size: 0.9167vw;
              line-height: 1.25vw;
              margin-right: 1.1667vw;
            }
          }

          .secondary-col {
            padding: 0 2.8333vw 0 2.6667vw;
            margin-top: 0.8333vw;

            > {
              p, .row > p {
                font-size: 1vw;
                line-height: 1.4167vw;
              }
            }
          }
        }
      }
    }

    .footer {
      border-top: 0.0833vw solid var(--oliver-border);

      > {
        .totals {
          padding: 1.25vw;

          > .row {
            margin-top: 0.5833vw;

            > {
              p {
                font-size: 1.1667vw;
                line-height: 1.6667vw;

                &:first-of-type {
                  margin-right: 0.5833vw;
                }
              }

              button {
                &#editCartDiscount {
                  font-size: 1vw;
                  line-height: 1.4167vw;
                }

                &#taxesButton {
                  font-size: 1.1667vw;
                  line-height: 1.6667vw;
                  margin-right: 0.3333vw;
                }
              }
            }
          }
        }

        .checkout-container {
          border-top: 0.0833vw solid var(--oliver-border);
          height: 7vw;

          > button {
            padding: 1.25vw;

            > p {
              border-radius: 0.6667vw;
              font-size: 1.5vw;
              line-height: 2vw;
            }
          }
        }
      }
    }
  }
}