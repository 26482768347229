@media (orientation: portrait) {
  /* Transactions Search */

  .transactions-search {
    position: fixed;
    top: 70px;
    background-color: #fff;
    z-index: 3;
    width: 100%;
    height: calc(100% - 70px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    &.open {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    > {
      .search-header {
        display: none;
      }

      .search-header-mobile {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > {
          #mobileSearchExit {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-color: transparent;
            border: 0;

            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-blue);
            margin-right: auto;

            > img {
              width: 9.1px;
              height: 16.2px;
              margin-right: 9px;
            }
          }

          #mobileSearchFieldClear {
            width: 109px;
            height: 40px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid var(--oliver-blue);
            border-radius: 6px;
            background-color: #fff;

            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-blue);
          }
        }
      }

      .search-body {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > p.mobile-only {
          font-size: 16px;
          font-weight: 500;
          line-height: 23px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 6px;
        }

        label {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 4px;
        }

        input {
          width: 100%;
          height: 44px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          padding: 0 12px;
          outline: 0;

          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 20px;
        }

        > {
          input:first-of-type {
            margin-bottom: 7px;
          }

          p:not(.mobile-only) {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            text-align: left;
            color: var(--oliver-base-grey);
          }

          .divider {
            width: 100%;
            height: 1px;
            background-color: var(--oliver-border);
            margin: 20px 0;
          }

          .dropdown-wrapper {
            position: relative;
            width: 100%;
            height: 44px;
            margin-bottom: 20px;
            line-height: 0;

            &.open {
              z-index: 2;
            }

            &:hover {
              cursor: pointer;
            }

            > img {
              position: absolute;
              right: 14px;
              top: calc(50% - 9px);
              width: 18px;
              height: 18px;
            }

            &.open {
              > img {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
              }

              > input {
                border-radius: 6px 6px 0 0;
                border-color: var(--oliver-blue);
              }

              > .option-list {
                display: block;
              }
            }

            > input {
              width: 100%;
              height: 100%;
              margin: 0;

              &:focus {
                border-color: var(--oliver-border);
              }
            }

            > {
              input:hover {
                cursor: pointer;
              }

              .option-list {
                display: none;
                width: 100%;
                max-height: 176px;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
                overflow-y: scroll;
                border-radius: 0 0 7px 7px;
              }
            }

            > .option-list > .option {
              width: 100%;
              height: 44px;
              background-color: #fff;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0 12px;
              border: 1px solid var(--oliver-border);
              border-top: 0;

              > p {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
              }

              &:hover > p {
                color: var(--oliver-blue);
              }
            }
          }

          .input-row {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            > .input-col {
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;

              &:first-child {
                margin-right: 20px;
              }

              > .date-selector-wrapper {
                position: relative;
                margin-bottom: 20px;
                line-height: 0;

                > {
                  input {
                    margin-bottom: 0;
                    padding-right: 32px;
                  }

                  button {
                    position: absolute;
                    right: 12px;
                    top: calc(50% - 10px);
                    width: 20px;
                    height: 20px;
                    border: 0;
                    background-color: transparent;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    &:hover > img {
                      -webkit-filter: brightness(0) saturate(100%) invert(41%)
                        sepia(90%) saturate(579%) hue-rotate(166deg)
                        brightness(106%) contrast(89%);
                      filter: brightness(0) saturate(100%) invert(41%)
                        sepia(90%) saturate(579%) hue-rotate(166deg)
                        brightness(106%) contrast(89%);
                    }

                    > img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .date-selector {
                    position: fixed;
                    -ms-flex-item-align: center;
                    -ms-grid-row-align: center;
                    align-self: center;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 25px 20px;
                    border: 1px solid var(--oliver-border);
                    background-color: #fff;
                    z-index: 2;
                    border-radius: 6px;
                    margin-top: 7px;
                    display: none;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-filter: drop-shadow(
                      0px 10px 20px rgba(119, 135, 147, 0.1)
                    );
                    filter: drop-shadow(0px 10px 20px rgba(119, 135, 147, 0.1));
                  }
                }

                &.left > .date-selector {
                  left: 16px;
                }

                &.right > .date-selector {
                  right: 16px;
                }

                > .date-selector {
                  &.open {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                  }

                  > {
                    .header-row {
                      width: 280px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: justify;
                      -ms-flex-pack: justify;
                      justify-content: space-between;

                      > {
                        button {
                          background-color: transparent;
                          border: 0;

                          /* border: 1px solid var(--oliver-blue); */
                          display: -webkit-box;
                          display: -ms-flexbox;
                          display: flex;
                          -webkit-box-align: center;
                          -ms-flex-align: center;
                          align-items: center;
                          -webkit-box-pack: center;
                          -ms-flex-pack: center;
                          justify-content: center;
                          height: 30px;
                          padding: 0 10px;

                          font-size: 18px;
                          font-weight: 500;
                          line-height: 24px;
                          text-align: center;
                          color: var(--oliver-blue);

                          &:hover {
                            text-decoration: underline;
                          }

                          > img {
                            width: 40px;
                            height: auto;
                          }

                          &.calendar-left {
                            padding-left: 0;
                          }

                          &.calendar-right {
                            padding-right: 0;
                          }
                        }

                        div {
                          font-size: 18px;
                          font-weight: 500;
                          line-height: 24px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                        }
                      }
                    }

                    .day-row {
                      width: 280px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      border-bottom: 1px solid var(--oliver-border);
                      margin-bottom: 10px;

                      > .day {
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        height: 40px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;

                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: center;
                        color: var(--oliver-base-grey);
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                      }
                    }

                    .date-row {
                      width: 280px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;

                      > button.cell {
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        height: 40px;
                        background-color: transparent;
                        border: 0;

                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        color: var(--oliver-base-blue);
                        border-radius: 6px;

                        &:hover:not(:disabled) {
                          background-color: var(--oliver-blue);
                          color: #fff;
                        }

                        &:disabled {
                          color: var(--oliver-base-grey);
                        }
                      }
                    }

                    .month-row {
                      width: 320px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      margin-top: 15px;

                      > button.cell {
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        height: 40px;
                        background-color: transparent;
                        border: 0;

                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        color: var(--oliver-base-blue);
                        border-radius: 6px;

                        &:hover {
                          background-color: var(--oliver-blue);
                          color: #fff;
                        }
                      }
                    }

                    .year-row {
                      width: 280px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      margin-top: 15px;

                      > button.cell {
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        height: 40px;
                        background-color: transparent;
                        border: 0;

                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        color: var(--oliver-base-blue);
                        border-radius: 6px;

                        &:hover {
                          background-color: var(--oliver-blue);
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          button {
            width: 100%;
            height: 54px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            margin: auto 0 20px 0;

            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  /* Transactions Search */

  .transactions-search {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 2;

    > {
      .search-header-mobile {
        display: none;
      }

      .search-header {
        width: 100%;
        height: 68px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid var(--oliver-border);
        padding: 0 16px 0 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > {
          p {
            font-size: 20px;
            font-weight: 600;
            line-height: 29px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          button {
            width: 58px;
            height: 36px;
            background-color: #e7f4fe;
            border: 0;
            border-radius: 8px;
            padding: 4px;

            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: var(--oliver-blue);
          }
        }
      }

      .search-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        overflow: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 22px;

        > .mobile-only {
          display: none;
        }

        label {
          width: 100%;

          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 4px;
        }

        > label:first-of-type {
          margin-top: 13px;
        }

        input {
          width: 100%;
          height: 44px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          outline: 0;
          padding: 0 12px;

          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 20px;

          &:focus {
            border-color: var(--oliver-blue);
          }
        }

        > {
          input:first-of-type {
            margin-bottom: 7px;
          }

          p {
            width: 100%;

            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            text-align: left;
            color: var(--oliver-base-grey);
          }

          .divider {
            width: 100%;
            height: 1px;
            background-color: var(--oliver-border);
            margin: 20px 0;
          }

          .dropdown-wrapper {
            position: relative;
            width: 100%;
            height: 44px;
            margin-bottom: 20px;
            line-height: 0;

            &.open {
              z-index: 2;
              -webkit-box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.3);
              box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.3);
            }

            &:hover {
              cursor: pointer;
            }

            > img {
              position: absolute;
              right: 14px;
              top: calc(50% - 9px);
              width: 18px;
              height: 18px;
            }

            &.open {
              > img {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
              }

              > input {
                border-radius: 6px 6px 0 0;
                border-color: var(--oliver-blue);
              }

              > .option-list {
                display: block;
              }
            }

            > input {
              width: 100%;
              height: 100%;
              margin: 0;

              &:focus {
                border-color: var(--oliver-border);
              }
            }

            > {
              input:hover {
                cursor: pointer;
              }

              .option-list {
                display: none;
                width: 100%;
                max-height: 176px;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
                overflow: auto;
                border-radius: 0 0 7px 7px;
              }
            }

            > .option-list > .option {
              width: 100%;
              height: 44px;
              background-color: #fff;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0 12px;
              border: 1px solid var(--oliver-border);
              border-top: 0;

              &:last-child {
                border-radius: 0 0 7px 7px;
              }

              > p {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
              }

              &:hover > p {
                color: var(--oliver-blue);
              }
            }
          }

          .input-row {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            > .input-col {
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;

              &:first-child {
                margin-right: 18px;
              }

              > .date-selector-wrapper {
                position: relative;
                margin-bottom: 20px;
                line-height: 0;

                > {
                  input {
                    margin-bottom: 0;
                    padding-right: 32px;
                  }

                  button {
                    position: absolute;
                    right: 12px;
                    top: calc(50% - 10px);
                    width: 20px;
                    height: 20px;
                    border: 0;
                    background-color: transparent;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    &:hover > img {
                      -webkit-filter: brightness(0) saturate(100%) invert(41%)
                        sepia(90%) saturate(579%) hue-rotate(166deg)
                        brightness(106%) contrast(89%);
                      filter: brightness(0) saturate(100%) invert(41%)
                        sepia(90%) saturate(579%) hue-rotate(166deg)
                        brightness(106%) contrast(89%);
                    }

                    > img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .date-selector {
                    position: fixed;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 25px 20px;
                    border: 1px solid var(--oliver-border);
                    background-color: #fff;
                    z-index: 2;
                    border-radius: 6px;
                    margin-top: 7px;
                    display: none;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-filter: drop-shadow(
                      0px 10px 20px rgba(119, 135, 147, 0.1)
                    );
                    filter: drop-shadow(0px 10px 20px rgba(119, 135, 147, 0.1));

                    &.open {
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                    }

                    > {
                      .header-row {
                        width: 280px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;

                        > {
                          button {
                            background-color: transparent;
                            border: 0;

                            /* border: 1px solid var(--oliver-blue); */
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            height: 30px;
                            padding: 0 10px;

                            font-size: 18px;
                            font-weight: 500;
                            line-height: 24px;
                            text-align: center;
                            color: var(--oliver-blue);

                            &:hover {
                              text-decoration: underline;
                            }

                            > img {
                              width: 40px;
                              height: auto;
                            }

                            &.calendar-left {
                              padding-left: 0;
                            }

                            &.calendar-right {
                              padding-right: 0;
                            }
                          }

                          div {
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 24px;
                            text-align: center;
                            color: var(--oliver-base-blue);
                          }
                        }
                      }

                      .day-row {
                        width: 280px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        border-bottom: 1px solid var(--oliver-border);
                        margin-bottom: 10px;

                        > .day {
                          -webkit-box-flex: 1;
                          -ms-flex: 1;
                          flex: 1;
                          height: 40px;
                          display: -webkit-box;
                          display: -ms-flexbox;
                          display: flex;
                          -webkit-box-align: center;
                          -ms-flex-align: center;
                          align-items: center;
                          -webkit-box-pack: center;
                          -ms-flex-pack: center;
                          justify-content: center;

                          font-size: 16px;
                          font-weight: 400;
                          line-height: 24px;
                          text-align: center;
                          color: var(--oliver-base-grey);
                          -webkit-box-sizing: border-box;
                          box-sizing: border-box;
                        }
                      }

                      .date-row {
                        width: 280px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        > button.cell {
                          -webkit-box-flex: 1;
                          -ms-flex: 1;
                          flex: 1;
                          height: 40px;
                          background-color: transparent;
                          border: 0;

                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                          border-radius: 6px;

                          &:hover:not(:disabled) {
                            background-color: var(--oliver-blue);
                            color: #fff;
                          }

                          &:disabled {
                            color: var(--oliver-base-grey);
                          }
                        }
                      }

                      .month-row {
                        width: 320px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        margin-top: 15px;

                        > button.cell {
                          -webkit-box-flex: 1;
                          -ms-flex: 1;
                          flex: 1;
                          height: 40px;
                          background-color: transparent;
                          border: 0;

                          font-size: 16px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                          border-radius: 6px;

                          &:hover {
                            background-color: var(--oliver-blue);
                            color: #fff;
                          }
                        }
                      }

                      .year-row {
                        width: 280px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        margin-top: 15px;

                        > button.cell {
                          -webkit-box-flex: 1;
                          -ms-flex: 1;
                          flex: 1;
                          height: 40px;
                          background-color: transparent;
                          border: 0;

                          font-size: 16px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                          border-radius: 6px;

                          &:hover {
                            background-color: var(--oliver-blue);
                            color: #fff;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          button {
            width: 100%;
            height: 45px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;

            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin: auto 0 15px 0;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .transactions-search > {
    .search-header {
      height: 3.3203vw;
      border-bottom: 0.0488vw solid var(--oliver-border);
      padding: 0 0.7813vw 0 1.2207vw;

      > {
        p {
          font-size: 0.9766vw;
          line-height: 1.416vw;
        }

        button {
          width: 2.832vw;
          height: 1.7578vw;
          border-radius: 0.3906vw;
          padding: 0.1953vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }
      }
    }

    .search-body {
      padding: 0 1.0742vw;

      label {
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.1953vw;
      }

      > label:first-of-type {
        margin-top: 0.6348vw;
      }

      input {
        height: 2.1484vw;
        border: 0.0488vw solid var(--oliver-border);
        border-radius: 0.293vw;
        padding: 0 0.5859vw;
        font-size: 0.6836vw;
        line-height: 0.9766vw;
        margin-bottom: 0.9766vw;
      }

      > {
        input:first-of-type {
          margin-bottom: 0.3418vw;
        }

        p {
          font-size: 0.5859vw;
          line-height: 0.8301vw;
        }

        .divider {
          height: 0.0488vw;
          margin: 0.9766vw 0;
        }

        .dropdown-wrapper {
          height: 2.1484vw;
          margin-bottom: 0.9766vw;

          &.open {
            -webkit-box-shadow: 0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.3);
            box-shadow: 0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.3);

            > input {
              border-radius: 0.293vw 0.293vw 0 0;
            }
          }

          > img {
            right: 0.6836vw;
            top: calc(50% - 0.4395vw);
            width: 0.8789vw;
            height: 0.8789vw;
          }

          > .option-list {
            max-height: 8.5938vw;
            border-radius: 0 0 0.3418vw 0.3418vw;

            > .option {
              height: 2.1484vw;
              padding: 0 0.5859vw;
              border: 0.0488vw solid var(--oliver-border);

              &:last-child {
                border-radius: 0 0 0.3418vw 0.3418vw;
              }

              > p {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
              }
            }
          }
        }

        .input-row > .input-col {
          &:first-child {
            margin-right: 0.8789vw;
          }

          > .date-selector-wrapper {
            margin-bottom: 0.9766vw;

            > {
              input {
                padding-right: 1.5625vw;
              }

              button {
                right: 0.5859vw;
                top: calc(50% - 0.4883vw);
                width: 0.9766vw;
                height: 0.9766vw;
              }

              .date-selector {
                padding: 1.2207vw 0.9766vw;
                border: 0.0488vw solid var(--oliver-border);
                border-radius: 0.293vw;
                margin-top: 0.3418vw;
                -webkit-filter: drop-shadow(
                  0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.1)
                );
                filter: drop-shadow(
                  0vw 0.4883vw 0.9766vw rgba(119, 135, 147, 0.1)
                );

                > {
                  .header-row {
                    width: 13.6719vw;

                    > {
                      button {
                        height: 1.4648vw;
                        padding: 0 0.4883vw;
                        font-size: 0.8789vw;
                        line-height: 1.1719vw;

                        > img {
                          width: 1.9531vw;
                        }
                      }

                      div {
                        font-size: 0.8789vw;
                        line-height: 1.1719vw;
                      }
                    }
                  }

                  .day-row {
                    width: 13.6719vw;
                    border-bottom: 0.0488vw solid var(--oliver-border);
                    margin-bottom: 0.4883vw;

                    > .day {
                      height: 1.9531vw;
                      font-size: 0.7813vw;
                      line-height: 1.1719vw;
                    }
                  }

                  .date-row {
                    width: 13.6719vw;

                    > button.cell {
                      height: 1.9531vw;
                      font-size: 0.6836vw;
                      line-height: 0.9766vw;
                      border-radius: 0.293vw;
                    }
                  }

                  .month-row {
                    width: 15.625vw;
                    margin-top: 0.7324vw;

                    > button.cell {
                      height: 1.9531vw;
                      font-size: 0.7813vw;
                      line-height: 0.9766vw;
                      border-radius: 0.293vw;
                    }
                  }

                  .year-row {
                    width: 13.6719vw;
                    margin-top: 0.7324vw;

                    > button.cell {
                      height: 1.9531vw;
                      font-size: 0.7813vw;
                      line-height: 0.9766vw;
                      border-radius: 0.293vw;
                    }
                  }
                }
              }
            }
          }
        }

        button {
          height: 2.1973vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin: auto 0 0.7324vw 0;
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .transactions-search > {
    .search-header {
      height: 5.6667vw;
      border-bottom: 0.0833vw solid var(--oliver-border);
      padding: 0 1.3333vw 0 2.0833vw;

      > {
        p {
          font-size: 1.6667vw;
          line-height: 2.4167vw;
        }

        button {
          width: 4.8333vw;
          height: 3vw;
          border-radius: 0.6667vw;
          padding: 0.3333vw;
          font-size: 1.1667vw;
          line-height: 1.6667vw;
        }
      }
    }

    .search-body {
      padding: 0 1.8333vw;

      label {
        font-size: 1.1667vw;
        line-height: 1.6667vw;
        margin-bottom: 0.3333vw;
      }

      > label:first-of-type {
        margin-top: 1.0833vw;
      }

      input {
        height: 3.6667vw;
        border: 0.0833vw solid var(--oliver-border);
        border-radius: 0.5vw;
        padding: 0 1vw;
        font-size: 1.1667vw;
        line-height: 1.6667vw;
        margin-bottom: 1.6667vw;
      }

      > {
        input:first-of-type {
          margin-bottom: 0.5833vw;
        }

        p {
          font-size: 1vw;
          line-height: 1.4167vw;
        }

        .divider {
          height: 0.0833vw;
          margin: 1.6667vw 0;
        }

        .dropdown-wrapper {
          height: 3.6667vw;
          margin-bottom: 1.6667vw;

          &.open {
            -webkit-box-shadow: 0vw 0.8333vw 1.6667vw rgba(119, 135, 147, 0.3);
            box-shadow: 0vw 0.8333vw 1.6667vw rgba(119, 135, 147, 0.3);

            > input {
              border-radius: 0.5vw 0.5vw 0 0;
            }
          }

          > img {
            right: 1.1667vw;
            top: calc(50% - 0.75vw);
            width: 1.5vw;
            height: 1.5vw;
          }

          > .option-list {
            max-height: 14.6667vw;
            border-radius: 0 0 0.5833vw 0.5833vw;

            > .option {
              height: 3.6667vw;
              padding: 0 1vw;
              border: 0.0833vw solid var(--oliver-border);

              &:last-child {
                border-radius: 0 0 0.5833vw 0.5833vw;
              }

              > p {
                font-size: 1.1667vw;
                line-height: 1.6667vw;
              }
            }
          }
        }

        .input-row > .input-col {
          &:first-child {
            margin-right: 1.5vw;
          }

          > .date-selector-wrapper {
            margin-bottom: 1.6667vw;

            > {
              input {
                padding-right: 2.6667vw;
              }

              button {
                right: 1vw;
                top: calc(50% - 0.8333vw);
                width: 1.6667vw;
                height: 1.6667vw;
              }

              .date-selector {
                padding: 2.0833vw 1.6667vw;
                border: 0.0833vw solid var(--oliver-border);
                border-radius: 0.5vw;
                margin-top: 0.5833vw;
                -webkit-filter: drop-shadow(
                  0vw 0.8333vw 1.6667vw rgba(119, 135, 147, 0.1)
                );
                filter: drop-shadow(
                  0vw 0.8333vw 1.6667vw rgba(119, 135, 147, 0.1)
                );

                > {
                  .header-row {
                    width: 23.3333vw;

                    > {
                      button {
                        height: 2.5vw;
                        padding: 0 0.8333vw;
                        font-size: 1.5vw;
                        line-height: 2vw;

                        > img {
                          width: 3.3333vw;
                        }
                      }

                      div {
                        font-size: 1.5vw;
                        line-height: 2vw;
                      }
                    }
                  }

                  .day-row {
                    width: 23.3333vw;
                    border-bottom: 0.0833vw solid var(--oliver-border);
                    margin-bottom: 0.8333vw;

                    > .day {
                      height: 3.3333vw;
                      font-size: 1.3333vw;
                      line-height: 2vw;
                    }
                  }

                  .date-row {
                    width: 23.3333vw;

                    > button.cell {
                      height: 3.3333vw;
                      font-size: 1.1667vw;
                      line-height: 1.6667vw;
                      border-radius: 0.5vw;
                    }
                  }

                  .month-row {
                    width: 26.6667vw;
                    margin-top: 1.25vw;

                    > button.cell {
                      height: 3.3333vw;
                      font-size: 1.3333vw;
                      line-height: 1.6667vw;
                      border-radius: 0.5vw;
                    }
                  }

                  .year-row {
                    width: 23.3333vw;
                    margin-top: 1.25vw;

                    > button.cell {
                      height: 3.3333vw;
                      font-size: 1.3333vw;
                      line-height: 1.6667vw;
                      border-radius: 0.5vw;
                    }
                  }
                }
              }
            }
          }
        }

        button {
          height: 3.75vw;
          border-radius: 0.5vw;
          font-size: 1.5vw;
          line-height: 2vw;
          margin: auto 0 1.25vw 0;
        }
      }
    }
  }
}
