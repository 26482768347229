@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(344px, 95%);
    height: min(336px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 39px;

          > b {
            font-weight: 600px;
          }
        }

        button {
          width: 133px;
          height: 40px;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;

          &#takeoverRegister {
            background-color: var(--oliver-red);
            color: #fff;
            border: 0;
            margin-bottom: 14px;
          }

          &#cancelTakeover {
            background-color: #fff;
            color: var(--oliver-blue);
            border: 1px solid var(--oliver-blue);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(504px, 95%);
    height: min(420px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 60px;

          > b {
            font-weight: 600;
          }
        }

        button {
          width: 170px;
          height: 48px;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;

          &#takeoverRegister {
            background-color: var(--oliver-red);
            border: 0;
            color: #fff;
            margin-bottom: 17px;
          }

          &#cancelTakeover {
            background-color: #fff;
            border: 0.08375vw solid var(--oliver-blue);
            color: var(--oliver-blue);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.takeover-register {
    width: min(24.6094vw, 95%);
    height: min(20.5078vw, 95%);

    > .subwindow-body > {
      p {
        font-size: 0.8789vw;
        line-height: 1.2695vw;
        margin-bottom: 2.9297vw;
      }

      button {
        width: 8.3008vw;
        height: 2.3438vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;

        &#takeoverRegister {
          margin-bottom: 0.8301vw;
        }
      }
    }
  }
}
