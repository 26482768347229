@media (orientation: landscape) {
  .logout-confirmation-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: auto;

    &.hidden {
      display: none;
    }

    > {
      p {
        &.style1 {
          font-family: Poppins;
          font-size: 25px;
          font-weight: 500;
          line-height: 36px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin: 26px 0 49px 0;
        }

        &.style2 {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 61px;
        }
      }

      button {
        width: 190px;
        height: 48px;
        border-radius: 8px;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;

        &#logoutConfirm {
          background-color: var(--oliver-blue);
          color: #fff;
          border: 0;
          margin-bottom: 17px;
        }

        &#logoutCancel {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-blue);
          background-color: #fff;
          color: var(--oliver-blue);
          margin-bottom: 26px;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .logout-confirmation-wrapper > {
    p {
      &.style1 {
        font-size: 1.2207vw;
        line-height: 1.7578vw;
        margin: 1.2695vw 0 2.3926vw 0;
      }

      &.style2 {
        font-size: 0.8789vw;
        line-height: 1.1719vw;
        margin-bottom: 2.9785vw;
      }
    }

    button {
      width: 9.2773vw;
      height: 2.3438vw;
      border-radius: 0.3906vw;
      font-size: 0.8789vw;
      line-height: 1.1719vw;

      &#logoutConfirm {
        margin-bottom: 0.8301vw;
      }

      &#logoutCancel {
        border: 0.0488vw solid var(--oliver-blue);
        margin-bottom: 1.2695vw;
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .logout-confirmation-wrapper > {
    p {
      &.style1 {
        font-size: 2.0833vw;
        line-height: 3vw;
        margin: 2.1667vw 0 4.0833vw 0;
      }

      &.style2 {
        font-size: 1.5vw;
        line-height: 2vw;
        margin-bottom: 5.0833vw;
      }
    }

    button {
      width: 15.8333vw;
      height: 4vw;
      border-radius: 0.6667vw;
      font-size: 1.5vw;
      line-height: 2vw;

      &#logoutConfirm {
        margin-bottom: 1.4167vw;
      }

      &#logoutCancel {
        border: 0.0833vw solid var(--oliver-blue);
        margin-bottom: 2.1667vw;
      }
    }
  }
}
