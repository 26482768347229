@media (orientation: portrait) {
  .loading-page-wrapper {
    width: 100%;
    height: 100%;
    background-color: #eaf5ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 25px 0;
    img {
      width: 101px;
      height: 28px;
      margin-bottom: 60px;
    }
    svg {
      width: 85px;
      height: 85px;
      margin-bottom: 60px;
    }
    p.style1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-align: center;
      color: var(--oliver-base-blue-dark);
      margin-bottom: 10px;
    }
    p.style2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: var(--oliver-base-blue-dark);
      margin-bottom: 60px;
    }
    p.style3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: var(--oliver-base-grey);
    }
  }
}
@media (orientation: landscape) {
  .loading-page-wrapper {
    width: 100%;
    height: 100%;
    background-color: #eaf5ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 25px 0;

    img {
      width: 101px;
      height: 28px;
      margin-bottom: 60px;
    }
    svg {
      width: 85px;
      height: 85px;
      margin-bottom: 60px;
    }
    p.style1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-align: center;
      color: var(--oliver-base-blue-dark);
      margin-bottom: 10px;
    }
    p.style2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: var(--oliver-base-blue-dark);
      margin-bottom: 60px;
    }
    p.style3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: var(--oliver-base-grey);
    }
  }
}
@media (orientation: landscape) and (min-width: 2048px) {
  .loading-page-wrapper {
    padding: 1.2207vw 0;
    img {
      width: 4.9316vw;
      height: 1.3672vw;
      margin-bottom: 2.9297vw;
    }
    svg {
      width: 4.1504vw;
      height: 4.1504vw;
      margin-bottom: 2.9297vw;
    }
    p.style1 {
      font-size: 0.9766vw;
      line-height: 1.2695vw;
      margin-bottom: 0.4883vw;
    }
    p.style2 {
      font-size: 0.7813vw;
      line-height: 1.0254vw;
      margin-bottom: 2.9297vw;
    }
    p.style3 {
      font-size: 0.6836vw;
      line-height: 0.8789vw;
    }
  }
}
