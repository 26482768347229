@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: min(384px, 95%);
    height: min(458px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 15px;

          &:first-of-type {
            margin: 20px 0 6px 0;
          }
        }

        label {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 10px;
        }

        input {
          outline: 0;
          width: 306px;
          height: 70px;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          font-family: Poppins;
          font-size: 40px;
          font-weight: 500;
          line-height: 56px;
          text-align: center;
          color: var(--oliver-base-blue);

          &:focus {
            border-color: var(--oliver-blue);
            color: var(--oliver-blue);
          }
        }

        button {
          &#editStockButton {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 10px;
            border: 0;
            background-color: transparent;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-blue);
            margin-bottom: 7px;

            > img {
              width: 19px;
              height: 19px;
              margin-right: 5px;
            }
          }

          &#updateStockButton {
            width: 306px;
            height: 50px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin-bottom: 32px;
          }
        }

        .text-row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 306px;
          margin-bottom: 9px;
        }

        :nth-last-child(2) {
          margin-bottom: 20px;
        }

        .text-row > p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: var(--oliver-base-blue);

          > b {
            font-weight: 600;
          }

          &:first-of-type {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: 504px;
    height: min(531px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 11px;

          &:first-of-type {
            margin-top: 11px;
          }
        }

        label {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 7px;
        }

        input {
          outline: 0;
          width: 250px;
          height: 83px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          font-family: Poppins;
          font-size: 40px;
          font-weight: 600;
          line-height: 56px;
          text-align: center;
          color: var(--oliver-base-blue);
          cursor: default;

          &:focus {
            cursor: auto;
            color: var(--oliver-blue);
            border-color: var(--oliver-blue);
          }
        }

        button {
          &#editStockButton {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 7px 10px;
            background-color: transparent;
            border: 0;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-blue);
            margin-bottom: 17px;

            > img {
              width: 19px;
              height: 19px;
              margin-right: 5px;
            }
          }

          &#updateStockButton {
            width: 427px;
            height: 50px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin-bottom: 27px;
          }
        }

        .text-row {
          width: 427px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-top: 19px;
        }

        p + .text-row {
          margin-top: 14px;
        }

        .text-row > p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: var(--oliver-base-blue);

          > b {
            font-weight: 600;
          }

          &:first-of-type {
            margin-right: 20px;
            max-width: 213px;
            overflow: hidden;
            white-space: nowrap;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.adjust-inventory {
    width: 24.6094vw;
    height: min(25.9277vw, 95%);

    > .subwindow-body > {
      p {
        font-size: 0.8789vw;
        line-height: 1.2207vw;
        margin-bottom: 0.5371vw;

        &:first-of-type {
          margin-top: 0.5371vw;
        }
      }

      label {
        font-size: 0.7813vw;
        line-height: 1.0254vw;
        margin-bottom: 0.3418vw;
      }

      input {
        width: 12.207vw;
        height: 4.0527vw;
        border: 0.0488vw solid var(--oliver-border);
        border-radius: 0.293vw;
        font-size: 1.9531vw;
        line-height: 2.7344vw;
      }

      button {
        &#editStockButton {
          padding: 0.3418vw 0.4883vw;
          font-size: 0.7813vw;
          line-height: 1.0254vw;
          margin-bottom: 0.8301vw;

          > img {
            width: 0.9277vw;
            height: 0.9277vw;
            margin-right: 0.2441vw;
          }
        }

        &#updateStockButton {
          width: 20.8496vw;
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin-bottom: 1.3184vw;
        }
      }

      .text-row {
        width: 20.8496vw;
        margin-top: 0.9277vw;
      }

      p + .text-row {
        margin-top: 0.6836vw;
      }

      .text-row > p {
        font-size: 0.7813vw;
        line-height: 1.0742vw;

        &:first-of-type {
          margin-right: 0.9766vw;
          max-width: 10.4004vw;
        }
      }
    }
  }
}
