@media (orientation: portrait) {
  .homepage-wrapper {
    > {
      .header {
        z-index: 2;
        position: relative;
        width: 100%;
        height: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 10.23px;
        background-color: #fff;

        > .row {
          width: 100%;
          height: 60.46px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > {
            button#mobileNavToggle {
              width: 40px;
              height: 40px;
              background-color: transparent;
              border: 0;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              margin: 0 8px;

              > img {
                content: url("../assets/images/svg/Hamburger-Menu-Icon.svg");
                width: 20px;
                height: 13.33px;
              }

              &.opened > img {
                content: url("../assets/images/svg/Hamburger-Menu-Active-Icon.svg");
                width: 39px;
                height: 39px;
              }
            }

            img {
              width: 64px;
              height: 18px;
              margin-right: auto;
            }

            button {
              &#searchButton {
                position: absolute;
                width: calc(100% - 32px);
                height: 46px;
                left: 16px;
                bottom: 15px;
                background-color: #d1ecff;
                border: 0;
                border-radius: 8px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;
                color: var(--oliver-blue);

                > {
                  div {
                    display: none;
                  }

                  img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                  }
                }
              }

              &#userInfoButton {
                display: none;
              }

              &#mobileOptionsButton,
              &#mobileAppsButton {
                width: 34px;
                height: 34px;
                background-color: #d1ecff;
                border: 0;
                border-radius: 8px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
              }

              &#mobileOptionsButton {
                margin-right: 10px;

                > img {
                  width: 29.79px;
                  height: 29.79px;
                }

                &.filter {
                  background-color: var(--oliver-blue);

                  > img {
                    -webkit-filter: brightness(0) invert(1);
                    filter: brightness(0) invert(1);
                  }
                }
              }

              &#mobileAppsButton {
                margin-right: 16px;

                > img {
                  width: 18.62px;
                  height: 18.46px;
                }
              }
            }
          }
        }
      }

      .products {
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 4.266667% 4.266667% 0 4.266667%;
        overflow-y: scroll;
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#eaf5fe),
          to(#dbeefc)
        );
        background: -o-linear-gradient(top, #eaf5fe 0%, #dbeefc 100%);
        background: linear-gradient(180deg, #eaf5fe 0%, #dbeefc 100%);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > .change-row-count {
          width: 100%;
          display: none;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          /* justify-content: center; */
          margin-bottom: 16px;
        }

        &.edit-screen > .change-row-count {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-bottom: 10px;
        }

        > {
          .change-row-count > {
            p {
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-blue);
            }

            button {
              &:not(#exitEditing) {
                width: 40px;
                height: 40px;
                border: 1px solid var(--oliver-blue);
                border-radius: 8px;
                background-color: transparent;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin: 0 12px;
              }

              &:first-of-type {
                margin-left: 0 !important;
              }

              > img {
                width: 13px;
                height: 28px;
              }

              &#exitEditing {
                height: 40px;
                padding: 0 12px;
                border: 1px solid var(--oliver-blue);
                background-color: transparent;
                border-radius: 8px;
                margin-left: auto;

                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                color: var(--oliver-blue);
              }
            }
          }

          .category-header {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 12px;

            > {
              p {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                text-align: left;
                color: var(--oliver-blue);
              }

              button {
                width: 120px;
                height: 30px;
                margin-left: auto;
                background-color: var(--oliver-blue);
                border: 0;
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                text-align: center;
                color: #fff;

                > img {
                  width: 20px;
                  height: 20px;
                  margin-right: 6px;
                }
              }
            }
          }

          .products-container {
            width: 100%;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-line-pack: start;
            align-content: flex-start;

            > {
              .no-product-container {
                width: 100%;
                height: 100%;
                display: none;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                &:only-child {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                }

                > {
                  img {
                    width: 45px;
                    height: 45px;
                    margin-bottom: 13px;
                  }

                  p {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 27px;
                    text-align: center;
                    color: var(--oliver-blue);
                    margin-bottom: 38px;
                  }

                  button {
                    width: 140px;
                    height: 37px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    background-color: #fff;
                    border: 1px solid var(--oliver-blue);
                    border-radius: 6px;

                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: center;
                    color: var(--oliver-blue);
                  }
                }
              }

              button {
                position: relative;
                width: 47.8134109%;
                aspect-ratio: 1/1.29697;
                background-color: #1db9ca;
                margin: 0 4.3% 4.3% 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border-radius: 8px;
                border: 0;
                background-color: #fff;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
                box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);

                &:nth-of-type(2n) {
                  margin-right: 0;
                }

                &.product > {
                  .body {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    width: 100%;
                    -webkit-box-flex: 75;
                    -ms-flex: 75;
                    flex: 75;
                    border-radius: 8px 8px 0 0;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    overflow: hidden;

                    > img {
                      width: 100%;
                      height: 100%;
                      -o-object-fit: cover;
                      object-fit: cover;
                    }
                  }

                  .footer {
                    width: 100%;
                    -webkit-box-flex: 32;
                    -ms-flex: 32;
                    flex: 32;
                    background-color: #fff;
                    border-radius: 0 0 8px 8px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border-top: 1px solid var(--oliver-border);
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    > p {
                      max-width: 90%;
                      display: -webkit-box;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                      overflow: hidden;

                      font-size: 16px;
                      font-weight: 500;
                      line-height: 20px;
                      text-align: center;
                      color: var(--oliver-base-blue);
                    }
                  }
                }

                &.category {
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  background-color: #2797e8;

                  > p {
                    &.sub {
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 15px;
                      text-align: center;
                      color: #fff;
                      margin-bottom: 20px;
                      max-width: 80%;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                    }

                    &.main {
                      max-width: 80%;
                      display: -webkit-box;
                      -webkit-line-clamp: 5;
                      -webkit-box-orient: vertical;
                      overflow: hidden;

                      font-size: 16px;
                      font-weight: 500;
                      line-height: 20px;
                      text-align: center;
                      color: #fff;
                    }
                  }

                  &.background-lime > p {
                    &.sub,
                    &.main {
                      color: var(--oliver-base-blue);
                    }
                  }

                  &.background-yellow > p {
                    &.sub,
                    &.main {
                      color: var(--oliver-base-blue);
                    }
                  }
                }

                &.add-tile {
                  background-color: transparent;
                  border: 2px dashed var(--oliver-new-grey);
                  -webkit-box-orient: horizontal;
                  -webkit-box-direction: normal;
                  -ms-flex-direction: row;
                  flex-direction: row;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  font-size: 16px;
                  font-weight: 500;
                  line-height: 18px;
                  text-align: center;
                  color: var(--oliver-new-grey);
                  -webkit-box-shadow: none;
                  box-shadow: none;

                  > img {
                    width: 20px;
                    height: 20px;
                    margin-right: 7px;
                  }
                }

                > {
                  .remove-cover {
                    z-index: 2;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(58, 128, 180, 0.4);
                    border-radius: inherit;

                    > .remove-button {
                      width: 14.5454%;
                      aspect-ratio: 1/1;
                      background-color: #fff;
                      border-radius: inherit;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;
                      position: absolute;
                      right: 4.8484%;
                      top: 3.7383%;

                      > img {
                        width: 41.6667%;
                        aspect-ratio: 1/1;
                      }
                    }
                  }

                  .not-supported {
                    position: absolute;
                    background-color: rgba(255, 255, 255, 0.5);
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;

                    > img {
                      position: absolute;
                      width: 28px;
                      top: 12px;
                      left: 12px;
                      -webkit-filter: brightness(0) saturate(100%) invert(28%)
                        sepia(13%) saturate(1227%) hue-rotate(179deg)
                        brightness(94%) contrast(91%);
                      filter: brightness(0) saturate(100%) invert(28%)
                        sepia(13%) saturate(1227%) hue-rotate(179deg)
                        brightness(94%) contrast(91%);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .header {
        width: 100%;
        height: 135px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid var(--oliver-border);
      }

      .products {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }

      .navbar {
        position: fixed;
        left: 0;
        top: 72px;
        width: 100%;
        height: calc(100% - 72px);
      }
    }

    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* .homepage-wrapper > .header > .row > .options-container {
		display: ;
	} */

  /* --------------------Homepage Products-------------------- */
}

@media (orientation: landscape) {
  .homepage-wrapper {
    > {
      .header {
        width: 100%;
        height: 100%;

        > .row {
          width: 100%;
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > {
            button#mobileNavToggle {
              display: none;
            }

            img {
              width: 66px;
              height: 18px;
              -webkit-transform: translateY(-2px);
              -ms-transform: translateY(-2px);
              transform: translateY(-2px);
              margin-right: auto;
            }

            button#searchButton {
              width: 180px;
              height: 36px;
              background-color: #e7f4fe;
              border: 0;
              border-radius: 8px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              font-size: 13px;
              font-weight: 500;
              line-height: 18px;
              text-align: left;
              color: var(--oliver-blue);
              margin-right: 173px;

              > {
                div {
                  margin-left: 3px;
                  // font-size: 0.7166vw;
                  opacity: 0.5;
                }

                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                }
              }
            }

            .options-container {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: reverse;
              -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
              margin-right: 18px;

              > {
                p {
                  display: none;
                }

                button {
                  width: 24px;
                  height: 24px;
                  background-color: transparent;
                  border: 0;
                  margin-right: 7px;

                  &:first-of-type {
                    margin-right: 0;
                  }

                  > {
                    .img-container {
                      width: 100%;
                      height: 100%;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;
                    }

                    p {
                      display: none;
                    }
                  }
                }
              }
            }

            button {
              &#userInfoButton {
                background-color: transparent;
                border: 0;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 17px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                > {
                  .avatar {
                    width: 100%;
                    height: 100%;
                  }

                  img.default {
                    display: none;

                    &:only-of-type {
                      display: block;
                    }
                  }
                }
              }

              &#mobileOptionsButton,
              &#mobileAppsButton {
                display: none;
              }
            }
          }
        }
      }

      .products {
        width: 100%;
        height: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 2% 2% 0 2%;
        overflow: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#eaf5fe),
          to(#dbeefc)
        );
        background: -o-linear-gradient(top, #eaf5fe 0%, #dbeefc 100%);
        background: linear-gradient(180deg, #eaf5fe 0%, #dbeefc 100%);

        > .change-row-count {
          width: 100%;
          display: none;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          /* justify-content: center; */
          margin-bottom: 16px;
        }

        &.edit-screen > .change-row-count {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-bottom: 10px;
        }

        > {
          .change-row-count > {
            p {
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-blue);
            }

            button {
              &:not(#exitEditing) {
                width: 40px;
                height: 40px;
                border: 1px solid var(--oliver-blue);
                border-radius: 8px;
                background-color: transparent;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin: 0 12px;
              }

              &:first-of-type {
                margin-left: 0 !important;
              }

              > img {
                width: 13px;
                height: 28px;
              }

              &#exitEditing {
                height: 40px;
                padding: 0 12px;
                border: 1px solid var(--oliver-blue);
                background-color: transparent;
                border-radius: 8px;
                margin-left: auto;

                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                color: var(--oliver-blue);
              }
            }
          }

          .category-header {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 2%;

            > {
              p {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: var(--oliver-blue);
              }

              button {
                width: 170px;
                height: 40px;
                background-color: var(--oliver-blue);
                border-radius: 6px;
                border: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                margin-left: auto;

                > img {
                  width: 23px;
                  height: 23px;
                  margin-right: 10px;
                }
              }
            }
          }

          .products-container {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-line-pack: start;
            align-content: flex-start;

            > {
              .no-product-container {
                display: none;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                &:only-child {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                }

                > {
                  img {
                    width: 45px;
                    height: 45px;
                    margin-bottom: 13px;
                  }

                  p {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 27px;
                    text-align: center;
                    color: var(--oliver-blue);
                    margin-bottom: 38px;
                  }

                  button {
                    width: 140px;
                    height: 37px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border: 1px solid var(--oliver-blue);
                    border-radius: 6px;
                    background-color: #fff;

                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: center;
                    color: var(--oliver-blue);
                  }
                }
              }

              button {
                position: relative;
                width: 23.5%;
                aspect-ratio: 1/1.29697;
                background-color: #1db9ca;
                margin: 0 2% 2% 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border-radius: 8px;
                border: 0;
                background-color: #fff;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
                box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);

                img {
                  pointer-events: none;
                }
              }
            }

            &:not(.margin-script) > button:nth-of-type(4n) {
              margin-right: 0;
            }

            > button {
              &.product > {
                .body {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  width: 100%;
                  -webkit-box-flex: 75;
                  -ms-flex: 75;
                  flex: 75;
                  border-radius: 8px 8px 0 0;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  overflow: hidden;

                  > img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                  }
                }

                .footer {
                  width: 100%;
                  -webkit-box-flex: 32;
                  -ms-flex: 32;
                  flex: 32;
                  background-color: #fff;
                  border-radius: 0 0 8px 8px;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  border-top: 1px solid var(--oliver-border);
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  > p {
                    max-width: 90%;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
                    color: var(--oliver-base-blue);
                  }
                }
              }

              &.category {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                background-color: #2797e8;

                > p {
                  &.sub {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 20px;
                    max-width: 80%;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  }

                  &.main {
                    max-width: 80%;
                    overflow: hidden;

                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
                    color: #fff;
                  }
                }

                &.background-lime > p {
                  &.sub,
                  &.main {
                    color: var(--oliver-base-blue);
                  }
                }

                &.background-yellow > p {
                  &.sub,
                  &.main {
                    color: var(--oliver-base-blue);
                  }
                }
              }

              &.add-tile {
                background-color: transparent;
                border: 2px dashed var(--oliver-new-grey);
                -webkit-box-shadow: none;
                box-shadow: none;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                text-align: center;
                color: var(--oliver-new-grey);

                > img {
                  width: 20px;
                  height: 20px;
                  margin-right: 6px;
                }
              }

              > {
                .remove-cover {
                  z-index: 2;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(58, 128, 180, 0.4);
                  border-radius: inherit;

                  > .remove-button {
                    width: 14.5454%;
                    aspect-ratio: 1/1;
                    background-color: #fff;
                    border-radius: inherit;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    position: absolute;
                    right: 4.8484%;
                    top: 3.7383%;

                    > img {
                      width: 41.6667%;
                      aspect-ratio: 1/1;
                    }
                  }
                }

                .not-supported {
                  position: absolute;
                  background-color: rgba(255, 255, 255, 0.5);
                  width: 100%;
                  height: 100%;
                  border-radius: 6px;

                  > img {
                    position: absolute;
                    width: 28px;
                    top: 12px;
                    left: 12px;
                    -webkit-filter: brightness(0) saturate(100%) invert(28%)
                      sepia(13%) saturate(1227%) hue-rotate(179deg)
                      brightness(94%) contrast(91%);
                    filter: brightness(0) saturate(100%) invert(28%) sepia(13%)
                      saturate(1227%) hue-rotate(179deg) brightness(94%)
                      contrast(91%);
                  }
                }
              }
            }
          }
        }
      }

      .navbar {
        grid-area: navbar;
      }

      .header {
        grid-area: header;
      }

      .products {
        grid-area: products;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        border-left: 1px solid var(--oliver-border);
      }

      .cart {
        grid-area: cart;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        border-left: 1px solid var(--oliver-border);
      }
    }

    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 72px auto 25vw;
    grid-template-columns: 72px auto 25vw;
    -ms-grid-rows: 68px auto;
    grid-template-rows: 68px auto;
    grid-template-areas: "navbar header header" "navbar products cart";
  }

  /* --------------------Homepage Products-------------------- */
}

@media (orientation: landscape) {
  .navbar {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
  }
  .header {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }
  .products {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .cart {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .homepage-wrapper {
    > {
      .header > .row > {
        img {
          width: 3.2227vw;
          height: 0.8789vw;
          -webkit-transform: translateY(-0.0977vw);
          -ms-transform: translateY(-0.0977vw);
          transform: translateY(-0.0977vw);
        }

        button#searchButton {
          width: 8.7891vw;
          height: 1.7578vw;
          border-radius: 0.3906vw;
          font-size: 0.6348vw;
          line-height: 0.8789vw;
          margin-right: 8.4473vw;

          > {
            div {
              margin-left: 0.1465vw;
            }

            img {
              width: 0.9766vw;
              height: 0.9766vw;
              margin-right: 0.2441vw;
            }
          }
        }

        .options-container {
          margin-right: 0.8789vw;

          > button {
            width: 1.1719vw;
            height: 1.1719vw;
            margin-right: 0.3418vw;
          }
        }

        button#userInfoButton {
          width: 1.9531vw;
          height: 1.9531vw;
          margin-right: 0.8301vw;
        }
      }

      .products {
        > {
          .change-row-count {
            margin-bottom: 0.7813vw;

            > {
              p {
                font-size: 0.8789vw;
                line-height: 1.1719vw;
              }

              button {
                &:not(#exitEditing) {
                  width: 1.9531vw;
                  height: 1.9531vw;
                  border: 0.0488vw solid var(--oliver-blue);
                  border-radius: 0.3906vw;
                  margin: 0 0.5859vw;
                }

                > img {
                  width: 0.6348vw;
                  height: 1.3672vw;
                }

                &#exitEditing {
                  height: 1.9531vw;
                  padding: 0 0.5859vw;
                  border: 0.0488vw solid var(--oliver-blue);
                  border-radius: 0.3906vw;
                  font-size: 0.7813vw;
                  line-height: 0.8789vw;
                }
              }
            }
          }

          .category-header > {
            p {
              font-size: 0.7813vw;
              line-height: 1.1719vw;
            }

            button {
              width: 8.3008vw;
              height: 1.9531vw;
              border-radius: 0.293vw;
              font-size: 0.7813vw;
              line-height: 1.1719vw;

              > img {
                width: 1.123vw;
                height: 1.123vw;
                margin-right: 0.4883vw;
              }
            }
          }

          .products-container > {
            .no-product-container > {
              img {
                width: 2.1973vw;
                height: 2.1973vw;
                margin-bottom: 0.6348vw;
              }

              p {
                font-size: 0.8789vw;
                line-height: 1.3184vw;
                margin-bottom: 1.8555vw;
              }

              button {
                width: 6.8359vw;
                height: 1.8066vw;
                border: 0.0488vw solid var(--oliver-blue);
                border-radius: 0.293vw;
                font-size: 0.6836vw;
                line-height: 1.0254vw;
              }
            }

            button {
              border-radius: 0.3906vw;
              -webkit-box-shadow: 0vw 0.293vw 0.4883vw rgba(94, 94, 94, 0.1);
              box-shadow: 0vw 0.293vw 0.4883vw rgba(94, 94, 94, 0.1);

              &.product > {
                .body {
                  border-radius: 0.3906vw 0.3906vw 0 0;
                }

                .footer {
                  border-radius: 0 0 0.3906vw 0.3906vw;
                  border-top: 0.0488vw solid var(--oliver-border);

                  > p {
                    font-size: 0.7813vw;
                    line-height: 0.9766vw;
                  }
                }
              }

              &.category > p {
                &.sub {
                  font-size: 0.5859vw;
                  line-height: 0.7324vw;
                  margin-bottom: 0.9766vw;
                }

                &.main {
                  font-size: 0.7813vw;
                  line-height: 0.9766vw;
                }
              }

              &.add-tile {
                border: 0.0977vw dashed var(--oliver-new-grey);
                font-size: 0.7813vw;
                line-height: 0.8789vw;

                > img {
                  width: 0.9766vw;
                  height: 0.9766vw;
                  margin-right: 0.293vw;
                }
              }

              > .not-supported {
                border-radius: 0.293vw;

                > img {
                  width: 1.3672vw;
                  top: 0.5859vw;
                  left: 0.5859vw;
                }
              }
            }
          }
        }

        border-top: 0.0488vw solid var(--oliver-border);
        border-left: 0.0488vw solid var(--oliver-border);
      }

      .cart {
        border-top: 0.0488vw solid var(--oliver-border);
        border-left: 0.0488vw solid var(--oliver-border);
      }
    }

    -ms-grid-columns: 3.5156vw auto 25vw;

    grid-template-columns: 3.5156vw auto 25vw;
    -ms-grid-rows: 3.3203vw auto;
    grid-template-rows: 3.3203vw auto;
  }
  .homepage-wrapper > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .homepage-wrapper > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
  .homepage-wrapper > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .homepage-wrapper > *:nth-child(4) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  .homepage-wrapper > *:nth-child(5) {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .homepage-wrapper > *:nth-child(6) {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
  }
}
