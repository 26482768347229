@media (orientation: portrait) {
  .mobile-cv-header > {
    #mobileNavToggle {
      width: 40px;
      height: 40px;
      background-color: transparent;
      border: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-right: 8px;

      > img {
        content: url("../assets/images/svg/Hamburger-Menu-Icon.svg");
        width: 20px;
        height: 13.33px;
      }

      &.opened > img {
        content: url("../assets/images/svg/Hamburger-Menu-Active-Icon.svg");
        width: 39px;
        height: 39px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      color: var(--oliver-base-blue);
      margin-right: auto;
    }

    #mobileCVSearchButton {
      width: 34px;
      height: 34px;
      background-color: #d1ecff;
      border: 0;
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-right: 10px;

      > img {
        width: 21px;
        height: 21px;
      }
    }

    #mobileAppsButton {
      width: 34px;
      height: 34px;
      background-color: #d1ecff;
      border: 0;
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      > img {
        width: 18.62px;
        height: 18.46px;
        -webkit-transform: translateX(1px);
        -ms-transform: translateX(1px);
        transform: translateX(1px);
      }
    }

    button.filter {
      background-color: var(--oliver-blue) !important;

      > img {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }
    }
  }
}
