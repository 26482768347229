@media (orientation: portrait) {
  .subwindow-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(61, 76, 102, 0.5);
    z-index: 5;
  }

  .subwindow-wrapper.hidden {
    display: none;
  }

  .subwindow-wrapper > .subwindow {
    position: absolute;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
    display: none !important;
    flex-direction: column;
  }

  .subwindow-wrapper > .subwindow.current {
    display: flex !important;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    position: relative;
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    position: absolute;
    top: 11px;
    right: 11px;
    width: 32px;
    height: 32px;
    background-color: #f1f4f6;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 12px;
    height: 12px;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
    background-color: #f8fafc;
    border-radius: 0 0 8px 8px;
    padding: 20px 0;
    box-sizing: border-box;
  }

  /* Split by Group TODO */
}

@media (orientation: landscape) {
  .subwindow-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(123, 140, 152, 0.3);
    z-index: 5;
  }

  .subwindow-wrapper.hidden {
    display: none;
  }

  .subwindow-wrapper > .subwindow {
    position: absolute;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.3);
    display: none !important;
    flex-direction: column;
    overflow: auto;
  }

  .subwindow-wrapper > .subwindow.current {
    display: flex !important;
    max-height: 98%; /* To setup the discount popup height */
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    position: relative;
    width: 100%;
    min-height: 65px;
    height: fit-content;
    box-sizing: border-box;
    border-bottom: 1px solid var(--oliver-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--oliver-base-blue);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background-color: #f1f4f6;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 12px;
    height: 12px;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;
    background-color: #f8fafc;
    border-radius: 0 0 8px 8px;
    padding: 20px 0;
    box-sizing: border-box;
  }

  /* Split by Group (TODO) */

  .subwindow-wrapper > .subwindow.split-by-group {
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow {
    border-radius: 0.3906vw;
    box-shadow: 0vw 0.7324vw 1.9531vw rgba(0, 0, 0, 0.3);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header {
    min-height: 3.1738vw;
    border-bottom: 0.0488vw solid var(--oliver-border);
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > p {
    font-size: 0.8789vw;
    line-height: 1.1719vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-header > button.close-subwindow {
    top: 0.7813vw;
    right: 0.7813vw;
    width: 1.5625vw;
    height: 1.5625vw;
    border-radius: 0.293vw;
  }

  .subwindow-wrapper
    > .subwindow
    > .subwindow-header
    > button.close-subwindow
    > img {
    width: 0.5859vw;
    height: 0.5859vw;
  }

  .subwindow-wrapper > .subwindow > .subwindow-body {
    border-radius: 0 0 0.3906vw 0.3906vw;
    padding: 0.9766vw 0;
  }
}

// Imported below due to specificity issues
@import "./subwindow/subwindowUpdatePlugin.scss";
@import "./subwindow/subwindowCartNoItem.scss";
@import "./subwindow/subwindowDiscountError.scss";
@import "./subwindow/subwindowProductUnsupported.scss";
@import "./subwindow/subwindowRefundShow.scss";
@import "./subwindow/subwindowNoPermission.scss";
@import "./subwindow/subwindowNoAccount.scss";
@import "./subwindow/subwindowNoAccount.scss";
@import "./subwindow/subwindowEditCustomer.scss";
@import "./subwindow/subwindowCustomFee.scss";
@import "./subwindow/subwindowCartDiscount.scss";
@import "./subwindow/subwindowAddTile.scss";
@import "./subwindow/subwindowAdvancedSearch.scss";
@import "./subwindow/subwindowSwitchUser.scss";
@import "./subwindow/subwindowAdjustCredit.scss";
@import "./subwindow/subwindowRemoveCash.scss";
@import "./subwindow/subwindowNoCustomer.scss";
@import "./subwindow/subwindowReceipt.scss";
@import "./subwindow/subwindowCashPayment.scss";
@import "./subwindow/subwindowDetailedTax.scss";
@import "./subwindow/subwindowCreateCustomer.scss";
@import "./subwindow/subwindowLogoutConfirm.scss";
@import "./subwindow/subwindowEndSession.scss";
@import "./subwindow/subwindowKnowledgeBase.scss";
@import "./subwindow/subwindowUpdateTransactionStatus.scss";
@import "./subwindow/subwindowStoreCredit.scss";
@import "./subwindow/subwindowAddCash.scss";
@import "./subwindow/subwindowLayaway.scss";
@import "./subwindow/subwindowAdjustInventory.scss";
@import "./subwindow/subwindowProductDiscount.scss";
@import "./subwindow/subwindowAddCustomerNote.scss";
@import "./subwindow/subwindowQuickTax.scss";
@import "./subwindow/subwindowNoVariation.scss";
@import "./subwindow/subwindowSplitByProduct.scss";
@import "./subwindow/subwindowProductNote.scss";
@import "./subwindow/subwindowParkSale.scss";
@import "./subwindow/subwindowTakeoverRegister.scss";
@import "./subwindow/subwindowRegisterTaken.scss";
@import "./subwindow/subwindowLinkLauncher.scss";
@import "./subwindow/subwindowAppLauncher.scss";
@import "./subwindow/subwindowDeleteCustomer.scss";
@import "./subwindow/subwindowAddOrderNote.scss";
@import "./subwindow/subwindowProductNotFound.scss";
@import "./subwindow/subwindowUpgradeToUnlock.scss";
@import "./subwindow/subwindowPartialPayments.scss";
@import "./subwindow/subwindowOutOfStock.scss";
@import "./subwindow/subwindowEditOrderStatus.scss";
@import "./subwindow/subwindowSplitByPeople.scss";
