@media (orientation: portrait) {
  .navbar {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 4;
    background-color: #fff;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow-y: scroll;
    overflow-y: scroll;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 10px 17px 189px 17px;
    border-top: 1px solid var(--oliver-border);
    -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    &.open {
      -webkit-transform: translateX(0);
          -ms-transform: translateX(0);
              transform: translateX(0);

      ~ .user-info-wrapper {
        -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
                transform: translateY(0);
      }
    }

    > {
      .header-row {
        display: none;
      }

      button {
        width: 100%;
        height: 47px;
        border-radius: 8px;
        background-color: transparent;
        border: 0;
        margin-bottom: 4.49px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 8.65px;

        > .img-container {
          width: 30px;
          height: 30px;
          margin-right: 14px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;

          > img {
            width: 100%;
            height: 100%;
          }
        }

        &#linkLauncherButton > .img-container > img {
          width: 70%;
          height: 70%;
        }

        > p {
          
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: var(--oliver-base-blue);
        }

        &.selected {
          background-color: var(--oliver-blue);

          > {
            .img-container > img {
              -webkit-filter: brightness(0) invert(1);
                      filter: brightness(0) invert(1);
            }

            p {
              color: #fff;
            }
          }
        }

        > .f-key, &.launcher, &#navToggle {
          display: none;
        }

        &#linkLauncherButton {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .navbar {
    z-index: 2;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 18px 16px 20px 16px;
    overflow: hidden;

    &.open {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      width: calc(369.4444% + 1px);
      border-right: 1px solid var(--oliver-border);

      /* filter: drop-shadow(5px 0px 20px rgba(119, 135, 147, 0.2)); */

      > .header-row > img.oliver-text, > button > p {
        display: block;
      }

      > button > .f-key {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }

      > button.toggle-nav > .img-container > img {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg);
      }
    }

    > .header-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 100%;
      margin-bottom: 20px;

      > img {
        &.oliver-logo {
          width: 29px;
          height: 30px;
          margin: 0 20px 0 6px;
        }

        &.oliver-text {
          display: none;
          width: 66px;
          height: 18px;
        }
      }
    }

    > button {
      &.page-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: 100%;
        height: 40px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 8px;
        border: 0;
        border-radius: 8px;
        background-color: transparent;
        margin-bottom: 5px;
        line-height: 0;

        > .img-container {
          width: 24px;
          height: 24px;

          > img {
            width: 100%;
            height: 100%;
          }
        }
      }

      > p {
        display: none;
        
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        color: var(--oliver-base-blue);
        margin: 0 auto 0 9px;
      }
    }

    > button > .f-key {
      display: none;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: rgba(117, 134, 150, 0.1);
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
      color: var(--oliver-base-grey);
    }

    > {
      button {
        &.page-link {
          &.selected {
            background-color: rgba(13, 153, 255, 0.1);

            > {
              .img-container > img {
                -webkit-filter: brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(646%) hue-rotate(167deg) brightness(92%) contrast(97%);
                        filter: brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(646%) hue-rotate(167deg) brightness(92%) contrast(97%);
              }

              p {
                color: var(--oliver-blue);
              }

              .f-key {
                background-color: #c5e7ff;
                color: var(--oliver-blue);
              }
            }
          }

          &.disabled {
            background-color: transparent;

            > {
              .img-container > img {
                -webkit-filter: brightness(0) saturate(100%) invert(80%) sepia(19%) saturate(134%) hue-rotate(169deg) brightness(86%) contrast(83%);
                        filter: brightness(0) saturate(100%) invert(80%) sepia(19%) saturate(134%) hue-rotate(169deg) brightness(86%) contrast(83%);
              }

              p {
                color: #a6aeb6;
              }
            }
          }
        }

        &.launcher {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin-bottom: 20px;
          border: 0;
          background-color: transparent;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          padding: 0 4px;

          > .img-container {
            width: 32px;
            height: 32px;
            background-color: #f1f4f6;
            border-radius: 6px;
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
          }

          &.app > .img-container > img {
            width: 100%;
            height: 100%;
          }

          &#linkLauncherButton {
            margin: auto 0 16px 0;
          }

          &#navApp3 {
            margin-bottom: 18px;
          }

          &#linkLauncherButton > .img-container > img {
            width: 20px;
            height: 20px;
          }

          &#appLauncherButton > .img-container > img {
            width: 19px;
            height: 19px;
          }

          > p {
            margin-left: 12px;
          }
        }
      }

      .divider {
        width: 32px;
        height: 1px;
        background-color: var(--oliver-border);
        margin: 0 0 16px 4px;
      }

      button.toggle-nav {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        background-color: transparent;
        border: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 8px;

        > .img-container {
          width: 24px;
          height: 24px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;

          > img {
            width: 20px;
            height: 17px;
          }
        }
      }
    }

    > button {
      &.toggle-nav > p {
        margin-left: 16px;
      }

      &#appLauncherButton.filter > .img-container, &#linkLauncherButton.filter > .img-container {
        background-color: var(--oliver-blue);

        > img {
          -webkit-filter: brightness(0) invert(1);
                  filter: brightness(0) invert(1);
        }
      }
    }
  }

  /* --------------------Navbar Cover-------------------- */

  .nav-cover {
    z-index: 5;
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 266px;
    top: 0;
    background-color: #7b8c98;
    opacity: 0.3;
  }

  .navbar.open ~ {
    .nav-cover {
      display: block;
    }

    .app-launcher-wrapper, .link-launcher-wrapper {
      left: 266px;
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .navbar {
    padding: 0.8789vw 0.7813vw 0.9766vw 0.7813vw;

    &.open {
      width: calc(369.4444% + 0.0488vw);
      border-right: 0.0488vw solid var(--oliver-border);

      ~ {
        .app-launcher-wrapper, .link-launcher-wrapper {
          left: 12.9883vw;
        }
      }
    }

    > {
      .header-row {
        margin-bottom: 0.9766vw;

        > img {
          &.oliver-logo {
            width: 1.416vw;
            height: 1.4648vw;
            margin: 0 0.9766vw 0 0.293vw;
          }

          &.oliver-text {
            width: 3.2227vw;
            height: 0.8789vw;
          }
        }
      }

      button {
        &.page-link {
          height: 1.9531vw;
          padding: 0 0.3906vw;
          border-radius: 0.3906vw;
          margin-bottom: 0.2441vw;

          > .img-container {
            width: 1.1719vw;
            height: 1.1719vw;
          }
        }

        > {
          p {
            font-size: 0.5859vw;
            line-height: 0.8301vw;
            margin: 0 auto 0 0.4395vw;
          }

          .f-key {
            width: 1.1719vw;
            height: 1.1719vw;
            border-radius: 0.1953vw;
            font-size: 0.5859vw;
            line-height: 0.8301vw;
          }
        }

        &.launcher {
          margin-bottom: 0.9766vw;
          padding: 0 0.1953vw;

          > .img-container {
            width: 1.5625vw;
            height: 1.5625vw;
            border-radius: 0.293vw;
          }

          &#linkLauncherButton {
            margin: auto 0 0.7813vw 0;
          }

          &#navApp3 {
            margin-bottom: 0.8789vw;
          }

          &#linkLauncherButton > .img-container > img {
            width: 0.9766vw;
            height: 0.9766vw;
          }

          &#appLauncherButton > .img-container > img {
            width: 0.9277vw;
            height: 0.9277vw;
          }

          > p {
            margin-left: 0.5859vw;
          }
        }
      }

      .divider {
        width: 1.5625vw;
        height: 0.0488vw;
        margin: 0 0 0.7813vw 0.1953vw;
      }

      button.toggle-nav {
        padding: 0 0.3906vw;

        > {
          .img-container {
            width: 1.1719vw;
            height: 1.1719vw;

            > img {
              width: 0.9766vw;
              height: 0.8301vw;
            }
          }

          p {
            margin-left: 0.7813vw;
          }
        }
      }
    }
  }
}
