/* Other styles */

/*Setting facebook button text backbround-color and font */

.user_login_fb_on {
  background-color: #4267b2;
  border-color: #4267b2;
  position: relative;
  color: var(--oliver-white);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: contents;
  font-size: 18px;
}

/* Setting apple login button background-color black */

#appleid-signin {
  background-color: #050505 !important;
}

/* Setting breadcrum width with 100% in a line, so next element come in new line */

.mod-product {
  width: 100%;

  > .category-row > button {
    padding: 10px;
    background-color: transparent;
    border: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--oliver-blue);
  }
}

/* Setting for breadcrum link design */

.hide {
  display: none !important;
}

.unhide {
  display: block !important;
}

.display-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

/* Setting loading modal loader */

.popup {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  background-color: var(--oliver-white);
  border-radius: 1.85vw;
  z-index: 9;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Setting loader model (which comes in inner screens when data loads) */

.loaderThemeBg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  margin: auto;
  background-color: rgba(81, 80, 80, 0.51);
}

/* Setting loader model background-color */

.preloaderbackground {
  background-color: var(--oliver-white);
  text-align: center;
  border-radius: 14px;
  padding: 20px 40px;
}

/* Setting loader model text font family */

.fn-poppins {
  font-family: Poppins;
}

/* Setting small loader width */

.w-100 {
  width: 100% !important;
}

/* Setting user info wrapper above the tile section so tile can not be selected while user info is open */

.user-info-wrapper {
  z-index: 4;
}

/* Setting the component disable and not clickable */

.btn-disable {
  opacity: 0.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Setting to cross the text like old price after discount etc */

.strike-through {
  text-decoration: line-through;
}

/* Setting center the text/content */

.doCenter {
  text-align: center;
}

.main-row > .text-group {
  margin-right: auto;
}

.input-col1 {
  width: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  margin-left: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.subwindow-wrapper
  > .subwindow.create-customer
  > .subwindow-body
  .input-row
  > .input-col
  > label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--oliver-base-blue);
  margin-bottom: 4px;
}

.input-col1 > input {
  height: 42px;
  background-color: var(--oliver-white);
  border: 1px solid var(--oliver-border);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 6px;
  outline: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--oliver-base-blue);
}

.text-center {
  text-align: center;
}

/* .transactions-search {
        z-index: unset;
    } */

.sticky-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--oliver-light-blue);

  > .change-row-count {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5px;

    > {
      button:not(#exitEditing) {
        width: 32px;
        height: 32px;
      }

      p {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: var(--oliver-blue);
      }

      button#exitEditing {
        width: 40px;
        height: 40px;
        border: 1px solid var(--oliver-blue);
        border-radius: 8px;
        background-color: transparent;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 12px;
        font-size: 14px;
      }
    }
  }

  .change-row-count > button {
    &:not(#exitEditing) {
      width: 40px;
      height: 40px;
      border: 1px solid var(--oliver-blue);
      border-radius: 8px;
      background-color: transparent;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0 12px;
    }

    &#exitEditing {
      height: 40px;
      padding: 0 12px;
      border: 1px solid var(--oliver-blue);
      background-color: transparent;
      border-radius: 8px;
      margin-left: auto;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      color: var(--oliver-blue);
    }
  }

  > .change-row-count > button > img {
    width: 13px;
    height: 28px;
  }
}

.tileNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: var(--oliver-base-blue);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  > .change-row-count {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    > {
      button:not(#exitEditing) {
        width: 40px;
        height: 40px;
        border: 1px solid var(--oliver-blue);
        border-radius: 8px;
        background-color: transparent;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 12px;
      }

      p {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: var(--oliver-blue);
      }
    }
  }
}

.tileNav_menu {
  background-color: var(--oliver-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
}

.tileNav_menu__nav-button {
  background-color: transparent;
  min-width: 110px;
  height: 100%;
  border: none;
  color: var(--oliver-base-blue);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 10px;
  border-radius: 8px;
}

.tileNav__edit-button {
  text-transform: capitalize;
  border: none;
  background-color: transparent;
  border: 1px solid var(--oliver-blue);
  color: var(--oliver-blue);
  border-radius: 8px;
  padding: 10px;
}

.tileNav_menu__nav-button {
  > svg {
    fill: #3d4c66;
    margin-right: 2px;
  }

  &:hover {
    color: #2797e8;

    > svg {
      fill: #2797e8;
    }
  }
}

.tileNav_menu__selected-button {
  background-color: #2797e8;
  color: var(--oliver-white);

  &:hover {
    color: var(--oliver-white);

    > svg {
      fill: var(--oliver-white);
    }
  }

  > svg {
    fill: var(--oliver-white);
  }
}

.homepage-wrapper > .products {
  > .sticky-container > .tileNav > .category-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    > p {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: var(--oliver-grey-text);

      &:last-child {
        color: var(--oliver-blue);
      }

      &:hover {
        color: var(--oliver-base-blue);
      }

      &.active {
        color: var(--oliver-blue);
      }
    }
  }

  padding: 0;
}

.products > .products-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (orientation: landscape) {
  .cart > .footer > {
    .totals {
      padding: 15px 15px 0 15px;
    }

    .checkout-container {
      border-top: none;
    }
  }

  .homepage-wrapper > .products {
    padding: 0;
  }

  .tileNav {
    padding: 2%;
  }

  .products > .products-container {
    padding: 2% 2% 0 2%;
  }
}

@media (orientation: portrait) and (max-width: 700px) {
  .tileNav_menu__nav-button {
    min-width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    > {
      svg {
        margin-right: 0px;
      }

      span {
        display: none;
      }
    }
  }

  .homepage-wrapper
    > .products
    > .sticky-container
    > .tileNav
    > .category-header
    > p {
    font-size: 12px;
  }
}

/* CustomDropdown.css */

.custom-dropdown-container {
  position: relative;
  min-width: 170px;
  width: auto;
}

.custom-dropdown-header {
  cursor: pointer;
  padding: 10px;
  border: 1px solid var(--oliver-border);
  border-radius: 10px;
  background-color: var(--oliver-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.custom-dropdown-options {
  z-index: 3;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;

  /* Adjust the max height as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: var(--oliver-white);
}

.custom-dropdown-option {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
}

.refund-complete-txt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: var(--oliver-white);
  margin-bottom: 43px;
}

.product-wrapper > .header > .mobile-buttons > {
  button {
    background-color: #e8f5ff;
    position: fixed;
    z-index: 4;
  }

  #mobileAppsButton {
    right: 2%;
  }
}

.homepage-wrapper > .products > .products-container > button {
  > .remove-cover > .remove-button {
    width: 15%;
    height: 11%;
  }

  &.product > {
    .body {
      width: 100%;
      max-height: calc(var(--oliver-tile-height) * 0.7);
      height: calc(var(--oliver-tile-height) * 0.7);

      /* min-height: calc(var(--oliver-tile-height) * 0.65); */
      -webkit-box-flex: 1;
      -ms-flex: auto;
      flex: auto;
      overflow: hidden;
      -webkit-box-align: normal;
      -ms-flex-align: normal;
      align-items: normal;

      > img {
        /* px width and height are important for Sunmi devices! */
        width: 100%;
        overflow: hidden;
        height: var(--oliver-tile-height);
        max-height: 100%;
        max-width: 100%;
      }
    }

    .footer {
      min-height: calc(var(--oliver-tile-height) * 0.3);
      height: calc(var(--oliver-tile-height) * 0.3);
      max-height: calc(var(--oliver-tile-height) * 0.35);
      width: 100%;
      max-width: 100%;
      -webkit-box-flex: 1;
      -ms-flex: auto;
      flex: auto;
      overflow-y: hidden;
    }
  }

  > .not-supported > img {
    top: 2%;
    left: 1%;
    width: 25%;
  }
}

.manual-payment {
  width: 424px;

  > {
    .input-row {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 12px;

      > .input-col {
        width: 0;
        -webkit-box-flex: 1;
        -ms-flex: 1 1;
        flex: 1 1;
        margin-left: 14px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > {
          label {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-bottom: 4px;
          }

          input {
            height: 42px;
            background-color: var(--oliver-white);
            border: 1px solid var(--oliver-border);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 12px;
            border-radius: 6px;
            outline: 0;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
          }
        }
      }
    }

    .charge-card {
      width: 424px;
      height: 48px;
      border: 0;
      border-radius: 6px;
      background-color: var(--oliver-blue);
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: var(--oliver-white);
      margin-bottom: 10px;
    }
  }
}

#bottomText {
  cursor: pointer;
}

.cv-list > .body > .no-results {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 58px;

  /* display: none; */
  border: 0 !important;

  > p {
    &.style1 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      color: var(--oliver-base-grey);
      margin-bottom: 10px;
    }

    &.style2 {
      font-family: Poppins;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: var(--oliver-base-grey);
    }
  }
}

.partial-text-row {
  width: 348px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  /* padding-top: 21px; */
  /* border-top: 1px solid var(--oliver-border);
        margin-bottom: 18px; */

  > p {
    &.style1 {
      font-family: Poppins;
      font-size: 14px;

      /* font-weight: 500;
            line-height: 24px; */
      text-align: left;
      color: var(--oliver-base-grey);
    }

    &.style2 {
      font-family: Poppins;
      font-size: 14px;
      text-align: right;
      color: var(--oliver-base-blue);
      font-weight: 500;
    }
  }
}

.floatAmountfont {
  font-size: 20px !important;
}

.notification-card {
  cursor: pointer;
}

.close-register-wrapper > header > .col > .divider {
  width: auto;
}

.custom-error-message {
  padding: 11px 28px;
  background-color: #ffe7e8;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--error);
  margin: -10px 0 20px 0;
}

/* Setting change border color of the Balance Due box when partial payment is done */

.payment-type-disable:hover {
  border: 1px solid var(--oliver-border) !important;
  cursor: unset !important;
}

/* Setting change text color of the "Click to make a partial payment" when partial payment is done */

.payment-type-disable-txt {
  color: var(--oliver-base-grey) !important;
  cursor: unset !important;
}

/* Setting rotating this "RefreshGrey.svg" image mean while inventory data is coming */

.rotate {
  -webkit-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;
}

.transactions-detailed
  > .scrollable
  > .order-details
  > .item
  > .col
  > .main-row {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;

  > p:first-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

/* Setting border radius for inputbox in advanced search in Mobile View  */

#advSearchInputWrapper {
  border-radius: 10px;
}

.subwindow-wrapper
  > .subwindow.add-tile
  > .subwindow-body
  > .dropdown-search
  > input.error {
  + .error-message {
    background-color: unset !important;
  }

  &.duplicate-category + .error-message::after {
    content: "*This category has already been added";
  }

  &.duplicate-attribute + .error-message::after {
    content: "*This attribute has already been added";
  }
}

/* Setting error message for category already exist. */

/* Setting error message for attribute already exist. */

@media (max-width: 700px) {
  /* Setting error message for category already exist. Mobile View */

  .subwindow-wrapper
    > .subwindow.add-tile
    > .subwindow-body
    > .dropdown-search
    > input.error {
    &.duplicate-category + .error-message::after {
      content: "*Category already exists";
    }

    &.duplicate-attribute + .error-message::after {
      content: "*Attribute already exists";
    }
  }

  /* Setting error message for atrribute already exist. Mobile View*/
}

div {
  &.is-disabled,
  &.is-disabled-footer {
    pointer-events: none;
    opacity: 0.5;
    -webkit-user-select: none;

    /* Safari */
    -ms-user-select: none;

    /* IE 10 and IE 11 */
    -moz-user-select: none;
    user-select: none;

    /* Standard syntax */
  }
}

/* Seeting disable product view content form right bottom corner START */

.is-disabled-footer > div > {
  button {
    &#addProductNote {
      border-color: var(--oliver-base-grey) !important;
      color: var(--oliver-base-grey) !important;

      > img {
        -webkit-filter: var(--oliver-base-grey) !important;
        filter: var(--oliver-base-grey) !important;
      }
    }

    &#addProductDiscount {
      border-color: var(--oliver-base-grey) !important;
      color: var(--oliver-base-grey) !important;
      opacity: unset;
    }

    &#addProductToCart {
      background-color: var(--oliver-base-grey) !important;
      border-color: var(--oliver-base-grey) !important;
    }
  }

  div > button {
    background-color: var(--oliver-bg-grey) !important;
    border-color: var(--oliver-bg-grey) !important;
  }

  .increment-input > button > img {
    filter: var(--oliver-base-grey) !important;
    -webkit-filter: hue-rotate(70deg) saturate(100) !important;
    filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6) !important;
  }
}

/* Seeting disable product view content form right bottom corner END */
@media (orientation: landscape) {
  .navbar.open ~ .nav-cover {
    display: none !important;
  }
}

.products-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.quantity-disable {
  color: var(--oliver-disabled) !important;
}

.product-wrapper > .mod-product > .img-container.no-var > button {
  display: unset;
}

.cv-detailed > .cust-totals > .col > button:before {
  content: unset;
}

.modifier:hover {
  color: var(--oliver-base-blue-dark) !important;
  text-decoration: none !important;
}

.mod-product-disable > * {
  pointer-events: none;
  opacity: 0.5;
  -webkit-user-select: none;

  /* Safari */
  -ms-user-select: none;

  /* IE 10 and IE 11 */
  -moz-user-select: none;
  user-select: none;

  /* Standard syntax */
}

.transactions-detailed
  > .scrollable
  > .order-details
  > .item
  > .col
  > .main-row
  > del
  > p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--oliver-base-grey);
}

.activityDetailHeaderBlue {
  background-color: #e9f5fd !important;
}

/* Setting cloud printer popup START */

.subwindow-body > #cloud-printer-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--oliver-base-blue);
  padding-bottom: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  > {
    input {
      position: fixed;
      left: -10000px;
      opacity: 0;
    }

    .custom-toggle {
      width: 40px;
      height: 25px;
      border-radius: 25px;
      background-color: var(--oliver-spacer);
      margin-left: 20px;
      position: relative;
    }
  }
}

label.cloud-printer-wrapper > {
  .custom-toggle::after {
    position: absolute;
    top: calc(50% - 11px);
    left: 1.5px;
    content: "";
    width: 22px;
    height: 22px;
    background-color: var(--oliver-white);
    border-radius: 50%;
    -webkit-box-shadow: 0px 2px 6px rgb(0 0 0 / 15%),
      0px 2px 1px rgb(0 0 0 / 6%);
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%), 0px 2px 1px rgb(0 0 0 / 6%);
  }

  input:checked ~ .custom-toggle {
    background-color: var(--oliver-blue) !important;
  }
}

/* Setting cloud printer popup END */

/* Setting error message on partial payment popup */

.partial_amount_err {
  color: red !important;
  margin-top: -15px;
}

/* Setting Adjust height if there is no user apps(My Apps sections) */

.user-info {
  height: auto !important;
}

.transactions-list > .header > .sort-wrapper {
  /* transaction page filter box width increse to fit all filter types  */
  width: 165px !important;
}

.devider-width {
  width: auto !important;
}

.add_border {
  /* border: 2px solid #1583d3 !important; */
  /* border: 2px solid red !important; */
  border: 2px solid #1583d3 !important;
}

.subwindow-wrapper
  > .subwindow.advanced-search
  > .subwindow-body
  > .right-col
  > .body
  > .search-result
  > .row
  > button {
  &.search-view:focus,
  &.search-add-to-sale:focus,
  &.search-transactions:focus,
  &.search-add-to-sale:focus,
  &.search-transactions:focus {
    outline-color: #1583d3 !important;
  }
}

#selectDiv .search-result:nth-child(2) button:nth-child(1) {
  border: 2px solid #1583d3;
}

.statusClose {
  background-color: #ff525f !important;
}

.textcapital {
  text-transform: capitalize;
}

.idle-register-wrapper > header > .col > p {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.hide_wrapper {
  display: none !important;
}

.trim-long-email {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.input-container-pin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  margin-bottom: 1.4167vw;

  input {
    border-radius: 0.666vw;
    width: 82px;
    height: 82px;
    border: none;
  }
}

.jc-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;

  > button {
    font-family: Poppins;
    font-size: 2vw;
    color: var(--oliver-base-blue);
  }
}

.idle-register-wrapper > main > .pinpad > .pin-button-row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0px;
  margin-bottom: 16px;

  &:last-of-type::before {
    content: "";
    width: 82px;
    height: 82px;
  }

  &:last-child > button:first-child {
    margin-left: 0;
  }
}

@media (orientation: portrait) {
  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    gap: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .subwindow.product-unsupported > .subwindow-body {
    padding: 20px 1vw;
  }

  /* Apply equal space to the pseudo-element */

  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    &:last-child > button:first-child {
      margin-left: 0;
    }
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .remove-cover
    > .remove-button {
    width: 1rem;
    height: 1rem;

    > img {
      width: 45%;
      aspect-ratio: 1/1;
    }
  }
}

@media (orientation: portrait) and (max-width: 375px) {
  .jc-center > button {
    font-family: Poppins;
    font-size: 9.6vw;
    line-height: 9.6vw;
    color: var(--oliver-base-blue);
  }
}

.pinCountinue {
  width: 261px;
  height: 50px;
  border: 0;
  border-radius: 4px;
  background-color: var(--oliver-base-blue);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--oliver-white);
  margin-bottom: 20px;
}

.date-selected {
  background-color: var(--oliver-blue) !important;
  color: var(--oliver-white) !important;
  font-weight: 500 !important;
}

.user-info-wrapper > .user-info > .body {
  padding-bottom: 0px !important;
}

.date-selector {
  top: 35% !important;

  /* -ms-transform: translate(-50%,-50%);
        -moz-transform:translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
         transform: translate(-50%,-50%); */
}

/* .product-wrapper {
        z-index: 3;
    } */

.page-options.hidden {
  display: none;
}

/* .product-footer {
        z-index: 9 !important;
        border: 1px solid crimson;
    } */

/* .product-wrapper {
        z-index: 3;
    } */

.dropdown-input-wrapper {
  z-index: 1;
}

.refreshBtn {
  background-color: #e7f4fe;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--oliver-blue);
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.variation_out_of_stock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;

  p {
    color: var(--oliver-base-grey) !important;
    margin-bottom: 16px;
    opacity: 0.6;
  }

  button {
    width: 160px;
    height: 36px;
    border: 0.0833vw solid var(--oliver-blue);
    border-radius: 0.6667vw;
    font-size: 14px;
    line-height: 1.75vw;
    color: var(--oliver-blue);
    background: transparent;
  }
}

.checkout_page > .page-options > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  > {
    .img-container {
      width: unset !important;
    }

    p {
      display: unset !important;
      font-size: 12px;
    }
  }
}

.camera-button {
  position: absolute;
  top: 50%;
  right: 0.9167vw;
  height: 60%;
  width: auto;
  border: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  cursor: pointer;

  &.scaled {
    -webkit-transform: scale(0.8) translateY(-50%);
    -ms-transform: scale(0.8) translateY(-50%);
    transform: scale(0.8) translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

#parkSaleButton img {
  width: 100% !important;
  height: 100% !important;
}

#addNoteButton img {
  height: 70% !important;
  width: 100% !important;
}

@media not all and (-webkit-min-device-pixel-ratio: 0),
  not all and (-o-min-device-pixel-ratio: 0/1),
  not all and (min-resolution: 0.001dpcm) {
  #cashPaymentAmount {
    height: 60%;
  }

  .subwindow-wrapper
    > .subwindow.cash-payment
    > .subwindow-body
    > .step1
    > .input-numpad
    > .input-container
    > label {
    margin-bottom: 0;
  }
}

.color-disable {
  color: var(--oliver-disabled) !important;
}

.homepage-wrapper {
  > .products > .products-container > button {
    &.category > p.main {
      min-width: 70%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: center;
      -webkit-width: 100%;

      > span {
        display: block;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        white-space: pre;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        text-align: center;
      }
    }

    &.product > .footer > p.main > span {
      max-width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  &.amelia {
    -ms-grid-columns: 6vw 1fr;
    grid-template-columns: 6vw 1fr;

    > .header {
      height: auto;
    }
  }
}

/* AMELIA */

.amelia-content-container {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eaf5fe),
    to(#dbeefc)
  );
  background: -o-linear-gradient(top, #eaf5fe 0%, #dbeefc 100%);
  background: linear-gradient(180deg, #eaf5fe 0%, #dbeefc 100%);
  padding: 15px 18px;

  > .amelia-content {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: var(--oliver-white);
    -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);

    > {
      .amelia-content-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px 18px;

        > {
          .amelia-logo {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 11px;

            > {
              img {
                width: 28px;
                height: 28px;
              }

              p {
                font-size: 18px;
                font-weight: 600;
                color: var(--oliver-base-blue);
              }
            }
          }

          .amelia-header-end {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 12px;

            > {
              p {
                font-size: 14px;
                color: var(--oliver-base-blue);
              }

              button {
                width: 36px;
                height: 36px;
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                border: none;
              }

              .amelia-close > img {
                width: 12px;
                height: 12px;
                -o-object-fit: cover;
                object-fit: cover;
              }

              .menu-wrapper {
                width: 155px;
                height: 36px;
                position: relative;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                line-height: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                &.open {
                  z-index: 10;
                  -webkit-box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);
                  box-shadow: 0px 6px 10px rgba(94, 94, 94, 0.1);

                  > img {
                    -webkit-transform: rotate(180deg) translateY(-2px);
                    -ms-transform: rotate(180deg) translateY(-2px);
                    transform: rotate(180deg) translateY(-2px);
                  }

                  > input {
                    border-color: var(--oliver-blue);
                    border-radius: 8px 8px 0 0;
                  }

                  > .option-container {
                    display: block;
                  }
                }

                > img {
                  position: absolute;
                  top: calc(50% - 6px);
                  right: 9px;
                  width: 13px;
                  height: 13px;
                  -webkit-filter: brightness(0) saturate(100%) invert(53%)
                    sepia(37%) saturate(1027%) hue-rotate(165deg)
                    brightness(91%) contrast(99%);
                  filter: brightness(0) saturate(100%) invert(53%) sepia(37%)
                    saturate(1027%) hue-rotate(165deg) brightness(91%)
                    contrast(99%);
                }

                > input {
                  width: 100%;
                  height: 100%;
                  border: 1px solid var(--oliver-border);
                  border-radius: 8px;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0 25px 0 10px;
                  font-family: Poppins;
                  font-size: 14px;
                  line-height: 20px;
                  color: var(--oliver-blue);
                }

                > {
                  input:hover {
                    border-color: var(--oliver-blue);
                    cursor: pointer;
                  }

                  .option-container {
                    width: 100%;
                  }
                }

                > .option-container > .option {
                  width: 100%;
                  height: 36px;
                  background-color: var(--oliver-white);
                  border: 1px solid var(--oliver-border);
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0 10px;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);

                  &:hover {
                    cursor: pointer;
                    color: var(--oliver-blue);
                  }

                  &:last-child {
                    border-radius: 0 0 8px 8px;
                  }
                }
              }
            }
          }
        }
      }

      .amelia-iframe-container {
        padding: 5px;
        background-color: var(--oliver-bg-grey);
        height: 90%;

        > iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    overflow: hidden;
  }
}

@media (orientation: portrait) {
  .amelia-content-container {
    height: 100%;

    > .amelia-content {
      height: 82%;

      > .amelia-content-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 16px;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: start;

        > .amelia-header-end {
          > .menu-wrapper {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
          }

          width: 100%;
        }
      }
    }
  }
}

.custom-tile-size {
  width: var(--oliver-tile-size) !important;
  min-height: var(--oliver-tile-height);
  height: var(--oliver-tile-height);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  > .btn-home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: var(--oliver-tile-size_px);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: auto;
    font-size: 16px;

    > img {
      width: 80%;
      max-width: 24px;
      max-height: 24px;
    }
  }
}

.homepage-wrapper
  > .products
  > .products-container:not(.margin-script)
  > button:nth-of-type(4n) {
  margin-right: 2%;
}

.mr-right {
  margin-right: 0% !important;
}

@media (orientation: landscape) {
  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .body
    > img {
    /* for Sunmi landscape device important to have widht calculated */
    min-width: var(--oliver-tile-size_px);
    max-width: 100%;
  }
}

@media (orientation: landscape) {
  .product-wrapper > .detailed-product > .row > .product-image-container > img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

@media (orientation: landscape) {
  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.custom-tile-size {
  width: var(--oliver-tile-size) !important;
  min-height: var(--oliver-tile-height);
  height: var(--oliver-tile-height);
}

.homepage-wrapper
  > .products
  > .products-container:not(.margin-script)
  > button:nth-of-type(4n) {
  margin-right: 2%;
}

@media (orientation: portrait) {
  .custom-tile-size {
    min-height: calc(var(--oliver-tile-height) + 3vh);
    height: calc(var(--oliver-tile-height) + 3vh);
  }

  .camera-button {
    height: 50%;
    right: 2.9333vw;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  .transactions-search > .search-body > .input-wrapper > .camera-button {
    top: 35%;
  }
}

@media (orientation: portrait) and (max-width: 700px) {
  .custom-tile-size {
    width: calc(var(--oliver-tile-size_px) + 4vw) !important;
    max-width: var(--oliver-tile-size);
    min-height: calc(var(--oliver-tile-height) + 3vh);
    height: calc(var(--oliver-tile-height) + 3vh);
  }

  .homepage-wrapper > .products > .products-container > button {
    &.add-tile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      font-size: 12px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      > img {
        margin-right: 0;
        width: 30%;
        height: 30%;
      }
    }

    &.product > {
      .body {
        height: 65%;
        max-height: 70%;
        -webkit-box-flex: 60;
        -ms-flex: 60 1;
        flex: 60 1;

        > img {
          min-width: var(--oliver-tile-size_px);
          width: var(--oliver-tile-size_px);
          max-width: 100%;
          overflow: hidden;
          max-height: 100%;
        }
      }

      .footer {
        min-height: 30%;
        height: 30%;
        max-height: 35%;
        max-width: 100%;
        -webkit-box-flex: 35;
        -ms-flex: 35 1;
        flex: 35 1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > p {
          font-size: 12px;
          line-height: 1;
        }
      }
    }

    &.category > p {
      &.sub {
        margin-bottom: 10px;
      }

      &.main {
        max-width: 100%;
        padding: 0px 5px;
        font-size: 12px;
        line-height: 1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > span {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .homepage-wrapper
    > .products
    > .products-container:not(.margin-script)
    > button {
    margin-right: 2%;
    margin-bottom: 2%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 8px;
  }
}

.mr-right {
  margin-right: 0% !important;
}

@media (orientation: landscape) {
  .transactions-detailed
    > .scrollable
    > .order-details
    > .item
    > .img-container
    > img {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

@media (orientation: landscape) {
  .product-wrapper > .detailed-product > .row > .product-image-container > img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

@media (orientation: landscape) {
  .product-wrapper
    > .recommended-upsells
    > .button-row
    > button
    > .img-container
    > img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.btm-boder {
  padding: 1.8333vw 0 0.5vw 0;
  border-bottom: 0.0833vw solid var(--oliver-border);
}

.hgt-wdt-unset {
  height: unset !important;
  width: unset !important;
}

/* Search button home */
@media (orientation: landscape) {
  .homepage-wrapper > .header > .row > button#searchButton {
    width: auto;
    padding: 0 8px;
  }
}

/* Customer page edit button */
@media (orientation: landscape) {
  .cv-detailed > .quick-info > button {
    width: auto;
    padding: 0 8px;
  }
}

@media (orientation: portrait) {
  .cv-detailed > .quick-info > button {
    width: auto;
    padding: 0 8px;
  }
}

/* Custom discount toggle container */

@media (orientation: landscape) {
  .subwindow {
    &.custom-fee > .subwindow-body > .toggle-container,
    &.cart-discount > .subwindow-body > .toggle-container {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      gap: 8px;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
    }

    &.custom-fee
      > .subwindow-body
      > .toggle-container
      > label
      > .custom-radio
      > p,
    &.cart-discount
      > .subwindow-body
      > .toggle-container
      > label
      > .custom-radio
      > p {
      padding: 0 8px;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label,
    &.cart-discount > .subwindow-body > .toggle-container > label {
      -webkit-box-flex: unset;
      -ms-flex: unset;
      flex: unset;
    }
  }
}

@media (orientation: portrait) {
  .subwindow {
    &.custom-fee > .subwindow-body > .toggle-container,
    &.cart-discount > .subwindow-body > .toggle-container {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      gap: 8px;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
    }

    &.custom-fee
      > .subwindow-body
      > .toggle-container
      > label
      > .custom-radio
      > p,
    &.cart-discount
      > .subwindow-body
      > .toggle-container
      > label
      > .custom-radio
      > p {
      padding: 0 8px;
    }

    &.custom-fee > .subwindow-body > .toggle-container > label,
    &.cart-discount > .subwindow-body > .toggle-container > label {
      -webkit-box-flex: unset;
      -ms-flex: unset;
      flex: unset;
    }
  }
}

/* Discount toggle container tax fee switch */

@media (orientation: landscape) {
  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    > p {
      max-width: 65%;
    }
  }
}

@media (orientation: portrait) {
  .subwindow.custom-fee
    > .subwindow-body
    > #customFeeDiv
    > label.custom-toggle-wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    > p {
      max-width: 70%;
    }
  }
}

/* Button return to home */
@media (orientation: landscape) {
  .homepage-wrapper
    > .products
    > .sticky-container
    > .category-header
    > button {
    width: auto;
    padding: 0 8px;
  }
}

@media (orientation: portrait) {
  .homepage-wrapper > .products > {
    .sticky-container > .category-header > button {
      width: auto;
      padding: 0 8px;
    }

    .products-container > button > .not-supported > img {
      top: 2%;
      left: 1%;
    }
  }
}

/* Checkbox Button */

@media (orientation: landscape) and (max-width: 1200px) {
  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input[type="checkbox"]:checked
    ~ .custom-radio {
    border: 0.1667vw solid var(--oliver-blue);
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .homepage-wrapper
    > .products
    > .products-container
    > button
    > .not-supported
    > img {
    top: 2%;
    left: 1%;
  }

  .idle-register-wrapper > main > .pinpad > .pin-button-row {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    &:last-of-type {
      > * {
        /* Additional styles for the items */
      }

      &::before {
        content: "";
        width: 6.8333vw;
        height: 6.8333vw;
      }
    }

    &:last-child > button:first-child {
      margin-left: 0;
    }
  }

  /* Apply equal space to the pseudo-element */

  .homepage-wrapper
    > .products
    > .products-container
    > button.product
    > .footer {
    min-height: calc(var(--oliver-tile-height) * 0.3);
    height: calc(var(--oliver-tile-height) * 0.3);
    max-height: calc(var(--oliver-tile-height) * 0.35);
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
}

@media (orientation: portrait) {
  .homepage-wrapper > .products {
    padding: 0;

    > {
      .products-container {
        padding: 2%;
      }

      .sticky-container {
        padding: 4% 2% 1%;
      }

      .products-container > button.product > .footer {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        max-height: calc((var(--oliver-tile-height) + 3vh) * 0.4);

        > p {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          max-height: 100%;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input[type="checkbox"]:checked
    ~ .custom-radio {
    border: 2px solid var(--oliver-blue);
  }

  .idle-register-wrapper
    > main
    > .pinpad
    > .pin-button-row:last-child
    > button:first-child {
    margin-left: 0;
  }
}


@media (orientation: landscape) and (max-width: 1200px) {
  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input[type="checkbox"]:checked
    ~ .custom-radio {
    border: 0.1667vw solid var(--oliver-blue);
  }
}

@media (orientation: landscape) {
  /* and (max-width: 1200px) */

  .product-wrapper
    > .mod-product
    > .radio-group
    > label
    > input[type="checkbox"] {
    display: none;
  }
}

/* Tile-cart-btn */

.tile-cart-btn {
  &.hide {
    display: none;
  }

  position: absolute;
  top: 2%;
  right: 3%;
  width: 15%;
  height: 11%;
  padding: 4%;
  border: none;
  background-color: #f8f9fa;
  border-radius: 12%;
  opacity: 0.85;
  -webkit-box-shadow: 0px 4px 8px 0px #24324933;
  box-shadow: 0px 4px 8px 0px #24324933;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  &:active {
    scale: 0.9;
  }
}

/* Page options position change */

@media (orientation: landscape) {
  .homepage-wrapper > .header > .row > button#searchButton {
    margin-right: 16px;
  }

  .page-options-wrapper {
    position: unset;
    margin-right: 16px;
  }
}

/* Demo Header */

.demo-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 8vh;
  background-color: var(--oliver-blue);
  color: var(--oliver-white);

  &.hide {
    display: none;
  }
}

.demo-header-text {
  font-size: 14px;
  margin-left: 16px;
}

.demo-started-btn {
  background-color: #ffd026;
  color: var(--oliver-base-blue);
  border: none;
  border-radius: 6px;
  padding: 8px 40px;
  font-weight: 600;
  margin-right: 16px;
  text-decoration: none;

  &:active {
    scale: 0.95;
  }
}

.content-wrapper {
  &.set {
    height: 92vh;
    width: 100vw;
  }

  &.unset {
    height: 100vh;
    width: 100vw;
  }
}

/* Unlimited text */

.unlimited-text {
  font-size: 36px;
  font-weight: 600;
  margin: 16px 0;
}

@media (orientation: landscape) {
  .login-wrapper {
    width: 100%;
    height: 100%;
  }
}

/* Split by product text overflow */

@media (orientation: landscape) {
  .subwindow-wrapper
    > .subwindow.split-by-product
    > .subwindow-body
    > .body
    > .product-row
    > .main-row {
    -webkit-box-align: self-start;
    -ms-flex-align: self-start;
    align-items: self-start;

    > .text-group {
      max-width: 60%;
    }
  }
}

/* Backorder Button */

.product-wrapper > {
  .product-footer > .row > button#addProductToCart.back-order-btn {
    background-color: #ff525f;
  }

  .detailed-product
    > .row
    > .col
    > .group
    > .text-row
    > p.quantity.quantity-red {
    color: #ff525f;
  }
}

/* Quantity Red */

.logout-confirm > .idle-register-wrapper {
  width: 0;
  height: 0;

  /* display: flex; */
  background-color: #f8fafc;

  /* /* overflow: auto; */

  > #logoutRegisterButton {
    color: var(--oliver-base-blue) !important;
  }
}

/* ********************************************** */
/* Button click issue fix */

button {
  position: relative;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  overflow: visible;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &::before {
    content: "";
    position: absolute;

    /* inset: -5px; */
    margin: auto;
    background-color: transparent;
    cursor: pointer;
  }

  &:active:not([disabled]):not(.selected):not(.active):not(.no-transform):not(
      .remove-state
    ) {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }
}

/* User info wrapper setting scroll */

@media (orientation: landscape) {
  .user-info-wrapper > .user-info > .footer > .button-row {
    overflow-x: auto;
    padding-bottom: 20px;
  }
}

@media (orientation: landscape) {
  .subwindow.product-unsupported > .subwindow-body > p.style1 {
    margin: 0 0 20px 0;
  }
}

@media (orientation: landscape) {
  .subwindow.product-unsupported > .subwindow-body > button {
    margin-bottom: 0 !important;
  }
}

/* Unsuported prodcut on tile*/
@media (orientation: landscape) {
  .subwindow-wrapper > .product-unsupported.subwindow > .subwindow-body {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 14px;
  }
}

@media (orientation: portrait) {
  .login-wrapper {
    height: 100% !important;
  }
}

@media (orientation: portrait) {
  .demo-started-btn {
    font-size: 14px;
    padding: 6px 16px;
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.end-session > .subwindow-body {
    padding: 0 12px;
  }
}

/* Refresh btn position in mobile view  */
@media (orientation: portrait) {
  .product-wrapper > .header > .main.main-grid-view {
    display: -ms-grid;
    display: grid;
    gap: 12px;
    -ms-grid-columns: 1fr 12px auto;
    grid-template-columns: 1fr auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    > {
      p {
        &.prod-name {
          grid-column: 1/-1;
        }

        &.route {
          margin-bottom: 0;
        }
      }

      button.refreshBtn {
        grid-column: 2/-1;
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;
        grid-row: 1/2;
      }
    }
  }
}

.txt-base-grey {
  color: var(--oliver-base-grey) !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

/* .txt-base-grey:hover{
      color: var(--oliver-base-grey) !important;
      font-weight: 400 !important;
    } */

.txt-modifier-fields {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px 0 0 13px;
}

/* Add note btn */

#addNoteButton {
  gap: 4px;
}

/* payment-type button disabled */

.payment-types > .button-container > button {
  &.disabled {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.4;
  }

  scale: unset;
}

/* Brower not supported popup */
@media (orientation: landscape) {
  .error-404-wrapper {
    > .error-404-container > p.style4 {
      font-size: 1.1vw;
      margin-bottom: 1.6vw;
      color: var(--oliver-base-blue);
      text-align: center;
    }

    .error-404-container p.style1.style1-md {
      font-size: 2.3vw;
    }
  }
}

@media (orientation: portrait) {
  .error-404-wrapper > .error-404-container > p.style4 {
    font-size: 2.4vw;
    margin-bottom: 1.6vw;
    color: var(--oliver-base-blue);
    text-align: center;
  }
}

@media (orientation: landscape) {
  .error-404-wrapper > .error-404-container {
    height: auto;
  }
}

.error-404-wrapper .error-404-container {
  .buttons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1vw;
    margin-bottom: 1vw;
  }

  .download-app-btn {
    display: -ms-grid;
    display: grid;
    background-color: var(--oliver-base-blue);
    width: unset;
    height: unset;
    padding: 0.4vw 0.8vw;
    -ms-grid-columns: auto 0.6vw 1fr;
    grid-template-columns: auto 1fr;
    -webkit-column-gap: 0.6vw;
    -moz-column-gap: 0.6vw;
    column-gap: 0.6vw;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    border: 0.15vw solid var(--oliver-grey-text);
    border-radius: 0.5vw;
    color: var(--oliver-white);
    text-decoration: none;

    .span--img {
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      grid-row: 1/3;
      width: 2vw;
      display: -ms-grid;
      display: grid;
      place-items: center;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    .span-text-sm {
      font-size: 0.8vw;
      text-transform: uppercase;
    }

    .span-text-lg {
      font-size: 1.2vw;
      font-weight: 600;
      text-transform: capitalize;

      /* translate: 0 -0.2vw; */
    }
  }
}

@media (orientation: portrait) {
  .error-404-wrapper .error-404-container {
    .download-app-btn {
      .span-text-lg {
        font-size: 3.2vw;
      }

      .span-text-sm {
        font-size: 1.6vw;
      }

      .span--img {
        width: 4.8vw;
      }

      padding: 1.4vw 2.4vw;
      -webkit-column-gap: 0.8vw;
      -moz-column-gap: 0.8vw;
      column-gap: 0.8vw;
      border: 0.4vw solid var(--oliver-grey-text);
      border-radius: 1.4vw;
    }

    .buttons-container {
      gap: 1.6vw;
    }
  }
}

@media (orientation: portrait) and (max-width: 375px) {
  .error-404-wrapper {
    .error-404-container {
      .download-app-btn {
        .span-text-lg {
          font-size: 4.8vw;
        }

        .span-text-sm {
          font-size: 2.4vw;
        }

        .span--img {
          width: 6.8vw;
        }

        padding: 1.6vw 3.2vw;
        -webkit-column-gap: 1.2vw;
        -moz-column-gap: 1.2vw;
        column-gap: 1.2vw;
        border: 0.6vw solid var(--oliver-grey-text);
        border-radius: 2vw;
      }

      p.style1.style1-md {
        font-size: 7vw;
      }
    }

    > .error-404-container > p.style4 {
      font-size: 3.6vw;
      margin-bottom: 2.4vw;
    }
  }
}

.subwindow.delete-customer-confirm > .subwindow-body > button#blueButton {
  background-color: var(--oliver-blue);
  border: 0;
  color: var(--oliver-white);
  margin-bottom: 14px;
}

#cancelcloseregister {
  .close_register_logout {
    width: 2vw;
    height: 2vw;
    margin-right: 0.5833vw;
  }

  white-space: nowrap;
  margin-left: 5px;
}

@media (orientation: portrait) {
  #cancelcloseregister .close_register_logout {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.close_register_div {
  overflow-y: auto;
  max-height: 420px;
  padding: 4px;
}

.search_title {
  white-space: normal !important;
}

.content-wrapper.unset {
  height: 100dvh;
  width: 100vw;
}

/* product Drop down */

.product-wrapper .dropdown-wrapper {
  position: relative;
  width: 100%;
  width: min(25vw, 100%);
  height: 44px;
  margin-bottom: 20px;
  line-height: 0;

  &.open {
    z-index: 2;
    -webkit-box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.3);
    box-shadow: 0px 10px 20px rgba(119, 135, 147, 0.3);
  }

  &:hover {
    cursor: pointer;
  }

  > img {
    position: absolute;
    right: 14px;
    top: calc(50% - 9px);
    width: 18px;
    height: 18px;
  }

  &.open {
    > img {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    > input {
      border-radius: 6px 6px 0 0;
      border-color: var(--oliver-blue);
    }

    > .option-list {
      display: block;
    }
  }

  > input {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0.0833vw solid var(--oliver-border);
    border-radius: 0.6667vw;
    padding: 0 1vw;

    &:focus {
      border-color: var(--oliver-border);
    }
  }

  > {
    input:hover {
      cursor: pointer;
    }

    .option-list {
      display: none;
      width: 100%;
      max-height: 176px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      overflow: auto;
      border-radius: 0 0 7px 7px;
    }
  }

  > .option-list > .option {
    width: 100%;
    height: 44px;
    background-color: var(--oliver-white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid var(--oliver-border);
    border-top: 0;

    &:last-child {
      border-radius: 0 0 7px 7px;
    }

    > p {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: var(--oliver-base-blue);
    }

    &:hover > p {
      color: var(--oliver-blue);
    }
  }
}

/* ----------End product drop down ----------- */

.no-access {
  scale: 2;
  margin-bottom: 0 !important;
}

.nomargine {
  margin: 0 !important;
}

.margine16 {
  margin-top: 16px !important;
}

.gap16 {
  gap: 16px;
}

.noaccess-navBar {
  z-index: 8;
}

.mod-product input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: unset;
    margin: 0;
  }

  -moz-appearance: unset;
}

/*addons Error */

.product-wrapper > .mod-product > p.error-addon::after {
  content: "*Required";
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: var(--error);
  margin-left: 10px;
}

.no-access {
  scale: 2;
  margin-bottom: 0 !important;
}

.nomargine {
  margin: 0 !important;
}

.margine16 {
  margin-top: 16px !important;
}

.gap16 {
  gap: 16px;
}

.noaccess-navBar {
  z-index: 8;
}

/* New Checkout Container */

#checkout-container {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.2fr 1fr;
  grid-template-columns: 0.2fr 1fr;
}

#checkoutButton.checkout__menu-btn {
  padding-right: 0;

  p {
    background: var(--oliver-light-blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    .dot {
      background: transparent;
      width: 0.3vw;
      height: 0.3vw;
      border: 0.1vw solid var(--oliver-blue);
      border-radius: 50%;
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;

      @media (orientation: portrait) {
        width: 1.2vw;
        height: 1.2vw;
        border: 0.2vw solid var(--oliver-blue);
      }
    }

    &:hover .dot {
      background: var(--oliver-blue);
    }
  }
}

@media (orientation: landscape) and (max-width: 1500px) {
  #checkout-container {
    -ms-grid-columns: 0.3fr 1fr;
    grid-template-columns: 0.3fr 1fr;
  }
}

/* Small popup */

.small-popup {
  z-index: 999;
  position: absolute;
  top: -14rem;
  left: 1rem;


  &.hidden {
    display: none;
  }
}

.small-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(123, 140, 152, 0.3);
}

.small-popup-content {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  padding: 1rem;
  background-color: var(--oliver-white);
  border-radius: 0.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0px 20px 30px 0px #122b5140;
  box-shadow: 0px 20px 30px 0px #122b5140;
  z-index: 9999;
  border: 0.1rem solid var(--oliver-border);
}

.small-popup .row-img-container {
  width: 1.5rem;
  height: 1.5rem;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.small-popup-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:active {
    scale: 0.95;
  }

  &:hover {
    background-color: var(--oliver-light-blue);
  }
}

/* Checkout menu for mobile */

#mobile-homepage-footer {
  position: relative;
  padding: 0 1rem;
  gap: 1rem;
  width: auto;

  #openMobileCart {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .checkout__menu-btn {
    background-color: var(--oliver-light-blue);
    width: 15%;

    p {
      background: var(--oliver-light-blue);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      gap: 3px;
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;

      .dot {
        background: transparent;
        width: 1.2vw;
        height: 1.2vw;
        border: 0.1vw solid var(--oliver-blue);
        border-radius: 50%;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

/* Avatar Border Radius */

.user-info-wrapper > .user-info > .header > .avatar {
  border-radius: 0.8rem;
}

.homepage-wrapper > .header > .row > button#userInfoButton {
  border-radius: 0.5rem;

  > .avatar {
    border-radius: 0.5rem;
  }
}

.refund-wrapper > .header > button#userInfoButton {
  border-radius: 0.5rem;

  > .avatar {
    border-radius: 0.5rem;
  }
}

/* Header Menu items */

.page-options-wrapper > .page-options {
  position: relative;
}

.page-options {
  .small-popup {
    top: unset;
    bottom: -8.2rem;
    right: 3rem;
    left: unset;
  }

  .vertical-line {
    height: 1.5rem;
    width: 1px;
    background-color: var(--oliver-border);
    margin-left: 1rem;
  }
}

.checkout-wrapper > .header > button#userInfoButton {
  border-radius: 0.5rem;

  > .avatar {
    border-radius: 0.5rem;
  } 
}

@media (orientation: landscape) {
  .page-options-wrapper > .page-options > button {
    width: 40px;
    height: 40px;
    background: #f1f6f6;
    padding: 0.6rem;
    border-radius: 0.5rem;
  }

  .checkout-wrapper .page-options-wrapper > .page-options > button {
    width: auto;
  }

  .refund-wrapper .page-options-wrapper > .page-options > button {
    width: auto;
  }

  #add-giftcard-btn,
  #add-product-btn {
    display: none;
  }
}

@media (orientation: portrait) {
  #custom-product-btn,
  #custom-product-popup,
  .page-options .vertical-line {
    display: none;
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .page-options-wrapper > .page-options > button {
    width: 1.9531vw;
    height: 1.9531vw;
  }
}


/* New Tiles Header */

.tile-segmented {
  background-color: var(--oliver-white);
  padding: 0;
}

.tiles-header {
  margin-bottom: 1rem;
}

.tile-segmented-option {
  padding: 6px 6px 8px;

  &:hover svg {
    fill: #2797e8;
  }

  svg {
    fill: #3d4c66;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .ant-segmented-item-selected svg {
    fill: var(--oliver-white);
  }
}

.tiles-header .tile-select {
  width: 160px;
  height: 100%;
}

.btn-edit-tiles {
  height: 100%;
}

/* Tile Card */

.tile-card {
  width: 100%;
  height: 72px;
  background-color: var(--oliver-white);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  color: #3d4c66;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  /* z-index: 2; */
  -webkit-box-shadow: 0px 6px 10px 0px rgba(94, 94, 94, 0.102);
  box-shadow: 0px 6px 10px 0px rgba(94, 94, 94, 0.102);
  border: 1px solid;
  border-color: transparent;
}

@media (min-width: 520px) {
  .tile-card:hover {
    .tile-card-text {
      color: #2797e8;
    }

    background-color: var(--oliver-bg-grey);
  }
}

.tile-card-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 16px 1fr 16px auto 16px auto;
  grid-template-columns: auto 1fr auto auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  padding: 0 12px;
  width: calc(100% - 24px);
  height: 100%;
}

.tile-card-img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--oliver-light-blue);
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}

.tile-card-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  span {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
}

.tile-card-price {
  font-size: 18px;
}

.tile-card-btn {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--oliver-light-blue);
  border: 1px solid;
  border-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:active {
    scale: 0.95;
  }

  &:hover {
    background-color: transparent;
    border-color: var(--oliver-border);
  }
}

.tile-card-cart img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
  object-fit: contain;
}

.tile-card-arrow img {
  width: 36%;
  height: 36%;
  rotate: -90deg;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tile-card-text-arrow {
  width: 24px;
  height: 24px;
  display: none;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    rotate: 180deg;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}

/* Tile Collapse */

.tile-collapse {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;

  /* z-index: 4; */
}

.tile-collapse-container {
  position: relative;
  margin-bottom: 1rem;
}

.tile-collapse-header {
  position: relative;
  margin-bottom: 10px;
  z-index: 2;
  -webkit-box-shadow: 0px 6px 10px 0px rgba(94, 94, 94, 0.102);
  box-shadow: 0px 6px 10px 0px rgba(94, 94, 94, 0.102);
}

/** When Collapse is active **/

.tile-collapse.active {
  .tile-collapse-span {
    top: 0;
  }

  .tile-collapse-header .tile-card-btn {
    background-color: transparent;
    border-color: var(--oliver-border);
  }

  .tile-card-arrow img {
    rotate: 90deg;
  }

  .tile-collapse-header {
    margin-bottom: 0;
  }

  .tile-collapse-childrens {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-animation: tileSideUp 0.2s ease-in-out;
    animation: tileSideUp 0.2s ease-in-out;
  }
}

/******/

.tile-collapse-span {
  content: "";
  position: absolute;
  display: inline-block;
  top: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 72px;
  border-radius: 10px;
  background-color: var(--oliver-white);
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0px 6px 10px 0px rgba(94, 94, 94, 0.102);
  box-shadow: 0px 6px 10px 0px rgba(94, 94, 94, 0.102);

  &:nth-of-type(2) {
    top: 8px;
    z-index: 0;
    -webkit-box-shadow: 0px 6px 10px 0px rgba(94, 94, 94, 0.102);
    box-shadow: 0px 6px 10px 0px rgba(94, 94, 94, 0.102);
  }
}

.tile-collapse-childrens {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  width: 95%;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tile-divider .ant-divider-inner-text {
  color: #3d4c66;
}

/* Notification Wrapper  */

.notifications-wrapper {
  z-index: 2;
}

.tile-card-text,
.tile-card-img {
  cursor: pointer;
}

@media (max-width: 520px) {
  .homepage-wrapper > .products {
    padding: 0;
    position: relative;
  }

  .custom-tile-size > .btn-home {
    font-size: 12px;
  }

  .homepage-wrapper > .products > {
    .sticky-container,
    .products-container {
      padding: 10px;
    }
  }

  .tiles-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
  }

  .tile-segmented {
    width: 100%;
  }

  .tile-segmented-option {
    .ant-segmented-item-icon {
      display: none;
    }

    -webkit-box-flex: 1;

    -ms-flex: 1;

    flex: 1;
  }

  .tiles-header {
    button {
      width: 100%;
    }

    .tile-select {
      width: 100%;
      height: 40px;
    }
  }

  .tile-card {
    height: 56px;
  }

  .tile-card-content {
    gap: 8px;
    padding: 0 8px;
    width: calc(100% - 16px);
  }

  .tile-card-text {
    font-size: 14px;
    gap: 4px;

    span {
      width: 120px;
    }
  }

  .tile-card-price {
    font-size: 14px;
  }

  .tile-collapse-span {
    height: 56px;
  }

  .tile-collapse-childrens {
    width: 100%;
  }

  .tile-card-text-arrow {
    display: none;
  }

  .tile-card-img,
  .tile-card-btn {
    width: 40px;
    height: 40px;
  }

  .btn-edit-tiles {
    height: 40px;
  }
}

/* Pin Page Design Chnage  */
@media (orientation: landscape) {
  .idle-register-wrapper > header > {
    img {
      width: 200px;
      margin: 0;
    }

    .col > p.style1 {
      font-size: 32px;
    }
  }
}

.activity-skeleton-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.activity-skeleton-card {
  padding: 10px 20px 10px 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px auto;
  grid-template-columns: 1fr auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e5e5e5;
  background: var(--oliver-white);

  .input {
    min-width: 160px;
    width: 160px;
    height: 28px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgb(239, 245, 245)),
      color-stop(20%, rgba(239, 245, 245, 0.8)),
      to(#f1f6f6)
    );
    background: -o-linear-gradient(
      left,
      rgb(239, 245, 245),
      rgba(239, 245, 245, 0.8) 20%,
      #f1f6f6
    );
    background: linear-gradient(
      90deg,
      rgb(239, 245, 245),
      rgba(239, 245, 245, 0.8) 20%,
      #f1f6f6
    );
    border-radius: 6px;
    -webkit-animation: wavelines 3.2s infinite ease-in-out,
      fadeInSmall 2.4s infinite linear alternate;
    animation: wavelines 3.2s infinite ease-in-out,
      fadeInSmall 2.4s infinite linear alternate;
  }

  .button {
    min-width: 64px;
    width: 64px;
    height: 28px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgb(239, 245, 245)),
      color-stop(20%, rgba(239, 245, 245, 0.8)),
      to(#f1f6f6)
    );
    background: -o-linear-gradient(
      left,
      rgb(239, 245, 245),
      rgba(239, 245, 245, 0.8) 20%,
      #f1f6f6
    );
    background: linear-gradient(
      90deg,
      rgb(239, 245, 245),
      rgba(239, 245, 245, 0.8) 20%,
      #f1f6f6
    );
    border-radius: 6px;
    -webkit-animation: wavelines 3.2s infinite ease-in-out,
      fadeInSmall 2.4s infinite linear alternate;
    animation: wavelines 3.2s infinite ease-in-out,
      fadeInSmall 2.4s infinite linear alternate;
  }
}

.sale-complete-wrapper > .main > .button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;

  > button {
    width: 200px;
    height: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid var(--oliver-white);
    border-radius: 6px;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--oliver-white);
    margin: 10px 5px;

    &#emailSubwindowButton {
      display: none;
    }
  }
}

@media (max-width: 970px) {
  .sale-complete-wrapper > .main > .button-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    > button#emailSubwindowButton {
      display: block;
    }
  }
}

.location-refresh-button {
  background-color: #2797e8;
  color: var(--oliver-white);
  border: none;
  border-radius: 6px;
  padding: 8px 40px;
  margin-right: 16px;
  text-decoration: none;
  font-family: "Poppins";
  cursor: pointer;

  &:hover {
    -webkit-filter: brightness(95%);
    filter: brightness(95%);
  }
}

.order-error {
  background-color: #f1f4f6;
  padding: 16px 16px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Poppins";
  color: #3d4c66;
  margin: 8px 0;
  max-width: 300px;

  h3 {
    margin: 0;
  }

  p {
    font-size: 14px;
    max-width: 90%;
  }

  button {
    background-color: #2797e8;
    color: var(--oliver-white);
    border: none;
    font-family: inherit;
    padding: 8px 16px;
    border-radius: 8px;
    width: 100%;
  }
}

.order-error-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.bug-card {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--oliver-bg-grey);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  margin-bottom: 16px;
  color: var(--oliver-base-blue);
  margin-top: auto;

  @media (orientation: portrait) {
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;
    margin-bottom: 0px;
    align-self: flex-start;
  }
}


.bug-card-text {
  color: var(--oliver-base-blue);
  font-size: 12px;
  max-width: 100%;
}

.bug-card-heading {
  font-size: 16px;
  font-weight: 500;
  display: none;
}

.bug-card-link {
  color: var(--oliver-blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.bug-card-group {
  gap: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

.bug-card-group-img {
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.bug-card-footer {
  display: none;

  /* flex-direction: column; */
  gap: 8px;
  font-size: 8px;
}

.navbar.open {
  .bug-card-footer,
  .bug-card-heading,
  .bug-card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.bug-card-group {
  &.img-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  button {
    background: transparent;
    border: none;
  }

  &.img-container img {
    cursor: pointer;
  }
}

.bug-card-footer {
  .bug-card-text {
    font-size: 8px;
  }

  .bug-card-group {
    gap: 4px;
  }
}

.bug-card-key {
  background-color: var(--oliver-white);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  font-weight: 600;
  display: -ms-grid;
  display: grid;
  place-content: center;
  color: inherit;
  padding: 4px;
  font-family: Poppins;
  text-transform: uppercase;
  font-size: 10px;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    cursor: pointer;
  }
}

#resync {
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  color: var(--oliver-white);
  border: 1px solid var(--oliver-white);
  padding: 12px 32px;
  border-radius: 8px;
}

@media (orientation: portrait) {
  .idle-register-wrapper > header {
    margin-top: 56px;
  }

  .close-register-wrapper > header {
    margin-top: 56px;
  }

  #resync {
    top: 12px;
    right: 12px;
  }

  #logoutRegisterButton {
    top: 12px;
    left: 12px !important;
  }
}
