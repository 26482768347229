@media (orientation: portrait) {
  .cm-list {
    width: 100%;
    height: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    > {
      .cm-list-header-landscape {
        display: none;
      }

      .cm-list-body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

        > {
          .current-register {
            position: relative;
            width: 100%;
            height: 99px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border: 0;
            border-bottom: 1px solid var(--oliver-border);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            padding: 0 91px 0 16px;
            background-color: transparent;

            p {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              -o-text-overflow: ellipsis;
                 text-overflow: ellipsis;
            }

            > {
              p {
                &.style1 {
                  
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 21px;
                  text-align: left;
                  color: var(--oliver-blue);
                }

                &.style2 {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }
              }

              .text-row > p {
                
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);

                &.open, &.closed {
                  display: none;
                }
              }

              .mobile-fake-button {
                position: absolute;
                right: 16px;
                -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                width: 75px;
                height: 40px;
                border: 1px solid var(--oliver-blue);
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: var(--oliver-blue);
              }
            }

            &.selected {
              background-color: #e9f5fd;

              .mobile-fake-button {
                background-color: #fff !important;
              }
            }
          }

          .date {
            width: 100%;
            height: 30px;
            background-color: #f1f4f6;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border-bottom: 1px solid var(--oliver-border);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            padding: 16px;

            > p {
              
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-grey);
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              -o-text-overflow: ellipsis;
                 text-overflow: ellipsis;
            }
          }

          .other-register {
            position: relative;
            width: 100%;
            height: 75px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid var(--oliver-border);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            padding: 0 16px;

            > .row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;

              &:nth-child(1) {
                margin-bottom: 4px;
              }

              > p {
                &:first-of-type {
                  width: 0;
                  -webkit-box-flex: 1;
                      -ms-flex-positive: 1;
                          flex-grow: 1;
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                     text-overflow: ellipsis;
                }

                &.style1 {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                &.style2 {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }
              }
            }

            &.selected {
              background-color: #e9f5fd;

              &::before {
                content: "";
                position: absolute;
                width: 6px;
                height: 60px;
                top: 7px;
                left: 0;
                border-radius: 0 4px 4px 0;
                background-color: var(--oliver-blue);
              }

              p {
                color: var(--oliver-blue) !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .cm-list {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    > {
      .cm-list-header-landscape {
        width: 100%;
        height: 68px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 20px;
        border-bottom: 1px solid var(--oliver-border);

        > p {
          
          font-size: 20px;
          font-weight: 600;
          line-height: 29px;
          text-align: left;
          color: var(--oliver-base-blue);
        }
      }

      .cm-list-body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        overflow: auto;

        > {
          .current-register {
            width: 100%;
            height: 103px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border: 0;
            border-bottom: 1px solid var(--oliver-border);
            background-color: transparent;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            padding: 0 20px;

            &.selected {
              background-color: #e9f5fd;
            }

            > {
              .mobile-fake-button {
                display: none;
              }

              .text-row {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                margin-bottom: 4px;

                > p {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  margin-right: 7px;

                  &.open {
                    font-weight: 600;
                    color: var(--approval);
                  }
                }
              }

              p {
                &.style1 {
                  
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 21px;
                  text-align: left;
                  color: var(--oliver-blue);
                  margin-bottom: 5px;
                }

                &.style2 {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }
              }
            }
          }

          .date {
            width: 100%;
            height: 30px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border-bottom: 1px solid var(--oliver-border);
            background-color: #f1f4f6;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            padding: 0 12px;

            > p {
              
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-grey);
            }
          }

          .other-register {
            position: relative;
            width: 100%;
            height: 76px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border: 0;
            border-bottom: 1px solid var(--oliver-border);
            background-color: transparent;
            padding: 0 22px;
            line-height: 0;

            &.selected {
              background-color: #e9f5fd;

              p {
                color: var(--oliver-blue) !important;
              }

              &::before {
                content: "";
                width: 7px;
                height: 60px;
                position: absolute;
                left: 0;
                top: 8px;
                background-color: var(--oliver-blue);
                border-radius: 0 4px 4px 0;
              }
            }

            > .row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;

              &:nth-child(1) {
                margin-bottom: 4px;
              }

              > p {
                &.style1 {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                &.style2 {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }

                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                   text-overflow: ellipsis;

                &:first-of-type {
                  -webkit-box-flex: 1;
                      -ms-flex-positive: 1;
                          flex-grow: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .cm-list > {
    .cm-list-header-landscape {
      height: 3.3203vw;
      padding: 0 0.9766vw;
      border-bottom: 0.0488vw solid var(--oliver-border);

      > p {
        font-size: 0.9766vw;
        line-height: 1.416vw;
      }
    }

    .cm-list-body > {
      .current-register {
        height: 5.0293vw;
        border-bottom: 0.0488vw solid var(--oliver-border);
        padding: 0 0.9766vw;

        > {
          .text-row {
            margin-bottom: 0.1953vw;

            > p {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
              margin-right: 0.3418vw;
            }
          }

          p {
            &.style1 {
              font-size: 0.7324vw;
              line-height: 1.0254vw;
              margin-bottom: 0.2441vw;
            }

            &.style2 {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
            }
          }
        }
      }

      .date {
        height: 1.4648vw;
        border-bottom: 0.0488vw solid var(--oliver-border);
        padding: 0 0.5859vw;

        > p {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }
      }

      .other-register {
        height: 3.7109vw;
        border-bottom: 0.0488vw solid var(--oliver-border);
        padding: 0 1.0742vw;

        &.selected::before {
          width: 0.3418vw;
          height: 2.9297vw;
          top: 0.3906vw;
          border-radius: 0 0.1953vw 0.1953vw 0;
        }

        > .row {
          &:nth-child(1) {
            margin-bottom: 0.1953vw;
          }

          > p {
            &.style1, &.style2 {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
            }
          }
        }
      }
    }
  }
}

