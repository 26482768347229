@media (orientation: portrait) {
  .cm-detailed {
    position: fixed;
    top: 70px;
    background-color: #fff;
    z-index: 3;
    width: 100%;
    height: calc(100% - 70px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    &.open {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    > {
      .detailed-header-mobile {
        width: 100%;
        height: 52px;

        > button {
          height: 100%;
          padding: 0 20px;
          background-color: transparent;
          border: 0;

          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: var(--oliver-blue);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          > img {
            width: 9px;
            height: 18px;
            margin-right: 8px;
          }
        }
      }

      .detailed-header-landscape {
        display: none;
      }

      .detailed-quick-info {
        width: 100%;
        background-color: #eaf5ff;
        padding: 15px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > .row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          &:first-child {
            margin-bottom: 15px;
          }

          > {
            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-base-blue);

              &.active {
                color: var(--oliver-blue);
              }
            }

            .row-group {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-right: auto;

              > {
                p {
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 27px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                  margin-right: 10px;
                }

                .status {
                  height: 24px;
                  padding: 0 8px;
                  border-radius: 6px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  text-align: center;

                  &.closed {
                    background-color: var(--oliver-border);
                    color: var(--oliver-base-blue);
                  }

                  &.open {
                    background-color: var(--oliver-teal);
                    color: #fff;
                  }
                }
              }
            }

            .col-group {
              margin-right: auto;

              > p {
                &.style1 {
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 19px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }

                &.style2 {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }
              }
            }

            button {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              width: 88px;
              height: 40px;
              border: 1px solid var(--oliver-blue);
              border-radius: 8px;
              background-color: #fff;

              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-blue);

              > img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
              }
            }
          }
        }
      }

      .detailed-body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        overflow: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 20px;

        > .action {
          width: 100%;
          padding: 15px 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          border-top: 1px solid var(--oliver-border);

          &:first-child {
            border-top: 0;
          }

          > {
            .header-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;

              > p {
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                text-align: left;
                color: var(--oliver-base-blue);

                &.style2 {
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 21px;
                  text-align: right;
                  color: var(--oliver-base-grey);
                }

                &.green {
                  color: var(--approval);
                }

                &.blue {
                  color: var(--oliver-blue);
                }

                &.red {
                  color: var(--error);
                }
              }
            }

            .body-col {
              width: 100%;

              > p {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-grey);
              }
            }

            .footer-col {
              width: 100%;
              margin-top: 10px;

              > p {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);

                > b {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .detailed-footer {
        width: 100%;
        padding: 16px;
        border-top: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > button {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          height: 50px;
          margin-left: 20px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;

          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .cm-detailed {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    > {
      .detailed-header-mobile {
        display: none;
      }

      .detailed-header-landscape {
        width: calc(100% - 40px);
        height: 67px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > {
          img {
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }

          p {
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            text-align: center;
            color: var(--oliver-base-blue);
          }
        }
      }

      .detailed-quick-info {
        width: calc(100% - 40px);
        background-color: #eaf5ff;
        padding: 15px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > .row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          &:first-child {
            margin-bottom: 10px;
          }

          > {
            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-base-blue);

              &.active {
                color: var(--oliver-blue);
              }
            }

            .row-group {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-right: auto;

              > {
                p {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                  margin-right: 10px;
                }

                .status {
                  height: 24px;
                  padding: 0 8px;
                  border-radius: 6px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  text-align: center;

                  &.closed {
                    background-color: var(--oliver-border);
                    color: var(--oliver-base-blue);
                  }

                  &.open {
                    background-color: var(--oliver-teal);
                    color: #fff;
                  }
                }
              }
            }

            .col-group {
              margin-right: auto;

              > p {
                &.style1 {
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 19px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }

                &.style2 {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }
              }
            }

            button {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              width: 88px;
              height: 40px;
              border: 1px solid var(--oliver-blue);
              border-radius: 8px;
              background-color: #fff;

              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-blue);

              > img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
              }
            }
          }
        }
      }

      .detailed-body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        overflow: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 20px;

        > .action {
          width: 100%;
          padding: 15px 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          border-top: 1px solid var(--oliver-border);

          &:first-child {
            border-top: 0;
          }

          > {
            .header-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;

              > p {
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                text-align: left;
                color: var(--oliver-base-blue);

                &.style2 {
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 21px;
                  text-align: right;
                  color: var(--oliver-base-grey);
                }

                &.green {
                  color: var(--approval);
                }

                &.blue {
                  color: var(--oliver-blue);
                }

                &.red {
                  color: var(--error);
                }
              }
            }

            .body-col {
              width: 100%;

              > p {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-grey);
              }
            }

            .footer-col {
              width: 100%;
              margin-top: 10px;

              > p {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);

                > b {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .detailed-footer {
        width: 100%;
        padding: 20px 30px;
        border-top: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > button {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          height: 50px;
          margin-left: 30px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;

          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .cm-detailed > {
    .detailed-header-landscape {
      width: calc(100% - 1.9531vw);
      height: 3.2715vw;

      > {
        img {
          width: 1.0742vw;
          height: 1.0742vw;
          margin-right: 0.3906vw;
        }

        p {
          font-size: 0.8789vw;
          line-height: 1.3184vw;
        }
      }
    }

    .detailed-quick-info {
      width: calc(100% - 1.9531vw);
      padding: 0.7324vw;

      > .row {
        &:first-child {
          margin-bottom: 0.4883vw;
        }

        > {
          p {
            font-size: 0.6836vw;
            line-height: 1.0254vw;
          }

          .row-group > {
            p {
              font-size: 0.7813vw;
              line-height: 1.1719vw;
              margin-right: 0.4883vw;
            }

            .status {
              height: 1.1719vw;
              padding: 0 0.3906vw;
              border-radius: 0.293vw;
              font-size: 0.5859vw;
              line-height: 0.8789vw;
            }
          }

          .col-group > p {
            &.style1 {
              font-size: 0.6348vw;
              line-height: 0.9277vw;
            }

            &.style2 {
              font-size: 0.7813vw;
              line-height: 1.1719vw;
            }
          }

          button {
            width: 4.2969vw;
            height: 1.9531vw;
            border: 0.0488vw solid var(--oliver-blue);
            border-radius: 0.3906vw;
            font-size: 0.7813vw;
            line-height: 1.1719vw;

            > img {
              width: 0.9766vw;
              height: 0.9766vw;
              margin-right: 0.3906vw;
            }
          }
        }
      }
    }

    .detailed-body {
      padding: 0 0.9766vw;

      > .action {
        padding: 0.7324vw 0;
        border-top: 0.0488vw solid var(--oliver-border);

        > {
          .header-row > p {
            font-size: 0.6836vw;
            line-height: 1.0254vw;

            &.style2 {
              font-size: 0.6348vw;
              line-height: 1.0254vw;
            }
          }

          .body-col > p {
            font-size: 0.6348vw;
            line-height: 0.9766vw;
          }

          .footer-col {
            margin-top: 0.4883vw;

            > p {
              font-size: 0.6348vw;
              line-height: 0.9766vw;
            }
          }
        }
      }
    }

    .detailed-footer {
      padding: 0.9766vw 1.4648vw;
      border-top: 0.0488vw solid var(--oliver-border);

      > button {
        height: 2.4414vw;
        margin-left: 1.4648vw;
        border-radius: 0.293vw;
        font-size: 0.7813vw;
        line-height: 1.1719vw;
      }
    }
  }
}
