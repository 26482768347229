@media (orientation: portrait) {
  .subwindow.no-permissions {
    width: min(344px, 95%);
    height: min(372px, 95%);

    > .subwindow-body {
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        img {
          width: 50px;
          height: 50px;
          padding-top: 25px;
          margin: auto 0 28px 0;
        }

        p {
          &.style1 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 28px;
          }

          &.style2 {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 59px;

            > br.landscape {
              display: none;
            }
          }
        }

        button {
          width: 306px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin-bottom: auto;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow.no-permissions {
    width: min(504px, 95%);
    height: min(400px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        img {
          width: 50px;
          height: 50px;
          margin: auto 0 25px 0;
        }

        p {
          &.style1 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 36px;
          }

          &.style2 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 44px;

            > br.mobile {
              display: none;
            }
          }
        }

        button {
          width: 198px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin-bottom: auto;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow.no-permissions {
    width: min(24.6094vw, 95%);
    height: min(19.5313vw, 95%);

    > .subwindow-body > {
      img {
        width: 2.4414vw;
        height: 2.4414vw;
        margin: auto 0 1.2207vw 0;
      }

      p {
        &.style1 {
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin-bottom: 1.7578vw;
        }

        &.style2 {
          font-size: 0.7813vw;
          line-height: 1.0254vw;
          margin-bottom: 2.1484vw;
        }
      }

      button {
        width: 9.668vw;
        height: 2.4414vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
