@media (orientation: portrait) {
  .user-info-wrapper {
    z-index: 5;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f1f9ff;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    > .user-info {
      width: 100%;
      height: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      > {
        .header {
          width: 100%;
          margin-bottom: 22px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > {
            .avatar {
              width: 55px;
              height: 55px;
              border-radius: 50%;
              overflow: hidden;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              background-color: var(--oliver-base-blue);
              margin-right: 20px;
              font-size: 20px;

              > img {
                width: 100%;
                height: 100%;
                border-radius: 50%;

                &.default {
                  display: none;

                  &:only-child {
                    display: block;
                  }
                }
              }
            }

            .col > p {
              &.style1 {
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                text-align: left;
                color: var(--oliver-base-blue);
              }

              &.style2 {
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                color: var(--oliver-base-grey);
              }
            }
          }
        }

        .body {
          width: 100%;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;

          > {
            .language-select {
              width: 80%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-bottom: 20px;

              > {
                p {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 17px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                  margin-right: 10px;
                }

                .dropdown-input-wrapper {
                  position: relative;
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  height: 35px;
                  line-height: 0;

                  &:hover {
                    cursor: pointer;

                    > img {
                      -webkit-filter: invert(43%) sepia(76%) saturate(756%)
                        hue-rotate(168deg) brightness(103%) contrast(86%);
                      filter: invert(43%) sepia(76%) saturate(756%)
                        hue-rotate(168deg) brightness(103%) contrast(86%);
                    }
                  }

                  > img {
                    position: absolute;
                    top: calc(50% - 7px);
                    right: 12px;
                    width: 14px;
                    height: 14px;
                  }

                  &.open > {
                    img {
                      -webkit-transform: rotate(180deg);
                      -ms-transform: rotate(180deg);
                      transform: rotate(180deg);
                      -webkit-filter: invert(43%) sepia(76%) saturate(756%)
                        hue-rotate(168deg) brightness(103%) contrast(86%);
                      filter: invert(43%) sepia(76%) saturate(756%)
                        hue-rotate(168deg) brightness(103%) contrast(86%);
                    }

                    input {
                      border-radius: 8px 8px 0 0;
                      border-color: var(--oliver-blue);
                      border-width: 2px;
                    }
                  }

                  > input {
                    width: 100%;
                    height: 100%;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border: 1px solid var(--oliver-base-grey);
                    border-radius: 8px;
                    outline: 0;
                    padding: 0 12px;

                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-grey);
                  }

                  &:hover > input:hover {
                    cursor: pointer;
                  }

                  > .option-container {
                    width: 100%;
                    max-height: 400%;
                    overflow: auto;
                    border-radius: 0 0 8px 8px;
                    display: none;
                  }

                  &.open > .option-container {
                    display: block;
                  }

                  > .option-container > .language-option {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 100%;
                    height: 35px;
                    border: 1px solid var(--oliver-border);
                    border-top: 0;
                    background-color: #fff;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding: 0 12px;

                    > p {
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-grey);
                      max-width: calc(100% - 16px);
                      overflow: hidden;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                      text-overflow: ellipsis;
                    }

                    &:hover {
                      background-color: var(--oliver-blue);

                      > p {
                        color: #fff;
                      }
                    }

                    &:last-child {
                      border-radius: 0 0 8px 8px;
                    }
                  }
                }
              }
            }

            button {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              background-color: transparent;
              border: 0;

              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              text-align: center;
              color: var(--oliver-base-blue);

              &:hover {
                color: var(--oliver-blue);

                &:not(:last-child) > .img-container > img {
                  -webkit-filter: invert(43%) sepia(76%) saturate(756%)
                    hue-rotate(168deg) brightness(103%) contrast(86%);
                  filter: invert(43%) sepia(76%) saturate(756%)
                    hue-rotate(168deg) brightness(103%) contrast(86%);
                }
              }

              > .img-container {
                width: 55px;
                height: 30px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-right: 15px;
              }

              &#knowledgeBaseButton > .img-container > img {
                width: 24px;
                height: 24px;
              }

              &#switchUserButton {
                margin-top: 10px;

                > .img-container > img {
                  width: 16.96px;
                  height: 17.5px;
                }
              }

              &#endSessionButton {
                color: var(--oliver-red);
                margin-top: 10px;

                > .img-container > img {
                  width: 24.06px;
                  height: 24px;
                }
              }
            }
          }
        }

        .footer {
          display: none;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .user-info-wrapper {
    position: fixed;
    left: 72px;
    top: 68px;
    width: calc(100% - 72px);
    height: calc(100% - 68px);
    background-color: rgba(123, 140, 152, 0.3);

    &.hidden {
      display: none;
    }

    > .user-info {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 350px;
      height: 457px;
      background-color: #fff;
      border-radius: 10px;
      -webkit-box-shadow: 0px 20px 30px rgba(18, 43, 81, 0.25);
      box-shadow: 0px 20px 30px rgba(18, 43, 81, 0.25);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 20px 20px 25px 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      > {
        .header {
          width: 100%;
          padding-bottom: 18px;
          border-bottom: 1px solid var(--oliver-border);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          > {
            .avatar {
              width: 60px;
              height: 60px;
              margin-right: 17px;
              font-size: 20px;

              > {
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;

                  &.default {
                    display: none;

                    &:only-of-type {
                      display: block;
                    }
                  }
                }

                .col {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                }
              }
            }

            .col > p {
              &.style1 {
                max-width: 100%;

                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                text-align: left;
                color: var(--oliver-base-blue);
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
              }

              &.style2 {
                max-width: 100%;

                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                color: var(--oliver-base-grey);
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .body {
          width: 100%;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 18px 0;

          > {
            .language-select {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-bottom: 20px;

              > {
                p {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 17px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                  margin-right: 10px;
                }

                .dropdown-input-wrapper {
                  position: relative;
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  height: 35px;
                  line-height: 0;

                  &:hover {
                    cursor: pointer;
                  }

                  > img {
                    position: absolute;
                    top: calc(50% - 7px);
                    right: 12px;
                    width: 14px;
                    height: 14px;
                  }

                  &.open > {
                    img {
                      -webkit-transform: rotate(180deg);
                      -ms-transform: rotate(180deg);
                      transform: rotate(180deg);
                      -webkit-filter: invert(43%) sepia(76%) saturate(756%)
                        hue-rotate(168deg) brightness(103%) contrast(86%);
                      filter: invert(43%) sepia(76%) saturate(756%)
                        hue-rotate(168deg) brightness(103%) contrast(86%);
                    }

                    input {
                      border-radius: 8px 8px 0 0;
                      border-color: var(--oliver-blue);
                      border-width: 2px;
                    }
                  }

                  > input {
                    width: 100%;
                    height: 100%;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border: 1px solid var(--oliver-base-grey);
                    border-radius: 8px;
                    outline: 0;
                    padding: 0 12px;

                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-grey);
                  }

                  &:hover > {
                    input:hover {
                      cursor: pointer;
                    }

                    img {
                      -webkit-filter: invert(43%) sepia(76%) saturate(756%)
                        hue-rotate(168deg) brightness(103%) contrast(86%);
                      filter: invert(43%) sepia(76%) saturate(756%)
                        hue-rotate(168deg) brightness(103%) contrast(86%);
                    }
                  }

                  > .option-container {
                    width: 100%;
                    max-height: 400%;
                    overflow: auto;
                    border-radius: 0 0 8px 8px;
                    display: none;
                  }

                  &.open > .option-container {
                    display: block;
                  }

                  > .option-container > .language-option {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 100%;
                    height: 35px;
                    border: 1px solid var(--oliver-border);
                    border-top: 0;
                    background-color: #fff;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding: 0 12px;

                    > p {
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-grey);
                      max-width: calc(100% - 16px);
                      overflow: hidden;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                      text-overflow: ellipsis;
                    }

                    &:hover {
                      background-color: var(--oliver-blue);

                      > p {
                        color: #fff;
                      }
                    }

                    &:last-child {
                      border-radius: 0 0 8px 8px;
                    }
                  }
                }
              }
            }

            button {
              width: 100%;
              height: 40px;
              margin-bottom: 8px;

              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-blue);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              background-color: transparent;
              border: 0;
              cursor: pointer;

              &:hover {
                color: var(--oliver-primary);

                &:not(:last-child) > .img-container > img {
                  -webkit-filter: invert(43%) sepia(76%) saturate(756%)
                    hue-rotate(168deg) brightness(103%) contrast(86%);
                  filter: invert(43%) sepia(76%) saturate(756%)
                    hue-rotate(168deg) brightness(103%) contrast(86%);
                }
              }

              &:last-of-type {
                margin-bottom: 0;
              }

              > .img-container {
                width: 30px;
                height: 30px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-right: 10px;
              }

              &#knowledgeBaseButton > .img-container > img {
                width: 22px;
                height: 22px;
              }

              &#switchUserButton > .img-container > img {
                width: 14px;
                height: 15px;
              }

              &#endSessionButton {
                color: var(--oliver-red);

                > .img-container > img {
                  width: 26px;
                  height: 22px;
                }
              }
            }
          }
        }

        .footer {
          width: 100%;
          padding-top: 18px;
          border-top: 1px solid var(--oliver-border);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;

          > {
            .row {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-bottom: 13px;

              > {
                img {
                  width: 19px;
                  height: 19px;
                  margin-right: 7px;
                }

                p {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }
              }
            }

            .button-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;

              > button {
                width: 64px;
                height: 64px;
                border-radius: 10px;
                overflow: hidden;
                background-color: transparent;
                border: 0;
                margin-right: 18px;

                &:last-of-type {
                  margin-right: 0;
                }

                > img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .user-info-wrapper {
    left: 3.5156vw;
    top: 3.3203vw;
    width: calc(100% - 3.5156vw);
    height: calc(100% - 3.3203vw);

    > .user-info {
      right: 0.7813vw;
      top: 0.7813vw;
      width: 17.0898vw;
      height: 22.3145vw;
      border-radius: 0.4883vw;
      -webkit-box-shadow: 0vw 0.9766vw 1.4648vw rgba(18, 43, 81, 0.25);
      box-shadow: 0vw 0.9766vw 1.4648vw rgba(18, 43, 81, 0.25);
      padding: 0.9766vw 0.9766vw 1.2207vw 0.9766vw;

      > {
        .header {
          padding-bottom: 0.8789vw;
          border-bottom: 0.0488vw solid var(--oliver-border);

          > {
            .avatar {
              width: 2.9297vw;
              height: 2.9297vw;
              margin-right: 0.8301vw;
              font-size: 0.9766vw;
            }

            .col > p {
              &.style1 {
                font-size: 0.8789vw;
                line-height: 1.2207vw;
              }

              &.style2 {
                font-size: 0.6348vw;
                line-height: 0.8789vw;
              }
            }
          }
        }

        .body {
          padding: 0.8789vw 0;

          > {
            .language-select {
              margin-bottom: 0.9766vw;

              > {
                p {
                  font-size: 0.5859vw;
                  line-height: 0.8301vw;
                  margin-right: 0.4883vw;
                }

                .dropdown-input-wrapper {
                  height: 1.709vw;

                  > img {
                    top: calc(50% - 0.3418vw);
                    right: 0.5859vw;
                    width: 0.6836vw;
                    height: 0.6836vw;
                  }

                  &.open > input {
                    border-radius: 0.3906vw 0.3906vw 0 0;
                    border-width: 0.0977vw;
                  }

                  > {
                    input {
                      border: 0.0488vw solid var(--oliver-base-grey);
                      border-radius: 0.3906vw;
                      padding: 0 0.5859vw;
                      font-size: 0.6836vw;
                      line-height: 0.9766vw;
                    }

                    .option-container {
                      border-radius: 0 0 0.3906vw 0.3906vw;

                      > .language-option {
                        height: 1.709vw;
                        border: 0.0488vw solid var(--oliver-border);
                        padding: 0 0.5859vw;

                        > p {
                          font-size: 0.6836vw;
                          line-height: 0.9766vw;
                          max-width: calc(100% - 0.7813vw);
                        }

                        &:last-child {
                          border-radius: 0 0 0.3906vw 0.3906vw;
                        }
                      }
                    }
                  }
                }
              }
            }

            button {
              height: 1.9531vw;
              margin-bottom: 0.3906vw;
              font-size: 0.6836vw;
              line-height: 0.9766vw;

              > .img-container {
                width: 1.4648vw;
                height: 1.4648vw;
                margin-right: 0.4883vw;
              }

              &#knowledgeBaseButton > .img-container > img {
                width: 1.0742vw;
                height: 1.0742vw;
              }

              &#switchUserButton > .img-container > img {
                width: 0.6836vw;
                height: 0.7324vw;
              }

              &#endSessionButton > .img-container > img {
                width: 1.2695vw;
                height: 1.0742vw;
              }
            }
          }
        }

        .footer {
          padding-top: 0.8789vw;
          border-top: 0.0488vw solid var(--oliver-border);

          > {
            .row {
              margin-bottom: 0.6348vw;

              > {
                img {
                  width: 0.9277vw;
                  height: 0.9277vw;
                  margin-right: 0.3418vw;
                }

                p {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                }
              }
            }

            .button-row > button {
              width: 3.125vw;
              height: 3.125vw;
              border-radius: 0.4883vw;
              margin-right: 0.8789vw;
            }
          }
        }
      }
    }
  }
}
