@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: min(344px, 95%);
    height: min(95%, 564px);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-grey);
        }

        label {
          margin-top: 15px;

          > {
            input {
              position: fixed;
              left: -10000px;
              opacity: 0;
            }

            .custom-radio {
              width: 285px;
              height: 45px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              background-color: #fff;
              border-radius: 8px;
              border: 1px solid var(--oliver-border);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-base-blue);
            }

            input {
              &:not(:disabled):not(:checked) + .custom-radio:hover {
                cursor: pointer;
                border: 1px solid var(--oliver-blue);
              }

              &:checked + .custom-radio {
                border: 2px solid var(--oliver-blue);
                color: var(--oliver-blue);
              }

              &:disabled + .custom-radio {
                background-color: transparent;
                color: var(--oliver-border);
              }
            }
          }
        }

        button {
          margin-top: 28px;
          width: 285px;
          height: 45px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 8px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: 344px;
    height: min(95%, 564px);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-grey);
        }

        label {
          margin-top: 15px;

          > {
            input {
              position: fixed;
              left: -10000px;
              opacity: 0;
            }

            .custom-radio {
              width: 285px;
              height: 45px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              background-color: #fff;
              border-radius: 8px;
              border: 1px solid var(--oliver-border);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-base-blue);
            }

            input {
              &:not(:disabled):not(:checked) + .custom-radio:hover {
                cursor: pointer;
                border: 1px solid var(--oliver-blue);
              }

              &:checked + .custom-radio {
                border: 2px solid var(--oliver-blue);
                color: var(--oliver-blue);
              }

              &:disabled + .custom-radio {
                background-color: transparent;
                color: var(--oliver-border);
              }
            }
          }
        }

        button {
          margin-top: 28px;
          width: 285px;
          height: 45px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 8px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.update-transaction-status {
    width: 16.7969vw;
    height: min(95%, 27.5391vw);

    > .subwindow-body > {
      p {
        font-size: 0.6836vw;
        line-height: 0.8789vw;
      }

      label {
        margin-top: 0.7324vw;

        > {
          input {
            left: -488.2813vw;
          }

          .custom-radio {
            width: 13.916vw;
            height: 2.1973vw;
            border-radius: 0.3906vw;
            border: 0.0488vw solid var(--oliver-border);
            font-size: 0.7813vw;
            line-height: 1.0254vw;
          }

          input {
            &:not(:disabled):not(:checked) + .custom-radio:hover {
              border: 0.0488vw solid var(--oliver-blue);
            }

            &:checked + .custom-radio {
              border: 0.0977vw solid var(--oliver-blue);
            }
          }
        }
      }

      button {
        margin-top: 1.3672vw;
        width: 13.916vw;
        height: 2.1973vw;
        border-radius: 0.3906vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
