@media (orientation: portrait) {
  .subwindow.edit-order-status {
    width: min(344px, 95%);
    height: min(564px, 95%);

    > .subwindow-body > div.order-status-options {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 15px;

      > button {
        width: 285px;
        height: 45px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid var(--oliver-blue);
        border-radius: 6px;
        background-color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: var(--oliver-blue);

        &.button-update-order-status {
          width: 285px;
          height: 44px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          margin-top: 13px;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow.edit-order-status {
    width: min(344px, 95%);
    height: min(564px, 95%);

    > .subwindow-body > div.order-status-options {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 15px;

      > button {
        width: 285px;
        height: 45px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid var(--oliver-blue);
        border-radius: 6px;
        background-color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: var(--oliver-blue);

        &.button-update-order-status {
          width: 285px;
          height: 44px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          margin-top: 13px;
          color: #fff;
        }
      }
    }
  }
}
