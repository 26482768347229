/* Version 0.9.18 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Mono:wght@300;400;500;600;700&display=swap");

@import "./styles/defaults.scss";
@import "./styles/navbar.scss";
@import "./styles/homePage.scss";
@import "./styles/login.scss";
@import "./styles/notifications.scss";
@import "./styles/choose.scss";
@import "./styles/loadingPage.scss";
@import "./styles/subwindow.scss";
@import "./styles/transactions.scss";
@import "./styles/transactionsDetailed.scss";
@import "./styles/transactionsList.scss";
@import "./styles/transactionsSearch.scss";
@import "./styles/product.scss";
@import "./styles/openRegister.scss";
@import "./styles/idleRegister.scss";
@import "./styles/closeRegister.scss";
@import "./styles/saleComplete.scss";
@import "./styles/checkout.scss";
@import "./styles/createUserId.scss";
@import "./styles/refundComplete.scss";
@import "./styles/appLauncher.scss";
@import "./styles/cart.scss";
@import "./styles/refund.scss";
@import "./styles/linkLauncher.scss";
@import "./styles/userInfo.scss";
@import "./styles/customerView.scss";
@import "./styles/pageOptions.scss";
@import "./styles/cashManagement.scss";
@import "./styles/error404.scss";
@import "./styles/mobileHeader.scss";
@import "./styles/cashManagementDetailed.scss";
@import "./styles/customerViewDetailed.scss";
@import "./styles/customerViewSearch.scss";
@import "./styles/customerViewList.scss";
@import "./styles/cashManagementList.scss";
@import "./styles/index.scss";
@import "./styles/keyframes.scss";
@import "./styles/avatar.scss";
@import "./styles/logoutConfirmation.scss";

/*Setting on dashboard checkout button is going down, some height issue */
#root {
  width: 100%;
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: fixed;
  top: 25px;
}

.test-row > button {
  padding: 5px;
}

.auto-margin-top {
  margin-bottom: auto;
}

.auto-margin-bottom {
  margin-top: auto;
}

@media (orientation: portrait) {
  .mobile-homepage-footer {
    width: 100%;
    height: 89px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-homepage-footer > button {
    width: 91.4667%;
    height: 54px;
    border-radius: 8px;
    background-color: var(--oliver-blue);
    border: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }
} 

@media (orientation: landscape) {
  .mobile-homepage-footer {
    display: none;
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .nav-cover {
    left: 12.9883vw;
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .nav-cover {
    left: 22.1667vw;
  }
}
