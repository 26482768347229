@media (orientation: portrait) {
  .product-wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - 144px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;

    > {
      .header {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        z-index: 2;

        > {
          .mobile-buttons {
            width: 100%;
            height: 281px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 10px 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            > button {
              width: 34px;
              height: 34px;
              background-color: rgba(209, 236, 255, 0.5);
              border: 0;
              border-radius: 8px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              &#mobileExitProductButton > img {
                width: 12px;
                height: 12px;
              }

              &#mobileAppsButton > img {
                width: 18.62px;
                height: 18.62px;
              }
            }
          }

          .main {
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 26px 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            > {
              p {
                &.route {
                  font-size: 13px;
                  font-weight: 500;
                  line-height: 13px;
                  text-align: left;
                  color: var(--oliver-blue);
                  margin-bottom: 12px;
                }

                &.prod-name {
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }
              }

              button#desktopExitProductButton {
                display: none;
              }
            }
          }
        }
      }

      .mod-product {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0 7px 16px;
        position: relative;
      }
    }

    &.no-edit {
      > .mod-product {
        padding-top: 152px;
      }

      > .mod-product > .no-edit-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        width: calc(100% + 16px);
        height: 100%;
        background-color: rgba(241, 244, 246, 0.3);
        left: -16px;
        top: 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        > .inner-container {
          width: min(calc(100% - 32px), 343px);
          height: 128px;
          background-color: #f1f4f6;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          border-radius: 8px;
          margin-top: 8px;
          overflow: auto;

          > p {
            &.style1 {
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
              text-align: center;
              color: var(--oliver-base-blue);
              margin-bottom: 20px;
            }

            &.style2 {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: center;
              color: var(--oliver-base-blue);
            }
          }
        }
      }
    }

    > .mod-product > .no-edit-item {
      display: none;
    }

    > {
      .mod-product > {
        .row > button,
        .img-container {
          display: none;
        }

        .row > p {
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 12px;
        }

        p {
          font-size: 13px;
          font-weight: 600;
          line-height: 13px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 8px;

          &.error::after {
            content: "*Select a variation";

            font-size: 13px;
            font-weight: 400;
            line-height: 13px;
            text-align: left;
            color: var(--error);
            margin-left: 10px;
          }
        }

        .radio-group {
          width: calc(100% + 16px);
          margin-left: -16px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          overflow-x: scroll;
          -ms-overflow-style: none;

          /* IE and Edge */
          scrollbar-width: none;

          /* Firefox */
          margin-bottom: 28px;

          &::-webkit-scrollbar {
            display: none;
          }

          > label {
            margin-right: 16px;

            &:nth-child(1) {
              margin-left: 16px;
            }

            > {
              input {
                opacity: 0;
                position: fixed;
                left: -10000px;
              }

              .custom-radio {
                padding: 14px;
                border: 1.5px solid var(--oliver-border);
                border-radius: 10px;

                > p {
                  font-size: 13px;
                  font-weight: 600;
                  line-height: 13px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  max-width: 300px;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }

              input:checked ~ .custom-radio {
                border: 2px solid var(--oliver-blue);

                > p {
                  color: var(--oliver-blue);
                }
              }
            }
          }

          &.error > label > .custom-radio {
            border-color: var(--error);
          }
        }

        input {
          width: min(300px, 100%);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 16px;
          height: 40px;
          border: 1px solid var(--oliver-border);
          border-radius: 8px;

          font-size: 13px;
          font-weight: 400;
          line-height: 13px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 28px;

          &::-webkit-input-placeholder {
            color: var(--oliver-borders);
          }

          &::-moz-placeholder {
            color: var(--oliver-borders);
          }

          &:-ms-input-placeholder {
            color: var(--oliver-borders);
          }

          &::-ms-input-placeholder {
            color: var(--oliver-borders);
          }

          &::placeholder {
            color: var(--oliver-borders);
          }

          &:focus {
            border: 2px solid var(--oliver-blue);
          }
        }

        .increment-input {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 11px;
          margin-bottom: 28px;

          > {
            button {
              width: 40px;
              height: 40px;
              border: 0;
              border-radius: 8px;
              background-color: #ddf1ff;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > img {
                width: 16px;
                height: 30px;
              }
            }

            input {
              width: 80px !important;
              height: 40px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;

              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              text-align: center;
              color: var(--oliver-base-blue);
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content;
              border: 1px solid var(--oliver-border);
              border-radius: 8px;
            }
          }
        }
      }

      .detailed-body {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      .detailed-product > {
        .row {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          padding: 30px 0;
          margin: 0 16px;
          border-top: 1px solid var(--oliver-border);
          border-bottom: 1px solid var(--oliver-border);

          > .col > {
            p.mobile-only {
              font-size: 14px;
              font-weight: 600;
              line-height: 14px;
              text-align: left;
              color: var(--oliver-base-blue);
              margin-bottom: 8px;
            }

            .group {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              margin-bottom: 20px;

              > {
                .text-row {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;

                  > p {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 13px;
                    text-align: left;
                    color: var(--oliver-base-grey);

                    &:first-of-type {
                      margin-right: 4px;
                    }
                  }
                }

                button {
                  margin-top: -4px;
                  background-color: transparent;
                  border: 0;
                  padding: 4px 2px;

                  font-size: 13px;
                  font-weight: 500;
                  line-height: 13px;
                  text-align: left;
                  color: var(--oliver-blue);
                  text-decoration: underline;

                  &:disabled {
                    color: var(--oliver-border);
                  }
                }

                .desktop-only {
                  display: none;
                }
              }
            }

            button {
              width: 151px;
              height: 35px;
              background-color: #fff;
              border: 1px solid var(--oliver-blue);
              border-radius: 8px;

              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: center;
              color: var(--oliver-blue);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }
            }
          }
        }

        .col {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          margin: 0 16px;
          padding: 30px 0;
          border-bottom: 1px solid var(--oliver-border);

          > p {
            &.title {
              font-size: 14px;
              font-weight: 600;
              line-height: 14px;
              text-align: left;
              color: var(--oliver-base-blue);
              margin-bottom: 7px;
            }

            &.para {
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;
              text-align: left;
              color: var(--oliver-base-grey);
              margin-bottom: 21px;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      .product-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 144px;
        background-color: #fff;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px;
        border-top: 1px solid var(--oliver-border);

        > .row {
          &:nth-child(1) {
            position: absolute;
            width: calc(100% - 32px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }

          > {
            button {
              &#addProductNote {
                width: calc(100% - 160px);
                height: 40px;
                background-color: #fff;
                border: 1px solid var(--oliver-blue);
                border-radius: 8px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                font-size: 12px;
                font-weight: 500;
                line-height: 12px;
                text-align: center;
                color: var(--oliver-blue);

                > img {
                  width: 15px;
                  height: 15px;
                  margin-right: 10px;
                  -webkit-filter: brightness(0) saturate(100%) invert(50%)
                    sepia(43%) saturate(2356%) hue-rotate(180deg)
                    brightness(95%) contrast(91%);
                  filter: brightness(0) saturate(100%) invert(50%) sepia(43%)
                    saturate(2356%) hue-rotate(180deg) brightness(95%)
                    contrast(91%);
                }
              }

              &#addProductDiscount {
                display: none;
              }

              &#addProductToCart {
                position: absolute;
                bottom: 16px;
                width: calc(100% - 32px);
                height: 55px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                background-color: var(--oliver-blue);
                border: 0;
                border-radius: 8px;

                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: #fff;
              }
            }

            .increment-input {
              position: absolute;
              right: 16px;
              width: 140px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;

              > {
                button {
                  width: 40px;
                  height: 40px;
                  background-color: #edf1f4;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  border: 0;
                  border-radius: 8px;

                  &:first-of-type > img {
                    width: 14px;
                    height: 30px;
                  }

                  &:last-of-type > img {
                    width: 16px;
                    height: 30px;
                  }
                }

                input {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  border: 0;
                  outline: 0;

                  font-size: 18px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                  border: 0.0833vw solid var(--oliver-border);
                  border-radius: 8px;
                  margin: 0 5px;
                }
              }
            }

            button#addProductToCart > img {
              width: 29px;
              height: 29px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .product-image-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 281px;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      > img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;

        &.no-image {
          display: none;
          width: 100%;
          height: 100%;

          &:only-child {
            display: block;
          }
        }
      }
    }

    > .recommended-upsells {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 22px 0;

      > {
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin: 0 0 10px 16px;
        }

        .button-row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          overflow-x: scroll;
          -ms-overflow-style: none;

          /* IE and Edge */
          scrollbar-width: none;

          /* Firefox */

          &::-webkit-scrollbar {
            display: none;
          }

          > button {
            width: 129px;
            height: 129px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            background-color: transparent;
            border: 1px solid var(--oliver-border);
            border-radius: 8px;
            overflow: hidden;
            margin-right: 22px;

            &:first-of-type {
              margin-left: 16px;
            }

            &:last-of-type {
              margin-right: 16px;
            }

            > {
              .img-container {
                width: 100%;
                height: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                overflow: hidden;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                > img {
                  width: 100%;
                  height: 100%;
                  -o-object-fit: cover;
                  object-fit: cover;
                }
              }

              .prod-name {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 100%;
                height: 25px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                border-top: 1px solid var(--oliver-border);

                > p {
                  max-width: 90%;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;

                  font-size: 13px;
                  font-weight: 400;
                  line-height: 19px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .product-wrapper {
    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 72px 11px auto 11px 40vw;
    grid-template-columns: 72px auto 40vw;
    -ms-grid-rows: 68px 11px auto 11px 160px;
    grid-template-rows: 68px auto 160px;
    grid-template-areas: "navbar header header" "navbar mod-product detailed-product" "navbar recommended-upsells product-footer";

    > {
      .navbar {
        grid-area: navbar;
      }

      .header {
        grid-area: header;
      }

      .mod-product {
        grid-area: mod-product;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        border-left: 1px solid var(--oliver-border);
      }

      .detailed-product {
        grid-area: detailed-product;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        border-left: 1px solid var(--oliver-border);
      }

      .recommended-upsells {
        grid-area: recommended-upsells;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        border-left: 1px solid var(--oliver-border);
      }

      .product-footer {
        grid-area: product-footer;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        border-left: 1px solid var(--oliver-border);
      }

      .header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > {
          .mobile-buttons {
            display: none;
          }

          .main {
            width: 100%;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              p {
                &.route {
                  display: none;
                }

                &.prod-name {
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 29px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  margin-right: auto;
                }
              }

              button#desktopExitProductButton {
                width: 46px;
                height: 46px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                background-color: transparent;
                border: 0;
                margin-right: 14px;

                > img {
                  width: 19.17px;
                  height: 19.17px;
                }
              }
            }
          }
        }
      }

      .mod-product {
        width: 100%;
        height: 100%;
        background-color: #f8fafc;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 20px 20px 20px;
        overflow: auto;
        position: relative;
      }
    }

    &.no-edit {
      > .mod-product {
        overflow: hidden;
      }

      > .mod-product > .no-edit-item {
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        position: absolute;
        background-color: rgba(241, 244, 246, 0.5);
        left: -20px;
        top: -20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
            z-index:1;
      }
    }

    > .mod-product {
      > .img-container {
        display: none;
        width: 100%;
        height: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        > {
          img {
            &:first-of-type {
              width: 318px;
              height: 177px;
            }

            &:last-of-type {
              width: 204px;
              height: 85px;
              margin-bottom: 38px;
            }
          }

          button {
            width: 167px;
            height: 37px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid var(--oliver-blue);
            border-radius: 6px;
            background-color: #fff;

            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-blue);
          }
        }
      }

      &.show-image > .img-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 60px 0;

        &:only-child {
          height: 100% !important;
          padding: 0 !important;
        }
      }

      > {
        .img-container {
          &.no-var > {
            img:last-of-type,
            button {
              display: none;
            }
          }

          &.no-stock > {
            img {
              &:first-of-type {
                display: none;
              }

              &:last-of-type {
                display: block;
              }
            }

            button {
              display: block;
            }
          }
        }

        img:only-child {
          display: block;
        }

        .no-edit-item {
          display: none;
        }
      }
    }

    > {
      .mod-product > {
        .no-edit-item > .inner-container {
          width: min(400px, 95%);
          height: min(225px, 95%);
          background-color: #fff;
          -webkit-box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          overflow: auto;

          > p {
            &.style1 {
              font-size: 16px;
              font-weight: 600;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-base-blue);
              margin-bottom: 20px;
            }

            &.style2 {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              text-align: center;
              color: var(--oliver-base-blue);
            }
          }
        }

        .row {
          width: 100%;
          height: 38px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 6px;

          > {
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              text-align: left;
              color: var(--oliver-base-grey);
            }

            button {
              padding: 10px;
              background-color: transparent;
              border: 0;

              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              text-align: center;
              color: var(--oliver-blue);
            }
          }
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 9px;

          &.error::after {
            content: "*Please select a variant";

            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            color: var(--error);
            margin-left: 10px;
          }
        }

        .radio-group {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;

          /* align-content: flex-start; */
          margin-bottom: 5px;

          > label {
            max-width: 100%;
            margin: 0 10px 10px 0;

            &:hover {
              cursor: pointer;
            }

            > {
              input[type="radio"] {
                position: fixed;
                opacity: 0;
                left: -10000px;
              }

              .custom-radio {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 18px;
                border: 1px solid var(--oliver-border);
                border-radius: 8px;
                background-color: #fff;

                > p {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  max-width: 100%;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
              }

              input[type="radio"]:checked ~ .custom-radio {
                border: 2px solid var(--oliver-blue);

                > p {
                  color: var(--oliver-blue);
                }
              }
            }
          }

          + .row {
            margin-top: 40px;
          }

          &.error > label > .custom-radio {
            border-color: var(--error);
          }
        }

        input {
          width: min(300px, 100%);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 16px 18px;
          border: 1px solid var(--oliver-border);
          border-radius: 8px;
          margin-bottom: 15px;

          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
          color: var(--oliver-base-blue);

          &::-webkit-input-placeholder {
            color: var(--oliver-borders);
          }

          &::-moz-placeholder {
            color: var(--oliver-borders);
          }

          &:-ms-input-placeholder {
            color: var(--oliver-borders);
          }

          &::-ms-input-placeholder {
            color: var(--oliver-borders);
          }

          &::placeholder {
            color: var(--oliver-borders);
          }

          &:focus {
            border: 2px solid var(--oliver-blue);
          }
        }

        .increment-input {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 11px;

          > {
            button {
              width: 40px;
              height: 40px;
              border: 0;
              border-radius: 8px;
              background-color: #ddf1ff;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > img {
                width: 16px;
                height: 30px;
              }
            }

            input {
              width: 80px !important;
              height: 40px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;

              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              text-align: center;
              color: var(--oliver-base-blue);
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content;
              border: 1px solid var(--oliver-border);
              border-radius: 8px;
            }
          }
        }
      }

      .detailed-product {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        overflow: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 20px 0 20px;

        > {
          .row {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 23px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              .product-image-container {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                aspect-ratio: 1/1;
                border-radius: 8px;
                overflow: hidden;
                margin-right: 20px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                > img {
                  width: 100%;
                  height: 100%;
                  -o-object-fit: cover;
                  object-fit: cover;

                  &.no-image {
                    display: none;
                    width: 100%;
                    height: 100%;

                    &:only-child {
                      display: block;
                    }
                  }
                }
              }

              .col {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                > button#addProductDiscountMobile,
                p.mobile-only {
                  display: none;
                }

                > .group > {
                  .text-row > p.quantity {
                    font-size: 48px;
                    font-weight: 500;
                    line-height: 67px;
                    text-align: center;
                    color: var(--oliver-base-blue);
                  }

                  p.desktop-only {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
                    color: var(--oliver-base-blue);
                    margin-bottom: 21px;
                  }

                  button {
                    width: 167px;
                    height: 37px;
                    background-color: #fff;
                    border: 1px solid var(--oliver-blue);
                    border-radius: 8px;

                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: center;
                    color: var(--oliver-blue);

                    &:disabled {
                      border-color: var(--oliver-border) !important;
                      color: var(--oliver-border) !important;
                    }
                  }
                }
              }
            }
          }

          .col {
            width: 100%;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            > p {
              &.title {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 4px;
              }

              &.para {
                font-size: 13px;
                font-weight: 400;
                line-height: 17px;
                text-align: left;
                color: var(--oliver-base-grey);
                margin-bottom: 20px;
              }
            }
          }
        }
      }

      .recommended-upsells {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-top: 1px solid var(--oliver-border);
        background-color: #f8fafc;

        > {
          p {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 10px;
          }

          .button-row {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            > button {
              width: 103px;
              height: 103px;
              background-color: #fff;
              border: 1px solid var(--oliver-border);
              border-radius: 8px;
              overflow: hidden;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              margin-right: 16px;

              &:last-of-type {
                margin-right: 0;
              }

              > {
                .img-container {
                  width: 100%;
                  height: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  overflow: hidden;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  > img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                  }
                }

                .prod-name {
                  width: 100%;
                  height: 22px;
                  border-top: 1px solid var(--oliver-border);
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  > p {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 15px;
                    text-align: center;
                    color: var(--oliver-base-blue);
                    max-width: 90%;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
      }

      .product-footer {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > .row {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 3.2193%;
          width: 100%;
          height: 87px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          &:nth-child(1) {
            width: 100%;
            height: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            border-bottom: 1px solid var(--oliver-border);
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }

          > {
            button {
              &#addProductNote {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                height: 55.5556%;
                margin-right: 3.2193%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid var(--oliver-blue);
                border-radius: 8px;

                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                text-align: center;
                color: var(--oliver-blue);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                > img {
                  width: 14px;
                  height: 15px;
                  margin-right: 10px;
                  -webkit-filter: brightness(0) saturate(100%) invert(50%)
                    sepia(43%) saturate(2356%) hue-rotate(180deg)
                    brightness(95%) contrast(91%);
                  filter: brightness(0) saturate(100%) invert(50%) sepia(43%)
                    saturate(2356%) hue-rotate(180deg) brightness(95%)
                    contrast(91%);
                }
              }

              &#addProductDiscount {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                height: 55.5556%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid var(--oliver-blue);
                border-radius: 8px;

                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                color: var(--oliver-blue);
              }
            }

            .increment-input {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              width: 142px;
              margin-right: 23px;

              > {
                button {
                  width: 40px;
                  height: 40px;
                  background-color: #ddf1ff;
                  border: 0;
                  border-radius: 8px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  &:nth-child(1) > img {
                    width: 14px;
                    height: 30px;
                  }

                  &:nth-child(3) > img {
                    width: 16px;
                    height: 30px;
                  }
                }

                input {
                  width: 0;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1;
                  height: 40px;
                  border: 0;
                  outline: 0;

                  font-size: 18px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                  border: 0.0833vw solid var(--oliver-border);
                  border-radius: 8px;
                  margin: 0 5px;
                }
              }
            }

            button#addProductToCart {
              width: 0;
              -webkit-box-flex: 1;
              -ms-flex-positive: 1;
              flex-grow: 1;
              height: 55px;
              background-color: var(--oliver-blue);
              border: 0;
              border-radius: 8px;

              font-size: 18px;
              font-weight: 500;
              line-height: 24px;
              text-align: center;
              color: #fff;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              &:disabled {
                background-color: var(--oliver-disabled);
              }

              > img {
                width: 29px;
                height: 29px;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .navbar {
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    -ms-grid-column: 1;
  }
  .header {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
  }
  .mod-product {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  .detailed-product {
    -ms-grid-row: 3;
    -ms-grid-column: 5;
  }
  .recommended-upsells {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
  .product-footer {
    -ms-grid-row: 5;
    -ms-grid-column: 5;
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .product-wrapper {
    -ms-grid-columns: 3.5156vw 0.5371vw auto 0.5371vw 40vw;
    grid-template-columns: 3.5156vw auto 40vw;
    -ms-grid-rows: 3.3203vw 0.5371vw auto 0.5371vw 7.8125vw;
    grid-template-rows: 3.3203vw auto 7.8125vw;

    > {
      .mod-product,
      .detailed-product,
      .recommended-upsells,
      .product-footer {
        border-top: 0.0488vw solid var(--oliver-border);
        border-left: 0.0488vw solid var(--oliver-border);
      }

      .header > .main > {
        p.prod-name {
          font-size: 0.9766vw;
          line-height: 1.416vw;
        }

        button#desktopExitProductButton {
          width: 2.2461vw;
          height: 2.2461vw;
          margin-right: 0.6836vw;

          > img {
            width: 0.936vw;
            height: 0.936vw;
          }
        }
      }

      .mod-product {
        padding: 0.9766vw 0.9766vw 0.9766vw 0.9766vw;

        > .img-container > img {
          &:first-of-type {
            width: 15.5273vw;
            height: 8.6426vw;
          }

          &:last-of-type {
            width: 9.9609vw;
            height: 4.1504vw;
            margin-bottom: 1.8555vw;
          }
        }

        &.show-image > .img-container {
          padding: 2.9297vw 0;
        }

        > {
          .img-container > button {
            width: 8.1543vw;
            height: 1.8066vw;
            border: 0.0488vw solid var(--oliver-blue);
            border-radius: 0.293vw;
            font-size: 0.6836vw;
            line-height: 1.0254vw;
          }

          .no-edit-item {
            width: calc(100% + 0.9766vw);
            height: calc(100% + 0.9766vw);
            left: -0.9766vw;
            top: -0.9766vw;

            > .inner-container {
              width: min(19.5313vw, 95%);
              height: min(10.9863vw, 95%);
              -webkit-box-shadow: 0vw 0.7324vw 1.9531vw rgba(0, 0, 0, 0.1);
              box-shadow: 0vw 0.7324vw 1.9531vw rgba(0, 0, 0, 0.1);
              border-radius: 0.3906vw;

              > p {
                &.style1 {
                  font-size: 0.7813vw;
                  line-height: 1.0254vw;
                  margin-bottom: 0.9766vw;
                }

                &.style2 {
                  font-size: 0.6836vw;
                  line-height: 0.8789vw;
                }
              }
            }
          }

          .row {
            height: 1.8555vw;
            margin-bottom: 0.293vw;

            > {
              p {
                font-size: 0.7813vw;
                line-height: 1.0254vw;
              }

              button {
                padding: 0.4883vw;
                font-size: 0.6836vw;
                line-height: 0.8789vw;
              }
            }
          }

          p {
            font-size: 0.6836vw;
            line-height: 1.0254vw;
            margin-bottom: 0.4395vw;

            &.error::after {
              font-size: 0.6836vw;
              line-height: 0.6836vw;
              margin-left: 0.4883vw;
            }
          }

          .radio-group {
            margin-bottom: 0.2441vw;

            > label {
              margin: 0 0.4883vw 0.4883vw 0;

              > {
                input[type="radio"] {
                  left: -488.2813vw;
                }

                .custom-radio {
                  padding: 0.8789vw;
                  border: 0.0488vw solid var(--oliver-border);
                  border-radius: 0.3906vw;

                  > p {
                    font-size: 0.6836vw;
                    line-height: 0.8789vw;
                  }
                }

                input[type="radio"]:checked ~ .custom-radio {
                  border: 0.0977vw solid var(--oliver-blue);
                }
              }
            }

            + .row {
              margin-top: 1.9531vw;
            }
          }

          input {
            width: min(14.6484vw, 100%);
            padding: 0.7813vw 0.8789vw;
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.3906vw;
            margin-bottom: 0.7324vw;
            font-size: 0.6836vw;
            line-height: 0.6836vw;

            &:focus {
              border: 0.0977vw solid var(--oliver-blue);
            }
          }

          .increment-input {
            gap: 0.5371vw;

            > {
              button {
                width: 1.9531vw;
                height: 1.9531vw;
                border-radius: 0.3906vw;

                > img {
                  width: 0.7813vw;
                  height: 1.4648vw;
                }
              }

              input {
                width: 3.9063vw !important;
                height: 1.9531vw;
                font-size: 0.6836vw;
                line-height: 0.8789vw;
                border: 0.0488vw solid var(--oliver-border);
                border-radius: 0.3906vw;
              }
            }
          }
        }
      }

      .detailed-product {
        padding: 0.9766vw 0.9766vw 0 0.9766vw;

        > {
          .row {
            margin-bottom: 1.123vw;

            > {
              .product-image-container {
                border-radius: 0.3906vw;
                margin-right: 0.9766vw;
              }

              .col > .group > {
                .text-row > p.quantity {
                  font-size: 2.3438vw;
                  line-height: 3.2715vw;
                }

                p.desktop-only {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                  margin-bottom: 1.0254vw;
                }

                button {
                  width: 8.1543vw;
                  height: 1.8066vw;
                  border: 0.0488vw solid var(--oliver-blue);
                  border-radius: 0.3906vw;
                  font-size: 0.6836vw;
                  line-height: 1.0254vw;
                }
              }
            }
          }

          .col > p {
            &.title {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
              margin-bottom: 0.1953vw;
            }

            &.para {
              font-size: 0.6348vw;
              line-height: 0.8301vw;
              margin-bottom: 0.9766vw;
            }
          }
        }
      }

      .recommended-upsells {
        border-top: 0.0488vw solid var(--oliver-border);

        > {
          p {
            font-size: 0.6836vw;
            line-height: 0.9766vw;
            margin-bottom: 0.4883vw;
          }

          .button-row > button {
            width: 5.0293vw;
            height: 5.0293vw;
            border: 0.0488vw solid var(--oliver-border);
            border-radius: 0.3906vw;
            margin-right: 0.7813vw;

            > .prod-name {
              height: 1.0742vw;
              border-top: 0.0488vw solid var(--oliver-border);

              > p {
                font-size: 0.4883vw;
                line-height: 0.7324vw;
              }
            }
          }
        }
      }

      .product-footer > .row {
        height: 4.248vw;

        &:nth-child(1) {
          border-bottom: 0.0488vw solid var(--oliver-border);
        }

        > {
          button {
            &#addProductNote {
              border: 0.0488vw solid var(--oliver-blue);
              border-radius: 0.3906vw;
              font-size: 0.6836vw;
              line-height: 0.6836vw;

              > img {
                width: 0.6836vw;
                height: 0.7324vw;
                margin-right: 0.4883vw;
              }
            }

            &#addProductDiscount {
              border: 0.0488vw solid var(--oliver-blue);
              border-radius: 0.3906vw;
              font-size: 0.6836vw;
              line-height: 0.9766vw;
            }
          }

          .increment-input {
            width: 6.9336vw;
            margin-right: 1.123vw;

            > {
              button {
                width: 1.9531vw;
                height: 1.9531vw;
                border-radius: 0.3906vw;

                &:nth-child(1) > img {
                  width: 0.6836vw;
                  height: 1.4648vw;
                }

                &:nth-child(3) > img {
                  width: 0.7813vw;
                  height: 1.4648vw;
                }
              }

              input {
                height: 1.9531vw;
                font-size: 0.8789vw;
                line-height: 1.1719vw;
              }
            }
          }

          button#addProductToCart {
            height: 2.6855vw;
            border-radius: 0.3906vw;
            font-size: 0.8789vw;
            line-height: 1.1719vw;

            > img {
              width: 1.416vw;
              height: 1.416vw;
              margin-right: 0.3906vw;
            }
          }
        }
      }
    }
  }
  .product-wrapper > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .product-wrapper > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .product-wrapper > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }
  .product-wrapper > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .product-wrapper > *:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  .product-wrapper > *:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 5;
  }
  .product-wrapper > *:nth-child(7) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
  .product-wrapper > *:nth-child(8) {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
  .product-wrapper > *:nth-child(9) {
    -ms-grid-row: 5;
    -ms-grid-column: 5;
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .product-wrapper {
    > {
      .mod-product,
      .detailed-product,
      .recommended-upsells,
      .product-footer {
        border-top: 0.0833vw solid var(--oliver-border);
        border-left: 0.0833vw solid var(--oliver-border);
      }

      .header > .main > {
        p.prod-name {
          font-size: 1.6667vw;
          line-height: 2.4167vw;
        }

        button#desktopExitProductButton {
          width: 3.8333vw;
          height: 3.8333vw;
          margin-right: 1.1667vw;

          > img {
            width: 1.5975vw;
            height: 1.5975vw;
          }
        }
      }

      .detailed-product {
        padding: 1.6667vw 1.6667vw 0 1.6667vw;

        > {
          .row {
            margin-bottom: 1.9167vw;

            > {
              .product-image-container {
                border-radius: 0.6667vw;
                margin-right: 1.6667vw;
              }

              .col > .group > {
                .text-row > p.quantity {
                  font-size: 4vw;
                  line-height: 5.5833vw;
                }

                p.desktop-only {
                  font-size: 1.1667vw;
                  line-height: 1.6667vw;
                  margin-bottom: 1.75vw;
                }

                button {
                  width: 13.9167vw;
                  height: 3.0833vw;
                  border: 0.0833vw solid var(--oliver-blue);
                  border-radius: 0.6667vw;
                  font-size: 1.1667vw;
                  line-height: 1.75vw;
                }
              }
            }
          }

          .col > p {
            &.title {
              font-size: 1.1667vw;
              line-height: 1.6667vw;
              margin-bottom: 0.3333vw;
            }

            &.para {
              font-size: 1.0833vw;
              line-height: 1.4167vw;
              margin-bottom: 1.6667vw;
            }
          }
        }
      }

      .recommended-upsells {
        border-top: 0.0833vw solid var(--oliver-border);

        > {
          p {
            font-size: 1.1667vw;
            line-height: 1.6667vw;
            margin-bottom: 0.8333vw;
          }

          .button-row > button {
            width: 8.5833vw;
            height: 8.5833vw;
            border: 0.0833vw solid var(--oliver-border);
            border-radius: 0.6667vw;
            margin-right: 1.3333vw;

            > .prod-name {
              height: 1.8333vw;
              border-top: 0.0833vw solid var(--oliver-border);

              > p {
                font-size: 0.8333vw;
                line-height: 1.25vw;
              }
            }
          }
        }
      }

      .product-footer > .row {
        height: 7.25vw;

        &:nth-child(1) {
          border-bottom: 0.0833vw solid var(--oliver-border);
        }

        > {
          button {
            &#addProductNote {
              border: 0.0833vw solid var(--oliver-blue);
              border-radius: 0.6667vw;
              font-size: 1.1667vw;
              line-height: 1.1667vw;

              > img {
                width: 1.1667vw;
                height: 1.25vw;
                margin-right: 0.8333vw;
              }
            }

            &#addProductDiscount {
              border: 0.0833vw solid var(--oliver-blue);
              border-radius: 0.6667vw;
              font-size: 1.1667vw;
              line-height: 1.6667vw;
            }
          }

          .increment-input {
            width: 11.8333vw;
            margin-right: 1.9167vw;

            > {
              button {
                width: 3.3333vw;
                height: 3.3333vw;
                border-radius: 0.6667vw;

                &:nth-child(1) > img {
                  width: 1.1667vw;
                  height: 2.5vw;
                }

                &:nth-child(3) > img {
                  width: 1.3333vw;
                  height: 2.5vw;
                }
              }

              input {
                height: 3.3333vw;
                font-size: 1.5vw;
                line-height: 2vw;
                border-radius: 0.6667vw;
              }
            }
          }

          button#addProductToCart {
            height: 4.5833vw;
            border-radius: 0.6667vw;
            font-size: 1.5vw;
            line-height: 2vw;

            > img {
              width: 2.4167vw;
              height: 2.4167vw;
              margin-right: 0.6667vw;
            }
          }
        }
      }
    }
  }
  .product-wrapper > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .product-wrapper > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .product-wrapper > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }
  .product-wrapper > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .product-wrapper > *:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  .product-wrapper > *:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 5;
  }
  .product-wrapper > *:nth-child(7) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
  .product-wrapper > *:nth-child(8) {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
  .product-wrapper > *:nth-child(9) {
    -ms-grid-row: 5;
    -ms-grid-column: 5;
  }
}
