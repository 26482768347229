@media (orientation: portrait) {
  .choose-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-bg-grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    > {
      .choose-header {
        width: 100%;
        height: 64px;
        background-color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 20px;

        > {
          button {
            width: 109px;
            height: 40px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            background-color: #fff;
            border: 1px solid var(--oliver-blue);
            border-radius: 6px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-blue);

            /* margin-right: auto; */

            > img {
              width: 7.1px;
              height: 14.2px;
              margin-right: 14.9px;
            }
          }

          p {
            width: 0;
            -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                    flex-grow: 1;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--oliver-base-grey);
            text-align: right;
            padding-left: 20px;
            white-space: nowrap;
            overflow: hidden;
            -o-text-overflow: ellipsis;
               text-overflow: ellipsis;
          }
        }
      }

      .choose-body-default {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        overflow-y: scroll;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding-top: 14px;

        button {
          &:disabled {
            &::after {
              content: "";
              background-image: url("../assets/images/svg/LockIcon.svg");
              background-size: 24px 24px;
              width: 24px;
              height: 24px;
              right: 17px;
            }

            > {
              img {
                display: none;
              }

              .img-container {
                background-color: var(--oliver-border) !important;
              }

              .col > p {
                &.style1 {
                  color: #b0bec9 !important;
                }

                &.style2 {
                  color: var(--oliver-border) !important;
                }
              }
            }
          }

          width: 100%;
          height: 92px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          padding: 0 23.9px 0 17px;
          margin-bottom: 22px;
          background-color: #fff;
          border: 1px solid var(--oliver-border);
          border-radius: 8px;

          > {
            .img-container {
              width: 56px;
              height: 56px;
              border-radius: 50%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              -webkit-box-pack: center;
                  -ms-flex-pack: center;
                      justify-content: center;
              overflow: hidden;
              margin-right: 18px;

              > img {
                width: 100%;
                height: 100%;

                &.oliver-icon {
                  width: 28px;
                  height: 28px;
                  -webkit-transform: translateX(1px);
                      -ms-transform: translateX(1px);
                          transform: translateX(1px);
                }

                &.www-icon {
                  width: 36px;
                  height: 36px;
                }

                &.store-icon {
                  width: 28.71px;
                  height: 26.66px;
                }

                &.register-icon {
                  width: 27px;
                  height: 27px;
                }

                &.kiosk-icon {
                  width: 37px;
                  height: 37px;
                }
              }
            }

            .col {
              width: 0;
              -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                      flex-grow: 1;

              > p {
                &.style1 {
                  
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 23px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                  max-width: 90%;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }

                &.style2 {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                  max-width: 90%;
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                     text-overflow: ellipsis;
                }
              }
            }
          }
        }

        > {
          p {
            font-size: 22px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 12px;
          }

          .divider {
            width: 60px;
            height: 1px;
            background-color: var(--oliver-border);
            margin-bottom: 26px;
          }

          .button-container {
            width: 335px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
          }
        }

        .button-group > button:not(.assigned) > .col > p.style2 {
          color: var(--oliver-blue);
        }

        button {
          > {
            img {
              width: 10.1px;
              height: 20.2px;
            }

            .fake-button {
              display: none;
            }
          }

          &#addNew {
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            height: 40.4px;
            padding: 0;
            border: 0;
            background-color: transparent;
            
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
            color: var(--oliver-blue);

            > img {
              &.mobile {
                width: 24px;
                height: 24px;
                margin-right: 11px;
              }

              &.landscape {
                display: none;
              }
            }
          }
        }

        > .button-container > {
          p, .divider {
            display: none;
          }

          .button-group {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
          }
        }
      }
    }
  }
}
  
@media (orientation: landscape) {
  .choose-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-bg-grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    > {
      .choose-header {
        width: 100%;
        height: 64px;
        background-color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 20px;

        > {
          button {
            width: 109px;
            height: 40px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            background-color: #fff;
            border: 1px solid var(--oliver-blue);
            border-radius: 6px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-blue);
            margin-right: auto;

            > img {
              width: 7.1px;
              height: 14.2px;
              margin-right: 10px;
            }
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: right;
            color: var(--oliver-base-grey);
            max-width: 90%;
            -o-text-overflow: ellipsis;
               text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      .choose-body-default {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        overflow-y: scroll;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 55px 0;

        button {
          &:disabled {
            &::after {
              content: "";
              background-image: url("../assets/images/svg/LockIcon.svg");
              background-size: 24px 24px;
              width: 24px;
              height: 24px;
              position: absolute;
              left: 8px;
              top: 10px;
            }

            &:hover {
              cursor: default !important;
            }

            > {
              .img-container {
                background-color: var(--oliver-border) !important;
              }

              .col > p {
                &.style1 {
                  color: #b0bec9 !important;
                }

                &.style2 {
                  color: var(--oliver-border) !important;
                }
              }

              .fake-button {
                background-color: var(--oliver-border) !important;
              }
            }
          }

          width: 174px;
          height: 256px;
          margin: 0 25px 25px 0;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          border: 1px solid var(--oliver-ui-bubble-grey);
          border-radius: 6.78146px;
          background-color: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          padding: 26.28px 0 19px 0;
          -webkit-filter: drop-shadow(0px 4.23841px 8.47682px rgba(0, 0, 0, 0.1));
                  filter: drop-shadow(0px 4.23841px 8.47682px rgba(0, 0, 0, 0.1));

          &:nth-child(5n), &:last-of-type {
            margin-right: 0;
          }

          > {
            .img-container {
              width: 82.33px;
              height: 82.33px;
              border-radius: 50%;
              background-color: var(--oliver-blue);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: center;
                  -ms-flex-pack: center;
                      justify-content: center;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              overflow: hidden;

              > img {
                width: 100%;
                height: 100%;

                &.oliver-icon {
                  width: 36px;
                  height: 36px;
                  -webkit-transform: translateX(1px);
                      -ms-transform: translateX(1px);
                          transform: translateX(1px);
                }

                &.www-icon {
                  width: 52px;
                  height: 52px;
                }

                &.store-icon {
                  width: 40px;
                  height: 37px;
                }

                &.register-icon {
                  width: 43px;
                  height: 43px;
                }

                &.kiosk-icon {
                  width: 56px;
                  height: 56px;
                }
              }
            }

            .col {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                      flex-direction: column;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;

              > p {
                &.style1 {
                  
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 22px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                  max-width: 90%;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }

                &.style2 {
                  
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  text-align: center;
                  color: var(--oliver-base-grey);
                  max-width: 90%;
                  overflow: hidden;
                  white-space: nowrap;
                  -o-text-overflow: ellipsis;
                     text-overflow: ellipsis;
                }
              }
            }
          }

          &:not(#addNew) > img {
            display: none;
          }

          > .fake-button {
            background-color: var(--oliver-blue);
            width: 126.18px;
            height: 48px;
            
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            border-radius: 4px;
          }

          &.assigned > .fake-button {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border: 1px solid var(--oliver-blue);
            color: var(--oliver-blue);
            background-color: #fff !important;

            &.background-coral {
              border-color: var(--oliver-coral);
              color: var(--oliver-coral);
            }

            &.background-cyan {
              border-color: var(--oliver-cyan);
              color: var(--oliver-cyan);
            }

            &.background-lime {
              border-color: var(--oliver-lime);
              color: var(--oliver-lime);
            }

            &.background-primary {
              border-color: var(--oliver-primary);
              color: var(--oliver-primary);
            }

            &.background-red {
              border-color: var(--oliver-red);
              color: var(--oliver-red);
            }

            &.background-teal {
              border-color: var(--oliver-teal);
              color: var(--oliver-teal);
            }

            &.background-violet {
              border-color: var(--oliver-violet);
              color: var(--oliver-violet);
            }

            &.background-yellow {
              border-color: var(--oliver-yellow);
              color: var(--oliver-yellow);
            }
          }

          &#addNew {
            border: 2px dashed var(--oliver-border);
            background-color: transparent;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
            text-align: center;
            color: var(--oliver-grey-text);
            -webkit-filter: none;
                    filter: none;

            > img {
              &.landscape {
                width: 20px;
                height: 20px;
                margin-right: 6px;
              }

              &.mobile {
                display: none;
              }
            }
          }
        }

        > {
          p {
            
            font-size: 22px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 19px;
          }

          .divider {
            width: 60px;
            height: 1px;
            background-color: var(--oliver-border);
            margin-bottom: 45px;
          }

          .button-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            -ms-flex-line-pack: start;
                align-content: flex-start;
            width: 970px;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;

            > {
              p {
                
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 20px;
                margin-right: auto;
              }

              .divider {
                width: 100%;
                height: 1px;
                background-color: var(--oliver-border);
                margin-bottom: 20px;
              }
            }
          }
        }

        .button-group {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
          -ms-flex-line-pack: start;
              align-content: flex-start;
          padding-bottom: 25px;

          > button:not(.assigned) > .col > p.style2 {
            color: var(--oliver-blue);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .choose-wrapper > {
    .choose-header {
      height: 3.125vw;
      padding: 0 0.9766vw;

      > {
        button {
          width: 5.3223vw;
          height: 1.9531vw;
          border: 0.0488vw solid var(--oliver-blue);
          border-radius: 0.293vw;
          font-size: 0.7813vw;
          line-height: 1.1719vw;

          > img {
            width: 0.3467vw;
            height: 0.6934vw;
            margin-right: 0.4883vw;
          }
        }

        p {
          font-size: 0.7813vw;
          line-height: 1.1719vw;
        }
      }
    }

    .choose-body-default {
      padding: 2.6855vw 0;

      button {
        &:disabled::after {
          background-size: 1.1719vw 1.1719vw;
          width: 1.1719vw;
          height: 1.1719vw;
          left: 0.3906vw;
          top: 0.4883vw;
        }

        width: 8.4961vw;
        height: 12.5vw;
        margin: 0 1.2207vw 1.2207vw 0;
        border: 0.0488vw solid var(--oliver-ui-bubble-grey);
        border-radius: 0.3311vw;
        padding: 1.2832vw 0 0.9277vw 0;
        -webkit-filter: drop-shadow(0vw 0.207vw 0.4139vw rgba(0, 0, 0, 0.1));
                filter: drop-shadow(0vw 0.207vw 0.4139vw rgba(0, 0, 0, 0.1));

        > {
          .img-container {
            width: 4.02vw;
            height: 4.02vw;

            > img {
              &.oliver-icon {
                width: 1.7578vw;
                height: 1.7578vw;
                -webkit-transform: translateX(0.0488vw);
                    -ms-transform: translateX(0.0488vw);
                        transform: translateX(0.0488vw);
              }

              &.www-icon {
                width: 2.5391vw;
                height: 2.5391vw;
              }

              &.store-icon {
                width: 1.9531vw;
                height: 1.8066vw;
              }

              &.register-icon {
                width: 2.0996vw;
                height: 2.0996vw;
              }

              &.kiosk-icon {
                width: 2.7344vw;
                height: 2.7344vw;
              }
            }
          }

          .col > p {
            &.style1 {
              font-size: 0.7324vw;
              line-height: 1.0742vw;
            }

            &.style2 {
              font-size: 0.5859vw;
              line-height: 0.8301vw;
            }
          }

          .fake-button {
            width: 6.1611vw;
            height: 2.3438vw;
            font-size: 0.6836vw;
            line-height: 1.1719vw;
            border-radius: 0.1953vw;
          }
        }

        &.assigned > .fake-button {
          border: 0.0488vw solid var(--oliver-blue);
        }

        &#addNew {
          border: 0.0977vw dashed var(--oliver-border);
          font-size: 0.6836vw;
          line-height: 0.7324vw;

          > img.landscape {
            width: 0.9766vw;
            height: 0.9766vw;
            margin-right: 0.293vw;
          }
        }
      }

      > {
        p {
          font-size: 1.0742vw;
          line-height: 1.5625vw;
          margin-bottom: 0.9277vw;
        }

        .divider {
          width: 2.9297vw;
          height: 0.0488vw;
          margin-bottom: 2.1973vw;
        }

        .button-container {
          width: 47.3633vw;

          > {
            p {
              font-size: 0.8789vw;
              line-height: 1.1719vw;
              margin-bottom: 0.9766vw;
            }

            .divider {
              height: 0.0488vw;
              margin-bottom: 0.9766vw;
            }
          }
        }
      }

      .button-group {
        padding-bottom: 1.2207vw;
      }
    }
  }
}
