:root {
  /* Customize Color */
  --oliver-primary: #2797e8;

  /* Oliver Colors */
  --oliver-blue: #2797e8;
  --oliver-red: #ff525f;
  --oliver-violet: #a568bc;
  --oliver-cyan: #1db9ca;
  --oliver-teal: #47d2a5;
  --oliver-lime: #a1e663;
  --oliver-yellow: #ffd026;
  --oliver-coral: #ff8552;
  --oliver-white: #ffffff;

  /* Oliver Greys */
  --oliver-base-grey: #758696;
  --oliver-border: #d4dee5;
  --oliver-new-grey: #b0bec9;
  --oliver-grey-text: #a6aeb6;
  --oliver-disabled: #c8cbcf;
  --oliver-spacer: #e7e9ec;
  --oliver-bg-grey: #f8f9fa;
  --oliver-ui-bubble-grey: #e1e1e1;

  /* Oliver Default Font Colors */
  --oliver-base-blue: #3d4c66;
  --oliver-base-blue-dark: #243249;

  /* Alert Colors */
  --approval: #00b998;
  --error: #d51a52;

  /* Oliver Font Sizes */
  --oliver-tile-size: 0%;
  --oliver-light-blue: #e7f4fe;
  --oliver-tile-height: 0%;
  --oliver-tile-size_px: 0px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #b0bec9;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-button {
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 6px;
  background-color: #b0bec9;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

html {
  height: 100%;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

p {
  margin: 0;
  font-weight: 400;
}

button {
  padding: 0;
  font-family: inherit;
  font-weight: 400;
  text-align: center;

  &:hover:not([disabled]):not(.selected):not(.active) {
    cursor: pointer;
  }

  &:active:not([disabled]):not(.selected):not(.active):not(.no-transform):not(
      .remove-state
    ) {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

label,
input,
textarea,
a {
  font-family: inherit;
  font-weight: 400;
}

input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;

  &::-webkit-input-placeholder {
    color: var(--oliver-new-grey) !important;
  }

  &::-moz-placeholder {
    color: var(--oliver-new-grey) !important;
  }

  &:-ms-input-placeholder {
    color: var(--oliver-new-grey) !important;
  }

  &::-ms-input-placeholder {
    color: var(--oliver-new-grey) !important;
  }

  &::placeholder {
    color: var(--oliver-new-grey) !important;
  }
}

textarea::-webkit-input-placeholder {
  color: var(--oliver-new-grey) !important;
}

textarea::-moz-placeholder {
  color: var(--oliver-new-grey) !important;
}

textarea:-ms-input-placeholder {
  color: var(--oliver-new-grey) !important;
}

textarea::-ms-input-placeholder {
  color: var(--oliver-new-grey) !important;
}

textarea::placeholder {
  color: var(--oliver-new-grey) !important;
}

input:hover:not(.error),
textarea:hover:not(.error) {
  border-color: var(--oliver-blue) !important;
}

input:focus,
textarea:focus {
  border-color: var(--oliver-blue);
}

input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}

* {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

/* Background Colors */

.background-primary {
  background-color: var(--oliver-primary) !important;
  border-color: var(--oliver-primary) !important;
}

.background-blue {
  background-color: var(--oliver-blue) !important;
  border-color: var(--oliver-blue) !important;
}

.background-red {
  background-color: var(--oliver-red) !important;
  border-color: var(--oliver-red) !important;
}

.background-yellow {
  background-color: var(--oliver-yellow) !important;
  border-color: var(--oliver-yellow) !important;
}

.background-coral {
  background-color: var(--oliver-coral) !important;
  border-color: var(--oliver-coral) !important;
}

.background-lime {
  background-color: var(--oliver-lime) !important;
  border-color: var(--oliver-lime) !important;
}

.background-teal {
  background-color: var(--oliver-teal) !important;
  border-color: var(--oliver-teal) !important;
}

.background-cyan {
  background-color: var(--oliver-cyan) !important;
  border-color: var(--oliver-cyan) !important;
}

.background-violet {
  background-color: var(--oliver-violet) !important;
  border-color: var(--oliver-violet) !important;
}

@media (orientation: portrait) {
  *::-webkit-scrollbar {
    display: none;
  }

  * {
    scrollbar-width: none;
  }

  input:focus,
  textarea:focus {
    border-width: 2px !important;
  }
}

@media (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-button {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 6px;
    background-color: #b0bec9;
  }

  input:focus,
  textarea:focus {
    border-width: 2px !important;
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  ::-webkit-scrollbar {
    width: 0.293vw;
  }

  ::-webkit-scrollbar-button {
    height: 0.1953vw;
  }

  ::-webkit-scrollbar-thumb {
    width: 0.2441vw;
    border-radius: 0.293vw;
  }

  input:focus,
  textarea:focus {
    border-width: 0.0977vw !important;
  }
}

.ologo-1 {
  animation: animate-svg-fill-1 3s ease-in 0.2s both infinite;
  fill: #a1e663;
}

.ologo-2 {
  animation: animate-svg-fill-2 3s ease-in 0.4s both infinite;
  fill: #47d2a5;
}

.ologo-3 {
  animation: animate-svg-fill-3 3s ease-in 0.6s both infinite;
  fill: #1db9ca;
}

.ologo-4 {
  animation: animate-svg-fill-4 3s ease-in 0.8s both infinite;
  fill: #2797e8;
}

.ologo-5 {
  animation: animate-svg-fill-5 3s ease-in 0.9s both infinite;
  fill: #a568bc;
}

.ologo-6 {
  animation: animate-svg-fill-6 3s ease-in 1.1s both infinite;
  fill: #ff525f;
}

.ologo-7 {
  animation: animate-svg-fill-7 3s ease-in 1.2s both infinite;
  fill: #ff8552;
}

.ologo-8 {
  animation: animate-svg-fill-8 3s ease-in-out 1.3s both infinite;
  fill: #ffd026;
}
