@media (orientation: portrait) {
  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > {
    .main {
      width: 302px;
      padding: 22px 0 30px 0;
      border-top: 1px solid var(--oliver-border);
      border-bottom: 1px solid var(--oliver-border);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        label {
          width: 100%;
          padding-bottom: 9px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);

          &:hover {
            cursor: pointer;

            + input {
              border-color: var(--oliver-blue);
            }
          }
        }

        input {
          width: 100%;
          height: 83px;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          padding: 0 12px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-family: Poppins;
          font-size: 40px;
          font-weight: 400;
          line-height: 56px;
          text-align: center;
          color: var(--oliver-blue);
          margin-bottom: 23px;

          &:hover {
            border-color: var(--oliver-blue);
          }

          &:focus {
            border-color: var(--oliver-blue);
            border-width: 2px;
          }
        }

        p {
          width: 100%;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-grey);
          margin-bottom: 12px;
        }

        .button-row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          > button {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 44px;
            border: 0;
            border-radius: 6px;
            background-color: var(--oliver-blue);
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin-left: 12px;

            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }

    .list {
      padding-top: 25px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-grey);
        }

        button {
          width: 285px;
          height: 44px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          margin-top: 15px;
          background-color: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          > p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-base-blue);
            max-width: 90%;
            overflow: hidden;
            white-space: nowrap;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }

          &#clearCartDiscountDiv {
            background-color: transparent;

            > p {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              text-align: center;
              color: var(--oliver-base-grey);
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > {
    .main {
      width: 100%;
      padding: 22px 0 30px 0;
      border-top: 1px solid var(--oliver-border);
      border-bottom: 1px solid var(--oliver-border);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        label {
          width: 348px;
          padding-bottom: 9px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);

          &:hover {
            cursor: pointer;

            + input {
              border-color: var(--oliver-blue);
            }
          }
        }

        input {
          width: 348px;
          height: 83px;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          padding: 0 12px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-family: Poppins;
          font-size: 40px;
          font-weight: 400;
          line-height: 56px;
          text-align: center;
          color: var(--oliver-blue);
          margin-bottom: 23px;

          &:hover {
            border-color: var(--oliver-blue);
          }

          &:focus {
            border-color: var(--oliver-blue);
            border-width: 2px;
          }
        }

        p {
          width: 348px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-grey);
          margin-bottom: 10px;
        }

        .button-row {
          width: 348px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          > button {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 44px;
            border: 0;
            border-radius: 6px;
            background-color: var(--oliver-blue);
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin-left: 16px;

            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }

    .list {
      padding-top: 25px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-grey);
        }

        button {
          width: 285px;
          height: 44px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid var(--oliver-border);
          border-radius: 6px;
          margin-top: 15px;
          background-color: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          > p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-base-blue);
            max-width: 90%;
            overflow: hidden;
            white-space: nowrap;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }

          &#clearCartDiscountDiv {
            background-color: transparent;

            > p {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              text-align: center;
              color: var(--oliver-base-grey);
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow.cart-discount > .subwindow-body > #cartDiscountDiv > {
    .main {
      padding: 1.0742vw 0 1.4648vw 0;
      border-top: 0.0488vw solid var(--oliver-border);
      border-bottom: 0.0488vw solid var(--oliver-border);

      > {
        label {
          width: 16.9922vw;
          padding-bottom: 0.4395vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }

        input {
          width: 16.9922vw;
          height: 4.0527vw;
          border: 0.0488vw solid var(--oliver-border);
          border-radius: 0.293vw;
          padding: 0 0.5859vw;
          font-size: 1.9531vw;
          line-height: 2.7344vw;
          margin-bottom: 1.123vw;

          &:focus {
            border-width: 0.0977vw;
          }
        }

        p {
          width: 16.9922vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
          margin-bottom: 0.4883vw;
        }

        .button-row {
          width: 16.9922vw;

          > button {
            height: 2.1484vw;
            border-radius: 0.293vw;
            font-size: 0.8789vw;
            line-height: 1.1719vw;
            margin-left: 0.7813vw;
          }
        }
      }
    }

    .list {
      padding-top: 1.2207vw;

      > {
        p {
          font-size: 0.6836vw;
          line-height: 0.8789vw;
        }

        button {
          width: 13.916vw;
          height: 2.1484vw;
          border: 0.0488vw solid var(--oliver-border);
          border-radius: 0.293vw;
          margin-top: 0.7324vw;

          > p {
            font-size: 0.7813vw;
            line-height: 1.0254vw;
          }

          &#clearCartDiscountDiv > p {
            font-size: 0.6836vw;
            line-height: 0.8789vw;
          }
        }
      }
    }
  }
}
