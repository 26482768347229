

@media (orientation: landscape) {
  .cv-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    > {
      .header {
        width: 100%;
        height: 68px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 13px 0 22px;
        border-bottom: 1px solid var(--oliver-border);

        > {
          p {
            
            font-size: 20px;
            font-weight: 600;
            line-height: 29px;
            text-align: left;
            color: var(--oliver-base-blue);
          }

          button {
            width: 110px;
            height: 36px;
            background-color: #e7f4fe;
            border: 0;
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-blue);

            > img {
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }

            &#mobileCVExitSearch {
              display: none;
            }
          }

          p.mobile-only {
            display: none;
          }
        }
      }

      .body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        overflow: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 15px 22px;

        > {
          .row {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 13px;

            > {
              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }

              p {
                
                font-size: 16px;
                font-weight: 600;
                line-height: 23px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-right: auto;
              }

              button {
                padding: 5px;
                -webkit-transform: translateY(-5px);
                    -ms-transform: translateY(-5px);
                        transform: translateY(-5px);
                background-color: transparent;
                border: 0;
                
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-blue);
                text-decoration: underline;
              }
            }
          }

          label {
            width: 100%;
            
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-bottom: 4px;
          }

          input {
            width: 100%;
            height: 44px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border: 1px solid var(--oliver-border);
            border-radius: 6px;
            padding: 0 12px;
            
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-bottom: 20px;

            &:focus {
              border-color: var(--oliver-blue);
            }
          }

          button {
            width: 100%;
            height: 44px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 6px;
            margin-top: auto;
            
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .cv-search > {
    .header {
      height: 3.3203vw;
      padding: 0 0.6348vw 0 1.0742vw;
      border-bottom: 0.0488vw solid var(--oliver-border);

      > {
        p {
          font-size: 0.9766vw;
          line-height: 1.416vw;
        }

        button {
          width: 5.3711vw;
          height: 1.7578vw;
          border-radius: 0.3906vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;

          > img {
            width: 0.8789vw;
            height: 0.8789vw;
            margin-right: 0.3906vw;
          }
        }
      }
    }

    .body {
      padding: 0.7324vw 1.0742vw;

      > {
        .row {
          margin-bottom: 0.6348vw;

          > {
            img {
              width: 0.9766vw;
              height: 0.9766vw;
              margin-right: 0.4883vw;
            }

            p {
              font-size: 0.7813vw;
              line-height: 1.123vw;
            }

            button {
              padding: 0.2441vw;
              -webkit-transform: translateY(-0.2441vw);
                  -ms-transform: translateY(-0.2441vw);
                      transform: translateY(-0.2441vw);
              font-size: 0.6836vw;
              line-height: 0.9766vw;
            }
          }
        }

        label {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
          margin-bottom: 0.1953vw;
        }

        input {
          height: 2.1484vw;
          border: 0.0488vw solid var(--oliver-border);
          border-radius: 0.293vw;
          padding: 0 0.5859vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
          margin-bottom: 0.9766vw;
        }

        button {
          height: 2.1484vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .cv-search > {
    .header {
      height: 5.6667vw;
      padding: 0 1.0833vw 0 1.8333vw;
      border-bottom: 0.0833vw solid var(--oliver-border);

      > {
        p {
          font-size: 1.6667vw;
          line-height: 2.4167vw;
        }

        button {
          width: 9.1667vw;
          height: 3vw;
          border-radius: 0.6667vw;
          font-size: 1.1667vw;
          line-height: 1.6667vw;

          > img {
            width: 1.5vw;
            height: 1.5vw;
            margin-right: 0.6667vw;
          }
        }
      }
    }

    .body {
      padding: 1.25vw 1.8333vw;

      > {
        .row {
          margin-bottom: 1.0833vw;

          > {
            img {
              width: 1.6667vw;
              height: 1.6667vw;
              margin-right: 0.8333vw;
            }

            p {
              font-size: 1.3333vw;
              line-height: 1.9167vw;
            }

            button {
              padding: 0.4167vw;
              -webkit-transform: translateY(-0.4167vw);
                  -ms-transform: translateY(-0.4167vw);
                      transform: translateY(-0.4167vw);
              font-size: 1.1667vw;
              line-height: 1.6667vw;
            }
          }
        }

        label {
          font-size: 1.1667vw;
          line-height: 1.6667vw;
          margin-bottom: 0.3333vw;
        }

        input {
          height: 3.6667vw;
          border: 0.0833vw solid var(--oliver-border);
          border-radius: 0.5vw;
          padding: 0 1vw;
          font-size: 1.1667vw;
          line-height: 1.6667vw;
          margin-bottom: 1.6667vw;
        }

        button {
          height: 3.6667vw;
          border-radius: 0.5vw;
          font-size: 1.5vw;
          line-height: 2vw;
        }
      }
    }
  }
}