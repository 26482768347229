@media (orientation: portrait) {
  .notifications-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(123, 140, 152, 0.3);
    z-index: 5;

    &.hidden {
      display: none;
    }

    > .notifications {
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;

      > .noti-header {
        width: 100%;
        height: 60px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 16px;
        border-bottom: 1px solid var(--oliver-border);

        > {
          p {
            
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          button {
            background-color: transparent;
            border: 0;
            width: 34px;
            height: 34px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            background-color: #cfebff;
            border-radius: 8px;
            margin-left: 11px;

            &#notiSettingButton > img {
              width: 24px;
              height: 24px;
            }

            &#mobileNotiExit > img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      &.settings {
        > .noti-header > button#notiSettingButton {
          background-color: var(--oliver-blue);

          > img {
            -webkit-filter: brightness(0) invert(1);
                    filter: brightness(0) invert(1);
          }
        }

        > .noti-settings-background {
          position: fixed;
          top: 60px;
          background-color: rgba(61, 76, 102, 0.4);
          width: 100%;
          height: 100%;
        }

        > .noti-settings-wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }

      > .noti-body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        overflow: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

        > {
          .date {
            width: 100%;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 9px 16px;
            background-color: #f1f4f6;

            > p {
              
              font-size: 13px;
              font-weight: 400;
              line-height: 17px;
              color: var(--oliver-base-grey);
            }
          }

          .notification-card {
            width: 100%;
            height: 76px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 0 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            border-top: 1px solid var(--oliver-border);
          }

          .date + .notification-card {
            border: 0;
          }

          .notification-card > {
            .icon-wrapper {
              width: 40px;
              height: 40px;
              border-radius: 8px;
              overflow: hidden;
              margin-right: 12px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              -webkit-box-pack: center;
                  -ms-flex-pack: center;
                      justify-content: center;

              > img {
                width: 32px;
                height: 32px;
              }

              &.green {
                background-color: #dbf8f2;
              }

              &.blue {
                background-color: #e7f4fe;
              }

              &.red {
                background-color: #ffe7e9;

                > img {
                  width: 25px;
                  height: 25px;
                }
              }

              &.grey {
                background-color: #f1f4f6;

                > img {
                  width: 24px;
                  height: 24px;
                }
              }
            }

            .col {
              width: 0;
              -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                      flex-grow: 1;
              -webkit-box-sizing: border-box;
                      box-sizing: border-box;
              padding-right: 12px;

              > p {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                   text-overflow: ellipsis;

                &.style1 {
                  
                  font-size: 13px;
                  font-weight: 600;
                  line-height: 17px;
                  color: var(--oliver-base-blue);
                }

                &.style2 {
                  
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                  color: var(--oliver-base-grey);
                }
              }
            }

            p {
              
              font-size: 13px;
              font-weight: 400;
              line-height: 17px;
              text-align: center;
              color: var(--oliver-base-grey);
            }
          }
        }
      }

      > .noti-settings-wrapper {
        display: none;
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        position: absolute;
        background-color: transparent;
        top: 60px;
      }

      > .noti-settings-wrapper > .noti-settings {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        border-radius: 0 0 15px 15px;
        overflow: auto;
        background-color: #fff;
        z-index: 2;

        > {
          .header {
            width: 100%;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 12px 16px;
            background-color: #f1f4f6;

            > p {
              
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              color: var(--oliver-base-blue);
            }
          }

          .settings-list {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 10px 16px;

            > .setting-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              padding: 5px 0;

              > {
                img {
                  width: 24px;
                  height: 24px;
                  margin-right: 10px;
                }

                p {
                  
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                label {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                  width: 200px;

                  &:hover {
                    cursor: pointer;
                  }

                  > {
                    input {
                      position: fixed;
                      left: -10000px;
                      opacity: 0;
                    }

                    .toggle {
                      position: relative;
                      width: 64px;
                      height: 32px;
                      border-radius: 32px;
                      background-color: var(--oliver-border);
                      margin-left: auto;
                      -webkit-box-sizing: border-box;
                              box-sizing: border-box;
                      padding: 2px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                          -ms-flex-align: center;
                              align-items: center;

                      &::before {
                        content: "";
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background-color: #fff;
                        position: absolute;
                        border: 1px solid rgba(0, 0, 0, 0.04);
                        -webkit-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
                                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
                        -webkit-box-sizing: border-box;
                                box-sizing: border-box;
                      }

                      &::after {
                        content: "Off";
                        
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: var(--oliver-base-blue-dark);
                        position: absolute;
                        right: 8px;
                      }
                    }

                    input:checked + .toggle {
                      background-color: var(--oliver-blue);

                      &::before {
                        right: 2px;
                      }

                      &::after {
                        content: "On";
                        left: 8px;
                        right: auto;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .notifications-wrapper {
    position: fixed;
    width: calc(100% - 72px);
    height: calc(100% - 68px);
    left: 72px;
    top: 68px;
    background-color: rgba(123, 140, 152, 0.3);

    &.hidden {
      display: none;
    }

    > .notifications {
      position: absolute;
      top: 1px;
      right: 16px;
      width: 375px;
      height: min(750px, 90%);
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;

      &.settings {
        height: min(240px, 90%);

        > .noti-header > button > img {
          -webkit-filter: brightness(0) saturate(100%) invert(45%) sepia(55%) saturate(2555%) hue-rotate(184deg) brightness(103%) contrast(82%);
                  filter: brightness(0) saturate(100%) invert(45%) sepia(55%) saturate(2555%) hue-rotate(184deg) brightness(103%) contrast(82%);
        }

        > .noti-body {
          display: none;
        }

        > .noti-settings-wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }

      > .noti-header {
        width: 100%;
        height: 54px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 0 16px;
        border-bottom: 1px solid var(--oliver-border);

        > {
          p {
            
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-base-blue);
          }

          button {
            background-color: transparent;
            border: 0;
            padding: 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-transform: translateX(10px);
                -ms-transform: translateX(10px);
                    transform: translateX(10px);

            > img {
              width: 25px;
              height: 25px;
            }

            &#mobileNotiExit {
              display: none;
            }
          }
        }
      }

      > .noti-body {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        overflow: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
      }

      > {
        .noti-body > {
          .date {
            width: 100%;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 9px 16px;
            background-color: #f1f4f6;

            > p {
              
              font-size: 13px;
              font-weight: 400;
              line-height: 17px;
              color: var(--oliver-base-grey);
            }
          }

          .notification-card {
            width: 100%;
            height: 76px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 0 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            border-top: 1px solid var(--oliver-border);
          }

          .date + .notification-card {
            border: 0;
          }

          .notification-card > {
            .icon-wrapper {
              width: 40px;
              height: 40px;
              border-radius: 8px;
              overflow: hidden;
              margin-right: 12px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              -webkit-box-pack: center;
                  -ms-flex-pack: center;
                      justify-content: center;

              > img {
                width: 32px;
                height: 32px;
              }

              &.green {
                background-color: #dbf8f2;
              }

              &.blue {
                background-color: #e7f4fe;
              }

              &.red {
                background-color: #ffe7e9;

                > img {
                  width: 25px;
                  height: 25px;
                }
              }

              &.grey {
                background-color: #f1f4f6;

                > img {
                  width: 24px;
                  height: 24px;
                }
              }
            }

            .col {
              width: 0;
              -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                      flex-grow: 1;
              -webkit-box-sizing: border-box;
                      box-sizing: border-box;
              padding-right: 12px;

              > p {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                   text-overflow: ellipsis;

                &.style1 {
                  
                  font-size: 13px;
                  font-weight: 600;
                  line-height: 17px;
                  color: var(--oliver-base-blue);
                }

                &.style2 {
                  
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                  color: var(--oliver-base-grey);
                }
              }
            }

            p {
              
              font-size: 13px;
              font-weight: 400;
              line-height: 17px;
              text-align: center;
              color: var(--oliver-base-grey);
            }
          }
        }

        .noti-settings-wrapper {
          display: none;
          width: 100%;
          height: 0;
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
        }
      }

      > .noti-settings-wrapper > .noti-settings {
        width: 100%;
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        overflow: auto;

        > {
          .header {
            width: 100%;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 12px 16px;
            background-color: #f1f4f6;

            > p {
              
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              color: var(--oliver-base-blue);
            }
          }

          .settings-list {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 10px 16px;

            > .setting-row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              padding: 5px 0;

              > {
                img {
                  width: 24px;
                  height: 24px;
                  margin-right: 10px;
                }

                p {
                  
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                label {
                  
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                  width: 200px;

                  &:hover {
                    cursor: pointer;
                  }

                  > {
                    input {
                      position: fixed;
                      left: -10000px;
                      opacity: 0;
                    }

                    .toggle {
                      position: relative;
                      width: 64px;
                      height: 32px;
                      border-radius: 32px;
                      background-color: var(--oliver-border);
                      margin-left: auto;
                      -webkit-box-sizing: border-box;
                              box-sizing: border-box;
                      padding: 2px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                          -ms-flex-align: center;
                              align-items: center;
                      line-height: 0;

                      &::before {
                        content: "";
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background-color: #fff;
                        position: absolute;
                        border: 1px solid rgba(0, 0, 0, 0.04);
                        -webkit-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
                                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
                        -webkit-box-sizing: border-box;
                                box-sizing: border-box;
                      }

                      &::after {
                        content: "Off";
                        
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: var(--oliver-base-blue-dark);
                        position: absolute;
                        right: 8px;
                      }
                    }

                    input:checked + .toggle {
                      background-color: var(--oliver-blue);

                      &::before {
                        right: 2px;
                      }

                      &::after {
                        content: "On";
                        left: 8px;
                        right: auto;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .notifications-wrapper {
    width: calc(100% - 3.5156vw);
    height: calc(100% - 3.3203vw);
    left: 3.5156vw;
    top: 3.3203vw;

    > .notifications {
      top: 0.0488vw;
      right: 0.7813vw;
      width: 18.3105vw;
      height: min(36.6211vw, 90%);
      border-radius: 0 0 0.3906vw 0.3906vw;

      &.settings {
        height: min(11.7188vw, 90%);
      }

      > {
        .noti-header {
          height: 2.6367vw;
          padding: 0 0.7813vw;
          border-bottom: 0.0488vw solid var(--oliver-border);

          > {
            p {
              font-size: 0.8789vw;
              line-height: 1.1719vw;
            }

            button {
              padding: 0.4883vw;
              -webkit-transform: translateX(0.4883vw);
                  -ms-transform: translateX(0.4883vw);
                      transform: translateX(0.4883vw);

              > img {
                width: 1.2207vw;
                height: 1.2207vw;
              }
            }
          }
        }

        .noti-body > {
          .date {
            padding: 0.4395vw 0.7813vw;

            > p {
              font-size: 0.6348vw;
              line-height: 0.8301vw;
            }
          }

          .notification-card {
            height: 3.7109vw;
            padding: 0 0.7324vw;
            border-top: 0.0488vw solid var(--oliver-border);

            > {
              .icon-wrapper {
                width: 1.9531vw;
                height: 1.9531vw;
                border-radius: 0.3906vw;
                margin-right: 0.5859vw;

                > img {
                  width: 1.5625vw;
                  height: 1.5625vw;
                }

                &.red > img {
                  width: 1.2207vw;
                  height: 1.2207vw;
                }

                &.grey > img {
                  width: 1.1719vw;
                  height: 1.1719vw;
                }
              }

              .col {
                padding-right: 0.5859vw;

                > p {
                  &.style1 {
                    font-size: 0.6348vw;
                    line-height: 0.8301vw;
                  }

                  &.style2 {
                    font-size: 0.5859vw;
                    line-height: 0.7813vw;
                  }
                }
              }

              p {
                font-size: 0.6348vw;
                line-height: 0.8301vw;
              }
            }
          }
        }

        .noti-settings-wrapper > .noti-settings > {
          .header {
            padding: 0.5859vw 0.7813vw;

            > p {
              font-size: 0.7813vw;
              line-height: 1.0254vw;
            }
          }

          .settings-list {
            padding: 0.4883vw 0.7813vw;

            > .setting-row {
              padding: 0.2441vw 0;

              > {
                img {
                  width: 1.1719vw;
                  height: 1.1719vw;
                  margin-right: 0.4883vw;
                }

                p {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                }

                label {
                  font-size: 0.6836vw;
                  line-height: 0.9766vw;
                  width: 9.7656vw;

                  > {
                    input {
                      left: -488.2813vw;
                    }

                    .toggle {
                      width: 3.125vw;
                      height: 1.5625vw;
                      border-radius: 1.5625vw;
                      padding: 0.0977vw;

                      &::before {
                        width: 1.3672vw;
                        height: 1.3672vw;
                        border: 0.0488vw solid rgba(0, 0, 0, 0.04);
                        -webkit-box-shadow: 0vw 0.1465vw 0.3906vw rgba(0, 0, 0, 0.15), 0vw 0.1465vw 0.0488vw rgba(0, 0, 0, 0.06);
                                box-shadow: 0vw 0.1465vw 0.3906vw rgba(0, 0, 0, 0.15), 0vw 0.1465vw 0.0488vw rgba(0, 0, 0, 0.06);
                      }

                      &::after {
                        font-size: 0.6836vw;
                        line-height: 0.9766vw;
                        right: 0.3906vw;
                      }
                    }

                    input:checked + .toggle {
                      &::before {
                        right: 0.0977vw;
                      }

                      &::after {
                        left: 0.3906vw;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
