@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 344px;
    height: min(323px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 8px;

      > {
        p.style1 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 23px;
        }

        img {
          width: 61px;
          height: 61px;
          margin-bottom: 22px;
        }

        p.style2 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 38px;
        }

        button {
          width: 306px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }

        .auto-margin-bottom {
          margin: 0;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 504px;
    height: min(350px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 8px;

      > {
        p.style1 {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 24px;
        }

        img {
          width: 61px;
          height: 61px;
          margin-bottom: 21px;
        }

        p.style2 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
          color: var(--oliver-base-blue);
          margin-bottom: 30px;
        }

        button {
          width: 198px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.no-variation-selected {
    width: 24.6094vw;
    height: min(17.0898vw, 95%);

    > .subwindow-body {
      border-radius: 0.3906vw;

      > {
        p.style1 {
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          margin-bottom: 1.1719vw;
        }

        img {
          width: 2.9785vw;
          height: 2.9785vw;
          margin-bottom: 1.0254vw;
        }

        p.style2 {
          font-size: 0.7813vw;
          line-height: 1.0254vw;
          margin-bottom: 1.4648vw;
        }

        button {
          width: 9.668vw;
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }
  }
}
