@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.register-taken {
    width: min(344px, 95%);
    height: min(298px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      > {
        img {
          width: 50px;
          height: 52px;
          margin-bottom: 30px;
        }

        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          color: var(--oliver-base-blue);
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.register-taken {
    width: 490px;
    height: min(95%, 339px);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        img {
          width: 50px;
          height: 52px;
          margin-bottom: 30px;
        }

        p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 23px;
          text-align: center;
          color: var(--oliver-base-blue);
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.register-taken {
    width: 23.9258vw;
    height: min(95%, 16.5527vw);

    > .subwindow-body > {
      img {
        width: 2.4414vw;
        height: 2.5391vw;
        margin-bottom: 1.4648vw;
      }

      p {
        font-size: 0.7813vw;
        line-height: 1.123vw;
      }
    }
  }
}
