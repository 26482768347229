@media (orientation: portrait) {
  .login-wrapper {
    width: 100%;
    height: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    > {
      img {
        width: 136px;
        height: 47.2px;
        margin: 50px 0 18.88px 0;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
        color: var(--oliver-base-blue);
        margin-bottom: 43.98px;
      }

      .login-form {
        width: 320px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-bottom: 28px;

        > {
          label {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
          }

          input {
            width: 100%;
            height: 44px;
            outline: 0;
            border: 1px solid var(--oliver-border);
            border-radius: 2px;
            padding: 0 12px;
            
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-bottom: 28px;

            &:focus {
              border-color: var(--oliver-blue);
            }
          }

          .row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            margin-bottom: 28px;

            > {
              a {
                
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                color: var(--oliver-base-grey);
              }

              .custom-checkbox-wrapper {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                
                font-size: 12px;
                font-weight: 400;
                line-height: 21px;
                color: var(--oliver-base-grey);

                > {
                  input[type="checkbox"] {
                    position: fixed;
                    left: -10000px;
                    opacity: 0;
                  }

                  .custom-checkbox {
                    width: 20px;
                    height: 20px;
                    border: 1px solid var(--oliver-border);
                    border-radius: 4px;
                    margin-right: 11px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    -webkit-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;

                    > img {
                      width: 75%;
                      height: auto;
                      display: none;
                    }
                  }

                  input[type="checkbox"]:checked ~ .custom-checkbox {
                    border-color: var(--oliver-blue);

                    > img {
                      display: block;
                    }
                  }
                }
              }
            }
          }

          button {
            width: 100%;
            height: 48px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 4px;
            
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }
        }
      }

      .or-row {
        width: 320px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin-bottom: 28px;

        > {
          .divider {
            width: 119.76px;
            height: 1px;
            background-color: var(--oliver-border);
          }

          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-border);
          }
        }
      }

      button {
        position: relative;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        width: 320px;
        height: 48px;
        padding-left: 48px;
        border: 0;
        border-radius: 4px;
        background-color: var(--oliver-blue);
        margin-bottom: 16px;
        
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        color: #fff;

        &:last-of-type {
          margin-bottom: 21.07px;
        }

        > .img-container {
          position: absolute;
          left: 2px;
          top: 2px;
          width: 44px;
          height: 44px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          background-color: #fff;
          border-radius: 4px;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
        }
      }

      #googleButton {
        background-color: var(--oliver-blue);

        > .img-container > img {
          width: 45.19px;
          height: 38.61px;
        }
      }

      #facebookButton {
        background-color: #4267b2;

        > .img-container > img {
          width: 26.52px;
          height: 21.29px;
        }
      }

      #appleButton {
        background-color: #050505;

        > .img-container > img {
          width: 40.58px;
          height: 27.42px;
        }
      }

      .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-bottom: 50px;

        > {
          p {
            
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            color: var(--oliver-base-grey);
            margin-right: 5px;
          }

          a {
            
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            text-align: left;
            color: var(--oliver-base-blue);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .login-wrapper {
    width: 100%;
    height: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    > {
      img {
        width: 143px;
        height: 50px;
        margin: 50px 0 20px 0;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
        color: var(--oliver-base-blue);
        margin-bottom: 43.98px;
      }

      .login-form {
        width: 347px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-bottom: 28px;

        > {
          label {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
          }

          input {
            width: 100%;
            height: 44px;
            outline: 0;
            border: 1px solid var(--oliver-border);
            border-radius: 2px;
            padding: 0 12px;
            
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-bottom: 28px;

            &:focus {
              border-color: var(--oliver-blue);
            }
          }

          .row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            margin-bottom: 28px;

            > {
              a {
                
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                color: var(--oliver-base-grey);
              }

              .custom-checkbox-wrapper {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                
                font-size: 12px;
                font-weight: 400;
                line-height: 21px;
                color: var(--oliver-base-grey);

                > {
                  input[type="checkbox"] {
                    position: fixed;
                    left: -10000px;
                    opacity: 0;
                  }

                  .custom-checkbox {
                    width: 20px;
                    height: 20px;
                    border: 1px solid var(--oliver-border);
                    border-radius: 4px;
                    margin-right: 11px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    -webkit-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;

                    > img {
                      width: 75%;
                      height: auto;
                      display: none;
                    }
                  }

                  input[type="checkbox"]:checked ~ .custom-checkbox {
                    border-color: var(--oliver-blue);

                    > img {
                      display: block;
                    }
                  }
                }
              }
            }
          }

          button {
            width: 100%;
            height: 48px;
            background-color: var(--oliver-blue);
            border: 0;
            border-radius: 4px;
            
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }
        }
      }

      .or-row {
        width: 347px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin-bottom: 28px;

        > {
          .divider {
            width: 127.53px;
            height: 1px;
            background-color: var(--oliver-border);
          }

          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-border);
          }
        }
      }

      button {
        position: relative;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        width: 347px;
        height: 48px;
        padding-left: 48px;
        border: 0;
        border-radius: 4px;
        background-color: var(--oliver-blue);
        margin-bottom: 16px;
        
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        color: #fff;
        line-height: 0;

        &:last-of-type {
          margin-bottom: 54px;
        }

        > .img-container {
          position: absolute;
          left: 2px;
          top: 2px;
          width: 44px;
          height: 44px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          background-color: #fff;
          border-radius: 4px;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
        }
      }

      #googleButton {
        background-color: var(--oliver-blue);

        > .img-container > img {
          width: 45.19px;
          height: 38.61px;
        }
      }

      #facebookButton {
        background-color: #4267b2;

        > .img-container > img {
          width: 26.52px;
          height: 21.29px;
        }
      }

      #appleButton {
        background-color: #050505;

        > .img-container > img {
          width: 40.58px;
          height: 27.42px;
        }
      }

      .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-bottom: 50px;

        > {
          p {
            
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            color: var(--oliver-base-grey);
            margin-right: 5px;
          }

          a {
            
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            text-align: left;
            color: var(--oliver-base-blue);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .login-wrapper > {
    img {
      width: 6.9824vw;
      height: 2.4414vw;
      margin: 2.4414vw 0 0.9766vw 0;
    }

    p {
      font-size: 0.7813vw;
      line-height: 1.123vw;
      margin-bottom: 2.1475vw;
    }

    .login-form {
      width: 16.9434vw;
      margin-bottom: 1.3672vw;

      > {
        label {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }

        input {
          height: 2.1484vw;
          border: 0.0488vw solid var(--oliver-border);
          border-radius: 0.0977vw;
          padding: 0 0.5859vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
          margin-bottom: 1.3672vw;
        }

        .row {
          margin-bottom: 1.3672vw;

          > {
            a {
              font-size: 0.6836vw;
              line-height: 1.0254vw;
            }

            .custom-checkbox-wrapper {
              font-size: 0.5859vw;
              line-height: 1.0254vw;

              > {
                input[type="checkbox"] {
                  left: -488.2813vw;
                }

                .custom-checkbox {
                  width: 0.9766vw;
                  height: 0.9766vw;
                  border: 0.0488vw solid var(--oliver-border);
                  border-radius: 0.1953vw;
                  margin-right: 0.5371vw;
                }
              }
            }
          }
        }

        button {
          height: 2.3438vw;
          border-radius: 0.1953vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }

    .or-row {
      width: 16.9434vw;
      margin-bottom: 1.3672vw;

      > {
        .divider {
          width: 6.2271vw;
          height: 0.0488vw;
        }

        p {
          font-size: 0.9766vw;
          line-height: 1.0254vw;
        }
      }
    }

    button {
      width: 16.9434vw;
      height: 2.3438vw;
      padding-left: 2.3438vw;
      border-radius: 0.1953vw;
      margin-bottom: 0.7813vw;
      font-size: 0.8789vw;
      line-height: 1.0254vw;

      &:last-of-type {
        margin-bottom: 2.6367vw;
      }

      > .img-container {
        left: 0.0977vw;
        top: 0.0977vw;
        width: 2.1484vw;
        height: 2.1484vw;
        border-radius: 0.1953vw;
      }
    }

    #googleButton > .img-container > img {
      width: 2.2065vw;
      height: 1.8853vw;
    }

    #facebookButton > .img-container > img {
      width: 1.2949vw;
      height: 1.0396vw;
    }

    #appleButton > .img-container > img {
      width: 1.9814vw;
      height: 1.3389vw;
    }

    .row {
      margin-bottom: 2.4414vw;

      > {
        p {
          font-size: 0.6836vw;
          line-height: 1.0254vw;
          margin-right: 0.2441vw;
        }

        a {
          font-size: 0.6836vw;
          line-height: 1.0254vw;
        }
      }
    }
  }
}
