@media (orientation: portrait) {
  .sale-complete-wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: var(--oliver-blue);
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;

    > {
      .main {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: auto;

        > {
          img {
            width: 50px;
            height: 50px;
            margin-bottom: 18px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: #fff;
            margin-bottom: 8px;
          }

          #loadingDiv {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 32px;

            &.hidden {
              display: none;
            }

            > {
              img {
                width: 23px;
                height: 23px;
                margin-right: 8px;
              }

              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                text-align: center;
                color: #fff;
              }
            }
          }

          button#openUpdateTransactionStatus {
            border: 0;
            padding: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-bottom: 32px;

            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            text-align: center;
            color: #fff;

            &:hover {
              background-color: rgba(16, 123, 201, 0.8);
            }

            &.hidden {
              display: none;
            }

            > img {
              width: 20px;
              height: 20px;
              margin-left: 8px;
              -webkit-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }

          .change-container {
            width: 290px;
            height: 104px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 10px;
            margin-bottom: 34px;

            > p {
              &.style1 {
                font-size: 22px;
                font-weight: 500;
                line-height: 29px;
                text-align: center;
                color: #0079cf;
                margin-bottom: 7px;
              }

              &.style2 {
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                text-align: center;
                color: #0079cf;
              }
            }
          }

          label {
            display: none;
          }

          button {
            width: 200px;
            height: 50px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #fff;
            border-radius: 6px;
            background-color: transparent;

            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;

            &#emailSubwindowButton {
              margin: 16px 0 34px 0;
            }
          }
        }
      }

      .footer {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: auto;

        > {
          .button-container > button {
            &#newSale {
              width: 292px;
              height: 55px;
              border-radius: 6px;
              border: 0;
              background-color: #fff;

              font-size: 18px;
              font-weight: 500;
              line-height: 24px;
              text-align: center;
              color: var(--oliver-base-blue);
              margin-bottom: 40px;
            }

            &#endSession {
              width: 147px;
              height: 40px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              background-color: transparent;
              border: 1px solid #fff;
              border-radius: 6px;

              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              text-align: center;
              color: #fff;

              > img {
                width: 26px;
                height: 22px;
                margin-right: 5px;
              }
            }
          }

          .app-container {
            max-width: 280px;
            max-height: 140px;
            padding: 15px 0 0 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            background-color: rgba(16, 123, 201, 0.8);
            margin-bottom: 35px;
            border-radius: 16px;
            overflow-y: auto;

            > button {
              width: 55px;
              height: 55px;
              background-color: transparent;
              border: 0;
              border-radius: 7px;
              overflow: hidden;
              margin: 0 15px 15px 0;

              > img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .sale-complete-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;

    > {
      .main {
        width: 100%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 0;

        > {
          img {
            width: 50px;
            height: 50px;
            margin: 20px 0 18px 0;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: #fff;
            margin-bottom: 8px;
          }

          #loadingDiv {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 42px;

            &.hidden {
              display: none;
            }

            > {
              img {
                width: 23px;
                height: 23px;
                margin-right: 8px;
              }

              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                text-align: center;
                color: #fff;
              }
            }
          }

          button#openUpdateTransactionStatus {
            border: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: auto;
            width: auto;
            padding: 8px;

            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            text-align: center;
            margin-bottom: 42px;

            &:hover {
              background-color: #0079cf;
            }

            &.hidden {
              display: none;
            }

            > img {
              width: 15px;
              height: 15px;
              margin-left: 8px;
              -webkit-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }

          .change-container {
            width: 490px;
            height: 104px;
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-bottom: 50px;

            &.hide {
              display: none;
            }

            > p {
              &.style1 {
                font-size: 22px;
                font-weight: 500;
                line-height: 29px;
                text-align: center;
                color: #0079cf;
                margin-bottom: 7px;
              }

              &.style2 {
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                text-align: center;
                color: #0079cf;
              }
            }
          }

          label {
            &.email-label {
              margin-bottom: 20px;
              position: relative;
              line-height: 0;

              > {
                input {
                  width: 490px;
                  height: 56px;
                  border: 0;
                  border-radius: 8px;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0 69px 0 19px;

                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                button {
                  position: absolute;
                  right: 6px;
                  top: 6px;
                  width: 118px;
                  height: 44px;
                  background-color: var(--oliver-base-blue);
                  border: 0;
                  border-radius: 6px;

                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18px;
                  text-align: center;
                  color: #fff;
                }
              }
            }

            &.checkbox-label {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;

              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              text-align: center;
              color: #fff;
              margin-bottom: 43px;

              &:hover {
                cursor: pointer;
              }

              > {
                input {
                  position: fixed;
                  left: -10000px;
                  opacity: 0;
                }

                .custom-checkbox {
                  width: 24px;
                  height: 24px;
                  background-color: #fff;
                  border-radius: 7px;
                  margin-left: 8px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  border: 2px solid #197bc1;

                  > img {
                    display: none;
                    width: 16px;
                    height: 16px;
                  }
                }

                input:checked ~ .custom-checkbox > img {
                  display: block;
                }
              }
            }
          }

          button {
            width: 238px;
            height: 56px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #fff;
            border-radius: 8px;
            background-color: transparent;

            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin-bottom: auto;

            &#emailSubwindowButton {
              display: none;
            }
          }
        }
      }

      .footer {
        width: 100%;
        padding-top: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 38px 38px 38px;

        > {
          .button-container {
            width: 197px;

            > button {
              &#endSession {
                width: 200px;
                height: 56px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: transparent;
                border: 1px solid #fff;
                border-radius: 6px;

                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                text-align: center;
                color: #fff;

                > img {
                  width: 26px;
                  height: 22px;
                  margin-right: 5px;
                }
              }

              &#newSale {
                width: 200px;
                height: 56px;
                background-color: #fff;
                border: 0;
                border-radius: 6px;

                font-size: 20px;
                font-weight: 500;
                line-height: 26px;
                text-align: center;
                color: var(--oliver-base-blue);
              }
            }
          }

          .app-container {
            background-color: rgba(16, 123, 201, 0.8);
            padding: 15px 15px 0 15px;
            border-radius: 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            max-width: 295px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            > button {
              width: 55px;
              height: 55px;
              border-radius: 7px;
              border: 0;
              background-color: transparent;
              overflow: hidden;
              margin: 0 15px 15px 0;
              background-color: #fff;
              line-height: 0;

              &:nth-child(4n) {
                margin-right: 0;
              }

              > img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .sale-complete-wrapper > {
    .main {
      padding: 0.9766vw 0;

      > {
        img {
          width: 2.4414vw;
          height: 2.4414vw;
          margin: auto 0 0.8789vw 0;
        }

        p {
          font-size: 0.6836vw;
          line-height: 0.8789vw;
          margin-bottom: 0.3906vw;
        }

        #loadingDiv {
          margin-bottom: 2.0508vw;

          > {
            img {
              width: 1.123vw;
              height: 1.123vw;
              margin-right: 0.3906vw;
            }

            p {
              font-size: 0.7813vw;
              line-height: 1.0254vw;
            }
          }
        }

        button#openUpdateTransactionStatus {
          padding: 0.3906vw;
          font-size: 0.9766vw;
          line-height: 1.2695vw;
          margin-bottom: 2.0508vw;

          > img {
            width: 0.7324vw;
            height: 0.7324vw;
            margin-left: 0.3906vw;
          }
        }

        .change-container {
          width: 23.9258vw;
          height: 5.0781vw;
          border-radius: 0.4883vw;
          margin-bottom: 2.4414vw;

          > p {
            &.style1 {
              font-size: 1.0742vw;
              line-height: 1.416vw;
              margin-bottom: 0.3418vw;
            }

            &.style2 {
              font-size: 0.7813vw;
              line-height: 1.0254vw;
            }
          }
        }

        label {
          &.email-label {
            margin-bottom: 0.9766vw;

            > {
              input {
                width: 23.9258vw;
                height: 2.7344vw;
                border-radius: 0.3906vw;
                padding: 0 3.3691vw 0 0.9277vw;
                font-size: 0.6836vw;
                line-height: 0.8789vw;
              }

              button {
                right: 0.293vw;
                top: 0.293vw;
                width: 5.7617vw;
                height: 2.1484vw;
                border-radius: 0.293vw;
                font-size: 0.6836vw;
                line-height: 0.8789vw;
              }
            }
          }

          &.checkbox-label {
            font-size: 0.7813vw;
            line-height: 1.0254vw;
            margin-bottom: 2.0996vw;

            > {
              input {
                left: -488.2813vw;
              }

              .custom-checkbox {
                width: 1.1719vw;
                height: 1.1719vw;
                border-radius: 0.3418vw;
                margin-left: 0.3906vw;
                border: 0.0977vw solid #197bc1;

                > img {
                  width: 0.7813vw;
                  height: 0.7813vw;
                }
              }
            }
          }
        }

        button {
          width: 11.6211vw;
          height: 2.7344vw;
          border: 0.0488vw solid #fff;
          border-radius: 0.3906vw;
          font-size: 0.7813vw;
          line-height: 1.1719vw;
        }
      }
    }

    .footer {
      padding-top: 0.9766vw;
      padding: 0 1.8555vw 1.8555vw 1.8555vw;

      > {
        .button-container {
          width: 9.6191vw;

          > button {
            &#endSession {
              width: 9.766vw;
              height: 2.734vw;
              border: 0.0488vw solid #fff;
              border-radius: 0.293vw;
              font-size: 0.7813vw;
              line-height: 1.0254vw;

              > img {
                width: 1.2695vw;
                height: 1.0742vw;
                margin-right: 0.2441vw;
              }
            }

            &#newSale {
              width: 9.766vw;
              height: 2.734vw;
              border-radius: 0.293vw;
              font-size: 0.9766vw;
              line-height: 1.2695vw;
            }
          }
        }

        .app-container {
          padding: 0.7324vw 0.7324vw 0 0.7324vw;
          border-radius: 0.7324vw;
          max-width: 14.4043vw;

          > button {
            width: 2.6855vw;
            height: 2.6855vw;
            border-radius: 0.3418vw;
            margin: 0 0.7324vw 0.7324vw 0;
          }
        }
      }
    }
  }
}
