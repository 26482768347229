@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.app-launcher-subwindow {
    width: 100%;
    height: 100%;
    border-radius: 0;

    > {
      .subwindow-header {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 16px 48px 16px 16px;

        > .app-wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;

          > {
            img {
              width: 50px;
              height: 50px;
              overflow: hidden;
              border-radius: 10px;
              margin-right: 19px;
            }

            .text-col {
              width: 0;
              -webkit-box-flex: 1;
              -ms-flex-positive: 1;
              flex-grow: 1;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;

              > p {
                max-width: 95%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;

                &.style1 {
                  font-family: Poppins;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 25px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                &.style2 {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }
              }
            }
          }
        }
      }

      .subwindow-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        border: 0;
        padding: 0;

        > iframe {
          width: 100%;
          height: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.app-launcher-subwindow {
    width: 800px;
    height: calc(100% - 40px);

    &.small {
      width: 600px;
    }

    &.large {
      width: calc(100% - 40px);
    }

    > {
      .subwindow-header {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 16px 48px 16px 16px;

        > .app-wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;

          > {
            img {
              width: 50px;
              height: 50px;
              overflow: hidden;
              border-radius: 10px;
              margin-right: 19px;
            }

            .text-col {
              width: 0;
              -webkit-box-flex: 1;
              -ms-flex-positive: 1;
              flex-grow: 1;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;

              > p {
                max-width: 95%;
                overflow: hidden;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;

                &.style1 {
                  font-family: Poppins;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 25px;
                  text-align: left;
                  color: var(--oliver-base-blue);
                }

                &.style2 {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                }
              }
            }
          }
        }
      }

      .subwindow-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0;

        > iframe {
          width: 100%;
          height: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.app-launcher-subwindow {
    width: 39.0625vw;
    height: calc(100% - 1.9531vw);

    &.small {
      width: 29.2969vw;
    }

    &.large {
      width: calc(100% - 1.9531vw);
    }

    > .subwindow-header {
      padding: 0.7813vw 2.3438vw 0.7813vw 0.7813vw;

      > .app-wrapper > {
        img {
          width: 2.4414vw;
          height: 2.4414vw;
          border-radius: 0.4883vw;
          margin-right: 0.9277vw;
        }

        .text-col > p {
          &.style1 {
            font-size: 0.8789vw;
            line-height: 1.2207vw;
          }

          &.style2 {
            font-size: 0.6836vw;
            line-height: 0.9766vw;
          }
        }
      }
    }
  }
}
