@media (orientation: portrait) {
  .error-404-wrapper {
    width: 100%;
    height: 100%;

    > .error-404-container {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 20px;
      background-color: #f1f4f6;
      overflow: auto;

      > {
        img {
          width: 130px;
          height: 94px;
          margin: auto 0 35px 0;
          content: url("../assets/images/svg/OliverIconErrorBlue.svg");
        }

        p {
          &.style1 {
            font-size: 40px;
            font-weight: 700;
            line-height: 53px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 10px;
          }

          &.style2 {
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            color: var(--oliver-blue);
            margin-bottom: 10px;
          }

          &.style3 {
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: auto;
          }
        }

        button {
          width: 173px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;

          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin: 20px 0 35px 0;
        }

        .row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 6px;

          > {
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-base-blue);
              margin-right: 4px;
            }

            a {
              font-size: 16px;
              font-weight: 600;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-blue);
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .error-404-wrapper {
    width: 100%;
    height: 100%;
    background-color: #f1f4f6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    > .error-404-container {
      width: min(100%, 507px);
      height: min(100%, 555px);
      background-color: #fff;
      -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 20px;
      overflow: auto;

      > {
        img {
          width: 130px;
          height: 94px;
          margin: auto 0 35px 0;
          content: url("../assets/images/svg/OliverIconError.svg");
        }

        p {
          &.style1 {
            font-size: 40px;
            font-weight: 700;
            line-height: 53px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 10px;
          }

          &.style2 {
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            color: var(--oliver-blue);
            margin-bottom: 10px;
          }

          &.style3 {
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 35px;

            > br {
              display: none;
            }
          }
        }

        button {
          width: 173px;
          height: 50px;
          border: 0;
          border-radius: 6px;
          background-color: var(--oliver-blue);

          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
          margin-bottom: 35px;
        }

        .row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: auto;

          > {
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-base-blue);
              margin-right: 4px;
            }

            a {
              font-size: 16px;
              font-weight: 600;
              line-height: 21px;
              text-align: center;
              color: var(--oliver-blue);
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .error-404-wrapper > .error-404-container {
    width: min(100%, 24.7559vw);
    height: min(100%, 27.0996vw);
    -webkit-box-shadow: 0vw 0.4883vw 0.9766vw rgba(0, 0, 0, 0.05);
    box-shadow: 0vw 0.4883vw 0.9766vw rgba(0, 0, 0, 0.05);
    border-radius: 0.4883vw;
    padding: 0.9766vw;

    > {
      img {
        width: 6.3477vw;
        height: 4.5898vw;
        margin: auto 0 1.709vw 0;
      }

      p {
        &.style1 {
          font-size: 1.9531vw;
          line-height: 2.5879vw;
          margin-bottom: 0.4883vw;
        }

        &.style2 {
          font-size: 0.9766vw;
          line-height: 1.2695vw;
          margin-bottom: 0.4883vw;
        }

        &.style3 {
          font-size: 0.9766vw;
          line-height: 1.2695vw;
          margin-bottom: 1.709vw;
        }
      }

      button {
        width: 8.4473vw;
        height: 2.4414vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
        margin-bottom: 1.709vw;
      }

      .row > {
        p {
          font-size: 0.7813vw;
          line-height: 1.0254vw;
          margin-right: 0.1953vw;
        }

        a {
          font-size: 0.7813vw;
          line-height: 1.0254vw;
        }
      }
    }
  }
}
