@media (orientation: portrait) {
  .create-userid-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: var(--oliver-base-blue);

    > {
      .header {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        > {
          #goBackPinSetup,
          #cancelPinSetup {
            background-color: transparent;
            border: 0;
            display: none;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            padding: 10px;

            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            text-align: left;
            color: #fff;
          }

          #goBackPinSetup > img,
          #cancelPinSetup > img {
            width: 9px;
            height: 16px;
            margin-right: 8px;
          }

          #logoutPinSetup {
            background-color: transparent;
            border: 0;
            display: none;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            text-align: left;
            color: #fff;

            > img {
              width: 20px;
              height: 20px;
              margin-right: 7px;
            }
          }
        }

        &.var-1 > {
          #cancelPinSetup,
          #logoutPinSetup {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
        }

        &.var-2 > #goBackPinSetup {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }

      .body {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        > .col {
          &.static {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 343px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            padding-bottom: 30px;
            border-bottom: 1px solid #fff;

            > {
              img {
                width: 106.86px;
                height: 97.72px;
                margin-right: 28px;
              }

              .text-group > p {
                &.style1 {
                  font-size: 30px;
                  font-weight: 500;
                  line-height: 43px;
                  text-align: left;
                  color: #fff;
                }

                &.style2 {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 16px;
                  text-align: left;
                  color: #fff;
                  margin-bottom: 2px;
                }

                &.style3 {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 16px;
                  text-align: left;
                  color: #fff;
                }
              }
            }
          }

          &.dynamic > {
            .step-1 {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              margin-top: 33px;

              &.hidden {
                display: none;
              }

              > {
                p {
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 35px;
                  text-align: center;
                  color: #fff;
                  margin-bottom: 20px;

                  > b {
                    font-weight: 700;
                  }
                }

                button {
                  width: 343px;
                  height: 50px;
                  background-color: var(--oliver-blue);
                  border: 0;
                  border-radius: 6px;

                  font-size: 18px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;
                }
              }
            }

            .step-2 {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-top: 20px;

              &.hidden {
                display: none;
              }

              > {
                p {
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 29px;
                  text-align: center;
                  color: #fff;
                  margin-bottom: 23px;
                }

                .id-row {
                  width: 276px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  margin-bottom: 25.33px;

                  > .id-entry {
                    -webkit-box-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    height: 44px;
                    background-color: #fff;
                    margin-left: 10px;
                    -webkit-box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.25);
                    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.25);
                    border-radius: 6px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    font-size: 25px;
                    font-weight: 500;
                    line-height: 36px;
                    text-align: center;
                    color: var(--oliver-blue);

                    &:first-child {
                      margin-left: 0;
                    }
                  }
                }

                .pin-key-row {
                  width: 276px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  margin-bottom: 16px;

                  > button {
                    -webkit-box-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    aspect-ratio: 1/1;
                    margin-left: 16px;
                    background-color: #fff;
                    border: 0;
                    border-radius: 8px;

                    &.backspace {
                      background-color: transparent;

                      > img {
                        width: 50.38px;
                        height: 40.09px;
                      }
                    }

                    &:first-of-type {
                      margin-left: 0;
                    }
                  }

                  &.last {
                    margin-bottom: 16px;

                    > button {
                      -webkit-box-flex: 0.312759;
                      -ms-flex: 0.312759;
                      flex: 0.312759;

                      &:first-of-type {
                        margin-left: auto;
                      }
                    }
                  }

                  > button > {
                    p {
                      font-size: 36px;
                      font-weight: 400;
                      line-height: 36px;
                      text-align: center;
                      color: var(--oliver-base-blue);
                    }

                    .letter-row > p {
                      font-size: 10px;
                      font-weight: 300;
                      line-height: 15px;
                      text-align: center;
                    }
                  }
                }

                button {
                  width: 343px;
                  height: 50px;
                  border-radius: 6px;
                  border: 0;
                  background-color: var(--oliver-blue);

                  font-size: 18px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;

                  &:disabled {
                    background-color: var(--oliver-disabled);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: portrait) and (max-width: 375px) {
  .create-userid-wrapper > {
    .header {
      padding: 2.6667vw;

      > {
        #goBackPinSetup,
        #cancelPinSetup {
          padding: 2.6667vw;
          font-size: 4.8vw;
          line-height: 7.2vw;
        }

        #goBackPinSetup > img,
        #cancelPinSetup > img {
          width: 2.4vw;
          height: 4.2667vw;
          margin-right: 2.1333vw;
        }

        #logoutPinSetup {
          font-size: 4.8vw;
          line-height: 7.2vw;

          > img {
            width: 5.3333vw;
            height: 5.3333vw;
            margin-right: 1.8667vw;
          }
        }
      }
    }

    .body > .col {
      &.static {
        width: 91.4667vw;
        padding-bottom: 8vw;
        border-bottom: 0.2667vw solid #fff;

        > {
          img {
            width: 28.496vw;
            height: 26.0587vw;
            margin-right: 7.4667vw;
          }

          .text-group > p {
            &.style1 {
              font-size: 8vw;
              line-height: 11.4667vw;
            }

            &.style2 {
              font-size: 4.2667vw;
              line-height: 4.2667vw;
              margin-bottom: 0.5333vw;
            }

            &.style3 {
              font-size: 4.2667vw;
              line-height: 4.2667vw;
            }
          }
        }
      }

      &.dynamic > {
        .step-1 {
          margin-top: 8.8vw;

          > {
            p {
              font-size: 5.3333vw;
              line-height: 9.3333vw;
              margin-bottom: 5.3333vw;
            }

            button {
              width: 91.4667vw;
              height: 13.3333vw;
              border-radius: 1.6vw;
              font-size: 4.8vw;
              line-height: 6.4vw;
            }
          }
        }

        .step-2 {
          margin-top: 5.3333vw;

          > {
            p {
              font-size: 5.3333vw;
              line-height: 7.7333vw;
              margin-bottom: 6.1333vw;
            }

            .id-row {
              width: 73.6vw;
              margin-bottom: 6.7547vw;

              > .id-entry {
                height: 11.7333vw;
                margin-left: 2.6667vw;
                -webkit-box-shadow: inset 0vw 1.0667vw 1.6vw rgba(0, 0, 0, 0.25);
                box-shadow: inset 0vw 1.0667vw 1.6vw rgba(0, 0, 0, 0.25);
                border-radius: 1.6vw;
                font-size: 6.6667vw;
                line-height: 9.6vw;
              }
            }

            .pin-key-row {
              width: 73.6vw;
              margin-bottom: 4.2667vw;

              > button {
                margin-left: 4.2667vw;
                border-radius: 2.1333vw;

                &.backspace > img {
                  width: 13.4347vw;
                  height: 10.6907vw;
                }
              }

              &.last {
                margin-bottom: 4.2667vw;
              }

              > button > {
                p {
                  font-size: 9.6vw;
                  line-height: 9.6vw;
                }

                .letter-row > p {
                  font-size: 2.6667vw;
                  line-height: 4vw;
                }
              }
            }

            button {
              width: 91.4667vw;
              height: 13.3333vw;
              border-radius: 1.6vw;
              font-size: 4.8vw;
              line-height: 6.4vw;
            }
          }
        }
      }
    }
  }
}

@media (orientation: portrait) and (min-width: 600px) {
  .create-userid-wrapper > {
    .header {
      padding: 1.6667vw;

      > {
        #goBackPinSetup,
        #cancelPinSetup {
          padding: 1.6667vw;
          font-size: 3vw;
          line-height: 4.5vw;
        }

        #goBackPinSetup > img,
        #cancelPinSetup > img {
          width: 1.5vw;
          height: 2.6667vw;
          margin-right: 1.3333vw;
        }

        #logoutPinSetup {
          font-size: 3vw;
          line-height: 4.5vw;

          > img {
            width: 3.3333vw;
            height: 3.3333vw;
            margin-right: 1.1667vw;
          }
        }
      }
    }

    .body > .col {
      &.static {
        width: 57.1667vw;
        padding-bottom: 5vw;
        border-bottom: 0.1667vw solid #fff;

        > {
          img {
            width: 17.81vw;
            height: 16.2867vw;
            margin-right: 4.6667vw;
          }

          .text-group > p {
            &.style1 {
              font-size: 5vw;
              line-height: 7.1667vw;
            }

            &.style2 {
              font-size: 2.6667vw;
              line-height: 2.6667vw;
              margin-bottom: 0.3333vw;
            }

            &.style3 {
              font-size: 2.6667vw;
              line-height: 2.6667vw;
            }
          }
        }
      }

      &.dynamic > {
        .step-1 {
          margin-top: 5.5vw;

          > {
            p {
              font-size: 3.3333vw;
              line-height: 5.8333vw;
              margin-bottom: 3.3333vw;
            }

            button {
              width: 57.1667vw;
              height: 8.3333vw;
              border-radius: 1vw;
              font-size: 3vw;
              line-height: 4vw;
            }
          }
        }

        .step-2 {
          margin-top: 3.3333vw;

          > {
            p {
              font-size: 3.3333vw;
              line-height: 4.8333vw;
              margin-bottom: 3.8333vw;
            }

            .id-row {
              width: 46vw;
              margin-bottom: 4.2217vw;

              > .id-entry {
                height: 7.3333vw;
                margin-left: 1.6667vw;
                -webkit-box-shadow: inset 0vw 0.6667vw 1vw rgba(0, 0, 0, 0.25);
                box-shadow: inset 0vw 0.6667vw 1vw rgba(0, 0, 0, 0.25);
                border-radius: 1vw;
                font-size: 4.1667vw;
                line-height: 6vw;
              }
            }

            .pin-key-row {
              width: 46vw;
              margin-bottom: 2.6667vw;

              > button {
                margin-left: 2.6667vw;
                border-radius: 1.3333vw;

                &.backspace > img {
                  width: 8.3967vw;
                  height: 6.6817vw;
                }
              }

              &.last {
                margin-bottom: 2.6667vw;
              }

              > button > {
                p {
                  font-size: 6vw;
                  line-height: 6vw;
                }

                .letter-row > p {
                  font-size: 1.6667vw;
                  line-height: 2.5vw;
                }
              }
            }

            button {
              width: 57.1667vw;
              height: 8.3333vw;
              border-radius: 1vw;
              font-size: 3vw;
              line-height: 4vw;
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .create-userid-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--oliver-base-blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;

    > {
      .header {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 10px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        > {
          #cancelPinSetup,
          #goBackPinSetup {
            display: none;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            padding: 10px;
            background-color: transparent;
            border: 0;

            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
            color: #fff;
          }

          #cancelPinSetup > img,
          #goBackPinSetup > img {
            width: 9px;
            height: 16px;
            margin-right: 7px;
          }

          #logoutPinSetup {
            padding: 10px;
            display: none;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            background-color: transparent;
            border: 0;

            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            color: #fff;

            > img {
              width: 24px;
              height: 24px;
              margin-right: 7px;
            }
          }
        }

        &.var-1 > {
          #cancelPinSetup,
          #logoutPinSetup {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
        }

        &.var-2 > #goBackPinSetup {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }

      .body {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-bottom: 20px;

        > .col {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          &.static {
            padding-left: 10%;

            > {
              img {
                width: 276.68px;
                height: 253px;
                margin-bottom: 20px;
              }

              .text-group {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                > {
                  p {
                    text-align: center;
                    color: #fff;
                    max-width: 80%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;

                    &.style1 {
                      font-size: 48px;
                      font-weight: 300;
                      line-height: 52px;
                      margin-bottom: 10px;
                    }

                    &.style2 {
                      font-size: 24px;
                      font-weight: 500;
                      line-height: 28px;
                      margin-bottom: 1px;
                    }

                    &.style3 {
                      font-size: 18px;
                      font-weight: 300;
                      line-height: 28px;
                    }
                  }

                  .divider {
                    width: 261px;
                    height: 1px;
                    background-color: #fff;
                    margin-bottom: 14px;
                  }
                }
              }
            }
          }

          &.dynamic {
            padding-right: 10%;

            > {
              .step-1 {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                &.hidden {
                  display: none;
                }

                > {
                  p {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 35px;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 20px;

                    > b {
                      font-weight: 700;
                    }
                  }

                  button {
                    width: 347px;
                    height: 50px;
                    background-color: var(--oliver-blue);
                    border: 0;
                    border-radius: 6px;

                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    color: #fff;
                  }
                }
              }

              .step-2 {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                &.hidden {
                  display: none;
                }

                > {
                  p {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 29px;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 23px;
                  }

                  .id-row {
                    width: 276px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin-bottom: 25.33px;

                    > .id-entry {
                      -webkit-box-flex: 1;
                      -ms-flex: 1;
                      flex: 1;
                      height: 44px;
                      background-color: #fff;
                      margin-left: 10px;
                      -webkit-box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.25);
                      box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.25);
                      border-radius: 6px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;

                      font-size: 25px;
                      font-weight: 500;
                      line-height: 36px;
                      text-align: center;
                      color: var(--oliver-blue);

                      &:first-child {
                        margin-left: 0;
                      }
                    }
                  }

                  .pin-key-row {
                    width: 276px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin-bottom: 16px;

                    > button {
                      -webkit-box-flex: 1;
                      -ms-flex: 1;
                      flex: 1;
                      aspect-ratio: 1/1;
                      margin-left: 16px;
                      background-color: #fff;
                      border: 0;
                      border-radius: 8px;

                      &.backspace {
                        background-color: transparent;

                        > img {
                          width: 50.38px;
                          height: 40.09px;
                        }
                      }

                      &:first-of-type {
                        margin-left: 0;
                      }
                    }

                    &.last {
                      margin-bottom: 30px;

                      > button {
                        -webkit-box-flex: 0.312759;
                        -ms-flex: 0.312759;
                        flex: 0.312759;

                        &:first-of-type {
                          margin-left: auto;
                        }
                      }
                    }

                    > button > {
                      p {
                        font-size: 36px;
                        font-weight: 400;
                        line-height: 36px;
                        text-align: center;
                        color: var(--oliver-base-blue);
                      }

                      .letter-row > p {
                        font-size: 10px;
                        font-weight: 300;
                        line-height: 15px;
                        text-align: center;
                      }
                    }
                  }

                  button {
                    width: 276px;
                    height: 50px;
                    border-radius: 6px;
                    border: 0;
                    background-color: var(--oliver-blue);

                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    color: #fff;

                    &:disabled {
                      background-color: var(--oliver-disabled);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .create-userid-wrapper > {
    .header {
      padding: 0.4883vw;

      > {
        #cancelPinSetup,
        #goBackPinSetup {
          padding: 0.4883vw;
          font-size: 0.9766vw;
          line-height: 1.4648vw;
        }

        #cancelPinSetup > img,
        #goBackPinSetup > img {
          width: 0.4395vw;
          height: 0.7813vw;
          margin-right: 0.3418vw;
        }

        #logoutPinSetup {
          padding: 0.4883vw;
          font-size: 0.9766vw;
          line-height: 1.4648vw;

          > img {
            width: 1.1719vw;
            height: 1.1719vw;
            margin-right: 0.3418vw;
          }
        }
      }
    }

    .body {
      padding-bottom: 0.9766vw;

      > .col {
        &.static > {
          img {
            width: 13.5098vw;
            height: 12.3535vw;
            margin-bottom: 0.9766vw;
          }

          .text-group > {
            p {
              &.style1 {
                font-size: 2.3438vw;
                line-height: 2.5391vw;
                margin-bottom: 0.4883vw;
              }

              &.style2 {
                font-size: 1.1719vw;
                line-height: 1.3672vw;
                margin-bottom: 0.0488vw;
              }

              &.style3 {
                font-size: 0.8789vw;
                line-height: 1.3672vw;
              }
            }

            .divider {
              width: 12.7441vw;
              height: 0.0488vw;
              margin-bottom: 0.6836vw;
            }
          }
        }

        &.dynamic > {
          .step-1 > {
            p {
              font-size: 1.1719vw;
              line-height: 1.709vw;
              margin-bottom: 0.9766vw;
            }

            button {
              width: 16.9434vw;
              height: 2.4414vw;
              border-radius: 0.293vw;
              font-size: 0.8789vw;
              line-height: 1.1719vw;
            }
          }

          .step-2 > {
            p {
              font-size: 0.9766vw;
              line-height: 1.416vw;
              margin-bottom: 1.123vw;
            }

            .id-row {
              width: 13.4766vw;
              margin-bottom: 1.2368vw;

              > .id-entry {
                height: 2.1484vw;
                margin-left: 0.4883vw;
                -webkit-box-shadow: inset 0vw 0.1953vw 0.293vw
                  rgba(0, 0, 0, 0.25);
                box-shadow: inset 0vw 0.1953vw 0.293vw rgba(0, 0, 0, 0.25);
                border-radius: 0.293vw;
                font-size: 1.2207vw;
                line-height: 1.7578vw;
              }
            }

            .pin-key-row {
              width: 13.4766vw;
              margin-bottom: 0.7813vw;

              > button {
                margin-left: 0.7813vw;
                border-radius: 0.3906vw;

                &.backspace > img {
                  width: 2.46vw;
                  height: 1.9575vw;
                }
              }

              &.last {
                margin-bottom: 1.4648vw;
              }

              > button > {
                p {
                  font-size: 1.7578vw;
                  line-height: 1.7578vw;
                }

                .letter-row > p {
                  font-size: 0.4883vw;
                  line-height: 0.7324vw;
                }
              }
            }

            button {
              width: 13.4766vw;
              height: 2.4414vw;
              border-radius: 0.293vw;
              font-size: 0.8789vw;
              line-height: 1.1719vw;
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .create-userid-wrapper > {
    .header {
      padding: 0.8333vw;

      > {
        #cancelPinSetup,
        #goBackPinSetup {
          padding: 0.8333vw;
          font-size: 1.6667vw;
          line-height: 2.5vw;
        }

        #cancelPinSetup > img,
        #goBackPinSetup > img {
          width: 0.75vw;
          height: 1.3333vw;
          margin-right: 0.5833vw;
        }

        #logoutPinSetup {
          padding: 0.8333vw;
          font-size: 1.6667vw;
          line-height: 2.5vw;

          > img {
            width: 2vw;
            height: 2vw;
            margin-right: 0.5833vw;
          }
        }
      }
    }

    .body {
      padding-bottom: 1.6667vw;

      > .col {
        &.static > {
          img {
            width: 23.0567vw;
            height: 21.0833vw;
            margin-bottom: 1.6667vw;
          }

          .text-group > {
            p {
              &.style1 {
                font-size: 4vw;
                line-height: 4.3333vw;
                margin-bottom: 0.8333vw;
              }

              &.style2 {
                font-size: 2vw;
                line-height: 2.3333vw;
                margin-bottom: 0.0833vw;
              }

              &.style3 {
                font-size: 1.5vw;
                line-height: 2.3333vw;
              }
            }

            .divider {
              width: 21.75vw;
              height: 0.0833vw;
              margin-bottom: 1.1667vw;
            }
          }
        }

        &.dynamic > {
          .step-1 > {
            p {
              font-size: 2vw;
              line-height: 2.9167vw;
              margin-bottom: 1.6667vw;
            }

            button {
              width: 28.9167vw;
              height: 4.1667vw;
              border-radius: 0.5vw;
              font-size: 1.5vw;
              line-height: 2vw;
            }
          }

          .step-2 > {
            p {
              font-size: 1.6667vw;
              line-height: 2.4167vw;
              margin-bottom: 1.9167vw;
            }

            .id-row {
              width: 23vw;
              margin-bottom: 2.1108vw;

              > .id-entry {
                height: 3.6667vw;
                margin-left: 0.8333vw;
                -webkit-box-shadow: inset 0vw 0.3333vw 0.5vw rgba(0, 0, 0, 0.25);
                box-shadow: inset 0vw 0.3333vw 0.5vw rgba(0, 0, 0, 0.25);
                border-radius: 0.5vw;
                font-size: 2.0833vw;
                line-height: 3vw;
              }
            }

            .pin-key-row {
              width: 23vw;
              margin-bottom: 1.3333vw;

              > button {
                margin-left: 1.3333vw;
                border-radius: 0.6667vw;

                &.backspace > img {
                  width: 4.1983vw;
                  height: 3.3408vw;
                }
              }

              &.last {
                margin-bottom: 2.5vw;
              }

              > button > {
                p {
                  font-size: 3vw;
                  line-height: 3vw;
                }

                .letter-row > p {
                  font-size: 0.8333vw;
                  line-height: 1.25vw;
                }
              }
            }

            button {
              width: 23vw;
              height: 4.1667vw;
              border-radius: 0.5vw;
              font-size: 1.5vw;
              line-height: 2vw;
            }
          }
        }
      }
    }
  }
}
