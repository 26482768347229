

@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.split-by-people {
    width: min(400px, 95%);
    height: min(400px, 95%);

    > .subwindow-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        .input-numpad {
          width: 100%;

          > {
            .input-container {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;

              > {
                label {
                  width: 306px;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  text-align: left;
                  color: var(--oliver-base-grey);
                  margin: 20px 0 10px 0;
                }

                input {
                  width: 306px;
                  height: 70px;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  border: 1px solid var(--oliver-border);
                  border-radius: 6px;
                  font-family: Poppins;
                  font-size: 40px;
                  font-weight: 500;
                  line-height: 56px;
                  text-align: center;
                  color: var(--oliver-blue);

                  &:focus {
                    border-color: var(--oliver-blue);
                  }
                }
              }
            }

            #numpad {
              display: none;
            }
          }
        }

        button {
          width: 306px;
          height: 50px;
          margin: 31px 0 20px 0;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: portrait) and (max-width: 375px) {
  .subwindow-wrapper > .subwindow.split-by-people {
    width: min(106.6667vw, 95%);
    height: min(106.6667vw, 95%);

    > .subwindow-body > {
      .input-numpad > .input-container > {
        label {
          width: 81.6vw;
          font-size: 3.7333vw;
          line-height: 5.3333vw;
          margin: 5.3333vw 0 2.6667vw 0;
        }

        input {
          width: 81.6vw;
          height: 18.6667vw;
          border: 0.2667vw solid var(--oliver-border);
          border-radius: 1.6vw;
          font-size: 10.6667vw;
          line-height: 14.9333vw;
        }
      }

      button {
        width: 81.6vw;
        height: 13.3333vw;
        margin: 8.2667vw 0 5.3333vw 0;
        border-radius: 1.6vw;
        font-size: 4.8vw;
        line-height: 6.4vw;
      }
    }
  }
}

@media (orientation: portrait) and (min-width: 600px) {
  .subwindow-wrapper > .subwindow.split-by-people {
    width: min(66.6667vw, 95%);
    height: min(66.6667vw, 95%);

    > .subwindow-body > {
      .input-numpad > .input-container > {
        label {
          width: 51vw;
          font-size: 2.3333vw;
          line-height: 3.3333vw;
          margin: 3.3333vw 0 1.6667vw 0;
        }

        input {
          width: 51vw;
          height: 11.6667vw;
          border: 0.1667vw solid var(--oliver-border);
          border-radius: 1vw;
          font-size: 6.6667vw;
          line-height: 9.3333vw;
        }
      }

      button {
        width: 51vw;
        height: 8.3333vw;
        margin: 5.1667vw 0 3.3333vw 0;
        border-radius: 1vw;
        font-size: 3vw;
        line-height: 4vw;
      }
    }
  }
}
