@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.logout-confirm {
    width: 100%;
    height: 100%;
    border-radius: 0;

    > .subwindow-body {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 0;

      > {
        .auto-margin-bottom {
          display: none;
        }

        p {
          &.style1 {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 500;
            line-height: 29px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-bottom: 53px;
          }

          &.style2 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-top: 20px;

            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: auto;
            }
          }
        }

        button {
          width: 343px;
          height: 54px;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;

          &#registerLogout {
            background-color: var(--oliver-blue);
            border: 0;
            color: #fff;
            margin: 53px 0 16px 0;
          }

          &#cancelRegisterLogout {
            background-color: #fff;
            border: 1px solid var(--oliver-blue);
            color: var(--oliver-blue);
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.logout-confirm {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;


    > .subwindow-body {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > {
        p {
          &.style1 {
            font-family: Poppins;
            font-size: 25px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin: 10px 0 49px 0;
          }

          &.style2 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--oliver-base-blue);
            margin-top: 10px;

            &:first-of-type {
              margin-top: 0;
            }

            > br {
              display: none;
            }
          }
        }

        button {
          width: 190px;
          height: 48px;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;

          &#registerLogout {
            background-color: var(--oliver-blue);
            border: 0;
            color: #fff;
            margin: 61px 0 17px 0;
          }

          &#cancelRegisterLogout {
            background-color: #fff;
            border: 1px solid var(--oliver-blue);
            color: var(--oliver-blue);
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.logout-confirm > .subwindow-body > {
    p {
      &.style1 {
        font-size: 1.2207vw;
        line-height: 1.7578vw;
        margin: 0.4883vw 0 2.3926vw 0;
      }

      &.style2 {
        font-size: 0.8789vw;
        line-height: 1.1719vw;
        margin-top: 0.4883vw;
      }
    }

    button {
      width: 9.2773vw;
      height: 2.3438vw;
      border-radius: 0.293vw;
      font-size: 0.8789vw;
      line-height: 1.1719vw;

      &#registerLogout {
        margin: 2.9785vw 0 0.8301vw 0;
      }

      &#cancelRegisterLogout {
        border: 0.0488vw solid var(--oliver-blue);
        margin-bottom: 0.4883vw;
      }
    }
  }
}
