@media (orientation: portrait) {
  /* --------------------Open Register-------------------- */

  .open-register-wrapper {
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--oliver-base-blue);

    > {
      #cancel,
      #goBack {
        -ms-flex-item-align: start;
        align-self: flex-start;
        display: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 132.1px;
        height: 52px;
        border: 0;
        background-color: transparent;

        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #fff;
      }

      #cancel > img,
      #goBack > img {
        width: 8.2px;
        height: 16.4px;
        margin-right: 10px;
      }

      #logout-landscape {
        display: none;
      }

      #logout-mobile {
        width: 131.1px;
        height: 52px;
        display: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: transparent;
        border: 0;

        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        color: #fff;
        margin-top: 20px;

        > img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }

    &.state-1 > {
      #logout-mobile,
      #cancel {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    }

    &.state-2 > #goBack,
    &.state-3 > #goBack {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    > {
      #logout.hidden {
        display: none;
      }

      header {
        width: 343px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 10px 0 30.28px 0;
        border-bottom: 1px solid #fff;
        margin-bottom: 20px;

        .col {
          max-width: 65%;
        }

        > img {
          width: 106.86px;
          height: 97.72px;
          margin-right: 28.14px;
        }

        p {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;

          &.style1 {
            font-size: 30px;
            font-weight: 500;
            line-height: 43px;
            text-align: left;
            color: #fff;
            margin-bottom: 5px;
          }

          &.style2 {
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            text-align: left;
            color: #fff;
            margin-bottom: 4px;
          }

          &.style3 {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #fff;
          }
        }

        .divider {
          display: none;
        }
      }

      main {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: auto 0;

        > {
          *.hidden {
            display: none !important;
          }

          .step1 {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              p {
                font-size: 20px;
                font-weight: 400;
                line-height: 29px;
                text-align: left;
                color: #fff;
                margin-bottom: 26px;
              }

              button {
                width: 343px;
                height: 54px;
                border: 0;
                border-radius: 8px;
                background-color: var(--oliver-blue);

                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
              }
            }
          }

          .step2 {
            width: 100%;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              p {
                width: 343px;

                font-size: 20px;
                font-weight: 500;
                line-height: 29px;
                text-align: left;
                color: #fff;
                margin-bottom: 23px;
                margin: 16px 0 23px 0;
              }

              label {
                width: 343px;

                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
                color: #fff;
                margin-bottom: 9px;
              }

              input {
                width: 343px;
                height: 72px;
                border: 0;
                border-radius: 6px;
                outline: 0;
                background-color: #fff;

                font-size: 32px;
                font-weight: 400;
                line-height: 45px;
                text-align: center;
                color: var(--oliver-base-blue);
                margin-bottom: 26px;
              }

              button {
                width: 343px;
                height: 54px;

                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                border-radius: 8px;

                &#openCashDrawer {
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  border: 1px solid #fff;
                  background-color: transparent;
                  margin-bottom: 23px;
                }

                &#openFloatButton {
                  border: 0;
                  background-color: var(--oliver-blue);
                  margin: auto 0 26px 0;
                }
              }

              textarea {
                width: 343px;
                height: 83px;
                outline: 0;
                resize: none;
                border-radius: 6px;
                border: 0;
                padding: 12px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin-bottom: 23px;
              }
            }
          }

          .step3 {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            > {
              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                text-align: center;
                color: #fff;
                margin: 20px 0 19.47px 0;
              }

              .error-message {
                width: 276px;
                height: 42px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #ffe7e8;
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-bottom: 18px;

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: var(--error);
              }

              .pinpad {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 276px;

                > {
                  .pin-entries {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    margin-bottom: 14.89px;

                    > .pin-entry {
                      width: 11.3px;
                      height: 11.3px;
                      border-radius: 50%;
                      background-color: #fff;
                      margin-left: 6.78px;

                      &.entered {
                        background-color: var(--oliver-blue);
                      }
                    }

                    &.incorrect {
                      -webkit-animation: shake 0.3s;
                      animation: shake 0.3s;

                      > .pin-entry {
                        background-color: var(--oliver-red);
                      }
                    }

                    > .pin-entry:nth-child(1) {
                      margin-left: 0;
                    }
                  }

                  .pin-button-row {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin-bottom: 16.3px;

                    > button {
                      width: 81.51px;
                      height: 81.51px;
                      border-radius: 8px;
                      border: 0;
                      background-color: #fff;
                      margin-right: 16.3px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-orient: vertical;
                      -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                      flex-direction: column;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;

                      > {
                        p {
                          font-size: 36px;
                          font-weight: 400;
                          line-height: 36px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                          -webkit-transform: translateY(3px);
                          -ms-transform: translateY(3px);
                          transform: translateY(3px);
                        }

                        .text-row {
                          font-size: 10px;
                          font-weight: 300;
                          line-height: 15px;
                          text-align: center;
                          color: var(--oliver-base-blue);

                          &.no-opacity {
                            opacity: 0;
                          }
                        }
                      }

                      &:first-of-type {
                        margin-left: auto;
                      }

                      &:last-of-type {
                        margin-right: 0;
                      }

                      &.backspace {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        background-color: transparent;

                        > img {
                          width: 50.38px;
                          height: 40.09px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .open-register-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--oliver-base-blue);
    overflow: auto;
    line-height: 0;

    > {
      #cancel,
      #goBack {
        position: absolute;
        left: 10px;
        top: 10px;
        display: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 132.1px;
        height: 52px;
        border: 0;
        background-color: transparent;

        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #fff;
      }

      #cancel > img,
      #goBack > img {
        width: 8.2px;
        height: 16.4px;
        margin-right: 8px;
      }

      #logout-landscape {
        position: absolute;
        right: 10px;
        top: 10px;
        display: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 132.1px;
        height: 52px;
        border: 0;
        background-color: transparent;

        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #fff;

        > img {
          width: 24px;
          height: 24px;
          margin-right: 7px;
        }
      }
    }

    &.state-1 > {
      #logout-landscape,
      #cancel {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    }

    &.state-2 > #goBack,
    &.state-3 > #goBack {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    > {
      #logout-mobile {
        display: none;
      }

      header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > img {
          width: 276.68px;
          height: 253px;
          margin-bottom: 20px;
        }

        .col {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }

        p {
          max-width: 30vw;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &.style1 {
            font-size: 48px;
            font-weight: 300;
            line-height: 69px;
            text-align: center;
            color: #fff;
            margin-bottom: 10px;
          }
        }

        .divider {
          width: 261px;
          height: 1px;
          background-color: #fff;
          margin-bottom: 14px;
        }

        p {
          &.style2 {
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            color: #fff;
          }

          &.style3 {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            text-align: center;
            color: #fff;
          }
        }
      }

      main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > {
          * {
            width: 348px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-left: 159px;

            &.hidden {
              display: none !important;
            }
          }

          .step1 {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            > {
              p {
                font-size: 24px;
                font-weight: 400;
                line-height: 35px;
                text-align: center;
                color: #fff;
                margin-bottom: 17px;
              }

              button {
                width: 347px;
                height: 50px;
                border: 0;
                border-radius: 6px;
                background-color: var(--oliver-blue);

                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
              }
            }
          }

          .step2 {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            > {
              p {
                font-size: 24px;
                font-weight: 500;
                line-height: 35px;
                text-align: left;
                color: #fff;
                margin-bottom: 24px;
              }

              label {
                width: 348px;

                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
                margin-bottom: 9px;
                color: #fff;
              }

              input {
                width: 348px;
                height: 83px;
                border: 0;
                border-radius: 6px;
                outline: 0;

                font-size: 40px;
                font-weight: 400;
                line-height: 56px;
                text-align: center;
                color: var(--oliver-base-blue);
                margin-bottom: 24px;
              }

              button {
                width: 348px;
                height: 50px;

                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #fff;
                border-radius: 6px;

                &#openCashDrawer {
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  border: 1px solid #fff;
                  background-color: transparent;
                  margin-bottom: 24px;
                }

                &#openFloatButton {
                  border: 0;
                  background-color: var(--oliver-blue);
                }
              }

              textarea {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 347px;
                height: 83px;
                outline: 0;
                resize: none;
                padding: 12px;
                border: 0;
                border-radius: 6px;
                margin-bottom: 24px;

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
              }
            }
          }

          .step3 {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            > {
              p {
                font-size: 20px;
                font-weight: 400;
                line-height: 29px;
                text-align: center;
                color: #fff;
                margin: 25px;
              }

              .error-message {
                width: 276px;
                height: 42px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #ffe7e8;
                border-radius: 6px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-bottom: 18px;

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: var(--error);
              }

              .pinpad {
                width: 276px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;

                > {
                  .pin-entries {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    margin-bottom: 17.06px;

                    > .pin-entry {
                      width: 15px;
                      height: 15px;
                      background-color: #fff;
                      border-radius: 50%;
                      margin-left: 9.42px;

                      &:nth-child(1) {
                        margin-left: 0;
                      }

                      &.entered {
                        background-color: var(--oliver-blue);
                      }
                    }

                    &.incorrect {
                      -webkit-animation: shake 0.3s;
                      animation: shake 0.3s;

                      > .pin-entry {
                        background-color: var(--oliver-red);
                      }
                    }
                  }

                  .pin-button-row {
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin-top: 16.3px;

                    &:nth-child(2) {
                      margin-top: 0;
                    }

                    > button {
                      width: 81.51px;
                      height: 81.51px;
                      border: 0;
                      border-radius: 8px;
                      background-color: #fff;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-orient: vertical;
                      -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                      flex-direction: column;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;
                      margin-right: 15.73px;

                      > {
                        p {
                          font-size: 36px;
                          font-weight: 400;
                          line-height: 36px;
                          text-align: center;
                          color: var(--oliver-base-blue);
                          -webkit-transform: translateY(3px);
                          -ms-transform: translateY(3px);
                          transform: translateY(3px);
                        }

                        .text-row {
                          font-size: 10px;
                          font-weight: 300;
                          line-height: 15px;
                          text-align: center;
                          color: var(--oliver-base-blue);

                          &.no-opacity {
                            opacity: 0;
                          }
                        }
                      }

                      &:last-of-type {
                        margin-right: 0;
                      }

                      &.backspace {
                        background-color: transparent;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;

                        > img {
                          width: 50.38px;
                          height: 40.09px;
                        }
                      }
                    }
                  }
                }

                &:last-child > .pin-button-row > button:first-of-type {
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .open-register-wrapper > {
    #cancel,
    #goBack {
      left: 0.4883vw;
      top: 0.4883vw;
      width: 6.4502vw;
      height: 2.5391vw;
      font-size: 0.9766vw;
      line-height: 1.4648vw;
    }

    #cancel > img,
    #goBack > img {
      width: 0.4004vw;
      height: 0.8008vw;
      margin-right: 0.3906vw;
    }

    #logout-landscape {
      right: 0.4883vw;
      top: 0.4883vw;
      width: 6.4502vw;
      height: 2.5391vw;
      font-size: 0.9766vw;
      line-height: 1.4648vw;

      > img {
        width: 1.1719vw;
        height: 1.1719vw;
        margin-right: 0.3418vw;
      }
    }

    header {
      > img {
        width: 13.5098vw;
        height: 12.3535vw;
        margin-bottom: 0.9766vw;
      }

      p.style1 {
        font-size: 2.3438vw;
        line-height: 3.3691vw;
        margin-bottom: 0.4883vw;
      }

      .divider {
        width: 12.7441vw;
        height: 0.0488vw;
        margin-bottom: 0.6836vw;
      }

      p {
        &.style2 {
          font-size: 1.1719vw;
          line-height: 1.7578vw;
        }

        &.style3 {
          font-size: 0.8789vw;
          line-height: 1.3672vw;
        }
      }
    }

    main > {
      * {
        width: 16.9922vw;
        margin-left: 7.7637vw;
      }

      .step1 > {
        p {
          font-size: 1.1719vw;
          line-height: 1.709vw;
          margin-bottom: 0.8301vw;
        }

        button {
          width: 16.9434vw;
          height: 2.4414vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }

      .step2 > {
        p {
          font-size: 1.1719vw;
          line-height: 1.709vw;
          margin-bottom: 1.1719vw;
        }

        label {
          width: 16.9922vw;
          font-size: 0.7813vw;
          line-height: 1.1719vw;
          margin-bottom: 0.4395vw;
        }

        input {
          width: 16.9922vw;
          height: 4.0527vw;
          border-radius: 0.293vw;
          font-size: 1.9531vw;
          line-height: 2.7344vw;
          margin-bottom: 1.1719vw;
        }

        button {
          width: 16.9922vw;
          height: 2.4414vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
          border-radius: 0.293vw;

          &#openCashDrawer {
            border: 0.0488vw solid #fff;
            margin-bottom: 1.1719vw;
          }
        }

        textarea {
          width: 16.9434vw;
          height: 4.0527vw;
          padding: 0.5859vw;
          border-radius: 0.293vw;
          margin-bottom: 1.1719vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }
      }

      .step3 > {
        p {
          font-size: 0.9766vw;
          line-height: 1.416vw;
          margin: 1.2207vw;
        }

        .error-message {
          width: 13.4766vw;
          height: 2.0508vw;
          border-radius: 0.293vw;
          margin-bottom: 0.8789vw;
          font-size: 0.6836vw;
          line-height: 0.9766vw;
        }

        .pinpad {
          width: 13.4766vw;

          > {
            .pin-entries {
              margin-bottom: 0.833vw;

              > .pin-entry {
                width: 0.7324vw;
                height: 0.7324vw;
                margin-left: 0.46vw;
              }
            }

            .pin-button-row {
              margin-top: 0.7959vw;

              > button {
                width: 3.98vw;
                height: 3.98vw;
                border-radius: 0.3906vw;
                margin-right: 0.7681vw;

                > {
                  p {
                    font-size: 1.7578vw;
                    line-height: 1.7578vw;
                    -webkit-transform: translateY(0.1465vw);
                    -ms-transform: translateY(0.1465vw);
                    transform: translateY(0.1465vw);
                  }

                  .text-row {
                    font-size: 0.4883vw;
                    line-height: 0.7324vw;
                  }
                }

                &.backspace > img {
                  width: 2.46vw;
                  height: 1.9575vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
