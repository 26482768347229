

@media (orientation: portrait) {
  .cv-detailed > {
    .mobile-back {
      width: 100%;
      height: 53px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 0 18px;

      > button {
        padding: 0 3px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        background-color: transparent;
        border: 0;
        
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: var(--oliver-blue);

        > img {
          width: 9.1px;
          height: 16.2px;
          margin-right: 8px;
        }
      }
    }

    .quick-info {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 16px 18px;

      /* margin-bottom: 17px; */
      background-color: #e9f5fd;

      > {
        .avatar {
          width: 36px;
          height: 36px;
          background-color: var(--oliver-blue);
          margin: 4px 15px 0 0;
        }

        .text-group {
          margin-right: auto;

          > p {
            &.style1 {
              
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              color: var(--oliver-base-blue);
            }

            &.style2 {
              
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              text-align: left;
              color: var(--oliver-base-blue);
            }
          }
        }

        button {
          width: 70px;
          height: 36px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          background-color: #fff;
          border: 1px solid var(--oliver-blue);
          border-radius: 8px;
          
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: var(--oliver-blue);

          > img {
            width: 15px;
            height: 16px;
            margin-right: 5px;
          }
        }
      }
    }

    .cust-totals {
      position: relative;
      width: calc(100% - 32px);
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 13px 0;
      margin: 0 16px;
      border-bottom: 1px solid var(--oliver-border);

      > .col {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        > {
          p {
            &.style1 {
              
              font-size: 16px;
              font-weight: 600;
              line-height: 25px;
              text-align: left;
              color: var(--oliver-base-blue);
              margin-right: auto;
            }

            &.style2 {
              
              font-size: 16px;
              font-weight: 400;
              line-height: 25px;
              text-align: left;
              color: var(--oliver-base-blue);
              margin-right: 8px;

              &::after {
                content: ":";
              }
            }
          }

          button {
            position: absolute;
            right: 0;
            background-color: transparent;
            border: 0;
            
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: right;
            color: var(--oliver-blue);
            text-decoration: underline;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            &::before {
              content: "Adjust Credit";
            }

            > img {
              width: 16px;
              height: 16px;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .bill-ship-info {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      width: calc(100% - 32px);
      border-bottom: 1px solid var(--oliver-border);
      padding: 15px 0;
      margin: 0 16px;

      > .col {
        > p.style1 {
          
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: var(--oliver-base-blue);
          margin-bottom: 5px;
          margin-top: 17px;
        }

        &:nth-child(1) > p.style1 {
          margin-top: 0;
        }

        > p.style2 {
          
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: var(--oliver-base-blue);
        }
      }
    }

    .cust-notes {
      width: calc(100% - 32px);
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 13px 0;
      margin: 0 16px;

      > {
        .header-row {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin-bottom: 13px;

          > {
            p {
              
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
              margin-right: auto;
            }

            button {
              background-color: transparent;
              border: 0;
              
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: right;
              color: var(--oliver-blue);
              text-decoration: underline;
            }
          }
        }

        .customer-note {
          width: 100%;
          margin-bottom: 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;

          > {
            .row {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;

              > {
                p {
                  &.style1 {
                    
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-grey);
                    margin-right: 16px;
                  }

                  &.style2 {
                    
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: var(--oliver-base-grey);
                    margin-right: auto;
                  }
                }

                button {
                  width: 17px;
                  height: 17px;
                  background-color: transparent;
                  border: 0;

                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            p {
              
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-blue);
            }
          }
        }
      }
    }

    .footer {
      width: calc(100% - 32px);
      height: 85px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-top: 1px solid var(--oliver-border);
      margin-top: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;

      > button {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        height: 50px;
        background-color: var(--oliver-blue);
        border: 0;
        border-radius: 6px;
        
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        color: #fff;

        &:first-of-type {
          margin-right: 15px;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .cv-detailed {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 23px;
    overflow: auto;

    > {
      .no-search-results-detailed {
        display: none;
        margin: auto;

        &:only-child {
          display: block;
        }

        > p {
          &.style1 {
            
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            color: var(--oliver-base-grey);
            margin-bottom: 10px;
          }

          &.style2 {
            
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: var(--oliver-base-grey);
          }
        }
      }

      .mobile-back {
        display: none;
      }

      .quick-info {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 15px;
        margin: 20px 0 17px 0;
        background-color: #e9f5fd;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;

        > {
          .avatar {
            width: 40px;
            height: 40px;
            background-color: var(--oliver-blue);
            margin: 4px 19px 0 0;
          }

          .text-group {
            margin-right: auto;

            > p {
              &.style1 {
                
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: var(--oliver-base-blue);
              }

              &.style2 {
                
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                text-align: left;
                color: var(--oliver-base-blue);
              }
            }
          }

          button {
            width: 70px;
            height: 36px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            background-color: #fff;
            border: 1px solid var(--oliver-blue);
            border-radius: 8px;
            
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: var(--oliver-blue);

            > img {
              width: 15px;
              height: 16px;
              margin-right: 5px;
            }
          }
        }
      }

      .cust-totals {
        width: 100%;
        height: 83px;
        padding-bottom: 22px;
        border-bottom: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        > .col {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          border-left: 1px solid var(--oliver-border);
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;

          &:nth-child(1) {
            border: 0;
          }

          > p {
            &.style1 {
              
              font-size: 22px;
              font-weight: 600;
              line-height: 27px;
              text-align: center;
              color: var(--oliver-base-blue);
              margin-bottom: 4px;
            }

            &.style2 {
              
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: center;
              color: var(--oliver-base-blue);
            }
          }

          &:last-child > p.style2 {
            display: none;
          }

          > button {
            background-color: transparent;
            border: 0;
            
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: var(--oliver-blue);
            text-decoration: underline;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            &:before {
              content: "Store Credit";
            }

            > img {
              width: 16px;
              height: 16px;
              margin-left: 5px;
            }
          }
        }
      }

      .bill-ship-info {
        width: 100%;
        padding: 20px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-bottom: 1px solid var(--oliver-border);

        > .col {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;

          &:nth-child(1) {
            margin-right: 40px;
          }

          > p {
            &.style1 {
              
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
              margin-bottom: 8px;
            }

            &.style2 {
              width: 100%;
              
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: var(--oliver-base-blue);
            }
          }
        }
      }

      .cust-notes {
        width: calc(100% + 46px);
        height: 0;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        min-height: 100px;
        overflow: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 20px 0;
        margin: 0 -23px;

        > {
          .header-row {
            width: calc(100% - 46px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            margin-bottom: 11px;

            > {
              p {
                
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                color: var(--oliver-base-blue);
              }

              button {
                background-color: transparent;
                border: 0;
                
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                text-align: right;
                color: var(--oliver-blue);
                text-decoration: underline;
              }
            }
          }

          .customer-note {
            width: calc(100% - 46px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            margin-top: 20px;

            &:nth-child(2) {
              margin-top: 0;
            }

            > {
              .row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;

                > {
                  p {
                    &.style1 {
                      
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 20px;
                      text-align: left;
                      color: var(--oliver-base-grey);
                      margin-right: auto;
                    }

                    &.style2 {
                      
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: right;
                      color: var(--oliver-base-blue);
                      margin-right: 6px;
                    }
                  }

                  button {
                    width: 17px;
                    height: 17px;
                    background-color: transparent;
                    border: 0;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    -webkit-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;

                    > img {
                      width: 16px;
                      height: 16px;
                    }

                    &:hover > img {
                      -webkit-filter: brightness(0) saturate(100%) invert(39%) sepia(60%) saturate(1129%) hue-rotate(317deg) brightness(105%) contrast(101%);
                              filter: brightness(0) saturate(100%) invert(39%) sepia(60%) saturate(1129%) hue-rotate(317deg) brightness(105%) contrast(101%);
                    }
                  }
                }
              }

              p {
                
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: var(--oliver-base-blue);
                margin: 10px 6px 0 10px;
              }
            }
          }
        }
      }

      .footer {
        width: 100%;
        height: 74px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-top: 1px solid var(--oliver-border);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        > button {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
          height: 44px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #fff;

          &:first-of-type {
            margin-right: 13px;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .cv-detailed {
    padding: 0 1.123vw;

    > {
      .no-search-results-detailed > p {
        &.style1 {
          font-size: 0.6836vw;
          line-height: 0.9766vw;
          margin-bottom: 0.4883vw;
        }

        &.style2 {
          font-size: 0.6348vw;
          line-height: 0.8789vw;
        }
      }

      .quick-info {
        padding: 0.7324vw;
        margin: 0.9766vw 0 0.8301vw 0;

        > {
          .avatar {
            width: 1.9531vw;
            height: 1.9531vw;
            margin: 0.1953vw 0.9277vw 0 0;
          }

          .text-group > p {
            &.style1 {
              font-size: 0.7813vw;
              line-height: 1.1719vw;
            }

            &.style2 {
              font-size: 0.5859vw;
              line-height: 0.8301vw;
            }
          }

          button {
            width: 3.418vw;
            height: 1.7578vw;
            border: 0.0488vw solid var(--oliver-blue);
            border-radius: 0.3906vw;
            font-size: 0.6836vw;
            line-height: 0.9766vw;

            > img {
              width: 0.7324vw;
              height: 0.7813vw;
              margin-right: 0.2441vw;
            }
          }
        }
      }

      .cust-totals {
        height: 4.0527vw;
        padding-bottom: 1.0742vw;
        border-bottom: 0.0488vw solid var(--oliver-border);

        > .col {
          border-left: 0.0488vw solid var(--oliver-border);

          > {
            p {
              &.style1 {
                font-size: 1.0742vw;
                line-height: 1.3184vw;
                margin-bottom: 0.1953vw;
              }

              &.style2 {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
              }
            }

            button {
              font-size: 0.6836vw;
              line-height: 0.9766vw;

              > img {
                width: 0.7813vw;
                height: 0.7813vw;
                margin-left: 0.2441vw;
              }
            }
          }
        }
      }

      .bill-ship-info {
        padding: 0.9766vw 0;
        border-bottom: 0.0488vw solid var(--oliver-border);

        > .col {
          &:nth-child(1) {
            margin-right: 1.9531vw;
          }

          > p {
            &.style1 {
              font-size: 0.7813vw;
              line-height: 1.1719vw;
              margin-bottom: 0.3906vw;
            }

            &.style2 {
              font-size: 0.6836vw;
              line-height: 0.9766vw;
            }
          }
        }
      }

      .cust-notes {
        width: calc(100% + 2.2461vw);
        min-height: 4.8828vw;
        padding: 0.9766vw 0;
        margin: 0 -1.123vw;

        > {
          .header-row {
            width: calc(100% - 2.2461vw);
            margin-bottom: 0.5371vw;

            > {
              p {
                font-size: 0.7813vw;
                line-height: 1.1719vw;
              }

              button {
                font-size: 0.6836vw;
                line-height: 1.0254vw;
              }
            }
          }

          .customer-note {
            width: calc(100% - 2.2461vw);
            margin-top: 0.9766vw;

            > {
              .row > {
                p {
                  &.style1 {
                    font-size: 0.6836vw;
                    line-height: 0.9766vw;
                  }

                  &.style2 {
                    font-size: 0.6836vw;
                    line-height: 0.9766vw;
                    margin-right: 0.293vw;
                  }
                }

                button {
                  width: 0.8301vw;
                  height: 0.8301vw;

                  > img {
                    width: 0.7813vw;
                    height: 0.7813vw;
                  }
                }
              }

              p {
                font-size: 0.6836vw;
                line-height: 0.9766vw;
                margin: 0.4883vw 0.293vw 0 0.4883vw;
              }
            }
          }
        }
      }

      .footer {
        height: 3.6133vw;
        border-top: 0.0488vw solid var(--oliver-border);

        > button {
          height: 2.1484vw;
          border-radius: 0.293vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;

          &:first-of-type {
            margin-right: 0.6348vw;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  .cv-detailed {
    padding: 0 1.9167vw;

    > {
      .no-search-results-detailed > p {
        &.style1 {
          font-size: 1.1667vw;
          line-height: 1.6667vw;
          margin-bottom: 0.8333vw;
        }

        &.style2 {
          font-size: 1.0833vw;
          line-height: 1.5vw;
        }
      }

      .quick-info {
        padding: 1.25vw;
        margin: 1.6667vw 0 1.4167vw 0;

        > {
          .avatar {
            width: 3.3333vw;
            height: 3.3333vw;
            margin: 0.3333vw 1.5833vw 0 0;
          }

          .text-group > p {
            &.style1 {
              font-size: 1.3333vw;
              line-height: 2vw;
            }

            &.style2 {
              font-size: 1vw;
              line-height: 1.4167vw;
            }
          }

          button {
            width: 5.8333vw;
            height: 3vw;
            border: 0.0833vw solid var(--oliver-blue);
            border-radius: 0.6667vw;
            font-size: 1.1667vw;
            line-height: 1.6667vw;

            > img {
              width: 1.25vw;
              height: 1.3333vw;
              margin-right: 0.4167vw;
            }
          }
        }
      }

      .cust-totals {
        height: 6.9167vw;
        padding-bottom: 1.8333vw;
        border-bottom: 0.0833vw solid var(--oliver-border);

        > .col {
          border-left: 0.0833vw solid var(--oliver-border);

          > {
            p {
              &.style1 {
                font-size: 1.8333vw;
                line-height: 2.25vw;
                margin-bottom: 0.3333vw;
              }

              &.style2 {
                font-size: 1.1667vw;
                line-height: 1.6667vw;
              }
            }

            button {
              font-size: 1.1667vw;
              line-height: 1.6667vw;

              > img {
                width: 1.3333vw;
                height: 1.3333vw;
                margin-left: 0.4167vw;
              }
            }
          }
        }
      }

      .bill-ship-info {
        padding: 1.6667vw 0;
        border-bottom: 0.0833vw solid var(--oliver-border);

        > .col {
          &:nth-child(1) {
            margin-right: 3.3333vw;
          }

          > p {
            &.style1 {
              font-size: 1.3333vw;
              line-height: 2vw;
              margin-bottom: 0.6667vw;
            }

            &.style2 {
              font-size: 1.1667vw;
              line-height: 1.6667vw;
            }
          }
        }
      }

      .cust-notes {
        width: calc(100% + 3.8333vw);
        min-height: 8.3333vw;
        padding: 1.6667vw 0;
        margin: 0 -1.9167vw;

        > {
          .header-row {
            width: calc(100% - 3.8333vw);
            margin-bottom: 0.9167vw;

            > {
              p {
                font-size: 1.3333vw;
                line-height: 2vw;
              }

              button {
                font-size: 1.1667vw;
                line-height: 1.75vw;
              }
            }
          }

          .customer-note {
            width: calc(100% - 3.8333vw);
            margin-top: 1.6667vw;

            > {
              .row > {
                p {
                  &.style1 {
                    font-size: 1.1667vw;
                    line-height: 1.6667vw;
                  }

                  &.style2 {
                    font-size: 1.1667vw;
                    line-height: 1.6667vw;
                    margin-right: 0.5vw;
                  }
                }

                button {
                  width: 1.4167vw;
                  height: 1.4167vw;

                  > img {
                    width: 1.3333vw;
                    height: 1.3333vw;
                  }
                }
              }

              p {
                font-size: 1.1667vw;
                line-height: 1.6667vw;
                margin: 0.8333vw 0.5vw 0 0.8333vw;
              }
            }
          }
        }
      }

      .footer {
        height: 6.1667vw;
        border-top: 0.0833vw solid var(--oliver-border);

        > button {
          height: 3.6667vw;
          border-radius: 0.5vw;
          font-size: 1.5vw;
          line-height: 2vw;

          &:first-of-type {
            margin-right: 1.0833vw;
          }
        }
      }
    }
  }
}