@media (orientation: portrait) {
  .subwindow.knowledge-base {
    width: 100%;
    height: 100%;
    border-radius: 0;

    > {
      .subwindow-header {
        height: 68px;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 0 50px 0 16px;

        > {
          .icon-container {
            width: 45px;
            height: 45px;
            border-radius: 8px;
            overflow: hidden;
            margin-right: 14px;

            > img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
            }
          }

          p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          button {
            &#kBRefresh {
              width: 34px;
              height: 34px;
              border-radius: 8px;
              border: 0;
              background-color: #d1ecff;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > {
                img {
                  width: 20px;
                  height: 20px;
                  -webkit-filter: brightness(0) saturate(100%) invert(27%)
                    sepia(15%) saturate(1200%) hue-rotate(179deg)
                    brightness(91%) contrast(84%);
                  filter: brightness(0) saturate(100%) invert(27%) sepia(15%)
                    saturate(1200%) hue-rotate(179deg) brightness(91%)
                    contrast(84%);
                }

                p {
                  display: none;
                }
              }
            }

            &.close-subwindow {
              top: calc(50% - 16px);
              width: 34px;
              height: 34px;
              border-radius: 8px;
              background-color: #d1ecff;
            }
          }
        }
      }

      .subwindow-body {
        padding: 0;

        > iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow.knowledge-base {
    width: min(1000px, 95%);
    height: min(794px, 95%);

    > {
      .subwindow-header {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 66px 0 19px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        > {
          .icon-container {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            overflow: hidden;
            margin-right: 17px;

            > img {
              width: 100%;
              height: 100%;
            }
          }

          p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: var(--oliver-base-blue);
            margin-right: auto;
          }

          button#kBRefresh {
            width: 128px;
            height: 32px;
            border-radius: 6px;
            background-color: transparent;
            border: 1px solid var(--oliver-base-grey);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-family: Poppins;
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            text-align: center;
            color: var(--oliver-base-grey);

            > img {
              width: 13px;
              height: 13px;
              margin-right: 6px;
            }
          }
        }
      }

      .subwindow-body {
        overflow: hidden;
        padding: 0;

        > iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow.knowledge-base {
    width: min(48.8281vw, 95%);
    height: min(38.7695vw, 95%);

    > .subwindow-header {
      padding: 0 3.2227vw 0 0.9277vw;

      > {
        .icon-container {
          width: 1.9531vw;
          height: 1.9531vw;
          border-radius: 0.3906vw;
          margin-right: 0.8301vw;
        }

        p {
          font-size: 0.7813vw;
          line-height: 1.1719vw;
        }

        button#kBRefresh {
          width: 6.25vw;
          height: 1.5625vw;
          border-radius: 0.293vw;
          border: 0.0488vw solid var(--oliver-base-grey);
          font-size: 0.6348vw;
          line-height: 0.8301vw;

          > img {
            width: 0.6348vw;
            height: 0.6348vw;
            margin-right: 0.293vw;
          }
        }
      }
    }
  }
}
