@media (orientation: portrait) {
  .subwindow-wrapper > .subwindow.cash-payment {
    &.step-1 {
      width: min(384px, 95%);
      height: min(666px, 95%);
    }

    &.step-2 {
      width: min(420px, 95%);
      height: 400px;
    }

    > .subwindow-body > .step1 {
      width: 100%;
      height: 100%;
      display: none;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      overflow-y: scroll;
    }

    &.step-1 > .subwindow-body > .step1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    > .subwindow-body > {
      .step1 > {
        .text-row {
          width: 321px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 19px;

          > p {
            &.style1 {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              text-align: left;
              color: var(--oliver-base-blue);
              margin-right: auto;
            }

            &.style2 {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              text-align: right;
              color: var(--oliver-base-blue);
            }
          }
        }

        .input-numpad {
          padding: 16px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          border-radius: 8px;
          background-color: rgba(212, 222, 229, 0.5);
          margin-bottom: 14px;

          > {
            .input-container {
              width: 289px;
              height: 93px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              background-color: #fff;
              border-radius: 6px;
              margin-bottom: 9px;

              > {
                label {
                  font-family: Poppins;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  text-align: center;
                  color: var(--oliver-base-grey);
                  margin-bottom: 11px;
                }

                input {
                  width: 193px;
                  height: 42px;
                  outline: 0;
                  border: 0;
                  background-color: transparent;
                  font-family: Poppins;
                  font-size: 40px;
                  font-weight: 400;
                  line-height: 40px;
                  text-align: center;
                  color: var(--oliver-blue);
                }
              }
            }

            #numpad2 {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;

              > .button-row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-top: 9px;

                &:nth-child(1) {
                  margin: 0;
                }

                > button {
                  width: 90px;
                  height: 68px;
                  border: 1px solid var(--oliver-border);
                  border-radius: 6px;
                  margin-left: 9px;
                  background-color: #fff;
                  font-family: Poppins;
                  font-size: 30px;
                  font-weight: 400;
                  line-height: 45px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  &:first-of-type {
                    margin-left: 0;
                  }

                  &.backspace > img {
                    width: 40px;
                    height: 40px;
                  }
                }
              }
            }
          }
        }

        button {
          width: 320px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;

          &:disabled {
            background-color: var(--oliver-border);
          }
        }
      }

      .step2 {
        width: 100%;
        height: 100%;
        display: none;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        overflow-y: scroll;
      }
    }

    &.step-2 > .subwindow-body > .step2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      > {
        .text-row {
          width: 306px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding-top: 15px;
          margin-top: 15px;
          border-top: 1px solid var(--oliver-border);

          &:nth-child(2) {
            border: 0;
            padding: 0;
            margin-top: 0;
            padding-top: 0;
          }

          > p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            color: var(--oliver-base-blue);

            > b {
              font-weight: 600;
            }
          }
        }

        button {
          width: 306px;
          height: 50px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          margin-top: 26px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .subwindow-wrapper > .subwindow.cash-payment {
    &.step-1 {
      width: 504px;
      height: min(709px, 95%);
    }

    &.step-2 {
      width: 504px;
      height: 500px;
    }

    > .subwindow-body > .step1 {
      width: 100%;
      height: 100%;
      display: none;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      overflow-y: scroll;
    }

    &.step-1 > .subwindow-body > .step1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    > .subwindow-body > {
      .step1 > {
        .text-row {
          width: 321px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 26px;

          > p {
            &.style1 {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: var(--oliver-base-blue);
              margin-right: auto;
            }

            &.style2 {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 26px;
              text-align: right;
              color: var(--oliver-base-blue);
            }
          }
        }

        .input-numpad {
          padding: 16px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          border-radius: 8px;
          background-color: rgba(212, 222, 229, 0.5);
          margin-bottom: 18px;

          > {
            .input-container {
              width: 289px;
              height: 93px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              background-color: #fff;
              border-radius: 6px;
              margin-bottom: 9px;

              > {
                label {
                  font-family: Poppins;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  text-align: center;
                  color: var(--oliver-base-grey);
                  margin-bottom: 10px;
                }

                input {
                  width: 193px;
                  height: 42px;
                  outline: 0;
                  border: 0;
                  background-color: transparent;
                  font-family: Poppins;
                  font-size: 40px;
                  font-weight: 400;
                  line-height: 40px;
                  text-align: center;
                  color: var(--oliver-blue);
                }
              }
            }

            #numpad2 {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;

              > .button-row {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-top: 9px;

                &:nth-child(1) {
                  margin: 0;
                }

                > button {
                  width: 90px;
                  height: 68px;
                  border: 1px solid var(--oliver-border);
                  border-radius: 6px;
                  margin-left: 9px;
                  background-color: #fff;
                  font-family: Poppins;
                  font-size: 30px;
                  font-weight: 400;
                  line-height: 45px;
                  text-align: center;
                  color: var(--oliver-base-blue);
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;

                  &:first-of-type {
                    margin-left: 0;
                  }

                  &.backspace > img {
                    width: 40px;
                    height: 40px;
                  }
                }
              }
            }
          }
        }

        button {
          width: 321px;
          height: 60px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;

          &:disabled {
            background-color: var(--oliver-border);
          }
        }
      }

      .step2 {
        width: 100%;
        height: 100%;
        display: none;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        overflow-y: scroll;
      }
    }

    &.step-2 > .subwindow-body > .step2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      > {
        .text-row {
          width: 370px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding-top: 20px;
          margin-top: 20px;
          border-top: 1px solid var(--oliver-border);

          &:nth-child(2) {
            border: 0;
            padding: 0;
            margin-top: 0;
          }

          > p {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: var(--oliver-base-blue);

            > b {
              font-weight: 600;
            }
          }
        }

        button {
          width: 370px;
          height: 60px;
          background-color: var(--oliver-blue);
          border: 0;
          border-radius: 6px;
          margin-top: 50px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 2048px) {
  .subwindow-wrapper > .subwindow.cash-payment {
    &.step-1 {
      width: 24.6094vw;
      height: min(34.6191vw, 95%);
    }

    &.step-2 {
      width: 24.6094vw;
      height: 24.4141vw;

      > .subwindow-body > .step2 > {
        .text-row {
          width: 18.0664vw;
          padding-top: 0.9766vw;
          margin-top: 0.9766vw;
          border-top: 0.0488vw solid var(--oliver-border);

          > p {
            font-size: 0.8789vw;
            line-height: 1.1719vw;
          }
        }

        button {
          width: 18.0664vw;
          height: 2.9297vw;
          border-radius: 0.293vw;
          margin-top: 2.4414vw;
          font-size: 0.8789vw;
          line-height: 1.1719vw;
        }
      }
    }

    > .subwindow-body > .step1 > {
      .text-row {
        width: 15.6738vw;
        margin-bottom: 1.2695vw;

        > p {
          &.style1 {
            font-size: 0.8789vw;
            line-height: 1.1719vw;
          }

          &.style2 {
            font-size: 0.9766vw;
            line-height: 1.2695vw;
          }
        }
      }

      .input-numpad {
        padding: 0.7813vw;
        border-radius: 0.3906vw;
        margin-bottom: 0.8789vw;

        > {
          .input-container {
            width: 14.1113vw;
            height: 4.541vw;
            border-radius: 0.293vw;
            margin-bottom: 0.4395vw;

            > {
              label {
                font-size: 0.5859vw;
                line-height: 0.8301vw;
                margin-bottom: 0.4883vw;
              }

              input {
                width: 9.4238vw;
                height: 2.0508vw;
                font-size: 1.9531vw;
                line-height: 1.9531vw;
              }
            }
          }

          #numpad2 > .button-row {
            margin-top: 0.4395vw;

            > button {
              width: 4.3945vw;
              height: 3.3203vw;
              border: 0.0488vw solid var(--oliver-border);
              border-radius: 0.293vw;
              margin-left: 0.4395vw;
              font-size: 1.4648vw;
              line-height: 2.1973vw;

              &.backspace > img {
                width: 1.9531vw;
                height: 1.9531vw;
              }
            }
          }
        }
      }

      button {
        width: 15.6738vw;
        height: 2.9297vw;
        border-radius: 0.293vw;
        font-size: 0.8789vw;
        line-height: 1.1719vw;
      }
    }
  }
}
